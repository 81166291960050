<p-toast pRipple [style]="{marginTop: 'marginTop'}"  key="confirm" position="top-right"  [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column align-items-start">
            <h1>
                {{message.summary}}
            </h1>
            <p-button size="small" (click)="reload()" icon="pi pi-refresh" label="Recargar"></p-button>
        </div>
    </ng-template>
</p-toast>
<router-outlet></router-outlet>
