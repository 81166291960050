<br>

<!-- FORM FIELDS -->
<p-panel>
    <div style="display: flex;">
        <h4>Datos de Subcontratos</h4>
        
    </div>
        <app-form-builder
            [model]="model"
            (formChanges)="formChanges()"
            [distributionColumns]="1" 
            [distributionOrder]="distributionCol" 
            [fields]="formFields">
        </app-form-builder>
                                 
        <br>
              
           
    <br>
    
   
    <!-- ACTION BUTTONS -->
    <div class="d-flex justify-content-center">
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>
<br>
