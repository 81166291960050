<p-panel>
  <div style="display: flex; flex-direction: column; min-height: 80vh;">
      <div style="border-bottom: 1px solid #cacaca; margin-bottom: 2rem;">
          <div class="row" style="align-items: center; margin-bottom: 1rem;">
              <div class="col-lg-2">
                  <div class="form-group">
                      <label for="">DNI/CIF: </label>
                      <input maxlength="9" class="form-control" type="text" [(ngModel)]="dni">
                  </div>
              </div>
              <div class="col-lg-2">
                  <div class="form-group">
                      <button pButton label="Buscar" (click)="dni.length === 9 ? buscaDni() : null"
                          class="btn" style="padding: 0.2rem; font-size: 1rem; margin-top: 30px; background-color: #27A745; color:white"
                          icon="pi pi-search" iconPos="left">
                      </button>

                  </div>
              </div>

              <div *ngIf="dni && dni.length !== 9" class="col-lg-3" style="margin-top: 40px; color: red;">
                <div class="form-group">
                    <span><i class="fa fa-exclamation-circle" aria-hidden="true"></i> El DNI/CIF debe tener 9 caracteres</span>
                </div>
              </div>

              <div *ngIf="!localizado && dni.length === 9" class="col-lg-2" style="margin-top: 40px;">
                  <div class="form-group">
                      <span><i style="font-size: 30px !important;" class="fa fa-spinner fa-spin fa-2"
                              aria-hidden="true"></i> Buscando...</span>

                  </div>
              </div>
          </div>




          <p-dialog [(visible)]="showInforme" (onShow)="scrollToTop()">
              <div *ngIf="informe.length > 0" style="margin-left: 20px; margin-right: 20px; width: 800px !important;">
                  <!--     <div *ngIf="informe.length > 0" style="margin-left: 20px; margin-right: 20px; width: 90vw !important;"> -->
                  <div id="html2canvasDiv" class="registroDiv mx-auto" style="max-width: 800px;">
                      <div class="mb-4 text-left">
                          <img [src]="logoUrl" style="width: 150px; height: auto;" alt="Logo" />
                      </div>
                      <h5>Informe del aplicador con DNI {{dni}} a {{hoy}}</h5>
                      <br>
                      <p *ngIf="!showRopo">Sin datos de ROPO</p>
                      <h6 *ngIf="showRopo">Datos ROPO</h6>

                      <table *ngIf="showRopo"
                          class="table table-bordered table-striped table-hover table-receta tablas">
                          <thead>
                              <th>Código</th>
                              <th>Nombre</th>
                              <th>Nivel</th>
                              <th>Categoría</th>
                          </thead>
                          <tbody>
                              <tr *ngFor="let ropo of informeROPO">
                                  <td>{{ropo['código'] || ''}}</td>
                                  <td>{{ropo.nombre || ''}}</td>
                                  <td>{{ropo.nivel || ''}}</td>
                                  <td>{{ropo['categoría'] || ''}}</td>
                              </tr>
                          </tbody>
                      </table>
                      <br>

                      <p *ngIf="!showRegepa">Sin datos de REGEPA</p>
                      <h6 *ngIf="showRegepa">Datos REGEPA</h6>

                      <table *ngIf="showRegepa"
                          class="table table-bordered table-striped table-hover table-receta tablas">
                          <thead>
                              <th>Código</th>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>{{this.codigo_regepa || ''}}</td>

                              </tr>
                          </tbody>
                      </table>
                      <br>

                      <p *ngIf="!showRoma">Sin datos de ROMA</p>
                      <h6 *ngIf="showRoma">Datos ROMA</h6>

                      <table *ngIf="showRoma"
                          class="table table-bordered table-striped table-hover table-receta tablas">
                          <thead>
                              <th>Código</th>
                              <th>Tipo</th>
                              <th>Marca</th>
                              <th>Modelo</th>
                              <th>Estado</th>
                              <th>Fecha inspección</th>
                          </thead>
                          <tbody>
                              <tr *ngFor="let roma of informeROMA">
                                  <td>{{roma.codigo || ''}}</td>
                                  <td>{{roma.tipo || ''}}</td>
                                  <td>{{roma.marca || ''}}</td>
                                  <td>{{roma.modelo || ''}}</td>
                                  <td>{{roma.estado || ''}}</td>
                                  <td>{{roma.fecha_inspeccion || ''}}</td>
                              </tr>
                          </tbody>
                      </table>
                      <br>




                  </div>


              </div>
              <br>
              <div class="d-flex justify-content-center">
                  <button style="margin-right: 1rem" class="btn btn-dark mx-2" (click)="showInforme=false">
                      Cerrar
                  </button>
                  <!-- <button class="btn btn-primary mx-2" (click)="imprimirConsulta()" [disabled]="imprimirRopo"> -->
                  <button class="btn btn-primary mx-2" (click)="imprimirConsulta()" [disabled]="isGeneratingCanvas">
                    Imprimir Consulta
                  </button>

              </div>
          </p-dialog>




      </div>



  </div>
</p-panel>