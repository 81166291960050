
import {Injectable} from '@angular/core';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';

@Injectable({
    providedIn: 'root'
})
export class CamposApiService {

    public campos = {
        GET: new RequestHandler<ParcelasModel[]>(),
        POST: new RequestHandler<ParcelasModel[]>(),
        PUT: new RequestHandler<ParcelasModel[]>(),
        DELETE: new RequestHandler<ParcelasModel>()
    };    

    constructor(
        private syncService: SyncService<ParcelasModel[]>,
        private syncServiceDelete: SyncService<ParcelasModel>,
        private api: ApiRequestService<ParcelasModel[]>,
        private apiDelete: ApiRequestService<ParcelasModel>,
    ) {

        this.defineCampos();
    }

    private defineCampos() {
        this.campos.GET.fetch(HttpRequestType.GET, 'campos/get');
        this.campos.POST.fetch(HttpRequestType.POST, 'generic/campos');
        this.campos.PUT.fetch(HttpRequestType.PUT, 'generic/campos');
        this.campos.DELETE.fetch(HttpRequestType.DELETE, 'generic/campos');
    
        this.api.registerRequest(this.campos.GET);
        this.api.registerRequest(this.campos.POST);
        this.api.registerRequest(this.campos.PUT);
        this.apiDelete.registerRequest(this.campos.DELETE);
    
        this.syncService.register(this.syncService.baseSyncPolicy(this.campos.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.campos.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.campos.PUT));
        this.syncServiceDelete.register(this.syncServiceDelete.baseSyncPolicy(this.campos.DELETE));
    }
}
