import {Component } from '@angular/core';
import {BaseView} from '../../../base-view';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {Filtering} from '../../../../service/filtering/filtering';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {Utils} from '../../../../common/utils';
import { TrabajadoresApiService } from 'src/app/service/api/trabajadores-api.service';
import { TareasApiService } from 'src/app/service/api/tareas-api.service';
import { environment } from 'src/environments/environment';

import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { CosteModel } from 'src/app/models/coste.model';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TareaOtraModel } from 'src/app/models/tareas/tarea-otra.model';
import { TreeNode } from 'primeng/api';
@Component({
    selector: 'app-costes-tareas',
    templateUrl: './costes-tareas.component.html',
    styleUrls: ['./costes-tareas.component.scss']
})
export class CostesTareasComponent extends BaseView<CosteModel> implements OnInit, OnDestroy {
    public getRequest: RequestHandler<CosteModel[]> = this.tareasApi.costes.GET;
    public deleteRequest: RequestHandler<TareaOtraModel> = this.tareasApi.otras.DELETE;
    public menuItemId = 'costes-tareas';

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public appName = environment.appName;
    public showCostesTareasOtrasCsv = environment.features.showCostesTareasOtrasCsv;

    public override canShow: Record<string, boolean> = {};
    public override model: CosteModel = {};
    public ps = 0;

    public mostrarTratamientosAplicados = true;
    public override filtering: Filtering<CosteModel> = new Filtering<CosteModel>();

    public distributionOrder = [4];

    public trabajadores: TypeAheadInterface<MantenimientoModel> = {
        selected: null,
        filtered: [{label: '...', value: null}],
        values: []
    };

    public formFields: ItemInterface<object>[] = [
        {
            field: 'finca', 
            label: this.fincaName, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'sector', 
            label: 'Sector', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'tarea', 
            label: 'Tareas', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'parcelas', 
            label: 'Parcela', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha', 
            label: 'Fecha', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'hora_inicio', 
            label: 'Hora inicio', 
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'ava' 
        },
        {
            field: 'hora_fin', 
            label: 'Hora fin', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'ava' 
        },
        {
            field: 'maquinaria', 
            label: 'Maquinaria', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName !== 'ava'
        },
        {
            field: 'trabajadores_nombres', 
            label: 'Trabajadores', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName !== 'ava'
        },
        {
            field: this.appName === 'ava' ? 'tiempo_total' : 'horas' , 
            label: 'Horas', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName !== 'ava'
        }
    ];

    public cols: ColInterface[] = [
        {field: 'fecha', header: 'Fecha'},
        {field: 'finca', header: this.fincaName, visible: this.appName === 'ava' ? false : true},
        {field: 'parcelas', header: 'Parcelas', visible: this.appName === 'ava' ? true : false},
        {field: 'tarea', header: 'Tarea'},
        {field: 'trabajadores_nombres', header: 'Trabajadores'},
        {field: 'maquinaria', header: 'Maquinaria'},
        {field: 'precio_hora_maquinaria', header: 'Precio/Hora (Maq.)'},
        {field: 'precio_cargo', header: 'Precio/Hora (Trab.)'},
        {field: 'horas', header: 'Horas', width: '60px'},
        {header: 'Total (Maq.)', field: this.appName === 'ava' ? 'precio_total' : 'maquinaria_precio_total', filter: '', footer: '0'},
        {header: 'Total (Trab.)', field: 'coste' , filter: '', footer: '0'},
        {header: 'Concepto', field: 'concepto' , filter: '', footer: '0', visible: this.appName === 'mbcpomelos'},
        {header: 'Proveedor', field: 'proveedor' , filter: '', footer: '0', visible: this.appName === 'mbcpomelos'},
        {header: 'Costes Subcontratos', field: 'coste_subcontrata' , filter: '', footer: '0', visible: this.appName === 'mbcpomelos'},
        {header: 'Costes Total', field: 'coste_total' , filter: '', footer: '0'},

    ];
    auxTotalTrabajadores = 0;
    showTarea = false;
    showTareaFito = false;
    showTareaFerti = false;

 
    constructor(public tareasApi: TareasApiService,
        public trabajadoresApi: TrabajadoresApiService,
        private dashboard: DashboardService) {
        super(dashboard, tareasApi.costes.GET);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);

        this.filtering.addFilter((copy: TreeNode<CosteModel>[] | void) => {
            this.updateFooter();
            return (copy as TreeNode<CosteModel>[]).filter(row => row);
        });

        this.filtering.addFilter((data: TreeNode<CosteModel>[] | void) => (data as TreeNode<CosteModel>[]).sort((a, b) =>
            parseInt(a.data?.id ?? '', 10) > parseInt(b.data?.id ?? '', 10) ? -1 : 1
        ));
    }

    public moneyFormat(input: number) {
        return Utils.decimalFormat(input, 2);
    }

    public cuantityFormat(input: number) {
        return Utils.decimalFormat(input, 1);
    }

    public override show(modal: string, data: CosteModel) {
        let x;

        this.model = data;
        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public override sum(field: string) {
        let sum = 0;
    
        (this.filtering.value as CosteModel[]).forEach(it => {
            if (field === 'horas') {
                const timeParts = (it[field] as string || '0:00').split(':');
                if (timeParts.length === 2) {
                    const hours = parseFloat(timeParts[0] ?? '') || 0;
                    const minutes = parseFloat(timeParts[1] ?? '') || 0;
                    sum += hours + (minutes / 60);
                }
            } else {
                sum += (parseFloat(it[field] as string) || 0);
            }
        });
    
        sum = Math.round(sum * 100) / 100;
    
        this.cols.forEach(it => {
            if (it.field === field) {
                it['footer'] = sum + '';
            }
        });
    }
    
    

    public override updateFooter() {
        ['cantidad', 'horas', 'coste', 'maquinaria_precio_total', 'coste_total', 'coste_subcontrata'].forEach(it => this.sum(it));
        ['precio_total'].forEach(it => this.sum(it));
    }


    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if (
            Utils.isNumber(value) || 
            ['cantidad', 'producto_precio', 'horas', 'coste', 'precio_total', 'coste_total', 'coste_subcontrata'].includes(col.field)
        ) {
            return {
                textAlign: 'center'
            };
        }
        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface) {
        if (
            Utils.isNumber(value) && 
            [
                'producto_precio', 
                'coste', 
                'precio_hora_maquinaria', 
                'precio_cargo', 
                'precio_total',
                'maquinaria_precio_total', 
                'coste_total', 
                'coste_subcontrata'].includes(col.field)
        ) {
            return Utils.decimalFormat(value, 2);
        } else {
            return value;
        }
    }


    public override ngOnDestroy() {
        Utils.unsuscribe([
            this.tareasApi.costes.GET,
        ]);
    }

    public transformStringToNumber( value: string ): number {
        if ( value == null || value.length === 0  ) {
            return 0;
        }
        return parseFloat(value);
    }

    public sumaAuxTrabajadores( horas: string ) {
        const trabajadores: MantenimientoModel[] | null = this.model.lista_trabajadores ?? null;
        let suma =  0;
        if ( trabajadores == null ) {
            return suma;
        }
        for (const trabajador of trabajadores) {
            if (trabajador.aux_cargos == null || horas == null ) {
                suma += 0;
            } else {
                suma += trabajador.aux_cargos * this.transformStringToNumber(horas);
            }
        }
        return suma;
    }


    public tipoTarea( tarea: string ) {
        this.showTarea = false;
        this.showTareaFito = false;
        this.showTareaFerti = false;
        if ( tarea == null || tarea.length === 0) {
            this.showTarea = true;
            return;
        }

        if ( tarea === 'Tareas fitosanitarias' ) {
            this.showTareaFito = true;
            return;
        }
        
        if ( tarea === 'Tareas fertilizantes' ) {
            this.showTareaFerti = true;
            return;
        }
        this.showTarea = true;
        return;
    }
}