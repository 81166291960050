import { list } from 'src/app/common/classes/request-builder';



export function fillTipoTransaccion() {
    const tipoTransaccion = list( ['...', null] );
    tipoTransaccion.filtered.push({ label: '1 - Compra en España (suministrador)' , value: '1' }); 
    tipoTransaccion.filtered.push({ label: '2 - Venta en España (suministrador)' , value: '2' });
    tipoTransaccion.filtered.push({ label: '3 - Importación UE (suministrador)' , value: '3' });
    tipoTransaccion.filtered.push({ label: '4 - Importación no UE (suministrador)' , value: '4' });
    tipoTransaccion.filtered.push({ label: '5 - Exportación UE (suministrador)' , value: '5' });
    tipoTransaccion.filtered.push({ label: '6 - Exportación no UE (suministrador)' , value: '6' });
    tipoTransaccion.filtered.push({ label: '7 - Adquisición (tratamientos)' , value: '7' });
    tipoTransaccion.filtered.push({ label: '8 - Aplicación (tratamientos)' , value: '8' });
    tipoTransaccion.filtered.push({ label: '9 - Retirada gestor de residuos/robo (suministrador)' , value: '9' });
    tipoTransaccion.filtered.push({ label: '10- Retirada gestor de residuos/robo (tratamientos)' , value: '10' });
    tipoTransaccion.filtered.push({ label: '11 - Compra en España Entrada (suministrador)' , value: '11' });
    tipoTransaccion.filtered.push({ label: '12 - Compra en España Salida (suministrador)' , value: '12' });    
    tipoTransaccion.filtered.push({ label: '13 - Investigación (suministrador)' , value: '13' });    
    return tipoTransaccion;
}



export function changevalueToLabelTipoTransaccion(transaccion: string ): string {
        
    const tipoTransaccion = fillTipoTransaccion();
    for (const tipoTransaccionFiltered of tipoTransaccion.filtered) {
      
        if  ( tipoTransaccionFiltered.value === transaccion ) {
            return tipoTransaccionFiltered.label;
        } 
            
    }
    return '';
}