import { checkIfRolAdmin } from 'src/app/common/check-role';
import {InputType} from '../../../../../../common/components/form-builder/form-builder.component';
import {list} from '../../../../../../common/classes/request-builder';
import {FormRequestTypes} from '../../../../../../common/classes/form-request';
import { environment } from 'src/environments/environment';

import type { ItemInterface, TypeAheadInterface } from '../../../../../../common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ClienteModel } from 'src/app/models/cliente.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';

export const maquinaria = list(
    ['...', '0'], 
    ['JOFEMESA', '1'], 
    ['PRIMOTI', '2'], 
    ['TOLLQUICK', '3'], 
    ['MALCOP', '4'], 
    ['ALQUICESMAR', '5'], 
    ['OTROS', '6']
);

export class TareasAlquilerMaquinariaFormDefinition {

    public lastFieldChanged = '';

    public fincas: TypeAheadInterface<FincasModel> = list();  
    public clientes: TypeAheadInterface<ClienteModel> = list();      
    public trabajadores: TypeAheadInterface<MantenimientoModel> = list();      
    public prioridades = list(['...', null], 'Alta', 'Realizada');

    public requestType: FormRequestTypes = 0;

    public model = {};
    
    public appName = environment.appName;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;    

    public maquinariaList = list(
        ['...', '0'], 
        ['JOFEMESA', '1'], 
        ['PRIMOTI', '2'], 
        ['TOLLQUICK', '3'], 
        ['MALCOP', '4'], 
        ['ALQUICESMAR', '5'], 
        ['OTROS', '6']
    );

    public formFields: ItemInterface<object>[] = 
        [
            {
                field: 'fecha', 
                label: 'Fecha', 
                inputType: {type: InputType.CALENDAR}, 
                required: true
            },
            {
                field: 'id_finca',
                label: this.fincaName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.fincas,
                valuePrimaryKey: 'id',
                filter: (it: { value: FincasModel }) => {
                    if (this.clientes.selected) {
                        return it.value.id_cliente === (this.clientes.selected || {}).id;
                    } else {
                        return true;
                    }
                },
                required: true
            },
            { 
                field: 'prioridad', 
                label: 'Prioridad/Realizada', 
                inputType: { type: InputType.DROPDOWN }, 
                values: this.prioridades 
            },
        ];        
    public formFieldsSimple: ItemInterface<object>[] = 
        [
            {
                field: 'maquina', 
                label: 'Maquinaria', 
                inputType: {type: InputType.DROPDOWN}, 
                values: this.maquinariaList
            },
            {
                field: 'hora_inicio', 
                label: 'Hora inicio', 
                inputType: {type: InputType.TIME}, 
                required: checkIfRolAdmin()
            },
            {
                field: 'hora_fin', 
                label: 'Hora fin', 
                inputType: {type: InputType.TIME}, 
                required: checkIfRolAdmin()
            },
            {
                field: 'horas_totales', 
                label: 'Horas', 
                inputType: {type: InputType.ONLY_TEXT} 
            }
        ];
    
    public formFieldsTrabajadores: ItemInterface<object>[] = [
        {
            field: 'ids_trabajadores',
            label: 'Profesionales',
            inputType: { type: InputType.MULTISELECT },
            values: this.trabajadores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: { value: { activo: string; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                return true;
            }
        },
    ];

    public formFieldsOrdenTrabajo: ItemInterface<object>[] = [
        {
            field: 'orden_trabajo', 
            label: 'Orden de trabajo', 
            inputType: { type: checkIfRolAdmin() ? InputType.TEXTAREA : InputType.EDIT_TEXTAREA },
        },
    ];
    public formFieldsObs: ItemInterface<object>[] = [
        { 
            field: 'observaciones', 
            label: 'Observaciones', 
            inputType: { type: InputType.EDIT_TEXTAREA } 
        },
    ];


    public formChanges(tag: string) {
        this.lastFieldChanged = tag;
    }

}