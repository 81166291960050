import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import { VademecumActivity } from 'src/app/models/usuarios/vademecum-activity';
import { UserActivityModel } from 'src/app/models/usuarios/user-activity.model';

@Injectable({
    providedIn: 'root'
})
export class UserActivityApiService {

    public statisticsGET = new RequestHandler<UserActivityModel[]>();
    public vademecumActivityGET = new RequestHandler<VademecumActivity[]>();
    public vademecumActivityPOST = new RequestHandler<VademecumActivity[]>();

    constructor(
        private syncServiceStatistics: SyncService<UserActivityModel[]>,
        private syncServiceVademecum: SyncService<VademecumActivity[]>,
        private apiStatistics: ApiRequestService<UserActivityModel[]>,
        private apiVademecum: ApiRequestService<VademecumActivity[]>) {

        this.defineStatisticsGET();
        this.defineVademecumActivityGET();
        this.defineVademecumActivityPOST();

    }

    private defineStatisticsGET() {
        this.statisticsGET.fetch(HttpRequestType.GET, 'user/statistics');
        this.apiStatistics.registerRequest(this.statisticsGET);
        this.syncServiceStatistics.register(this.syncServiceStatistics.baseSyncPolicy(this.statisticsGET));
    }

    private defineVademecumActivityGET() {
        this.vademecumActivityGET.fetch(HttpRequestType.GET, 'vademecum/activity_get');
        this.apiVademecum.registerRequest(this.vademecumActivityGET);
        this.syncServiceVademecum.register(this.syncServiceVademecum.baseSyncPolicy(this.vademecumActivityGET));
    }

    private defineVademecumActivityPOST() {
        this.vademecumActivityPOST.fetch(HttpRequestType.POST, 'generic/vademecum_activity');
        this.apiVademecum.registerRequest(this.vademecumActivityPOST);
        this.syncServiceVademecum.register(this.syncServiceVademecum.baseSyncPolicy(this.vademecumActivityPOST));
    }
}
