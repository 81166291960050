<!-- FORM FIELDS -->
<p-panel>
    <div style="display: flex; gap:10px; ">
        <h4>Datos de la tarea</h4>
        <div *ngIf="loadingData">
            <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="6" animationDuration="1.5s">
            </p-progressSpinner>
        </div>
    </div>
    
    <div class="row">
        <div class="mb-5 col-12">
            
            <app-form-builder
                    [model]="model"
                    (formChanges)="form.formChanges($event); formChanges($event)"
                    (selectAllcheck)="selectAll()"
                    [distributionColumns]="6"
                    [fields]="form.formFieldsTiempo">
            </app-form-builder>
            <app-form-builder
                    [model]="model"
                    (formChanges)="form.formChanges($event); formChanges($event)"
                    (selectAllcheck)="selectAll()"
                    [distributionColumns]="3"
                    [fields]="form.formFieldsKM">
            </app-form-builder>
            <app-form-builder
                    [model]="model"
                    (formChanges)="form.formChanges($event); formChanges($event)"
                    (selectAllcheck)="selectAll()"
                    [distributionColumns]="this.getType() === 2 ? 4 : 3"
                    [fields]="form.formFieldsLugar">
            </app-form-builder>
            <div class="col-12">
                <app-form-builder [model]="model" (formChanges)="form.formChanges($event); formChanges($event)"
                    (selectAllcheck)="selectAll()" [distributionOrder]="distributionOrderFormTratamientos" [distributionColumns]="4" [fields]="form.formFieldsTratamiento">
                </app-form-builder>
            </div>
        </div>

        <div *ngIf="appName === 'vginer' || showClimatologia" class="mb-5 col-sm-6">
            <h5>Condiciones meteorológicas</h5>
            <app-form-builder
                    [model]="model"
                    (formChanges)="form.formChanges($event); formChanges($event)"
                    [distributionColumns]="4"
                    [fields]="form.formFieldsClima">
            </app-form-builder>
        </div>
        <div *ngIf="appName === 'vginer'" class="mb-5 col-sm-6">
            <h5>Mezclas sobrantes</h5>
            <app-form-builder
                    [model]="model"
                    (formChanges)="form.formChanges($event); formChanges($event)"
                    [distributionColumns]="3"
                    [fields]="form.formFieldsMezclasSobrantes">
            </app-form-builder>
        </div>
        <div class="mb-5 col-sm-6">
            <h5>Datos de la maquinaria</h5>
            <app-form-builder
                    [model]="model"
                    (formChanges)="form.formChanges($event); formChanges($event)"
                    [distributionColumns]="appName === 'ava' ? 2 : 3"
                    [fields]="form.formFieldsTractor">
            </app-form-builder>
        </div>
        <div class="mb-5 col-sm-6">
            <h5>Tarea finalizada</h5>
            <app-form-builder
                    [model]="model"
                    (formChanges)="form.formChanges($event); formChanges($event)"
                    [distributionColumns]="3"
                    [fields]="form.formFieldsFinal"
                    (mouseleave)="formChanges('')"
                    >
            </app-form-builder>
        </div>
        <div class="mb-5 col-12" *ngIf="this.appName === 'innovia' && showLitros">
            <h5>Productos aplicados</h5>
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    [fields]="form.formFieldsInnovia">
            </app-form-builder>
        </div>
    </div>
    <div *ngIf="this.appName==='donjardin' || this.appName === 'coliberica'">
        <br>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <app-form-builder 
                    [model]="model" 
                    (formChanges)="form.formChanges($event);" 
                    [distributionColumns]="1"
                    [fields]="form.formFieldsOrdenTrabajo">
                </app-form-builder>
                <app-dictado
                mensaje="Grabar orden de trabajo" 
                [observaciones]="model.orden_trabajo ?? ''" 
                (sendObservaciones)="getOrdenTrabajo($event)"></app-dictado>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="tareas-camara">
                    <app-upload-images
                    [model]="model"
                    folder="fitosanitarios"
                    tipo="form"
                    (sendImageName)="updateImageModel($event)"
                    ></app-upload-images>
                </div>
            </div>
        </div>                
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <app-form-builder
                [model]="model"
                (formChanges)="form.formChanges($event); formChanges($event)"
                [distributionColumns]="1" 
                [fields]="form.formFieldsObs">
                </app-form-builder> 
                <app-dictado
                mensaje="Grabar observaciones" 
                [observaciones]="model.observaciones ?? ''" 
                (sendObservaciones)="getObservaciones($event)"
                ></app-dictado>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="tareas-camara">
                    <app-upload-images 
                    [model]="model" 
                    folder="fitosanitarios"
                    nombre_imagen = 'image_after' 
                    tipo="form" 
                    (sendImageName)="updateAfterImageModel($event)"
                    ></app-upload-images>
                </div>
            </div>
        </div>
        <br>
        <div class="distribution_camera_signature" style="display: flex;
        gap: 50px;
        align-items: center;" *ngIf="this.model.signature_img">
            <div>
                <div *ngIf="this.model.signature_img" style="display: flex; flex-direction: column;">
                    <p>Firma</p>
                    <img *ngIf="!this.model.signature_img.includes('base64')" [src]="auxServerUrl + 'images/fitosanitarios/'+this.model.signature_img " class="info-firma-img" alt="Firma">
                    <img *ngIf="this.model.signature_img.includes('base64')" [src]="this.model.signature_img " class="info-firma-img" alt="Firma">
                </div>
            </div>  
            <app-signature (sendFile)="getSignature($event)" (sendTempImg)="updateFirma($event);"></app-signature>
        </div>
        <div class="distribution_camera_signature"  *ngIf="!this.model.signature_img">
            
            <app-signature (sendFile)="getSignature($event)" (sendTempImg)="updateFirma($event);"></app-signature>
        </div>
    </div>
    <!-- ACTION BUTTONS -->
    <div class="d-flex justify-content-center">
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>