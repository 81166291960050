import { Component } from '@angular/core';
import { RopoApiService } from '../../../../service/api/ropo-api.service';
import { BaseView } from '../../../base-view';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { environment } from '../../../../../environments/environment';
import { Utils } from '../../../../common/utils';
import { firstValueFrom } from 'rxjs';
import { RequestHandler } from 'src/app/service/OffService/request-handler';

import type { OnDestroy } from '@angular/core';
import { InformeInterface, InformeREGEPA, InformeROMA, InformeROPO } from './informe.interface';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';


@Component({
    selector: 'app-consultas',
    templateUrl: './consultas.component.html',
    styleUrls: ['./consultas.component.scss']
})
export class ConsultasComponent extends BaseView<InformeInterface> implements OnDestroy {
    public localizado = true;
    public dni = '';
    public showRopo = false;
    public showRoma = false;
    public showRegepa = false;
    public informe: InformeInterface[] = [];
    public informeREGEPA = {} as InformeREGEPA;
    public informeROMA: InformeROMA[] = [];
    public informeROPO: InformeROPO[] = [];
    public showInforme = false;
    public logoUrl = environment.logoUrl;
    public hoy = Utils.formatDate(new Date());
    public listadoDnis: string[] = [];
    public imprimirRopo = false;
    public imprimirRopoMultiple = false;
    public contadorDnis = 0;
    public maxDnis = 0;
  
    public charset = 'windows-1252';
    codigo_regepa = '';

    public isGeneratingCanvas = false;
  
    constructor(private _ropoService: RopoApiService,
        public dashboard: DashboardService    ) {
        super(dashboard, new RequestHandler());
    }


    buscaDni() {

        this.localizado = false;
        this.showRopo = false;
        this.showRoma = false;
        this.showRegepa = false;
        this.codigo_regepa = '';

        (firstValueFrom(this._ropoService.getDatosDni(this.dni)))
            .then((resp) => {
                //console.log('resp[2].data', resp[2].data);
                try {
                    this.codigo_regepa = (resp[2]?.data as InformeREGEPA).código ?? '';
                } catch (error) {
                    console.log(error);
                    this.codigo_regepa = '';
                }

                this.informe = resp;
                if ( this.informe != null ){

                    if (this.informe[0]?.errores.length === 0) { 
                        this.showRopo = true; 
                        this.informeROPO = this.informe[0]?.data as InformeROPO[];
                    };
                    if ( this.informe.length >= 2) {
                        if (this.informe[1]?.errores.length === 0) {
                            this.showRoma = true; 
                            this.informeROMA = this.informe[1]?.data as InformeROMA[];
                        };
                    }

                    if ( this.informe.length >= 3) {
                        if (this.informe[2]?.errores.length === 0) { 
                            this.showRegepa = true;
                            this.informeREGEPA = this.informe[2]?.data as InformeREGEPA;
                        };
                    }

                }

                if ( this.informe.length >= 2 && this.informe[1]?.data) {
                    for (const informeRoma of this.informe[1].data as InformeROMA[]) {
                        informeRoma.tipo = Utils.htmlEntities(informeRoma.tipo);
                    }
                }


                this.localizado = true;
                this.showInforme = true;
                
                return;
            })
            .catch((error: string) => console.log('error', error)
            );

    }  

    // public imprimirConsulta() {
    //     this.imprimirRopo = true;
    //     firstValueFrom(this._ropoService.imprimirConsulta(this.dni))
    //         .then((resp) => {
    //             this.createCsv(resp); 
    //             this.imprimirRopo = false;
    //             return;
    //         }).catch(() => {
    //             this.imprimirRopo = false;
    //         });

    // }

    public imprimirConsulta() {
        this.isGeneratingCanvas = true; // Disable the print button
    
        const config = this.getPrintConfig();
    
        html2canvas(
            document.getElementById('html2canvasDiv') as HTMLElement, 
            { scale: config.canvasScale, logging: false }
        ).then(canvas => {
            this.isGeneratingCanvas = false; // Re-enable the print button
    
            const pdf = new jsPDF(config.orientation, 'mm', 'a4');
            const data = canvas.toDataURL('image/png');
    
            const imgProps = pdf.getImageProperties(data);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    
            pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    
            pdf.save('Consulta_' + this.dni + '.pdf');
            return;
        }).catch(error => {
            console.error('Error generating PDF:', error);
            this.isGeneratingCanvas = false; // Re-enable the print button in case of error
        });
    }
    

    public getPrintConfig() {
        return {
            canvasScale: 2,           // Adjust the scale as needed
            orientation: 'p' as 'p' | 'l', // 'p' for portrait, 'l' for landscape
            width: 210,               // Width in mm (A4 size)
            height: 297               // Height in mm (A4 size)
        };
    }


    createCsv(resp: {csv: string[]}, multiple: boolean = false) {
    //console.log('resp', resp);

        if (resp.csv != null) {
            let csvContent = 'data:text/csv;charset=' + this.charset + ',\ufeff';
            const rows = resp.csv;
            rows.forEach(function (rowArray) {
                const row = rowArray;
                csvContent += row;
            });
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            if (multiple === false) {
                link.setAttribute('download', 'Ropo_' + this.dni + '.csv');
            } else {
                link.setAttribute('download', 'Ropo_multiple.csv');
            }

            document.body.appendChild(link); // Required for FF

            link.click(); // This will download the data file named "my_data.csv".
        }
    }
}