import { Injectable } from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import { environment } from '../../../environments/environment';
import { UserModel } from 'src/app/models/usuarios/user.model';

@Injectable({
    providedIn: 'root'
})
export class UsuariosApiService {

    public usuariosGET = new RequestHandler<UserModel[]>();
    public usuariosGenericGET = new RequestHandler<UserModel[]>();
    public usuariosDELETE = new RequestHandler<UserModel>();
    public usuariosPUT = new RequestHandler<UserModel[]>();
    public usuariosPOST = new RequestHandler<UserModel[]>();

    public profesionalesGET = new RequestHandler<UserModel[]>();

    constructor(
        private syncService: SyncService<UserModel[]>,
        private syncServiceDelete: SyncService<UserModel>,
        private api: ApiRequestService<UserModel[]>,
        private apiDelete: ApiRequestService<UserModel>) {

        this.defineUsuarios();
        this.defineProfesionales();
    }

    public defineUsuarios() {
        this.usuariosGET.fetch(HttpRequestType.GET, 'user/getusers');
        this.usuariosGenericGET.fetch(HttpRequestType.GET, 'generic/usuarios');

        if (environment.features.applicationType === 'cropgest') {
            this.usuariosPOST.fetch(HttpRequestType.POST, 'user/register');
        } else {
            this.usuariosPOST.fetch(HttpRequestType.POST, 'generic/usuarios');
        }

        if (environment.features.canRegister || environment.features.applicationType === 'cropgest') {
            
            this.usuariosPUT.fetch(HttpRequestType.PUT, 'user/put');
            this.usuariosDELETE.fetch(HttpRequestType.DELETE, 'user/delete');
        } else {
            this.usuariosDELETE.fetch(HttpRequestType.DELETE, 'generic/usuarios');
            this.usuariosPUT.fetch(HttpRequestType.PUT, 'generic/usuarios');
        }

        this.api.registerRequest(this.usuariosGET);
        this.api.registerRequest(this.usuariosGenericGET);
        this.api.registerRequest(this.usuariosPOST);
        this.api.registerRequest(this.usuariosPUT);
        this.apiDelete.registerRequest(this.usuariosDELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.usuariosGET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.usuariosGenericGET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.usuariosPOST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.usuariosPUT));
        this.syncServiceDelete.register(this.syncServiceDelete.baseSyncPolicy(this.usuariosDELETE));
    }


    public defineProfesionales() {
        this.profesionalesGET.fetch(HttpRequestType.GET, 'user/getprofesionales');
        this.api.registerRequest(this.profesionalesGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.profesionalesGET));
    }
}
