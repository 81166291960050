import { list } from 'src/app/common/classes/request-builder';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';

import type { ItemInterface, TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { ClienteModel } from 'src/app/models/cliente.model';

const tareaComercial: TypeAheadInterface<string> = list(['...', null], 'Llamada', 'Email' , 'Reunión' , 'Demo' , 'Presupuesto');
const estadoPresupuestos: TypeAheadInterface<string> = list(['...', null], 'Enviado', 'Aplazado' , 'Perdidos' , 'Confirmado' );


export const buildFieldsForms = function ( 
    clientes_crm: TypeAheadInterface<ClienteModel> | TypeAheadInterface<ClienteModel>[]
): ItemInterface<object>[] {
    return [
        {
            label: 'Cliente',
            field: 'id_cliente',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            required: true,
            valuePrimaryKey: 'id',
            values: clientes_crm
        },
        {
            label: 'Fecha Acción Comercial',
            field: 'fecha',
            inputType: {type: InputType.CALENDAR},
            required: true,
        
        },
        {
            label: 'Tarea comercial',
            field: 'tarea_comercial',
            inputType: {type: InputType.DROPDOWN},
            values: tareaComercial,
            required: true,
        },
        {
            label: 'Interlocutor',
            field: 'interlocutor',
            inputType: {type: InputType.EDIT_TEXT},
        },
        {
            label: 'Estado presupuesto',
            field: 'estado_presupuesto',
            inputType: {type: InputType.DROPDOWN},
            values: estadoPresupuestos,
        },
        {
            label: 'Realizado',
            field: 'realizado',
            inputType: {type: InputType.SWITCH},
        },
        {
            label: 'Fecha Próxima Acción Comercial',
            field: 'fecha_proxima_accion_comercial',
            inputType: {type: InputType.CALENDAR},
        },
        {
            label: 'Observaciones',
            field: 'observaciones',
            inputType: {type: InputType.EDIT_TEXTAREA}
        },
        {
            label: 'Productos',
            field: 'productos',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Venta Cruzada',
            field: 'venta_cruzada',
            inputType: {type: InputType.EDIT_TEXT}
        }
    ];
};