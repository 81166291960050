import { Component } from '@angular/core';

@Component({
    selector: 'app-trabajadores',
    templateUrl: './trabajadores.component.html',
    styleUrls: ['./trabajadores.component.scss']
})
export class TrabajadoresComponent {

    constructor() { }
}
