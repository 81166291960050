import {Component} from '@angular/core';
import {DashboardService} from '../../../service/events/dashboard.service';

import type { OnInit } from '@angular/core';

@Component({
    selector: 'app-tareas',
    templateUrl: './tareas.component.html',
    styleUrls: ['./tareas.component.scss']
})
export class TareasComponent implements OnInit {

    constructor(private dashboardEvent: DashboardService) {
    }

    ngOnInit() {
        this.dashboardEvent.setMenuItem('tareas');
    }

}
