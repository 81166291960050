<br>
<p-panel>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
      <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
    </div>
  </div>
  
  <app-form-builder
    [model]='model'
    [undoEnabled]='undoEnabled'
    [distributionColumns]='3'
    [fields]='formFields'
    (formChanges)="formChanges()">
  </app-form-builder>
  <br>

  <app-form-builder
  [model]='model'
  [undoEnabled]='undoEnabled'
  [distributionColumns]='3'
  [fields]='formFieldsDireccion'
  (formChanges)="formChanges()">
</app-form-builder>
<br>

  <app-form-builder
  [model]='model'
  [undoEnabled]='undoEnabled'
  [distributionColumns]='3'
  [fields]='formFieldsDatos'
  (formChanges)="formChanges()">
</app-form-builder>
<br>
 
  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            [lockOnSuccess]='true'
            (click)="submit()"
            [error]='error'
            [mode]='requestButtonType'
            [request]='request'>
    </app-request-button>
  </div>
</p-panel>
<br>
<br>
