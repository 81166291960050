<br>

<div style="width: auto; overflow: auto">
    <app-common-list-table
        [request]="getRequest"
        [cols]="cols"
        [filtering]="filter"
        actionButtonText="Añadir transacción" 
        (actionButtonClick)="add()"
        [globalConditionalStyle]="globalConditionalStyle">
        <ng-template #header let-data>
            <div style="width: 100%; margin-right: 15px" class="d-flex justify-content-center align-items-center" *ngIf="appName !== 'ctl' ">
                <div style="margin-left: 10px; margin-right: 10px">
                    <div style="font-size: 0.7em" class="btn-group">
                        <button type="button" class="btn btn-info" style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white; width: 8rem;"
                        (click)="show('generate', modelGenFields)"
                        >Generar archivo</button>
                    </div>
                </div>
            </div>
            <div style="width: 100%; margin-right: 15px" class="d-flex justify-content-center align-items-center" *ngIf="appName === 'ctl' && (user.id === '2' || user.id === '1' ) ">
                <div style="margin-left: 10px; margin-right: 10px">
                    <div style="font-size: 0.7em" class="btn-group">
                        <button type="button" class="btn btn-info" style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white; width: 8rem;"
                        (click)="show('generate', modelGenFields)"
                        >Generar archivo</button>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #thEnd>
          
            <th>
                <p class="d-flex justify-content-center" style="margin-bottom: 0">Acciones</p>
            </th>
         
           
        </ng-template>

        <ng-template #tdEnd let-data>
         
            <td>
                <app-action-buttons
                    [showDuplicate]="true"
                    (duplicateRowButtonEvent)="duplicate(data)"
                    (editRowButtonEvent)="edit(data)"
                    (deleteRowButtonEvent)="show('delete', data)"
                    (showRowButtonEvent)="show('info', data)">
                </app-action-buttons>
            </td>
        
         
        </ng-template>
    </app-common-list-table>
</div>



<p-dialog 
    *ngIf="canShow['info']"
    header="Detalle del registro" 
    [(visible)]="canShow['info']" 
    (onShow)="scrollToTop()"
>
    <div style="width: 80vw !important">
       
       <!--  <form-builder [fields]="formFields" [distribution-columns]="3" [model]="model">
        </form-builder> -->
        <app-form-builder
        [model]="model"
        [distributionColumns]="3"
        [fields]="formFields"
        (formChanges)="formChanges()">
      </app-form-builder>
      <div *ngIf="checkAlmacen()">
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          [fields]="formAlmacenFields"
          (formChanges)="formChanges()">
        </app-form-builder>
        
      </div>
    
      <div style="margin-top: 0px; position: absolute;" >
        <div style="position: relative;top: 20px; left: 72px;">
            {{ this.model.unidades? '('+ this.model.unidades +')': '' }}
        </div>
      </div>
        
        <div id="transaccion-info">
            <app-form-builder
                [model]="model"
                [distributionColumns]="3"
                [fields]="formProductoFields"
                (formChanges)="formChanges()">
              </app-form-builder>
        </div>
    
      <div *ngIf="this.model.tipo_transaccion === '8 - Aplicación' ">
        <app-form-builder
          [model]="model"

          [distributionColumns]="3"
          [fields]="formCultivoFields"
          (formChanges)="formChanges()">
        </app-form-builder>

      
    
      </div>

      <div *ngIf="checkNIMA()">
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          [fields]="formNIMAFields"
          (formChanges)="formChanges()">
        </app-form-builder>
        
      </div>        
      <br>
        <br>
     
       
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>
</p-dialog>

<p-dialog 
    *ngIf="canShow['delete']"
    header="Eliminar registro" 
    [(visible)]="canShow['delete']" 
    (onShow)="scrollToTop()"
>

    <div style="width: 80vw !important">
        <app-form-builder [fields]="formFields" [distributionColumns]="3" [model]="model">
        </app-form-builder>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button [warning]="deleteWarning" (click)="deleteRegister()" [request]="deleteRequest" [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading && deleteWarning" style="color: red; text-align: center">
            {{deleteWarningMessage}}
        </p>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading" style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>

</p-dialog>

<p-dialog 
    *ngIf="canShow['generate']"
    header="Generar archivo" 
    [(visible)]="canShow['generate']" 
    (onShow)="scrollToTop()"
>

    <div  [ngStyle]="{'width.vw': '50'}" style="min-height: 40vw !important;">
            <app-form-builder
                [model]="model"
                [distributionColumns]="2"
                [fields]="generarFields"
                (formChanges)="formChanges()">
            </app-form-builder>
       
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="emptyDates();hide('generate')">Cerrar</button>

            <a class="d-flex justify-content-center mx-2" >
                <button *ngIf="canGenFile" class="btn btn-primary" (click)="generarArchivo()">Generar</button>
            </a>

        </div>
        <br>
    </div>
</p-dialog>
