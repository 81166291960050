import { environment } from 'src/environments/environment';
import { Component, ViewChild } from '@angular/core';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BaseForm } from 'src/app/view/base-form';
import { TratamientosApiService } from 'src/app/service/api/tratamientos-api.service';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { AppFormRequest } from 'src/app/view/app-common/form-request/app-form-request';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { build, stack, list } from 'src/app/common/classes/request-builder';
import moment from 'moment';
import { TrabajadoresApiService } from 'src/app/service/api/trabajadores-api.service';
import { CamposApiService } from 'src/app/service/api/campos-api.service';
import { ProductosApiService } from 'src/app/service/api/productos-api';
import { StorageManager } from '../../../../../common/storage-manager.class';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { UsuariosApiService } from '../../../../../service/api/usuarios-api.service';
import { firstValueFrom } from 'rxjs';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { BoquillaModel } from 'src/app/models/trabajadores/boquillas.model';
import { TratamientoManezModel } from 'src/app/models/tratamientos/tratatamiento-manez.model';
import { MaquinariaModel } from 'src/app/models/trabajadores/maquinaria.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { BaseResponseModel } from 'src/base-response.model';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';

export const modoTrabajo: TypeAheadInterface<string[]> = list(['...', null], ['Activado', 'Activado'], ['Desactivado', 'Desactivado']);
export const regulacionDosis: TypeAheadInterface<string[]> = list(['...', null], ['Manual', 'Manual'], ['Automática', 'Automática']);
export const estadoTratamiento: TypeAheadInterface<string[]> = list(
    ['...', null], 
    ['Preparado', 'Preparado'], 
    ['Parcial', 'Parcial'], 
    ['Finalizado', 'Finalizado']
);

@Component({
    selector: 'app-trata-manez-form',
    templateUrl: './trata-manez-form.component.html',
    styleUrls: ['./trata-manez-form.component.scss']
})
export class TrataManezFormComponent extends BaseForm<TratamientoManezModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: false, static: false })
        requestButton = new RequestButtonComponent<TratamientoManezModel>();

    public rol = (StorageManager.getUser()).rol;

    public unidad = (StorageManager.getUser()).uds_medida || 'Hectárea';
    public ratio = StorageManager.getUser().ratio_ha || 1;
    public userId = (StorageManager.getUser()).id;
    public totalCaudales: number[] = [];
    public boquilla: BoquillaModel= {
        hci: {index: []},
        amt: {index: []},
    };
    public tablaCaudales: BoquillaModel = {
        hci: {
            index: ['h005', 'h0075', 'h01', 'h015', 'h02', 'h025', 'h03', 'h035', 'h04', 'h05'],
            3: [0.19, 0.3, 0.4, 0.6, 0.8, 1, 1, 2, 1.4, 1.6, 2],
            4: [0.22, 0.35, 0.46, 0.69, 0.92, 1.15, 1.39, 1.62, 1.85, 2.31],
            5: [0.25, 0.39, 0.52, 0.77, 1.03, 1.29, 1.55, 1.81, 2.07, 2.58],
            6: [0.27, 0.42, 0.57, 0.85, 1.13, 1.41, 1.7, 1.98, 2.26, 2.83],
            7: [0.29, 0.46, 0.61, 0.92, 1.22, 1.53, 1.83, 2.14, 2.44, 3.06],
            8: [0.31, 0.49, 0.65, 0.98, 1.31, 1.63, 1.96, 2.29, 2.61, 3.27],
            9: [0.33, 0.52, 0.69, 1.04, 1.39, 1.73, 2.08, 2.42, 2.77, 3.46],
            10: [0.35, 0.55, 0.73, 0.1, 1.46, 1.83, 2.19, 2.56, 2.92, 3.65],
            11: [0.36, 0.57, 0.77, 1.15, 1.53, 1.91, 2.3, 2.68, 3.06, 3.83],
            12: [0.38, 0.6, 0.8, 1.2, 1.6, 2, 2.4, 2.8, 3.2, 4],
            13: [0.4, 0.62, 0.83, 1.25, 1.67, 2.08, 2.5, 2.91, 3.33, 4.16],
            14: [0.41, 0.65, 0.86, 1.31, 1.73, 2.16, 2.59, 3.02, 3.46, 4.32],
            15: [0.42, 0.67, 0.89, 1.34, 1.79, 2.24, 2.68, 3.13, 3.58, 4.47],
            16: [0.44, 0.69, 0.92, 1.39, 1.85, 2.31, 2.77, 3.23, 3.7, 4.62],
            17: [0.45, 0.71, 0.95, 1.43, 1.9, 2.38, 2.86, 3.33, 3.81, 4.76],
            18: [0.47, 0.73, 0.98, 1.47, 1.96, 2.45, 2.94, 3.43, 3.92, 4.9],
            19: [0.48, 0.75, 1.01, 1.51, 2.01, 2.52, 3.02, 3.52, 4.03, 5.03],
            20: [0.49, 0.77, 1.03, 1.55, 1.55, 2.58, 3.1, 3.61, 4.13, 5.16]
        },
        // Valores referenciales. Se calculan en la función calcularTablaCaudal().
        amt: {
            index: ['a08', 'a1', 'a11', 'a12', 'a1212', 'a15', 'a1515', 'a18', 'a1818', 'a2', 'a22'],
            3: [0.45, 0.50, 0.88, 0.56, 1.16, 0.77, 1.93, 1.24, 2.33, 1.05, 2.30],
            4: [0.52, 0.58, 0.95, 0.67, 1.28, 0.89, 2.15, 1.40, 2.66, 1.22, 2.71],
            5: [0.60, 0.65, 1.02, 0.78, 1.40, 1.02, 2.37, 1.56, 3, 1.40, 3.12],
            6: [0.68, 0.72, 1.09, 0.89, 1.52, 1.15, 2.59, 1.72, 3.34, 1.58, 3.53],
            7: [0.75, 0.80, 1.16, 1, 1.64, 1.27, 2.81, 1.88, 3.67, 1.75, 3.94],
            8: [0.83, 0.87, 1.24, 1.10, 1.76, 1.40, 3.04, 2.04, 4.01, 1.93, 4.34],
            9: [0.90, 0.95, 1.31, 1.21, 1.88, 1.52, 3.26, 2.20, 4.34, 2.10, 4.75],
            10: [0.98, 1.02, 1.38, 1.32, 2, 1.65, 3.48, 2.36, 4.68, 2.28, 5.16],
            11: [1, 1.05, 1.45, 1.36, 2.10, 1.70, 3.65, 2.50, 4.92, 2.41, 5.36],
            12: [1.02, 1.08, 1.52, 1.40, 2.19, 1.76, 3.82, 2.63, 5.16, 2.54, 5.57],
            13: [1.04, 1.12, 1.58, 1.45, 2.29, 1.81, 3.98, 2.77, 5.40, 2.68, 5.77],
            14: [1.06, 1.15, 1.65, 1.49, 2.38, 1.87, 4.15, 2.90, 5.64, 2.81, 5.98],
            15: [1.08, 1.18, 1.72, 1.53, 2.48, 1.92, 4.32, 3.04, 5.88, 2.94, 6.18],
            16: [1.12, 1.22, 1.77, 1.60, 2.56, 2.02, 4.49, 3.10, 6.10, 3.04, 6.42],
            17: [1.15, 1.27, 1.82, 1.66, 2.64, 2.11, 4.66, 3.17, 6.31, 3.13, 6.66],
            18: [1.19, 1.31, 1.86, 1.73, 2.72, 2.21, 4.82, 3.23, 6.53, 3.23, 6.90],
            19: [1.22, 1.36, 1.91, 1.79, 2.80, 2.30, 4.99, 3.30, 6.74, 3.32, 7.14],
            20: [1.26, 1.40, 1.96, 1.86, 2.88, 2.40, 5.16, 3.36, 6.96, 3.42, 7.38]
        }
    };

    public override model: TratamientoManezModel = {fecha: ''};
    public formRequest = new AppFormRequest<TratamientoManezModel>();

    public productosAll: TypeAheadInterface<ProductosModel> = list(['...', null]);
    public campos: TypeAheadInterface<Campos> = list(['...', null]);
    public maquinarias: TypeAheadInterface<MaquinariaModel> = list(['...', null]);
    public boquillas: TypeAheadInterface<BoquillaModel> = list(['...', null]);
    public modo_trabajo: TypeAheadInterface<string[]> = modoTrabajo;
    public regulacion_dosis: TypeAheadInterface<string[]> = regulacionDosis;
    public estado_tratamiento: TypeAheadInterface<string[]> = estadoTratamiento;

    public formFields: ItemInterface<object>[] = [
        {
            field: 'n_tratamiento',
            label: 'Nº\xa0Tratamiento',
            inputType: {type: InputType.TEXT},
            required: true
        },
        {
            field: 'nombre',
            label: 'Nombre',
            inputType: {type: InputType.EDIT_TEXT},
            required: true
        },
        { 
            field: '', 
            inputType: {type: InputType.EMPTY}
        },
        {
            field: 'id_maquinaria',
            label: 'Maquinaria',
            inputType: {type: InputType.DROPDOWN},
            values: this.maquinarias,
            valuePrimaryKey: 'id',
            filter: (it: { value: MaquinariaModel}) => it.value.id_usuario === this.userId || this.rol === 'admin',
            required: true
        },
        {
            field: 'id_campo',
            label: 'Campo',
            inputType: {type: InputType.DROPDOWN},
            values: this.campos,
            valuePrimaryKey: 'id',
            filter: (it: { value: FincasModel; })  => it.value.id_usuario === this.userId || this.rol === 'admin',
            required: true
        },
        {
            field: 'ancho_calle',
            label: 'Ancho de calle',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'fecha_inicio',
            label: 'Fecha\xa0del Tratamiento',
            inputType: {type: InputType.CALENDAR},
            required: true
        },
        {
            field: 'hora_inicio',
            label: 'Hora Inicio',
            inputType: {type: InputType.TIME}
        },
        {
            field: 'hora_fin',
            label: 'Hora Fin',
            inputType: {type: InputType.TIME}
        },
        {
            field: 'litros',
            label: 'Litros/' + this.unidad,
            inputType: {type: InputType.EDIT_TEXT},
            required: true
        },
        {
            field: 'marcha',
            label: 'Velocidad de avance (km/h)',
            inputType: {type: InputType.EDIT_TEXT},
            required: true
        },
        {
            field: 'rpm',
            label: 'RPM',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'presion',
            label: 'Presión',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'caudal',
            label: 'Caudal\xa0(l/min)',
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'id_boquilla',
            label: 'Boquilla',
            inputType: {type: InputType.DROPDOWN},
            values: this.boquillas,
            valuePrimaryKey: 'id',
            filter: (it: { value: BoquillaModel; }) => {
                if (this.maquinarias.selected) {
                    return (it.value).id_maquinaria === this.maquinarias.selected.id;
                }
                return false;
            }
        },
        {
            field: 'modo_trabajo',
            label: 'Modo trabajo',
            inputType: {type: InputType.DROPDOWN},
            values: this.modo_trabajo,
            required: true
        },
        {
            field: 'regulacion_dosis',
            label: 'Regulación dosis',
            inputType: {type: InputType.DROPDOWN},
            values: this.regulacion_dosis,
            required: true
        },
        {
            field: 'estado_tratamiento',
            label: 'Estado tratamiento',
            inputType: {type: InputType.TEXT}
        }
    ];

    constructor(
        public override dashboard: DashboardService,
        public override router: Router,
        public override route: ActivatedRoute,
        public camposApi: CamposApiService,
        public trabajadoresApi: TrabajadoresApiService,
        public tratamientosApi: TratamientosApiService,
        public productosApi: ProductosApiService,
        public usuariosApi: UsuariosApiService,
        public http: HttpClient) {
        super(
            route,
            router,
            dashboard,
            tratamientosApi.tratamientosManez.PUT,
            tratamientosApi.tratamientosManez.POST,
            'tratamientos-manez',
            'Actualizar Tratamiento',
            'Crear Tratamiento',
            'Duplicar Tratamiento'
        );
    }

    ngOnInit() {

   
        this.productosApi.productos.GET.response(() => {
            setTimeout(() => {
                this.formatProductos();
                this.formatBoquillas();
            }, 100);
        });

        moment.locale('es');

        this.initFormData();
        this.extendFormRequest();

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .isGeneric(false)
            .setGetRequest(this.tratamientosApi.tratamientosManez.GET)
            .setPostRequest(this.tratamientosApi.tratamientosManez.POST)
            .setPutRequest(this.tratamientosApi.tratamientosManez.PUT)
            .setFormFields(this.formFields)
            .setFieldsToSend(['id', 'id_usuario', 'ids_productos', 'cantidades_real']
                .concat(
                    this.formFields.map(it => it.field ?? '')
                )
            );

        this.formRequest.load();

        this.softInit(this.getType());

    }

    public formatProductos() {
        const idsProductos = (this.model.ids_productos || '').split(';');
        const cantidadesReal = (this.model.cantidades_real || '').split(';');

        this.model.productos_uds = [];

        this.productosAll.filtered = this.productosAll.filtered.filter(it => {
            if (it.value) {return (it.value as ProductosModel).id_usuario === this.userId || this.rol === 'admin'; }
            else {return true; }
        });

        for (const i of [1, 2, 3, 4, 5, 6, 7]) {
            if (idsProductos[i - 1]) {
                /**
         *  Corregir cuando se editan productos, reasignamos ambas variables,
         *  para que el listado de productos se actualice
         */
                if (this.model['producto' + i] != null) {
                    idsProductos[i - 1] = (this.model['producto' + i] as ProductosModel).id ?? '';
                    this.model.ids_productos = (idsProductos as string[]).toString();
                }

                const found = this.productosAll.filtered.find(it => it.value && ((it.value as ProductosModel).id === idsProductos[i - 1]));
                this.model.productos_uds[i - 1] = (found?.value as ProductosModel).uds_medida ?? '';
                this.model['producto' + i] = found?.value ?? '';
            }
            if (this.model['producto' + i]) {
                this.model.productos_uds[i - 1] = (this.model['producto' + i] as ProductosModel).uds_medida ?? '';
            }
            if (cantidadesReal[i - 1]) {
                this.model['dosis' + i] = cantidadesReal[i - 1] ?? '';
            }
        }

        this.formRequest.update();
    }

    public formatBoquillas() {
        if (this.boquillas && this.boquillas.selected) {
            this.boquilla = this.boquillas.selected as BoquillaModel;
            if (typeof this.boquilla.hci === 'string') {
                this.boquilla.hci = JSON.parse((this.boquillas.selected as BoquillaModel).hci.toString());
            }
            if (typeof this.boquilla.amt === 'string') {
                this.boquilla.amt = JSON.parse((this.boquillas.selected as BoquillaModel).amt.toString());
            }
            this.calcularTablaCaudal();
            for (const i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]) {
                this.getCaudal(i);
            }
        } else {
            for (const i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]) {
                this.totalCaudales[i] = 0;
            }
        }
    }

    public submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send();
    }

    public formChanges(tag: string) {

        if (['marcha', 'litros', 'id_campo'].includes(tag)) {
            this.calcularCaudal();
        }

        if (['id_maquinaria', 'id_boquilla'].includes(tag)) {
            if (tag === 'id_maquinaria') {
                this.model.id_boquilla = '';
                this.boquillas.selected = null;
            }
            this.formatBoquillas();
        }

        if (tag === 'id_producto') {
            this.formatProductos();
        }

        if (tag === 'id_campo') {
            this.getAnchoCalle();
        }

        this.requestButton.error = '';
    }

    public calcularCaudal() {
        const proporcion = this.ratio;

        let marco = [0, 0];
        const velocidad = (this.model.marcha || 0);
        const dosis = (this.model.litros || 0) * proporcion;

        /*   if (this.campos.selected) {
      marco = (this.campos.selected.marco as string).trim().split('x').map(it => parseFloat(it));
    } */
    
        if ( this.model.ancho_calle ) {
            marco = [parseFloat(this.model.ancho_calle)];
        }

        this.model.caudal = +(((marco[0] ?? 0) * velocidad * dosis) / 600).toFixed(2);

    }

    public getCaudal(tableIndex: number) {
        let total = 0;

        for (const prop in this.boquilla.hci) {
            if (this.boquilla.hci[prop]) {
                const idx = this.tablaCaudales.hci.index.indexOf(prop);
                total += (this.tablaCaudales.hci[tableIndex]?.[idx] as number) * (this.boquilla.hci[prop]?.[idx] as number);
            }
        }

        for (const prop in this.boquilla.amt) {
            if (this.boquilla.amt[prop]) {
                const idx = this.tablaCaudales.amt.index.indexOf(prop);
                total += (this.tablaCaudales.amt[tableIndex]?.[idx] as number) * (this.boquilla.amt[prop]?.[idx] as number);
            }
        }

        this.totalCaudales[tableIndex] = +total.toFixed(2);
    }

    public getAnchoCalle( ) {
        try {
            const idCampo = this.model['id_campo'];
            (firstValueFrom(this.http.get<BaseResponseModel<Campos>>( 
                environment.serverUrl + 'ws/index.php?p1=generic&p2=campos&p3=' + idCampo 
            )))
                .then( (campos: BaseResponseModel<Campos> ) => {
                    this.model.ancho_calle = (campos.data as Campos[])[0]?.marco_ancho ?? '';
                    this.calcularCaudal();
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
        } catch (error) {
            console.log(error);
            this.model.ancho_calle = '';
        }

    }

    private initFormData(): Promise<boolean> {
        const builds = [
            build(this.camposApi.campos.GET, this.campos, 'nombre', true),
            build(this.trabajadoresApi.boquillas.GET, this.boquillas, 'nombre', true),
            build(this.trabajadoresApi.maquinariaGET, this.maquinarias, 'nombre', true),
            build(this.productosApi.productos.GET, this.productosAll, 'nombre', false),
        ];
        return stack(builds, () => {
            this.route.params.subscribe(params => {
                if (params['id'] == null) { this.model.estado_tratamiento = 'Preparado'; };
            });
            this.formRequest.update();
        });
    }

    private extendFormRequest() {
        this.formRequest.afterLoad(resolve => {
            if (this.model.fecha_inicio) {
                this.model.fecha_inicio = moment(this.model.fecha_inicio).toDate();
            }
            if (this.model.hora_inicio) {
                this.model.hora_inicio = moment(this.model.hora_inicio, 'HH:mm').toDate();
            }
            if (this.model.hora_fin) {
                this.model.hora_fin = moment(this.model.hora_fin, 'HH:mm').toDate();
            }
            if (this.getType() === FormRequestTypes.CREATE || this.getType() === FormRequestTypes.DUPLICATE ) {
                const usuario = this.model.id_usuario ? this.model.id_usuario : StorageManager.getUser().id;
                this.tratamientosApi.tratamientosManez.GET.toPromise({ id_usuario: usuario } as never).then(res => {
                    this.model.n_tratamiento = (res).length + 1;
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
            }
            if (this.model.uds_medida) {
                this.unidad = this.model.uds_medida;
                this.ratio = this.model.ratio_ha ?? 0;
                this.formFields.map(campo => {
                    if (campo.field === 'litros') {
                        campo.label = 'litros/' + this.unidad;
                    };
                });
            }
            resolve(true);
        });

        this.formRequest.beforeSend(resolve => {
            this.model.ids_productos = '';
            this.model.cantidades_real = '';
            for (const i of [1, 2, 3, 4, 5, 6, 7]) {
                if (this.model['producto' + i]) {
                    this.model.ids_productos += (this.model['producto' + i] as ProductosModel).id + ';';
                }
                if (this.model['dosis' + i]) {
                    this.model.cantidades_real += this.model['dosis' + i] + ';';
                }
            }
            this.model.ids_productos = this.model.ids_productos.slice(0, -1);
            this.model.cantidades_real = this.model.cantidades_real.slice(0, -1);

            if (this.model.fecha_inicio) {
                this.model.fecha_inicio = new Date(moment(this.model.fecha_inicio).format('YYYY-MM-DD'));
            }

            if (this.model.hora_inicio) {
                /* corregir cuando se actualiza y hay fecha, que una vez que cambie el formato,
        ** y le das varias veces a actualizar no se quede el formato NaN 
        */
                if (moment(this.model.hora_inicio).isValid()) {
                    this.model.hora_inicio = moment(this.model.hora_inicio).format('HH:mm');

                }
            } else {
                this.model.hora_inicio = '';
            }
            if (this.model.hora_fin) {
                /* 
         * corregir cuando se actualiza y hay fecha, que una vez que cambie el formato,
         * y le das varias veces a actualizar no se quede el formato NaN 
         */
                if (moment(this.model.hora_fin).isValid()) {
                    this.model.hora_fin = moment(this.model.hora_fin).format('HH:mm');
                }
            } else {
                this.model.hora_fin = '';
            }
            resolve(true);
        });

        this.formRequest.afterSend(resolve => {
            this.formatProductos();
            resolve(true);
        });

        this.formRequest.afterUpdate(resolve => {
            this.calcularCaudal();
            resolve(true);
        });
    }

    private calcularTablaCaudal() {
        let D10: number, D15: number, D20: number, D25: number;

        for (const i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]) {
            for (const j of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
                D10 = (this.tablaCaudales.amt[5] as number[])[j] ?? 0;
                D15 = (this.tablaCaudales.amt[10] as number[])[j] ?? 0;
                D20 = (this.tablaCaudales.amt[15] as number[])[j] ?? 0;
                D25 = (this.tablaCaudales.amt[20] as number[])[j] ?? 0;

                if (i < 5) {
                    (this.tablaCaudales.amt[i] as number[])[j] = D10 - (((D15 - D10) / 5) * (5 - i));
                } else if (i < 10) {
                    (this.tablaCaudales.amt[i] as number[])[j] = D10 + (((D15 - D10) / 5) * (i - 5));
                } else if (i < 15) {
                    (this.tablaCaudales.amt[i] as number[])[j] = D15 + (((D20 - D15) / 5) * (i - 10));
                } else if (i < 20) {
                    (this.tablaCaudales.amt[i] as number[])[j] = D20 + (((D25 - D20) / 5) * (i - 15));
                }
            }
        }

    }
}

interface Campos{
    id: string;
    nombre: string;
    superficie_sigpac: string;
    superficie_cultivada: string;
    plantas: string;
    marco: string;
    marco_ancho: string;
    marco_largo: string;
    anyo_plantacion: string;
    patron: string;
    id_cultivo: string;
    id_variedad: string;
    cod_provincia: string;
    municipio: string;
    poligono: string;
    numero: string;
    recinto: string;
    id_cliente: string;
    id_usuario: string;
}