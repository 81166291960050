import {InputType} from '../../../../../../common/components/form-builder/form-builder.component';
import {list} from '../../../../../../common/classes/request-builder';
import {FormRequestTypes} from '../../../../../../common/classes/form-request';
import { environment } from 'src/environments/environment';
import { checkIfRolAdmin } from 'src/app/common/check-role';

import type { ItemInterface, TypeAheadInterface } from '../../../../../../common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ClienteModel } from 'src/app/models/cliente.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';

export class TareasPlantacionFormDefinition {

    public lastFieldChanged = '';

    public fincas: TypeAheadInterface<FincasModel> = list();  
    public clientes: TypeAheadInterface<ClienteModel> = list();      
    public trabajadores: TypeAheadInterface<MantenimientoModel> = list();      
    public prioridades = list(['...', null], 'Alta', 'Realizada');
    
    public requestType: FormRequestTypes = 0;

    public model = {};
    
    public appName = environment.appName;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;    

    public formFields: ItemInterface<object>[] = 
        [
            {
                field: 'fecha', 
                label: 'Fecha', 
                inputType: {type: InputType.CALENDAR}, 
                required: true
            },
            {
                field: 'id_finca',
                label: this.fincaName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.fincas,
                valuePrimaryKey: 'id',
                filter: (it: { value: FincasModel }) => {
                    if (this.clientes.selected) {
                        return it.value.id_cliente === (this.clientes.selected || {}).id;
                    } else {
                        return true;
                    }
                },
                required: true
            },
            {
                field: 'hora_inicio', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TIME}, 
                required: checkIfRolAdmin()
            },
            {
                field: 'hora_fin', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TIME}, 
                required: checkIfRolAdmin()
            },
            { 
                field: 'horas', 
                label: 'Horas', 
                inputType: {type: InputType.ONLY_TEXT} 
            },
            { 
                field: 'prioridad', 
                label: 'Prioridad/Realizada', 
                inputType: { type: InputType.DROPDOWN }, 
                values: this.prioridades 
            },
        ];        
    public formFieldsSimple: ItemInterface<object>[] = 
        [
            {
                field: 'arboles', 
                label: 'Árboles', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'arbustos_setos', 
                label: 'Arbustos/Setos', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
            {
                field: 'plantas', 
                label: 'Plantas', 
                inputType: {type: InputType.EDIT_NUMERIC}
            },
        ];
    
    public formFieldsTrabajadores: ItemInterface<object>[] = [
        {
            field: 'ids_trabajadores',
            label: 'Profesionales',
            inputType: { type: InputType.MULTISELECT },
            values: this.trabajadores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: { value: { activo: string; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                return true;
            }
        },
    ];

    public formFieldsOrdenTrabajo: ItemInterface<object>[] = [
        {
            field: 'orden_trabajo', 
            label: 'Orden de trabajo', 
            inputType: { type: checkIfRolAdmin() ? InputType.TEXTAREA : InputType.EDIT_TEXTAREA },
        },
       
    ];
    public formFieldsObs: ItemInterface<object>[] = [
        { 
            field: 'observaciones', 
            label: 'Observaciones', 
            inputType: { type: InputType.EDIT_TEXTAREA } 
        },
    ];
  


    public formChanges(tag: string) {
        this.lastFieldChanged = tag;
    }

}