import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-fichajes',
    templateUrl: './fichajes.component.html',
    styleUrls: ['./fichajes.component.scss']
})
export class FichajesComponent {

    public appName = environment.appName;
    public showTrabajadoresCuadrillas = environment.features.showTrabajadoresCuadrillas;
    public showFichajesCargos = environment.features.showFichajesCargos;
    public showFichajesExportarCSV = environment.features.showFichajesExportarCSV;
    public showFormatHourMinute = environment.features.showFormatHourMinute;

}
