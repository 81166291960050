import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseForm} from '../../../../base-form';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {ProductosApiService} from '../../../../../service/api/productos-api';
import {TrabajadoresApiService} from '../../../../../service/api/trabajadores-api.service';
import {TareasApiService} from '../../../../../service/api/tareas-api.service';
import {build, pool, buildParams} from '../../../../../common/classes/request-builder';
import {FileUploadAnswer, Utils} from '../../../../../common/utils';
import {OtrasFormDefinition} from './form-control/form-definition';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { FormRequestTypes } from '../../../../../common/classes/form-request';
import { environment } from '../../../../../../environments/environment';
import { StorageManager } from '../../../../../common/storage-manager.class';
import { 
    InputType, 
    ItemInterface, 
    ItemValuesInterface, 
    TypeAheadInterface 
} from '../../../../../common/components/form-builder/form-builder.component';
import { ClientesApiService } from '../../../../../service/api/clientes-api.service';
import moment from 'moment';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsuariosApiService } from '../../../../../service/api/usuarios-api.service';

import type { OnInit } from '@angular/core';
import { TareaOtraModel } from 'src/app/models/tareas/tarea-otra.model';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { catchError, map, of } from 'rxjs';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';

@Component({
    selector: 'app-otros-form',
    templateUrl: './otros-form.component.html',
    styleUrls: ['./otros-form.component.scss'],
    providers: [MessageService]
})
export class OtrosFormComponent extends BaseForm<TareaOtraModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<TareaOtraModel>();

    public appName = environment.appName;
    public hasClients = environment.features.hasClients;
    public clienteName = environment.features.clienteName;
    public userCanSeeClientes = environment.features.userCanSeeClientes
        ? true
        : (StorageManager.getUser().rol === 'admin');
    public horaFechaFinActualPorDefecto = environment.features.horaFechaFinActualPorDefecto;
    public tipoSuperficieParcelas = environment.features.hasSuperficieCultivada ? 'Sup.\xa0Cultivada' : 'S.\xa0Sigpac';
    public parcelasName = environment.features.parcelasName;
    public showTareasOtrosDuracion = environment.features.showTareasOtrosDuracion;
    public showTareasOtrosImagen = environment.features.showTareasOtrosImagen;
    public textoSuperficie = environment.features.textoSuperficie;
    public activateRequireHourForRolUser = this.appName === 'ava' ? StorageManager.getUser().rol !== 'admin' ? true : false : false;


    public seleccionados = 0;

    public distributionOrder = (this.appName !== 'ava') ? [3, 4, 4, 4] : [3, 4, 3];

    public form: OtrasFormDefinition = new OtrasFormDefinition();
    public formRequest = new AppFormRequest<TareaOtraModel>();
    public override model: TareaOtraModel = {};

    public idxMaquinaria = '';
    public adjunto: string | ArrayBuffer | null | undefined = null;

    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public clientesApi: ClientesApiService,
        public usersApi: UsuariosApiService,
        public fincasApi: FincasApiService,
        public productosApi: ProductosApiService,
        public trabajadoresApi: TrabajadoresApiService,
        public tareasApi: TareasApiService,
        private messageService: MessageService,
        public override router: Router,
        public http: HttpClient
    ) {
        super(
            route,
            router,
            dashboard,
            tareasApi.otras.PUT,
            tareasApi.otras.POST,
            'tareas-otros',
            'Actualizar Tarea',
            'Realizar Tarea Genérica',
            'Duplicar Tarea'
        );
    }

    ngOnInit() {
        this.expandFormFields();
        this.expandFormRequest();
        let fieldsToSend = [''];
        if ( this.showTareasOtrosImagen) {
            fieldsToSend = (['id', 'id_usuario', 'cultivo', 'variedad', 'sup_cultivada', 'adjunto']).concat(
                this.form.formFields.map(it => it.field ?? '')
            );
        } else {
            fieldsToSend = (['id', 'id_usuario', 'cultivo', 'variedad', 'sup_cultivada']).concat(
                this.form.formFields.map(it => it.field ?? '')
            );
        }

        if (this.hasClients) {
            fieldsToSend.push('id_cliente');
        }

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setPostRequest(this.tareasApi.otras.POST)
            .setPutRequest(this.tareasApi.otras.PUT)
            .setGetRequest(this.tareasApi.otras.GET)
            .setFormFields(this.form.formFields)
            .setFieldsToSend(Array.prototype.concat(this.form.FormFieldsObs.map(it => it.field)).concat(fieldsToSend));

        this.formRequest.load();
        this.softInit(this.getType());
    }

    public submit() {
        if ( this.showTareasOtrosDuracion && this.activateRequireHourForRolUser) {
            if ( this.comprobarHoraInicioFin(this.model) ) {
                return;
            };
        };
        this.formRequest.beforeSend(resolve => {
            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromFinca = (this.form.fincas.selected || {}).id_cliente;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
                clientIdFromFinca ? clientIdFromFinca :
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() || {}).id;
            const userIdFromFinca = (this.form.fincas.selected || {}).id_usuario;
            const userIdFromForm = this.model['id_usuario'];

            this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';

            this.model.fecha = this.model.fecha ?
                Utils.formatDate(this.model.fecha)
                : this.model.fecha ?? '';

            this.model.fecha_fin =
                this.model.fecha_fin ?
                    Utils.formatDate(this.model.fecha_fin) :
                    this.model.fecha_fin ?? '';

            if (this.form.showTareasOtrosDuracion) {
                if (this.model.hora_inicio && moment(this.model.hora_inicio).isValid()) {
                    this.model.hora_inicio = moment(this.model.hora_inicio).format('HH:mm');
                }
                if (this.model.hora_fin && moment(this.model.hora_fin).isValid()) {
                    this.model.hora_fin = moment(this.model.hora_fin).format('HH:mm');
                }
            }

            const horaFin = (this.model.hora_fin as string).indexOf(':') > -1 ?  
                moment(this.model.hora_fin, 'HH:mm').format('HH:mm') : '00:00';
            const horaInicio =
                (this.model.hora_inicio as string).indexOf(':') > -1 ?
                    moment(this.model.hora_inicio, 'HH:mm').format('HH:mm') :
                    '00:00';
            const horas = (this.model.horas as string).indexOf(':') > -1 ?  moment(this.model.horas, 'HH:mm').format('HH:mm') : '00:00';

            this.model.hora_inicio = horaInicio;
            this.model.hora_fin = horaFin;

            delete this.model.parcelas_superficie;

            if ( this.appName !== 'fotoagricultura' ){
                delete this.model.fecha_fin;
                delete this.model.fecha_inicio;
                this.model.horas = horas;
            }
            this.model.cultivo = (this.form.parcelas.selected || []).map((it: VisitasVariedadMesModel) => it.cultivo).join(';');
            this.model.variedad = (this.form.parcelas.selected || []).map((it: VisitasVariedadMesModel) => it.variedad).join(';');
            this.model.sup_cultivada = (this.form.parcelas.selected || []).map((it: ParcelasModel) => it.superficie_cultivada).join(';');

            if (this.appName !== 'onda' && this.appName !== 'lacooperativa'){
                delete this.model.estado_tarea;
                delete this.model.empresa_pagadora;
            }// if();

            resolve(true);
        });

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send();

        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    formatoHorasMinutos(horaEntrada: string, horaSalida: string) {
        let timeStr: string | string[] = horaEntrada;
        timeStr = timeStr.split(':');

        const h = timeStr[0] ?? '',
            m = timeStr[1] ?? '';

        const newTime = moment( horaSalida + ' 2021-4-5')
            .subtract({'hours': +h, 'minutes': +m})
            .format('HH:mm');


        return newTime;

    }


    public formChanges(tag: string) {
        console.log('FormChanges triggered with tag:', tag);

        if (tag === 'hora_inicio' || tag === 'hora_fin') {
            if (
                environment.features.showTareasMto ||
                environment.features.tareasHorasTotales ||
                environment.features.showTareasOtrosDuracion
            ) {
                if (this.model.hora_inicio != null && this.model.hora_fin != null) {
                    let horaFin = moment(this.model.hora_fin).format('HH:mm');
                    let horaInicio = moment(this.model.hora_inicio).format('HH:mm');
                    const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
                    if (!timeFormat.test(horaInicio)) {
                        horaInicio = this.model.hora_inicio;
                    }

                    if (!timeFormat.test(horaFin)) {
                        horaFin = this.model.hora_fin.toString();
                    }

                    const diferencia = this.formatoHorasMinutos(horaInicio, horaFin);
                    this.model.horas_totales = diferencia;
                } else {
                    this.model.horas_totales = '';
                }
            }
        }


        if ( this.model.id_maquinaria == null ) {
            this.model.id_maquinaria = null;
        }

        this.requestButton.error = '';

        if (tag === 'id_finca') {
            this.model.id_sector = null;
            this.model.ids_parcelas = null;
        }
        if (tag === 'id_sector') {
            this.model.ids_parcelas = null;
        }
        if (tag === 'id_cliente') {
            this.model.id_finca = null;
            this.model.id_sector = null;
            this.model.ids_parcelas = null;

            // console.log(this.form.fincas, this.form.sectores, this.form.parcelas);

            pool([
                buildParams(this.fincasApi.fincasGET, {id_cliente: this.model.id_cliente}, this.form.fincas,
                    'nombre', true),
                buildParams(this.fincasApi.sectoresGET, {id_cliente: this.model.id_cliente}, this.form.sectores,
                    'nombre', true),
                buildParams(this.fincasApi.parcelasGET, {id_cliente: this.model.id_cliente}, this.form.parcelas,
                    'nombre', true),
            ], () => {
                this.form.fincas.filtered = this.form.fincas.values;
                this.form.sectores.filtered = [];
                this.form.parcelas.values = this.form.parcelas.values.filter(it => (it.label !== '...'));
                this.formRequest.update();
            }).then(() => {
                this.formRequest.update();
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );

        }

        if (tag === 'ids_trabajadores') {
            const idxTrabajador = this.getItemPosition(this.form.formFields, 'ids_trabajadores');

            if ((this.form?.formFields[idxTrabajador]?.values as ItemValuesInterface<MantenimientoModel[]>).selected?.length === 1) {
                if (
                    !((this.form.formFields[idxTrabajador]?.values as ItemValuesInterface<MantenimientoModel[]>
                    ).selected?.[0] as MantenimientoModel).id_maquina) {
                } else {
                    this.model.id_maquinaria = ((this.form.formFields[idxTrabajador]?.values as ItemValuesInterface<MantenimientoModel[]>
                    ).selected?.[0] as MantenimientoModel).id_maquina ?? '';
                }
            }
        }
        this.countTrabajadores();
        this.countSuperficieParcelas();
    }

    public countTrabajadores() {
        if (this.model.ids_trabajadores) {
            this.seleccionados = this.model.ids_trabajadores.split(';').length;
            if (this.model.ids_trabajadores) {
                this.seleccionados = 1;
            }
        } else {
            this.seleccionados = 0;
        }
    }

    public countSuperficieParcelas() {
        const ids = (this.model.ids_parcelas || '').split(';');
        this.model.parcelas_superficie = 0;

        (this.form.parcelas.filtered || []).forEach(parcela => {
            if (parcela && parcela.value && ids.includes(parcela.value.id)) {
                if(this.model.parcelas_superficie) {
                    this.model.parcelas_superficie += parseFloat((parcela.value.superficie_cultivada || '').replace(',', '.') || '0.00');
                }
            }
        });
        this.model.parcelas_superficie = +Utils.decimalFormat(this.model.parcelas_superficie, 2, '.', ',', 3);

        const idxParcelas = this.getItemPosition(this.form.formFields, 'ids_parcelas');
        
        if (this.form.formFields[idxParcelas]) {
            (this.form.formFields[idxParcelas] as ItemInterface<ParcelasModel>).label = 
                this.parcelasName + ' (' + this.tipoSuperficieParcelas
            + ':\xa0' + this.model.parcelas_superficie + '\xa0' + this.textoSuperficie + ')';
        }
    }

    public selectAll() {
        this.countSuperficieParcelas();
    }

    public override getItemPosition(formFields: ItemInterface<object>[], field: string) {
        let position = 0;
        formFields.forEach((item, index) => {
            if (item.field === field) {
                position = index;
            }
        });
        return position;
    }

    public getObservaciones( event: string) {
        this.model.observaciones = event;
    }
    /**
 *
  hora_fin: "13:02"
  hora_inicio: "14:01"
 */
    public comprobarHoraInicioFin( model: TareaOtraModel ){

        if ( model.hora_inicio == null ||  model.hora_fin == null ) {
            this.messageService.add({summary: 'Error en horas', detail: 'Hora inicio y hora fin son necesarias'});
            return true;
        } else {
            let fechaInicio = '';
            let fechaFin = '';
            if (moment(this.model.hora_inicio).isValid()) {
                fechaInicio = moment(this.model.hora_inicio).format('HH:mm');
            } else {
                fechaInicio = this.model.hora_inicio ?? '';
            }

            if ( moment(this.model.hora_fin).isValid() ) {
                fechaFin =  moment(this.model.hora_fin).format('HH:mm');
            } else {
                fechaFin =  this.model.hora_fin?.toString() ?? '';
            }

            if ( fechaInicio === fechaFin ) {
                this.messageService.add({summary: 'Error en horas', detail: 'Hora inicio y hora fin no pueden ser iguales'});
                this.requestButton.error = 'Hora inicio y hora fin no pueden ser iguales';
                return true;
            }
            const timePartsInicio: string[] = fechaInicio.split(':');

            let h = 0;
            if (timePartsInicio[0]){
                h = +timePartsInicio[0];
            }

            let m = 0;
            if (timePartsInicio[1]){
                m = +timePartsInicio[1];
            }
            const resultInicio = this.milliseconds(h, m, 0);


            const timePartsFin = fechaFin.split(':');

            if (timePartsFin[0]){
                h = +timePartsFin[0];
            }

            if (timePartsFin[1]){
                m = +timePartsFin[1];
            }
            const resultFin = this.milliseconds(h, m, 0);
            if ( resultInicio >=  resultFin ){
                this.messageService.add({
                    summary: 'Error en horas',
                    detail: 'Hora inicio y hora fin no pueden ser iguales, ni la hora fin anterior a la hora de inicio'
                });
                this.requestButton.error = 'Hora inicio y hora fin no pueden ser iguales, ni la hora fin anterior a la hora de inicio';
                return true;
            }
            return  false;

        }

    }

    public milliseconds(h: number, m: number, s: number) {
        return ((h * 60 * 60 + m * 60 + s) * 1000);
    }

    // Adjuntos
    public readUrl(input: HTMLInputElement) {
        input.click();
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.adjunto = e.target?.result;
            };
            reader.readAsDataURL(input.files[0]);
            const r = this.http.post(environment.serverUrl + 'ws/seguimiento/upload_file.php', this.adjunto).subscribe(res => {
                const file = res as FileUploadAnswer;
                this.model.adjunto = file.fileName;
                r.unsubscribe();
            });
        }
    }
    public fileChange(event: Event ) {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            const fileList: FileList = input.files;
            if (fileList.length > 0) {
                const file: File = fileList[0] ?? new File([],'');
                const formData: FormData = new FormData();
                formData.append('uploadFile', file, file.name);
                const headers = new HttpHeaders();
                headers.append('Content-Type', 'multipart/form-data');
                headers.append('Accept', 'application/json');

                const name: string[] = file.name.split('.');
                const ext = name[name.length - 1];
                const accepted = ['gif', 'jpeg', 'jpg', 'jiff', 'png', 'svg', 'tiff'];
                let cond = false;

                for (const e of accepted) {
                    if (e === ext) {
                        cond = true;
                    }
                }
                
                if (cond) {
                    this.http.post<FileUploadAnswer>(
                        `${environment.serverUrl}ws/productos/upload_file.php`, 
                        formData, 
                        {headers: headers}
                    ).pipe(
                        map(data => {
                            const res = data || {};
                            this.model.adjunto = res.fileName;
                        }),
                        catchError(error => {
                            console.log(error);
                            return of(null);
                        })
                    ).subscribe();
                } else {
                    alert('Formato de imágen no válido.');
                    this.model.adjunto = '';
                }
            }
        }
    }

    public verAdjunto() {
        const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
        myWindow?.location.assign(environment.serverUrl + 'tareas_otros/' + this.model.adjunto);
        myWindow?.focus();
    }

    private expandFormFields() {
        if (this.hasClients && this.userCanSeeClientes && (this.getType() === FormRequestTypes.DUPLICATE)) {
            this.form.formFields.unshift({
                field: 'id_cliente',
                label: this.clienteName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.form.clientes,
                valuePrimaryKey: 'id',
                required: true
            });
        }
        this.formRequest.beforeSend(resolve => {
            if ( this.appName !== 'fotoagricultura') {
                const horaFin = moment(this.model.hora_fin, 'HH:mm').format('HH:mm');
                const horaInicio = moment(this.model.hora_inicio, 'HH:mm').format('HH:mm');
                const horas = moment(this.model.horas, 'HH:mm').format('HH:mm');
                this.model.hora_inicio = horaInicio;
                this.model.hora_fin = horaFin;
                this.model.horas = horas;
            }

            resolve(true);
        });
    }
    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {
            if (this.horaFechaFinActualPorDefecto && this.getType() !== FormRequestTypes.EDIT) {
                const fechaActual = new Date();

                if (this.appName !== 'sirga') {
                    this.model.fecha = fechaActual.toString();
                }

                this.model.hora_fin = fechaActual.toString();
            }

            const intervalo = setInterval( () => {
                if ( this.form.maquinarias.filtered.length <= 1 ) {

                } else {
                    this.form.maquinarias.filtered.forEach(row => {

                        if (row.value && row.value.fecha_ultima_inspeccion) {
                            const auxFechaCad = (row.value.fecha_ultima_inspeccion as string).split('/');

                            if ( parseInt(auxFechaCad[0] ?? '', 10) >= 2020 ) {
                                auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + 3).toString();
                            } else {
                                auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + 5).toString();
                            }

                            const fechaCaducidad = auxFechaCad[0] + '/' + auxFechaCad[1] + '/' + auxFechaCad[2];

                            if (moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment())) {
                                row.label = '** ' + row.label;
                            } else if (
                                moment(fechaCaducidad, 'DD/MM/YYYY').isSameOrAfter(moment()) &&
                                moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment().add(3, 'months'))
                            ) {
                                row.label = '* ' + row.label;
                            }
                        }
                    });

                    if (this.getType() === FormRequestTypes.DUPLICATE) {
                        const idxSector = this.getItemPosition(this.form.formFields, 'id_sector');

                        if (this.form.formFields[idxSector]) {
                            const sectorValues = this.form.formFields[idxSector]?.values as TypeAheadInterface<SectorModel> | undefined;
                        
                            sectorValues?.filtered?.forEach((sector) => {
                                if (sector.value?.id === this.model.id_sector && this.form.formFields[idxSector]) {
                                    const formField = this.form.formFields[idxSector]?.values as TypeAheadInterface<SectorModel>;
                                    formField.selected = sector.value;
                                }
                            });
                        }
                    }// if();

                    clearInterval(intervalo);
                }
            }, 500);
            resolve(true);
        });

        this.formRequest.beforeLoad(resolve => {

            this.initFormData().then(() => {
                resolve(true);
                return;
            }).catch (e => {
                console.log('catch en initFormData: ' + e);
            }
            );
        });

        this.formRequest.afterFinish(resolve => {
            if (this.getType() === FormRequestTypes.DUPLICATE) {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
            resolve(true);
        });
    }


    private initFormData(): Promise<boolean> {

        const buildsRequest  = [
            build(this.clientesApi.clientesGET, this.form.clientes, 'nombre'),
            build(this.fincasApi.fincasGET, this.form.fincas, 'nombre', true),
            build(this.fincasApi.sectoresGET, this.form.sectores, 'nombre', true),
            build(this.fincasApi.parcelasGET, this.form.parcelas, 'nombre', true),
            build(this.trabajadoresApi.maquinariaGET, this.form.maquinarias, 'nombre', true),
            build(this.trabajadoresApi.trabajadoresGET, this.form.trabajadores,
                (it: MantenimientoModel) => it.nombre + ' ' + (it.apellidos || '') + (it.maquinaria
                    ? ' - (' + (it.maquinaria || '...') + ')'
                    : ''),
                true, true),
            build(this.tareasApi.listaTareas.GET, this.form.tareas, 'nombre'),
            build(this.productosApi.productos.GET, this.form.productos, 'nombre')
        ];

        if (environment.features.showAssignUserTasksFitosanitario || environment.features.showAssignUserInAllTasks) {
            buildsRequest.push(build(this.usersApi.usuariosGET, this.form.users, 'nombre'));
        }

        return pool(buildsRequest, () => {
            setTimeout(() => {
                this.countTrabajadores();
                this.countSuperficieParcelas();
                this.formRequest.update();
                if ( this.form.fincas.filtered.length === 2  && this.getType() === 0 ){
                    this.model.id_finca = this.form.fincas.filtered[1].value.id;
                }
            }, 100);

            this.formRequest.update();

        });
    }

}
