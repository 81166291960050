<br>

<div style="width: auto; overflow: auto">
    <app-common-list-table
        [request]="getRequest"
        [cols]="cols"
        [minWidth]="1000"
        [minHeight]="400"
        [globalConditionalStyle]="globalConditionalStyle"
        [globalVisualTransform]="globalVisualTransform"
        [filtering]="filtering"
        [showExportCsv]="true">

        <ng-template #thStart>
            <th>
                <p class="d-flex justify-content-center" style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>
        <ng-template #tdStart let-data>
            <td>
                <div class="px-3">
                    <app-action-buttons
                        (showRowButtonEvent)="show('info', data)"
                        (editRowButtonEvent)="edit(data)"
                        (deleteRowButtonEvent)="show('delete', data)">
                    </app-action-buttons>
                </div>
            </td>
        </ng-template>
        <ng-template #header>
            <div class="d-flex justify-content-center rounded">
                <div class="d-flex align-items-center py-1 px-2" style="border-radius: 2px;">
                    <span class="mr-1">Semana</span>
                    <p-select
                        [options]="weeks"
                        [(ngModel)]="currentWeek"
                        optionLabel="name"
                        (onChange)="filterValues()">
                    </p-select>
                </div>
                <div class="d-flex align-items-center py-1 px-2" style="border-radius: 2px;">
                    <span class="mr-1">Año</span>
                    <p-select
                        [options]="years"
                        [(ngModel)]="currentYear"
                        optionLabel="name"
                        (onChange)="filterValues()">
                    </p-select>
                </div>
            </div>
        </ng-template>
    </app-common-list-table>
</div>

<p-dialog
    *ngIf="canShow['info']"
    header="Detalle del registro"
    [(visible)]="canShow['info']"
    (onShow)="scrollToTop()">

  <div style="width: 80vw !important">
    <app-form-builder
        [fields]="formFields"
        [distributionColumns]="4"
        [model]="model">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
  <br>

</p-dialog>

<p-dialog
    *ngIf="canShow['delete']"
    header="Eliminar registro"
    [(visible)]="canShow['delete']"
    (onShow)="scrollToTop()">

  <div style="width: 80vw !important">
    <app-form-builder
            [fields]="formFields"
            [distributionColumns]="4"
            [model]="model">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
              (click)="deleteRegister()"
              [request]="deleteRequest"
              [mode]="deleteRequestButtonType">
      </app-request-button>
    </div>
    <br>
    <p *ngIf="(deleteRequest?.hasError === undefined) && !deleteRequest?.isLoading"
       style="color: red; text-align: center">
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br>

</p-dialog>
