

<div style="width: auto; overflow: auto">
  <app-common-list-table
          [request]="getRequest"
          [cols]="cols"
          [filtering]="filter"
          [globalVisualTransform]="globalVisualTransform"
          [customExportCsv]="showTareasBioExportCsv"
          actionButtonText="Añadir Tarea"
          (actionButtonClick)="add()">
          <ng-template #header>
            <div *ngIf="this.rolUser ==='responsable'  || this.appName === 'benibal'"  style="margin-left: 20px;">
              <button class="mx-2 btn" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white;">
                  <i class="fa fa-download"></i>
                  Exportar listado
              </button>
            </div>
          </ng-template>
    <ng-template #thEnd>
      <th>
        <p class="d-flex justify-content-center"
           style="margin-bottom: 0">Acciones</p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td>
        <app-action-buttons
                [showDuplicate]="true"
                (duplicateRowButtonEvent)="duplicate(data)"        
                (editRowButtonEvent)="edit(data)"
                (deleteRowButtonEvent)="show('delete', data)"
                (showRowButtonEvent)="show('info', data)">
        </app-action-buttons>
      </td>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog 
  *ngIf="canShow['info']"
  header="Detalle del registro" 
  [(visible)]="canShow['info']" 
  (onShow)="scrollToTop()"
>

  <div style="width: 80vw !important">
    <div class="row">
      <div class="mb-5 col-sm-12">
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          [fields]="formFieldsPlaces">
        </app-form-builder>
      </div>
    </div>
    <div class="row">
      <div class="mb-5 col-sm-12">
        <h5>Cubiertas Vegetales Espontáneas o Sembrada</h5>
        <app-form-builder
          [model]="model"
          [distributionColumns]="4"
          [fields]="formFields">
        </app-form-builder>
      </div>
    </div>
    <div class="row">
      <div class="mb-5 col-sm-12">
        <h5>Cubiertas Inertes (Restos Poda)</h5>
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          [fields]="formFieldsCubiertasInertes">
        </app-form-builder>
      </div>
    </div>
    <div *ngIf="appName === 'sequiador' || appName === 'SIEX'" class="row">
      <div class="mb-5 col-sm-12">
        <h5>Arroz</h5>
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          [fields]="formFieldsArroz">
        </app-form-builder>
      </div>
    </div>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
  <br>

</p-dialog>

<p-dialog
  *ngIf="canShow['delete']"
  header="Eliminar registro"
  [(visible)]="canShow['delete']"
  (onShow)="scrollToTop()">

  <div style="width: 80vw !important">
    <div class="row">
      <div class="mb-5 col-sm-12">
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          [fields]="formFieldsPlaces">
        </app-form-builder>
      </div>
    </div>
    <div class="row">
      <div class="mb-5 col-sm-12">
        <h5>Cubiertas Vegetales Espontáneas o Sembrada</h5>
        <app-form-builder
          [model]="model"
          [distributionColumns]="4"
          [fields]="formFields">
        </app-form-builder>
      </div>
    </div>
    <div class="row">
      <div class="mb-5 col-sm-12">
        <h5>Cubiertas Inertes (Restos Poda)</h5>
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          [fields]="formFieldsCubiertasInertes">
        </app-form-builder>
      </div>
    </div>
    <div *ngIf="appName === 'sequiador' || appName === 'SIEX'" class="row">
      <div class="mb-5 col-sm-12">
        <h5>Arroz</h5>
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          [fields]="formFieldsArroz">
        </app-form-builder>
      </div>
    </div>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
              (click)="deleteRegister()"
              [request]="deleteRequest"
              [mode]="deleteRequestButtonType">
      </app-request-button>
    </div>
    <br>
    <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
       style="color: red; text-align: center">
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br>

</p-dialog>