import { Injectable } from '@angular/core';
import { LoginApiService } from 'src/app/service/api/login-api.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private loginApi: LoginApiService) {}

    public isAuthenticated(): boolean {
        return this.loginApi.isLoggedIn();
    }

}
