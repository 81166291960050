<br>
<div style="width: auto; overflow: auto">
    <app-common-list-table
            [request]="tableValues"
            [minWidth]="800"
            [cols]="cols"
            [globalConditionalStyle]="globalConditionalStyle"
            [globalVisualTransform]="globalVisualTransform"            
            [actionButtonText]="canAddProductos ? buttonText : ''"
            (actionButtonClick)="addProducto()">

        <ng-template #thEnd>
            <th style="width: 120px;">
                <p class="d-flex justify-content-center"
                   style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons
                        [hideEdit]="!canEditProductos"
                        [hideDelete]="!canDeleteProductos"
                        (editRowButtonEvent)="edit(data)"
                        (deleteRowButtonEvent)="show('delete', data)"
                        (showRowButtonEvent)="show('info', data)">
                </app-action-buttons>
            </td>
        </ng-template>

    </app-common-list-table>
</div>

<p-dialog
    *ngIf="canShow['info']"
    header="Detalle del registro"
    [(visible)]="canShow['info']"
    (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <h4>Datos del fertilizante</h4>
    <div class="form-row">
        <div class="form-group col-sm-12">
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    [fields]="topFormFields">
            </app-form-builder>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-sm-6">
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="2"
                    [fields]="bottomFormFieldsLeft">
            </app-form-builder>
        </div>
        <div class="form-group col-sm-6">
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="6"
                    [fields]="bottomFormFieldsRight">
            </app-form-builder>
        </div>
    </div>
    <div class="form-row">
        <div class="col-sm-12">
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="2"
                    [fields]="baselineFormFields">
            </app-form-builder>
        </div>
    </div>
    <br>
    <h4 *ngIf="applicationType === 'cropgest'" >Empresa</h4>
    <div class="form-row" *ngIf="applicationType === 'cropgest'">
        <div class="col">   
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="4"
                        [fields]="formFieldsEmpresaResiduos">
                </app-form-builder>
        </div>
    </div>
    <br>
    <h4 *ngIf="applicationType === 'cropgest'" >Macronutrientes</h4>
    <div class="form-row" *ngIf="applicationType === 'cropgest'">
        <div class="col">   
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="4"
                        [fields]="formFieldsMaterialFerti">
                </app-form-builder>
        </div>
    </div>
    <br>
    <h4 *ngIf="applicationType === 'cropgest'" >Metales Pesados</h4>
    <div class="form-row" *ngIf="applicationType === 'cropgest'">
        <div class="col">   
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="4"
                        [fields]="formFieldsMetalesPesados">
                </app-form-builder>
        </div>
    </div>

<!--         <div class="form-row">
            <div class="col-sm-12">
                <form-builder
                        [model]="model"
                        [distribution-columns]="1"
                        [fields]="baselineFormFields">
                </form-builder>
            </div>
        </div> -->
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog>

<p-dialog
    *ngIf="canShow['delete']"
    header="Eliminar registro"
    [(visible)]="canShow['delete']"
    (onShow)="scrollToTop()">

    <div style="width: 80vw !important">
        <div class="form-row">
            <div class="form-group col-sm-12">
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="3"
                        [fields]="topFormFields">
                </app-form-builder>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-sm-6">
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="2"
                        [fields]="bottomFormFieldsLeft">
                </app-form-builder>
            </div>
            <div class="form-group col-sm-6">
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="6"
                        [fields]="bottomFormFieldsRight">
                </app-form-builder>
            </div>
        </div>
        <div class="form-row">
            <div class="col-sm-12">
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="2"
                        [fields]="baselineFormFields">
                </app-form-builder>
            </div>
        </div>
        <br>
        <h4 *ngIf="applicationType === 'cropgest'" >Empresa</h4>
        <div class="form-row" *ngIf="applicationType === 'cropgest'">
            <div class="col">   
                    <app-form-builder
                            [model]="model"
                            [distributionColumns]="4"
                            [fields]="formFieldsEmpresaResiduos">
                    </app-form-builder>
            </div>
        </div>
        <br>
        <h4 *ngIf="applicationType === 'cropgest'" >Macronutrientes</h4>
        <div class="form-row" *ngIf="applicationType === 'cropgest'">
            <div class="col">   
                    <app-form-builder
                            [model]="model"
                            [distributionColumns]="4"
                            [fields]="formFieldsMaterialFerti">
                    </app-form-builder>
            </div>
        </div>
        <br>
        <h4 *ngIf="applicationType === 'cropgest'" >Metales Pesados</h4>
        <div class="form-row" *ngIf="applicationType === 'cropgest'">
            <div class="col">   
                    <app-form-builder
                            [model]="model"
                            [distributionColumns]="4"
                            [fields]="formFieldsMetalesPesados">
                    </app-form-builder>
            </div>
        </div>    
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button
                    (click)="deleteRegister()"
                    [request]="deleteRequest"
                    [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
           style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>
</p-dialog>
