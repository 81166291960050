import { HttpClient } from '@angular/common/http';
import { TrabajadoresApiService } from 'src/app/service/api/trabajadores-api.service';
import {TareasApiService} from '../../../../../service/api/tareas-api.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {Component,ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import { TareasEdificiosFormDefinition } from './form-control/form-definition';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { pool, buildParams, build } from 'src/app/common/classes/request-builder';
import { ClientesApiService } from 'src/app/service/api/clientes-api.service';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { FileUploadAnswer, Utils } from 'src/app/common/utils';
import { environment } from 'src/environments/environment';
import { FormRequestTypes } from 'src/app/common/classes/form-request';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import moment from 'moment';
import { firstValueFrom } from 'rxjs';

import type { OnDestroy, OnInit } from '@angular/core';
import { TareaEdificiosModel } from 'src/app/models/tareas/tarea-edificios.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';

const TRUTHY_VALUES = [1, '1', true, 'true'];

@Component({
    selector: 'app-tareas-edificios-form',
    templateUrl: './tareas-edificios-form.component.html',
    styleUrls: ['./tareas-edificios-form.component.scss']
})
export class TareasEdificiosFormComponent extends BaseForm<TareaEdificiosModel> implements OnInit, OnDestroy {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<TareaEdificiosModel>();

    public hasClients = environment.features.hasClients;
    public clienteName = environment.features.clienteName;
    public serverUrl = environment.serverUrl;
    public appName = environment.appName;

    public form: TareasEdificiosFormDefinition = new TareasEdificiosFormDefinition();
    public formRequest = new AppFormRequest<TareaEdificiosModel>();
    public override model: TareaEdificiosModel = {};  
    signatureFile: File = new File([], '');

    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public fincasApi: FincasApiService,
        public clientesApi: ClientesApiService,
        public tareasApi: TareasApiService,
        public trabajadoresApi: TrabajadoresApiService,
        public override router: Router,
        private http: HttpClient
    ) {
        super(
            route,
            router,
            dashboard,
            tareasApi.edificios.PUT,
            tareasApi.edificios.POST,
            'edificios',
            'Actualizar Tarea Edificio',
            'Realizar Tarea Edificio',
            'Duplicar Tarea Edificio'
        );
    }

    ngOnInit() {
        this.initFormData();
        this.expandFormRequest();

    

        const fieldsToSend = (['id', 'id_usuario', 'imagen', 'image_after', 'signature_img']).concat(
            this.form.formFields.map(it => it.field ?? ''),
            this.form.formFieldsJardin.map(it => it.field ?? ''),
            this.form.formFieldsPiscinaHoras.map(it => it.field ?? ''),
            this.form.formFieldsPiscinaTareas01.map(it => it.field ?? ''),
            this.form.formFieldsPiscinaTareas02.map(it => it.field ?? ''),
            this.form.formFieldsPiscinaTareas03.map(it => it.field ?? ''),
            this.form.formFieldsPiscinaProductos01.map(it => it.field ?? ''),
            this.form.formFieldsPiscinaProductos02.map(it => it.field ?? ''),
            this.form.formFieldsPiscinaProductos03.map(it => it.field ?? ''),
            this.form.formFieldsPiscinaRecambios.map(it => it.field ?? ''),
            this.form.formFieldsLimpiezaGeneral.map(it => it.field ?? ''),
            this.form.formFieldsRecambiosLuces.map(it => it.field ?? ''),
            this.form.formFieldsRecambiosPapelHigienico.map(it => it.field ?? ''),
            this.form.formFieldsRecambiosRejillas.map(it => it.field ?? ''),
            this.form.formFieldsRecambiosProductosLimpieza.map(it => it.field ?? ''),
            this.form.formFieldsObservaciones.map(it => it.field ?? ''),
            this.form.formFieldsOrdenTrabajo.map(it => it.field ?? ''),
            this.form.formFieldsTrabajadores.map(it => it.field ?? '')
        );

        if (this.hasClients) {
            fieldsToSend.push('id_cliente');
        }      

        if (this.appName === 'donjardin') {
            fieldsToSend.push('id_ref_usuario');
        }


        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.tareasApi.edificios.GET)
            .setPostRequest(this.tareasApi.edificios.POST)
            .setPutRequest(this.tareasApi.edificios.PUT)    
            .setFormFields(this.form.formFields.concat(this.form.formFieldsJardin)
                .concat(this.form.formFieldsPiscinaHoras)
                .concat(this.form.formFieldsPiscinaTareas01)
                .concat(this.form.formFieldsPiscinaTareas02)
                .concat(this.form.formFieldsPiscinaTareas03)
                .concat(this.form.formFieldsPiscinaProductos01)
                .concat(this.form.formFieldsPiscinaProductos02)
                .concat(this.form.formFieldsPiscinaProductos03)
                .concat(this.form.formFieldsPiscinaRecambios)
                .concat(this.form.formFieldsLimpiezaGeneral)
                .concat(this.form.formFieldsRecambiosLuces)
                .concat(this.form.formFieldsRecambiosPapelHigienico)
                .concat(this.form.formFieldsRecambiosRejillas)
                .concat(this.form.formFieldsRecambiosProductosLimpieza)
                .concat(this.form.formFieldsObservaciones)
                .concat(this.form.formFieldsOrdenTrabajo)
                .concat(this.form.formFieldsTrabajadores)
            ).setFieldsToSend(fieldsToSend);

        this.formRequest.load();
        
        this.softInit(this.getType());       
        // Añadir fecha actual solo cuando se crea el registro 
        if ( this.getType() === FormRequestTypes.CREATE ){
            this.model.fecha = new Date();
        }
        this.tareasApi.edificios.GET.response( resp => {
      
          
            if ( TRUTHY_VALUES.includes(resp[0]?.mantenimiento ?? '' )  ){
                this.form.formFields.forEach( it => {
                    if ( it.field === 'mantenimiento' ) {
                        it.label = 'Creación';
             
                    }
                });
            } else {
                this.form.formFields.forEach( it => {
                    if ( it.field === 'mantenimiento' ) {
                        it.label = 'Mantenimiento';
              
                    }
                });
            }
        });
    }

    updateImageModel( event: string  ) {
        this.model.imagen = event;
    }
    updateAfterImageModel( event: string  ) {
        this.model.imagen_after = event;
    }


    getSignature( event: File ) {
        this.signatureFile = event;
    }

    updateFirma(event: string) {
        this.model.signature_img = event;
        this.formRequest.update();
    
    }

    getObservaciones( event: string ) {
        this.model.observaciones = event;
    }

    public formChanges(tag: string) {

        this.model.horas_jardin = Utils.calculateTotalHours(this.model.hora_inicio_jardin, this.model.hora_fin_jardin);
        this.model.horas_piscina = Utils.calculateTotalHours(this.model.hora_inicio_piscina, this.model.hora_fin_piscina);
        this.model.horas_limpieza_general = Utils.calculateTotalHours(
            this.model.hora_inicio_limpieza_general, 
            this.model.hora_fin_limpieza_general
        );

        if (tag === 'mantenimiento')
        {

            if ( TRUTHY_VALUES.includes(this.model[tag]?.toString() ?? '' )  ){
                this.form.formFields.forEach( it => {
                    if ( it.field === 'mantenimiento' ) {
                        it.label = 'Creación';
            
                    }
                });
            } else {
                this.form.formFields.forEach( it => {
                    if ( it.field === 'mantenimiento' ) {
                        it.label = 'Mantenimiento';
            
                    }
                });
            }

        }
        if (tag === 'id_cliente') {
            this.model.id_finca = null;
            pool([
                buildParams(this.fincasApi.fincasGET, {id_cliente: this.model.id_cliente}, this.form.fincas,
                    'nombre', true),
            ], () => {
                this.form.fincas.filtered = this.form.fincas.values;
           
                this.formRequest.update();
            }).then(() => {
                this.formRequest.update();
                return;
            }).catch (e => {
                console.log('catch en pool: ' + e);
            }
            );
        
        }
    }

 
  
    public async submit() {

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
            return;
        }
 
    
        for (const key in this.model) {
            if (key.includes('hora')) {
                const claveHoraFin = key.replace(/inicio/g, 'fin');
                const horaInicio = moment(this.model[key]?.toString() ?? '', 'HH:mm');
                const horaFin = moment(this.model[claveHoraFin]?.toString() ?? '', 'HH:mm');
  
                const diferenciaEntreHoras = horaFin.diff(horaInicio);
                if (diferenciaEntreHoras < 0) {
                    this.requestButton.error = 'La hora de inicio es mayor que la fin.';
                    return;
                }
        
            }
      
        }

        if ( this.signatureFile != null ) {
            const formData = new FormData();
            formData.append('fileToUpload', this.signatureFile);
            formData.append('database', 'tareas_edificios');
            formData.append('folder', 'edificios');
            formData.append('field', 'signature_img');
            const signature = await firstValueFrom(
                this.http.post<FileUploadAnswer>( environment.serverUrl + 'ws/tareas/fileUploadGeneric.php', formData)
            );
            this.model.signature_img = signature.target_file.replace('../../images/edificios/', '');
        }

        this.formRequest.send();
      
        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    public getOrdenTrabajo( event: string ) {
        this.model.orden_trabajo = event;
    }



    private initFormData(): Promise<boolean> {
        return pool([
            build(this.fincasApi.fincasGET, this.form.fincas, 'nombre', true),
            build(this.clientesApi.clientesGET, this.form.clientes, {or: ['razon_social', 'nombre']}),
            build(this.trabajadoresApi.trabajadoresGET, this.form.trabajadores,
                (it: MantenimientoModel) => it.nombre + ' ' + (it.apellidos || ''), true )
        ], () => {
       
            if (this.getType() === FormRequestTypes.CREATE){
                this.fincasApi.fincasGET.toPromise().then( (resp_fincas: FincasModel[]) => {
             
                    if (resp_fincas.length > 0 ) {
                        this.model.id_finca = resp_fincas[0]?.id ?? '';
                    }
                    return;
                }).catch (e => {
                    console.log('catch en fincasApi: ' + e);
                }
                );
            }
       
            this.formRequest.update();
       
        });
    }

    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {

            const trueFields = [
                'barredera',
                'cepillado',
                'limpieza_red',
                'rascado_linea_flotacion',
                'lavado_enjuague_cesti',
                'limpieza_duchas',
                'limpieza_skimmer',
                'nivel_vaso',
                'limpieza_cuarto_depuradora'
            ];
      
            trueFields.forEach( f => {
                this.model[f] = TRUTHY_VALUES.includes(this.model[f]?.toString() ?? '');
            });

            const userInfo = StorageManager.getUser();
            if (userInfo.rol === 'user' && this.getType() === FormRequestTypes.CREATE){
                this.model.ids_trabajadores = userInfo.id.toString();
            }
      
            if (environment.features.showTareasMto && this.getType() === FormRequestTypes.DUPLICATE){
                delete this.model.hora_fin;
                delete this.model.hora_inicio;
                delete this.model.horas_totales;
            }

            if (environment.features.showTareasMto) {
                if (this.getType() === FormRequestTypes.EDIT ){
                    this.tareasApi.edificios.GET.response( (resp) => {
                        if ( resp[0]?.hora_fin_jardin == null || (resp[0].hora_fin_jardin as string).length === 0 ){
                            if ( StorageManager.getUser().rol === 'user'){
                                const date = new Date;
                                this.model.hora_fin_jardin = date.toString();
                            }
                        }
                        if ( resp[0]?.hora_fin_piscina == null || (resp[0].hora_fin_piscina as string).length === 0 ){
                            if ( StorageManager.getUser().rol === 'user'){
                                const date = new Date;
                                this.model.hora_fin_piscina = date.toString();
                            }
                        }
                    });
                }

                if (this.getType() === FormRequestTypes.DUPLICATE || this.getType() === FormRequestTypes.CREATE){
    
                    if ( StorageManager.getUser().rol === 'user'){
                        const date = new Date;
                        this.model.hora_fin_jardin = date.toString();
                        this.model.hora_fin_piscina = date.toString();
                    }
     
                }

                if ( environment.features.showTareasMto ) {
                    if ( StorageManager.getUser().rol === 'user'){
                        this.model.prioridad = 'Realizada';
                    }
                }  
            }
            resolve(true);
        });
        this.formRequest.beforeSend(resolve => {
            this.model.fecha = this.model.fecha instanceof Date
                ? Utils.formatDate(this.model.fecha)
                : this.model.fecha ?? '';


            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromFinca = (this.form.fincas.selected || {}).id_cliente;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
            clientIdFromFinca ? clientIdFromFinca :
                clientIdFromForm ? clientIdFromForm :
                    clientId ? clientId : ''; // DEFAULT

            const userId = StorageManager.getUser().id;
                    
            if ( this.getType() === FormRequestTypes.CREATE || this.getType() === FormRequestTypes.DUPLICATE){
                this.model.id_usuario = userId ? userId : '';
            }// if();
        
            this.model.mantenimiento = this.model.mantenimiento === true ? 1 : 0;
        
            for (const key in this.model) {
                if (key.includes('hora') ) {

                    const hora = moment(this.model[key]?.toString(), 'HH:mm').format('HH:mm');
                    this.model[key] = hora;
                    if ( !this.model[key]?.toString().includes(':') ) {this.model[key] = null; }
            
                }
            }
        
            resolve(true);
        });

        this.formRequest.afterSend(resolve => {
            StorageManager.saveSessionDataDonJardin(
                this.model.id_finca ?? '', 
                this.model.hora_inicio ?? '', 
                this.model.hora_fin?.toString() ?? ''
            );
            resolve(true);
        });

        this.formRequest.afterFinish(resolve => {
            if (this.getType() === FormRequestTypes.DUPLICATE) {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
            resolve(true);
        });

    }  
}