import { Injectable } from '@angular/core';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import { TratamientosModel } from 'src/app/models/tratamientos/tratamientos.model';
import { TratamientosControlModel } from 'src/app/models/tratamientos/tratamientos-control.model';
import { TratamientoManezModel } from 'src/app/models/tratamientos/tratatamiento-manez.model';

@Injectable({
    providedIn: 'root'
})
export class TratamientosApiService {

    public tratamientosGET = new RequestHandler<TratamientosModel[]>();
    public tratamientosDELETE = new RequestHandler<TratamientosModel>();
    public tratamientosPUT = new RequestHandler<TratamientosModel[]>();
    public tratamientosPOST = new RequestHandler<TratamientosModel[]>();
    public tratamientosControl = {
        GET: new RequestHandler<TratamientosControlModel[]>(),
        POST: new RequestHandler<TratamientosControlModel[]>(),
        PUT: new RequestHandler<TratamientosControlModel[]>(),
        DELETE: new RequestHandler<TratamientosControlModel>()
    };
    public tratamientosManez = {
        GET: new RequestHandler<TratamientoManezModel[]>(),
        POST: new RequestHandler<TratamientoManezModel[]>(),
        PUT: new RequestHandler<TratamientoManezModel[]>(),
        DELETE: new RequestHandler<TratamientoManezModel>()
    };
  
    public unidadesMedida = {
        GET: new RequestHandler<TratamientosModel[]>(),
        POST: new RequestHandler<TratamientosModel[]>(),
        PUT: new RequestHandler<TratamientosModel[]>(),
        DELETE: new RequestHandler<TratamientosModel>()    
    };

    public tratamientosList = new RequestHandler<TratamientosModel[]>();

    public enviarRecetaCaptura = new RequestHandler<TratamientosModel>();    

    constructor(
        private syncServiceTratamiento: SyncService<TratamientosModel[]>,
        private syncServiceTratamientoDelete: SyncService<TratamientosModel>,
        private syncServiceReceta: SyncService<TratamientosModel>,
        private syncServiceManez: SyncService<TratamientoManezModel[]>,
        private syncServiceManezDelete: SyncService<TratamientoManezModel>,
        private syncServiceControl: SyncService<TratamientosControlModel[]>,
        private syncServiceControlDelete: SyncService<TratamientosControlModel>,
        private apiTratamiento: ApiRequestService<TratamientosModel[]>,
        private apiTratamientoDelete: ApiRequestService<TratamientosModel>,
        private apiReceta: ApiRequestService<TratamientosModel>,
        private apiManez: ApiRequestService<TratamientoManezModel[]>,
        private apiManezDelete: ApiRequestService<TratamientoManezModel>,
        private apiControl: ApiRequestService<TratamientosControlModel[]>,
        private apiControlDelete: ApiRequestService<TratamientosControlModel>) {

        this.defineTratamientosGET();
        this.defineTratamientosPOST();
        this.defineTratamientosDELETE();
        this.defineTratamientosPUT();
        this.defineTratamientosList();
        this.defineTratamientosControl();
        this.defineTratamientosManez();

        this.defineEnviarRecetaCaptura();        
    }

    public defineEnviarRecetaCaptura() {
        this.enviarRecetaCaptura.fetch(HttpRequestType.POST, 'tareas/enviar_receta_captura');
        this.apiReceta.registerRequest(this.enviarRecetaCaptura);
        this.syncServiceReceta.register(this.syncServiceReceta.baseSyncPolicy(this.enviarRecetaCaptura));
    }    

    public defineTratamientosGET() {
        this.tratamientosGET
            .fetch(HttpRequestType.GET, 'generic/tratamientos');

        this.apiTratamiento
            .registerRequest(this.tratamientosGET);

        this.syncServiceTratamiento
            .register(this.syncServiceTratamiento.baseSyncPolicy(this.tratamientosGET));
    }

    public defineTratamientosList() {
        this.tratamientosList
            .fetch(HttpRequestType.GET, 'tratamientos/tratamientos_get');

        this.apiTratamiento
            .registerRequest(this.tratamientosList);

        this.syncServiceTratamiento
            .register(this.syncServiceTratamiento.baseSyncPolicy(this.tratamientosList));
    }

    public defineTratamientosPOST() {
        this.tratamientosPOST
            .fetch(HttpRequestType.POST, 'generic/tratamientos');

        this.apiTratamiento
            .registerRequest(this.tratamientosPOST);

        this.syncServiceTratamiento
            .register(this.syncServiceTratamiento.baseSyncPolicy(this.tratamientosPOST));
    }

    public defineTratamientosDELETE() {
        this.tratamientosDELETE
            .fetch(HttpRequestType.DELETE, 'generic/tratamientos');

        this.apiTratamientoDelete
            .registerRequest(this.tratamientosDELETE);

        this.syncServiceTratamientoDelete
            .register(this.syncServiceTratamientoDelete.baseSyncPolicy(this.tratamientosDELETE));
    }

    public defineTratamientosPUT() {
        this.tratamientosPUT
            .fetch(HttpRequestType.PUT, 'generic/tratamientos');

        this.apiTratamiento
            .registerRequest(this.tratamientosPUT);

        this.syncServiceTratamiento
            .register(this.syncServiceTratamiento.baseSyncPolicy(this.tratamientosPUT));
    }

    public defineTratamientosControl() {
        this.tratamientosControl.GET.fetch(HttpRequestType.GET, 'tratamientos/tratamientos_control_get');
        this.tratamientosControl.POST.fetch(HttpRequestType.POST, 'generic/tratamientos_control');
        this.tratamientosControl.PUT.fetch(HttpRequestType.PUT, 'generic/tratamientos_control');
        this.tratamientosControl.DELETE.fetch(HttpRequestType.DELETE, 'generic/tratamientos_control');

        this.apiControl.registerRequest(this.tratamientosControl.GET);
        this.apiControl.registerRequest(this.tratamientosControl.POST);
        this.apiControl.registerRequest(this.tratamientosControl.PUT);
        this.apiControlDelete.registerRequest(this.tratamientosControl.DELETE);

        this.syncServiceControl.register(this.syncServiceControl.baseSyncPolicy(this.tratamientosControl.GET));
        this.syncServiceControl.register(this.syncServiceControl.baseSyncPolicy(this.tratamientosControl.POST));
        this.syncServiceControl.register(this.syncServiceControl.baseSyncPolicy(this.tratamientosControl.PUT));
        this.syncServiceControlDelete.register(this.syncServiceControlDelete.baseSyncPolicy(this.tratamientosControl.DELETE));
    }

    public defineTratamientosManez() {
        this.tratamientosManez.GET.fetch(HttpRequestType.GET, 'tratamientos/tratamientos_manez_get');
        this.tratamientosManez.POST.fetch(HttpRequestType.POST, 'generic/tratamientos_manez');
        this.tratamientosManez.PUT.fetch(HttpRequestType.PUT, 'generic/tratamientos_manez');
        this.tratamientosManez.DELETE.fetch(HttpRequestType.DELETE, 'generic/tratamientos_manez');

        this.apiManez.registerRequest(this.tratamientosManez.GET);
        this.apiManez.registerRequest(this.tratamientosManez.POST);
        this.apiManez.registerRequest(this.tratamientosManez.PUT);
        this.apiManezDelete.registerRequest(this.tratamientosManez.DELETE);

        this.syncServiceManez.register(this.syncServiceManez.baseSyncPolicy(this.tratamientosManez.GET));
        this.syncServiceManez.register(this.syncServiceManez.baseSyncPolicy(this.tratamientosManez.POST));
        this.syncServiceManez.register(this.syncServiceManez.baseSyncPolicy(this.tratamientosManez.PUT));
        this.syncServiceManezDelete.register(this.syncServiceManezDelete.baseSyncPolicy(this.tratamientosManez.DELETE));
    }

    public defineUnidadesMedida() {
        this.unidadesMedida.GET.fetch(HttpRequestType.GET, 'tratamientos/unidadesMedida_get');
        this.apiTratamiento.registerRequest(this.unidadesMedida.GET);
        this.syncServiceTratamiento.register(this.syncServiceTratamiento.baseSyncPolicy(this.unidadesMedida.GET));
    }
}
