import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import { AnalisisModel } from 'src/app/models/analisis/analisis.model';

@Injectable({
    providedIn: 'root'
})
export class AnalisisApiService {

    public analisis = {
        GET: new RequestHandler<AnalisisModel[]>(),
        POST: new RequestHandler<AnalisisModel[]>(),
        PUT: new RequestHandler<AnalisisModel[]>(),
        DELETE: new RequestHandler<AnalisisModel>()
    };


    constructor(
        private syncService: SyncService<AnalisisModel[]>,
        private syncServiceDeletee: SyncService<AnalisisModel>,
        private api: ApiRequestService<AnalisisModel[]>,
        private apiDelete: ApiRequestService<AnalisisModel>,
    ) {

        this.defineAnalisis();

    }

    private defineAnalisis() {
        this.analisis.GET.fetch(HttpRequestType.GET, 'analisis/get');
        this.analisis.POST.fetch(HttpRequestType.POST, 'analisis/post');
        this.analisis.PUT.fetch(HttpRequestType.PUT, 'generic/analisis');
        this.analisis.DELETE.fetch(HttpRequestType.DELETE, 'generic/analisis');

        this.api.registerRequest(this.analisis.GET);
        this.api.registerRequest(this.analisis.POST);
        this.api.registerRequest(this.analisis.PUT);
        this.apiDelete.registerRequest(this.analisis.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.analisis.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.analisis.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.analisis.PUT));
        this.syncServiceDeletee.register(this.syncServiceDeletee.baseSyncPolicy(this.analisis.DELETE));
    }
}
