<br>
<p-panel>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
      <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
    </div>
  </div>
  <app-form-builder
          [model]="model"
          [distributionOrder]="distributionOrder"
          [undoEnabled]="undoEnabled"
          [distributionColumns]="3"
          [fields]="formFields"
          (formChanges)="formChanges($event)"
          (selectAllcheck)="selectAll()">
  </app-form-builder>
  <br>
  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            [lockOnSuccess]="true"
            (click)="submit()"
            [mode]="requestButtonType"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>
