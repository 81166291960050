import { createCsv } from 'src/app/common/createCsv';
import {TareasApiService} from '../../../../service/api/tareas-api.service';
import {Component} from '@angular/core';
import { Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {BaseView} from '../../../base-view';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { Filtering } from 'src/app/service/filtering/filtering';
import {StorageManager} from '../../../../common/storage-manager.class';
import { list } from 'src/app/common/classes/request-builder';
import {maquinaria} from './tareas-alquiler-maquinaria-form/form-control/form-definition';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { TareaAlquilerMaquinariaModel } from 'src/app/models/tareas/tarea-alquiler-maquinaria.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-tareas-alquiler-maquinaria-general',
    templateUrl: './tareas-alquiler-maquinaria.component.html',
    styleUrls: ['./tareas-alquiler-maquinaria.component.scss']
})
export class TareasAlquilerMaquinariaComponent extends BaseView<TareaAlquilerMaquinariaModel> implements OnInit {

    public rolUser = StorageManager.getUser().rol;
    public hiddenButtonDelete = false;

    public appName = environment.appName;
    public serverUrl = environment.serverUrl;
    public fincaName = environment.features.fincaName; 
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
  (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));     

    public getRequest: RequestHandler<TareaAlquilerMaquinariaModel[]> = this.tareasApi.alquilerMaquinaria.GET;
    public deleteRequest: RequestHandler<TareaAlquilerMaquinariaModel> = this.tareasApi.alquilerMaquinaria.DELETE;
    public pathToForm = 'alquiler-maquinaria';  
    public menuItemId = 'alquiler-maquinaria';  

    public override filtering: Filtering<TareaAlquilerMaquinariaModel> = new Filtering<TareaAlquilerMaquinariaModel>();
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;  

    public override canShow: Record<string, boolean> = {};
    public override model: TareaAlquilerMaquinariaModel = {};
    public currentYear = '';
    public currentUser = StorageManager.getUser();

    public fincas: FincasModel[] = [];

    public showReceta = false;
    public showRecetaGlobalGAP = environment.features.showRecetaGlobalGAP;
    public showUsuariosFirma = environment.features.showUsuariosFirma;
    public showClientesFirma = environment.features.showClientesFirma;

    public maquinariaList = list(
        ['...', '0'], 
        ['JOFEMESA', '1'], 
        ['PRIMOTI', '2'], 
        ['TOLLQUICK', '3'], 
        ['MALCOP', '4'], 
        ['ALQUICESMAR', '5'], 
        ['OTROS', '6']
    );

    public distributionOrder = [3];
    public formFields: ItemInterface<object>[] = 
        [
            {
                field: 'notas_cliente',
                label: 'Notas cliente',
                name: 'Nota Cliente',
                inputType: {type: InputType.TEXTAREA},
                visible: environment.features.showTareasMto ? true : false
            },   
            {
                field: 'cliente_nombre', 
                name: 'Cliente', 
                label: 'Cliente', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'fecha', 
                name: 'Fecha', 
                label: 'Fecha', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'finca', 
                name: this.fincaName, 
                label: this.fincaName, 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'prioridad', 
                name: 'Prioridad/Realizada', 
                label: 'Prioridad/Realizada', 
                inputType: {type: InputType.TEXT}
            },
        ];  

    public formFieldsSimple: ItemInterface<object>[] = 
        [
            {
                field: 'maquina', 
                name: 'Maquinaria', 
                label: 'Maquina', 
                inputType: {type: InputType.RO_DROPDOWN}, 
                values: this.maquinariaList
            },  
            {
                field: 'hora_inicio', 
                name: 'Hora inicio', 
                label: 'Hora Inicio', 
                inputType: { type: InputType.TEXT }
            },
            {
                field: 'hora_fin', 
                name: 'Hora fin', 
                label: 'Hora Fin', 
                inputType: { type: InputType.TEXT }
            },
            {
                field: 'horas_totales', 
                name: 'Horas totales', 
                label: 'Hora Totales', 
                inputType: { type: InputType.TEXT }
            },  
        ];  

    public formFieldsTrabajadores: ItemInterface<object>[] = [
        {
            field: 'trabajadores_nombres', 
            name: 'Profesional(es)', 
            label: 'Profesional(es)', 
            inputType: {type: InputType.TEXTAREA}
        }, 
    ];
  
    public formFieldsOrdenTrabajo: ItemInterface<object>[] = 
        [  
            {
                field: 'orden_trabajo', 
                name: 'Orden de trabajo', 
                label: 'Orden de trabajo', 
                inputType: {type: InputType.TEXTAREA}
            },
        ];

    public formFieldsObs: ItemInterface<object>[] = 
        [  
            {
                field: 'observaciones', 
                name: 'Observaciones', 
                label: 'Observaciones', 
                inputType: {type: InputType.TEXTAREA}
            },
        ];

    public formFielsCsv: ItemInterface<object>[] = [
        {
            field: 'cliente_nombre', 
            name: 'Cliente', 
            label: 'Cliente', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha', 
            name: 'Fecha', 
            label: 'Fecha', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'finca', 
            name: this.fincaName, 
            label: this.fincaName, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'prioridad', 
            name: 'Prioridad/Realizada', 
            label: 'Prioridad/Realizada', 
            inputType: {type: InputType.TEXT}
        },
 
    ];

    public prioridad = {
        selected: ''
    };

    public cols: ColInterface[] = [
        {field: 'cliente_nombre', header: 'Cliente', filter: ''},
        {field: 'trabajadores_nombres', header: 'Empleado', filter: ''},
        {field: 'finca', header: this.fincaName, filter: ''},
        {field: 'fecha', header: 'Fecha', filter: ''},
        {field: 'maquina', header: 'Maquina', filter: ''},
        {field: 'prioridad', header: 'Prioridad/Realizada', filter: ''},
        {field: 'horas_totales', header: 'Horas Totales', filter: ''},
        {field: 'observaciones', header: 'Observaciones', filter: ''},
    ];  

    public fecha: {inicio: string; fin: string} = {
        inicio: '',
        fin: '',
    };

    constructor(public tareasApi: TareasApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, tareasApi.alquilerMaquinaria.GET, tareasApi.alquilerMaquinaria.DELETE);
  
  
    }

    ngOnInit() {
    
        if (environment.features.showTareasMto){
      
            this.prioridad.selected = 'No-Realizada';
            if (this.rolUser !== 'admin'){
                this.hiddenButtonDelete = true;
            }
        }
    
        this.dashboard.setSubmenuItem(this.menuItemId);
        this.filtering.addFilter((copy: TreeNode<TareaAlquilerMaquinariaModel>[] | void) => (copy as TreeNode<TareaAlquilerMaquinariaModel>[]).filter(row => {
            switch (this.prioridad.selected) {
            case 'Realizada':
                return ['Realizada'].includes(row.data?.prioridad ?? '');
            case 'No-Realizada':
                return !['Realizada'].includes(row.data?.prioridad ?? '');
            default:
                return true;
            }
        }));

        /*** 
   *  En el caso de que el usuario no se admin,
   * filtrará por su id de usuario que coincide,
   *  con el id trabajador   
  */
        if ( StorageManager.getUser().rol !== 'admin' ){ 
            this.filtering.addFilter((copy: TreeNode<TareaAlquilerMaquinariaModel>[] | void) => 
                (copy as TreeNode<TareaAlquilerMaquinariaModel>[]).filter(row => {
            
                    if (row.data?.ids_trabajadores != null ) {
              
                        const idTrabajador = StorageManager.getUser().id;
                        const listaTrabajadores = (row.data.ids_trabajadores as string).split(';');
                        if ( listaTrabajadores.includes(idTrabajador)) {
                            return true;
                        }
              
                    }
                    return false;
                }));
        }
    
        /*** 
       *  Fin de filtrar por id_usuario = trabajador,
       *   
      */
        this.filtering.addFilter((copy: TreeNode<TareaAlquilerMaquinariaModel>[] | void) => (copy as TreeNode<TareaAlquilerMaquinariaModel>[]).filter(row => {
            if (this.fecha.inicio && this.fecha.fin && row.data?.fecha) {
    
                const arrayFecha = row.data.fecha.toString().split('/');
                const fechaAux = arrayFecha[2] + '-' + arrayFecha[1] + '-' + arrayFecha[0];
    
                if (
                    (moment(moment(this.fecha.inicio).format('YYYY-MM-DD')).diff(fechaAux) <= 0) && 
                    (moment(moment(this.fecha.fin).format('YYYY-MM-DD')).diff(fechaAux) >= 0)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }));
    }

    public add() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', 'crear-' + this.pathToForm]);
        }
    }
  
    public edit(data: TareaAlquilerMaquinariaModel) {
        //console.log('data',data);
      
        this.router.navigate(['dashboard', 'editar-alquiler-maquinaria', data.id]);
    }
  
    public duplicate(data: TareaAlquilerMaquinariaModel) {
        this.router.navigate(['dashboard', 'duplicar-alquiler-maquinaria', data.id]);
    }  

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }  

    public override show(modal: string, data: TareaAlquilerMaquinariaModel) {
        let x;

        this.model = data;

        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }  

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if (col.field === 'prioridad') {
            if (value === 'Alta') {
                return {
                    rowStyle: {
                        backgroundColor: '#f44242'
                    },  
                };
            }
            else if (value === 'Realizada'){
                return {
                    rowStyle: {
                        backgroundColor: '#00ff00'
                    },  
                };
            }
        }

        if (col.field === 'trabajadores_nombres') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        if (col.field === 'observaciones') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        return {};
    }
  
    public globalVisualTransform(value: string, col: ColInterface): string {  
        if (col.field === 'maquina'){
            return maquinaria.filtered.find(it => it.value === value) ? maquinaria.filtered.find(it => it.value === value).label : '';
        }
        return value;
    }
  
    public formatDate(date: string) {
        moment.locale('es');
        return moment(date, 'DD/MM/YYYY').format('LL');
    }  
  

    public filtrarRealizados(value: string, from = 'prioridad') {
        if (from === 'prioridad') {
            this.prioridad.selected = this.prioridad.selected === value ? '' : value;
        } 

        this.filtering.filter();
    }

    public async downloadCsv() {

        const arrayFormFields = [
            this.formFielsCsv,
            this.formFieldsSimple,
            this.formFieldsTrabajadores,
            this.formFieldsObs,
        ];

        const aux = this.filtering.value;

        (aux as TareaAlquilerMaquinariaModel[]).forEach( m => {
            m.maquina = 
              maquinaria.filtered.find(it => it.value === m.maquina) ? 
                  maquinaria.filtered.find(it => it.value === m.maquina).label : 
                  '';
        });

        const csvContent = createCsv(arrayFormFields, aux, 'observaciones' );

        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Alquiler maquinaria.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();
    }

    getFechaInicio( event: string) {
        this.fecha.inicio = event;
        this.filtering.filter();
  
    }
    getFechaFin( event: string) {
        this.fecha.fin = event;
        this.filtering.filter();
    }

}