import { Injectable } from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import { ClienteModel } from 'src/app/models/cliente.model';
import { ProductosModel } from 'src/app/models/productos/productos.model';

@Injectable({
    providedIn: 'root'
})
export class SdiApiService {
    public SdiClientesGET = new RequestHandler<ClienteModel[]>();
    public SdiProductosGET = new RequestHandler<ProductosModel[]>();

    constructor(
        private syncServiceCliente: SyncService<ClienteModel[]>,
        private syncServiceProducto: SyncService<ProductosModel[]>,
        private apiCliente: ApiRequestService<ClienteModel[]>,
        private apiProducto: ApiRequestService<ProductosModel[]>
    ) {

        this.defineSdiClientesGET();
        this.defineSdiProductosGET();
    }

    private defineSdiClientesGET() {
        this.SdiClientesGET
            .fetch(HttpRequestType.GET, 'sdi/clientes_get');

        this.apiCliente
            .registerRequest(this.SdiClientesGET);

        this.syncServiceCliente
            .register(this.syncServiceCliente.baseSyncPolicy(this.SdiClientesGET));
    }

    private defineSdiProductosGET() {
        this.SdiProductosGET
            .fetch(HttpRequestType.GET, 'sdi/productos_get');
  
        this.apiProducto
            .registerRequest(this.SdiProductosGET);
  
        this.syncServiceProducto
            .register(this.syncServiceProducto.baseSyncPolicy(this.SdiProductosGET));
    }

}
