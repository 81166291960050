import { ProductosList } from './../../models/stock/productos-list.model';
import { DetalleProducto } from './../../models/form-common/detalle-producto.model';
import { SectorModel } from './../../models/form-common/sector.model';
import { MateriaActivaModel } from './../../models/form-common/materia-activa.model';
import { ProductosModel } from './../../models/form-common/productos.model';
import { CubasModel } from './../../models/form-common/cubas.model';
import { FincasModel } from './../../models/form-common/fincas.module';
import { PulverizacionModel } from './../../models/tareas/pulverizacion.model';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import {CargasCamionModel} from '../../models/cargas-camion.model';
import {RequestHandler} from './request-handler';
import {MantenimientoModel} from '../../models/trabajadores/mantenimiento.model';
import {RiegoModel} from '../../models/tareas/riego.model';
import { TratamientosModel } from '../../models/form-common/tratamientos.model';
import { ParcelasModel } from '../../models/form-common/parcelas.model';
import { TratamientosList } from '../../models/tareas/tratamientos-list.model';

@Injectable({
    providedIn: 'root'
})
export class IdbServiceService extends Dexie {

    cargas: Dexie.Table<CargasCamionModel, number> | undefined;
    trabajadores_mantenimiento: Dexie.Table<MantenimientoModel, number> | undefined;

    tareas_riego: Dexie.Table<RiegoModel, number> | undefined;
    tareas_pulverizacion: Dexie.Table<PulverizacionModel, number> | undefined;
    tareas_tratamientos: Dexie.Table<TratamientosList, number> | undefined;

    form_common_fincas: Dexie.Table<FincasModel, number> | undefined;
    form_common_cubas: Dexie.Table<CubasModel, number> | undefined;
    form_common_tratamientos: Dexie.Table<TratamientosModel, number> | undefined;
    form_common_productos: Dexie.Table<ProductosModel, number> | undefined;
    form_common_materiaActiva: Dexie.Table<MateriaActivaModel, number> | undefined;
    form_common_sectores: Dexie.Table<SectorModel, number> | undefined;
    form_common_parcelas: Dexie.Table<ParcelasModel, number> | undefined;
    form_common_detalleProducto: Dexie.Table<DetalleProducto, number> | undefined;

    stock_productos: Dexie.Table<ProductosList, number> | undefined;

    constructor(){
        super('agrogest-db');

    /*this.version(8).stores({
      cargas: '&codigo',

      trabajadores_mantenimiento: '&codigo',

      tareas_riego: '&codigo',
      tareas_pulverizacion: '&codigo',
      tareas_tratamientos: '&codigo',

      form_common_fincas: '&id',
      form_common_cubas: '&id',
      form_common_tratamientos: '&id',
      form_common_productos: '&id',
      form_common_materiaActiva: '&id',
      form_common_sectores: '&id',
      form_common_parcelas: '&id',
      form_common_detalleProducto: '&id',

      stock_productos: '&codigo'
    });*/
    }

    public register(request: RequestHandler<CargasCamionModel[]>){
        request.onPerform(this.send.bind(request));
    }

    private send(req: RequestHandler<CargasCamionModel[]>){
        this.cargas?.toArray()
            .then(value => {
                req.emitResponse(value);
                req.value = value;
                return;
            }).catch (e => {
                console.log('catch en cargas.toArray(): ' + e);
            }
            );
    }
}
