import {RequestButtonTypes} from '../common/components/request-button/request-button.component';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../service/events/dashboard.service';
import {RequestHandler} from '../service/OffService/request-handler';
import {FormRequestTypes} from '../common/classes/form-request';
import {Directive} from '@angular/core';
import { environment } from '../../environments/environment';
import { Utils } from '../common/utils';

import type { Params } from '@angular/router';
import type { OnDestroy } from '@angular/core';
import type { ItemInterface } from '../common/components/form-builder/form-builder.component';

@Directive()
export class BaseForm<T> implements OnDestroy {

    public minTimeExahusted = false;
    public formTypes = FormRequestTypes;
    public path;
    public route: ActivatedRoute;
    public router: Router;
    public dashboard: DashboardService;

    public model = {} as T;
    public modelChanges = {} as T;
    public modelOld: Record<string,string> = {};

    public flag = '';
    public request = new RequestHandler<T[]>();
    public putRequest = new RequestHandler<T[]>();
    public postRequest = new RequestHandler<T[]>();
    public undoEnabled = false;
    public requestButtonType: RequestButtonTypes = 0;

    public pathToGoBack = '';
    public headerTitleWhenEdit = '';
    public headerTitleWhenNew = '';
    public headerTitleWhenDuplicate = '';

    // SignaturePad
    public pathToFirmas = environment.serverUrl + 'firmas';
    public signaturePadOptions: object = {
        'minWidth': 1,
        'canvasWidth': 300,
        'canvasHeight': 150,
        'allowTaint': true
    };

    constructor(route: ActivatedRoute,
        router: Router,
        dashboard: DashboardService,
        putRequest: RequestHandler<T[]>,
        postRequest: RequestHandler<T[]>,
        pathToGoBack: string,
        headerTitleWhenEdit: string,
        headerTitleWhenNew: string,
        headerTitleWhenDuplicate?: string) {
  

        this.route = route;
        this.router = router;
        this.dashboard = dashboard;
        this.dashboard.changeClientActive = false;

        this.putRequest = putRequest;
        this.postRequest = postRequest;

        this.pathToGoBack = pathToGoBack;
        this.headerTitleWhenEdit = headerTitleWhenEdit;
        this.headerTitleWhenNew = headerTitleWhenNew;
        this.headerTitleWhenDuplicate = headerTitleWhenDuplicate || 'Duplicar';
        this.path = this.route.snapshot.routeConfig?.path;

    }

    public init(mode?: string, fieldsToDelete?: string[]) {
        if (!this.model) { (this.model as Record<string,string>) = {}; }
        if (!this.modelChanges) { (this.modelChanges as Record<string,string>) = {}; }
        if (!this.modelOld) { this.modelOld = {}; }

        this.flag = mode ?? '';

        this.route.queryParams.subscribe((params) => {
            new Promise((resolve) => {
                if (params && params['model']) {
                    this.model = JSON.parse(params['model']) || {};
                } else {
                    (this.model as Record<string,string>) = {};
                }

                resolve(0);
            })
                .then(() => {
                    if (!mode) {
                        this.flag = (this.model as Record<string,string>)['id'] ? 'edit' : 'new';
                    }
                    return;
                })
                .then(() => {
                    this.setUp(fieldsToDelete);
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
        }).unsubscribe();
    }

    public getType(): FormRequestTypes {
        if (this.path?.includes('duplicar-')) {
            return FormRequestTypes.DUPLICATE;
        }

        if (this.path?.includes('editar-')) {
            return FormRequestTypes.EDIT;
        }

        if (this.path?.includes('crear-')) {
            return FormRequestTypes.CREATE;
        }
        return 0;
    }

    public getRegisterId(): string {
        let registerId = null;

        this.route.params.forEach(
            (params: Params) => {
                registerId = params['id'];
            }
        );

        return registerId ?? '';
    }

    public softInit(type: FormRequestTypes) {

        setTimeout(() => {
            this.minTimeExahusted = true;
        }, 700);

        switch (type) {
        case FormRequestTypes.EDIT:
            this.flag = 'edit';
            this.request = this.putRequest;
            break;
        case FormRequestTypes.DUPLICATE:
            this.flag = 'duplicate';
            this.request = this.postRequest;
            break;
        case FormRequestTypes.CREATE:
            this.flag = 'new';
            this.request = this.postRequest;
            break;
        }

        this.setUp();
       
    }

    public setUp(fieldsToDelete?: string[]) {
        switch (this.flag) {
        case 'edit':
            this.dashboard.setHeaderTitle(this.headerTitleWhenEdit);
            this.request = this.putRequest;
            this.undoEnabled = false;
            this.requestButtonType = RequestButtonTypes.EDIT;
            break;
        case 'new':
            this.dashboard.setHeaderTitle(this.headerTitleWhenNew);
            this.request = this.postRequest;
            this.undoEnabled = false;
            this.requestButtonType = RequestButtonTypes.NEW;
            break;
        case 'duplicate':
            delete (this.model as Record<string,string>)['id'];

            (fieldsToDelete || []).forEach(field => {
                delete (this.model as Record<string,string>)[field];
            });

            this.dashboard.setHeaderTitle(this.headerTitleWhenDuplicate);
            this.request = this.postRequest;
            this.undoEnabled = false;
            this.requestButtonType = RequestButtonTypes.NEW;
            break;
        }
    }

    public scrollToTop() {
        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        if (elements && elements[0]) {
            setTimeout(() => {
                if (elements[0]) {
                    elements[0].scrollTop = 0;
                }
            }, 100);
        }
    }

    public goBack() {
        this.request.reset();
        this.router.navigate(['dashboard', this.pathToGoBack]);
    }

    public decimalFormat(input: number | undefined, nDecimals?: number, millar?: string, comma?: string, progressiveTo?: number) {
        if (!input) {
            return '';
        }
        return Utils.decimalFormat(input, nDecimals, millar, comma, progressiveTo);
    }

    public splitFormat(str: string, splitSeparator = ';', join = false, joinSeparator = ', '): string | string[] {
        let result: string | string[] = (str || '').split(splitSeparator);
        if (join) {
            result = result.join(joinSeparator);
        }
        return result;
    }

    public splitFromArrayFormat(array: string[], prop = 'nombre', joinSeparator = ', ') {
        return (array || []).map(it => it[+prop]).join(joinSeparator);
    }

    public getItemPosition(formFields: ItemInterface<object>[], field: string) {
        let position = 0;
        formFields.forEach((item, index) => {
            if (item.field === field) {
                position = index;
            }
        });
        return position;
    }

    public ngOnDestroy() {
        for (const x in this) {
            if (this[x]) {
                if (this[x] instanceof RequestHandler) {
                    (this[x] as RequestHandler<T>).unsuscribe();
                }
            }
        }
    }

}
