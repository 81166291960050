import { CustomHTMLElement, FileUploadAnswer, UploadResponse, Utils } from './../../../../common/utils';
import { Component } from '@angular/core';
import moment from 'moment';
import { Router } from '@angular/router';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { RequestHandler } from '../../../../service/OffService/request-handler';
import { AnalisisApiService } from '../../../../service/api/analisis-api.service';
import { AnalisisModel } from '../../../../models/analisis/analisis.model';
import { environment } from '../../../../../environments/environment';
import { StorageManager } from '../../../../common/storage-manager.class';
import { catchError, map, of, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BaseView } from '../../../../view/base-view';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import type { OnInit, AfterViewInit } from '@angular/core';
import { CalendarOptions, EventClickArg, EventMountArg } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { FileSelectEvent, FileUploadEvent } from 'primeng/fileupload';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';

@Component({
    selector: 'app-analisis',
    templateUrl: './analisis.component.html',
    styleUrls: ['./analisis.component.scss']
})
export class AnalisisComponent extends BaseView<AnalisisModel> implements OnInit, AfterViewInit {

    public hasClients = environment.features.hasClients;
    public hasFincas = environment.features.hasFincas;
    public clienteName = environment.features.clienteName;
    public showAnalisisParcelas = environment.features.showAnalisisParcelas;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public showAnalisisBoletin = environment.features.showAnalisisBoletin;
    public showAnalisisNumeroAnalisis = environment.features.showAnalisisNumeroAnalisis;
    public appName: string = environment.appName;
    public applicationType = environment.features.applicationType;
    public showAnalisisM3Agua = environment.features.showAnalisisM3Agua;
    public showAnalisisMgrl = environment.features.showAnalisisMgrl;
    public showCultivosBajoAguaFields = environment.features.showCultivosBajoAguaFields;
    
    public isAdmin = (StorageManager.getUser().rol === 'admin');
    public rol = StorageManager.getUser().rol;
    public deleteRequest: RequestHandler<AnalisisModel> = this.analisisApi.analisis.DELETE;

    public analisisSeleccionado: AnalisisModel = new AnalisisModel();
    public analisisArray: AnalisisModel[] = [];
    public analisisArrayFiltered: AnalisisModel[] = [];
    public analisis: AnalisisModel[] = [];
    public analisisImg: string | ArrayBuffer | null | undefined = null;

    public clientChange: Subscription = new Subscription;
    public isMobile = false;

    public date: Date = new Date();
    public fechaSel = '';
    public analisisDia = [];
    public showEditAnalisis = false;
    public showEliminaAnalisis = false;

    public cambiaEstadoLabel = '';
    public cambiaEstadoColor = '';
    public cambiandoEstado = false;

    public showModalImport = false;
    public showImportError = true;
    public txtImportError = '';
    public txtImportInfo = '';
    public urlUpload = '';
    public enableUpload = true;

    public options: CalendarOptions = {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        locale: esLocale,
        height: 640,
        aspectRatio: 0.8,
        editable: true,
        eventClick: (event: EventClickArg) =>  { 
            this.irAlEvento(event);
        },
        eventDidMount: function (info: EventMountArg) {
            // @ts-expect-error Al tener lugar en un contexto diferente, técnicamente no se puede acceder a appName
            if ((info.el as CustomHTMLElement).fcSeg.eventRange.def.title && this.appName === 'ava') {
                const titulo = (info.el as CustomHTMLElement).fcSeg.eventRange.def.title.split('|');

                const a = info.el.getElementsByClassName('fc-event-title');
                if (a[0]) {
                    a[0].innerHTML = titulo[0] + '</br>' + titulo[1];
                }
            }
        },
        dateClick: () => {
            this.irAlDia();
        } 
    };

    public options2 = {
        height: 400,
        width: 400,
        aspectRatio: 0.4
    };

    public months = [
        {name: 'Enero', value: 1},
        {name: 'Febrero', value: 2},
        {name: 'Marzo', value: 3},
        {name: 'Abril', value: 4},
        {name: 'Mayo', value: 5},
        {name: 'Junio', value: 6},
        {name: 'Julio', value: 7},
        {name: 'Agosto', value: 8},
        {name: 'Septiembre', value: 9},
        {name: 'Octubre', value: 10},
        {name: 'Noviembre', value: 11},
        {name: 'Diciembre', value: 12},
    ];
    public years: {name: string, value: number}[] = [];
    public selectedMonth: {name: string, value: number} = {name: '', value: 0};
    public selectedYear: {name: string, value: number} = {name: '', value: 0};

    constructor(
        private analisisApi: AnalisisApiService,
        private dashboard: DashboardService,
        private router: Router,
        public http: HttpClient
    ) {
        super(dashboard, analisisApi.analisis.GET, analisisApi.analisis.DELETE);
        for (let i = 2017; i <= 2025; i++) {
            this.years.push( {name: i.toString(), value: i} );
        }
    }

    ngOnInit() {
        this.isMobile = (
            ((navigator.userAgent.match('iPad') || []).length > 0) ||
            ((navigator.userAgent.match('iPhone') || []).length > 0) ||
            ((navigator.userAgent.match('iPod') || []).length > 0) || 
            ((navigator.userAgent.match('Android') || []).length > 0)
        );

        this.dashboard.setSubmenuItem('analisis');

        this.dashboard.clientChanges$.subscribe(() => {
            this.filterArray();
        });

        this.dashboard.changeClientActive = true;

        if (localStorage.getItem('analisis_temp')) {
            this.analisisSeleccionado = JSON.parse(localStorage.getItem('analisis_temp') ?? '')[0] as AnalisisModel;
            localStorage.removeItem('analisis_temp');
        }

        this.getAnalisis();
    }

    ngAfterViewInit() {
        const elements = document.getElementsByClassName('fc-view') as HTMLCollectionOf<Element>;
        for (const element of Array.from(elements)) {
            element.classList.add('no-zindex');
        }
    }

    getAnalisis() {
        const fechaActual = new Date();
        let fechaDelAnalisis;
        let dia: number;
        let mes: number;
        let year: number;

        this.analisisApi.analisis.GET.safePerform();
        this.analisisApi.analisis.GET.response(data => {
            this.analisis = data;
            this.analisisArray = data
                .map((it: AnalisisModel) => {
                    let color = '';

                    const start = moment(
                        Utils.toDate(it.fecha ?? '')
                    ).format('YYYY-MM-DD');

                    if (it.fecha) {
                        dia = parseInt(it.fecha.split('/')[0] ?? '', 10);
                        mes = parseInt(it.fecha.split('/')[1] ?? '', 10);
                        year = parseInt(it.fecha.split('/')[2] ?? '', 10);
                    }

                    fechaDelAnalisis = new Date((mes) + '/' + dia + '/' + year);

                    switch (it.estado) {
                    case 'Programada':
                        if (fechaDelAnalisis < fechaActual) {
                            color = '#f44242';
                        } else {
                            color = '#4198f4';
                        }
                        break;
                    case 'Solicitada':
                        color = '#ff9a02';
                        break;
                    case 'Realizada':
                        color = '#3be246';
                        break;
                    }

                    if (this.analisisSeleccionado && this.analisisSeleccionado.id === it.id) {
                        this.analisisSeleccionado = it;
                        switch (this.analisisSeleccionado.estado) {
                        case 'Programada':
                            this.cambiaEstadoLabel = 'Solicitar';
                            this.cambiaEstadoColor = '#ff9a02';
                            break;
                        case 'Solicitada':
                            this.cambiaEstadoLabel = 'Realizar';
                            this.cambiaEstadoColor = '#3be246';
                            break;
                        case 'Realizada':
                            this.cambiaEstadoLabel = 'Realizado';
                            this.cambiaEstadoColor = '#3be246';
                            break;
                        }
                    }

                    if (!this.hasClients || !this.isAdmin) {
                        let titulo = '';
                        
                        if (this.appName === 'ava') {
                            titulo = (it.finca?.nombre || it.finca_nombre) + ' | ' + it.tipo_analisis;
                        } else { 
                            titulo = (it.finca?.nombre || it.finca_nombre) ??'';
                        }

                        return {
                            id: it.id,
                            title: titulo ?? '',
                            parcela_nombre: it.parcela_nombre,
                            id_usuario: it.id_usuario,
                            start,
                            color
                        };
                    } else if ((this.appName === 'sequiador' || this.appName === 'SIEX') && it.id_usuario === 1) {
                        const anyo = new Date().getFullYear();
                        return {
                            id: it.id,
                            title: 'Análisis ' + anyo,
                            parcela_nombre: it.parcela_nombre,
                            id_usuario: it.id_usuario,
                            start,
                            color
                        };
                    } else {
                        return {
                            id: it.id,
                            title: ((it.cliente || {}).razon_social || (it.cliente || {}).nombre || it.cliente_nombre) ?? '',
                            parcela_nombre: it.parcela_nombre,
                            id_usuario: it.id_usuario,
                            start,
                            color
                        };
                    }

                });
            this.filterArray();
            this.analisisApi.analisis.GET.unsuscribe();
        });
    }
    
    showSelectedDate(calendar: FullCalendarComponent) {
        const day = new Date().getDate();
        const month = this.selectedMonth ? this.selectedMonth.value : new Date().getMonth() + 1;
        const year = this.selectedYear ? this.selectedYear.value : new Date().getFullYear();
        const date = new Date(`${year}-${month}-${day}`);

        calendar.getApi().gotoDate(date.getTime());
    }

    filterArray() {
        const clienteActual = (StorageManager.getUser().tipo === 'comunero') ? 
            StorageManager.getUser().id_cliente : 
            StorageManager.getClient();
        this.analisisArrayFiltered = this.analisisArray.filter(it => {
            if (this.hasClients) {
                if (clienteActual && clienteActual.nombre && it.title) {
                    return it.title.includes(clienteActual.nombre);
                } else {
                    return it;
                }
            }
            return it;
        });
    }

    irAlDia() {
    }

    irAlEvento(e: EventClickArg) {
        const idAnalisis = e.event._def.publicId;
        for (const analisis of this.analisis) {
            if (analisis.id === idAnalisis) {
                this.analisisSeleccionado = analisis ?? new AnalisisModel();
                break;
            }
        }

        switch (this.analisisSeleccionado.estado) {
        case 'Programada':
            this.cambiaEstadoLabel = 'Solicitar';
            this.cambiaEstadoColor = '#ff9a02';
            break;
        case 'Solicitada':
            this.cambiaEstadoLabel = 'Realizar';
            this.cambiaEstadoColor = '#3be246';
            break;
        case 'Realizada':
            this.cambiaEstadoLabel = 'Realizado';
            this.cambiaEstadoColor = '#3be246';
            break;
        }
    }

    crearAnalisis() {
        this.router.navigate(['dashboard', 'crear-analisis']);
    }

    editAnalisis(data: AnalisisModel) {
        this.router.navigate(['dashboard', 'editar-analisis', data.id]);
    }

    duplicateAnalisis(data: AnalisisModel) {
        this.router.navigate(['dashboard', 'duplicar-analisis', data.id]);
    }

    eliminaAnalisis() {
        this.showEliminaAnalisis = false;

        this.deleteRequest.safePerform(this.analisisSeleccionado);
        this.deleteRequest.response(() => {
            if (!this.deleteRequest.hasError) {
                for (const analisis of this.analisisArrayFiltered) {
                    if (this.analisisSeleccionado && (analisis.id === this.analisisSeleccionado.id)) {
                        this.analisisArrayFiltered.splice(this.analisisArrayFiltered.indexOf(analisis), 1);
                    }
                }
                this.analisisSeleccionado = new AnalisisModel();
                this.analisisApi.analisis.GET.safePerform();
                // location.reload();
                // this.getAnalisis();
            } else {
                console.log('Error al borrar analisis...');
            }
        });
    }


    

    cambiaEstado(cambiaEstadoButton: HTMLButtonElement) {
        const oldLabel = this.cambiaEstadoLabel;

        cambiaEstadoButton.disabled = true;
        this.cambiandoEstado = true;

        switch (this.analisisSeleccionado.estado) {
        case 'Programada':
            this.analisisSeleccionado.estado = 'Solicitada';
            this.cambiaEstadoLabel = 'Solicitando...';
            break;
        case 'Solicitada':
            this.analisisSeleccionado.estado = 'Realizada';
            this.cambiaEstadoLabel = 'Realizando...';
            break;
        }

        
        this.analisisApi.analisis.PUT.perform({
            id: this.analisisSeleccionado.id,
            estado: this.analisisSeleccionado.estado
        } as never);

        this.analisisApi.analisis.PUT.response(data => {
            const analisisSiguiente = this.analisisSeleccionado;
            analisisSiguiente.id = '';
            analisisSiguiente.fecha = this.getNextDate() ?? '';
            analisisSiguiente.estado = 'Programada';

            // this.analisisApi.analisis.POST.perform(analisisSiguiente);
            // this.analisisApi.analisis.POST.response(result => {
            //     this.analisisApi.analisis.POST.unsuscribe();
            this.analisisApi.analisis.PUT.unsuscribe();

            cambiaEstadoButton.disabled = false;
            this.cambiandoEstado = false;
            if (oldLabel === 'Programado') { this.cambiaEstadoLabel = 'Solicitado'; }
            if (oldLabel === 'Solicitado') { this.cambiaEstadoLabel = 'Realizado'; }

            //     // location.reload();
            // });
            this.getAnalisis();
            localStorage.setItem('analisis_temp', JSON.stringify(data));
        });

    }

    public getNextDate() {
        let fechaSiguiente;
        let period = 0;
        let timelapse = '';
        switch (this.analisisSeleccionado.frecuencia) {
        case 'Quincena':    period = 15; timelapse = 'day';     break;
        case '1 mes':       period = 1; timelapse = 'month';    break;
        case '2 meses':     period = 2; timelapse = 'month';    break;
        case '3 meses':     period = 3; timelapse = 'month';    break;
        case '4 meses':     period = 4; timelapse = 'month';    break;
        case '6 meses':     period = 6; timelapse = 'month';    break;
        case '1 año':       period = 1; timelapse = 'year';     break;
        case '2 años':      period = 2; timelapse = 'year';     break;
        case '3 años':      period = 3; timelapse = 'year';     break;
        case '4 años':      period = 4; timelapse = 'year';     break;
        case '5 años':      period = 5; timelapse = 'year';     break;
        }
        if (this.analisisSeleccionado.fecha && this.analisisSeleccionado.estado === 'Realizada') {
            fechaSiguiente = this.setNextDate(this.analisisSeleccionado.fecha, period, timelapse);
        } else {
            fechaSiguiente = null;
        }
        return fechaSiguiente;
    }

    public setNextDate(date: Date | string, period: number, timelapse: string) {
        if (typeof date === 'string') { 
            date = moment(date, 'DD/MM/YYYY').toDate(); 
        }
        let aux: number;
        const auxDate = new Date(date);
        switch (timelapse) {

        case 'day':
            aux = (date as Date).getDate() + period;
            auxDate.setDate(aux);
            break;


        case 'month':
            aux = date.getMonth() + period;
            auxDate.setMonth(aux);
            break;
        case 'year':
            aux = date.getFullYear() + period;
            auxDate.setFullYear(aux);
            break;
        }
        return moment(auxDate).format('DD/MM/YYYY');
    }

    public verAdjunto(adjunto: string) {
        const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
        myWindow?.location.assign(environment.serverUrl + 'analisis/' + adjunto);
        myWindow?.focus();
    }

    public descargarAdjunto(adjunto: string) {
        const a: HTMLAnchorElement = (document.getElementById('downloadAdjunto') as HTMLAnchorElement);
        a.download = adjunto;

        fetch(environment.serverUrl + 'analisis/' + adjunto, {
            headers: {
                'Content-Type': 'application/pdf'
            }
        })
            .then(response => response.blob())
            .then(blob => {
                a.href = URL.createObjectURL(blob);
                a.click();
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
    }

    /*** ADJUNTAR ANÁLISIS ***/
    public readUrl(input: HTMLInputElement) {
        input.click();
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.analisisImg = e.target?.result ?? '';
            };
            reader.readAsDataURL(input.files[0]);
            const r = this.http.post<FileUploadAnswer>
            (environment.serverUrl + 'ws/analisis/upload_file.php', this.analisisImg).subscribe(res => {
                const file = res;
                this.analisisSeleccionado.adjunto = file.fileName;

                r.unsubscribe();
            });
        }
    }
    
    public fileChange(event: Event ) {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            const fileList: FileList = input.files;
            if (fileList.length > 0) {
                const file: File = fileList[0] ?? new File([],'');
                const formData: FormData = new FormData();
                formData.append('uploadFile', file, file.name);
                const headers = new HttpHeaders();
                headers.append('Content-Type', 'multipart/form-data');
                headers.append('Accept', 'application/json');

                this.http.post<FileUploadAnswer>(
                    `${environment.serverUrl}ws/analisis/upload_file.php`, 
                    formData, 
                    {headers: headers}
                ).pipe(
                    map(data => {
                        const postRes = data;
                        this.analisisSeleccionado.adjunto = postRes.fileName;
        
                        this.analisisApi.analisis.PUT.safePerform({
                            id: this.analisisSeleccionado.id,
                            adjunto: this.analisisSeleccionado.adjunto
                        } as never);
            
                        this.analisisApi.analisis.PUT.response(putRes => {
                            console.log(putRes);
                            this.analisisApi.analisis.PUT.unsuscribe();
                        });
                    }),
                    catchError(error => {
                        console.log(error);
                        return of(null);
                    })
                ).subscribe();
            }
        }
    }


    reloadAnalisis(){
        location.reload();
    }
    
    downloadCsv() {
        const charset = 'windows-1252';
        let csvContent = 'data:text/csv;charset=' + charset + ',\ufeff';
        csvContent += `
            Finca;
            Paraje;
            Parcelas;
            Fecha;
            Tipo de Análisis;
            Frecuencia;
            Muestra;
            Objeto del Análisis;
            Nº Analisis;
            Nº Boletin;
            Laboratorio;
            Sustancia Activa Detectada;
            Estado;
            Notas;
            Volumen Kg;
        `;

        this.analisis.forEach(dato => {
            csvContent += `
                ${dato.finca_nombre};
                ${dato.sector_nombre ?? ''};
                ${dato.parcelas_nombres ?? ''};
                ${dato.fecha};
                ${dato.tipo_analisis};
                ${dato.frecuencia ?? ''};
                ${dato.muestra ?? ''};
                ${dato.objeto_analisis ?? ''};
                ${dato.analisis ?? ''};
                ${dato.boletin ?? ''};
                ${dato.laboratorio ?? ''};
                ${dato.sustancia_activa ?? ''};
                ${dato.estado ?? ''};
                ${dato.muestra ?? ''};
                ${dato.volumen_kg ?? ''}
            `;
        });
        
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;

        csvLink.download = 'Análisis.csv';
        csvLink.href = encodeURI(csvContent);
        csvLink.click();
    } 

    checkFile(event: FileSelectEvent) {
        this.showImportError = false;
        if (event.files[0]) {
            const file = event.files[0].name;
            if (!file.includes('.xlsx') && !file.includes('.xls')) {
                this.showImportError = true;
                this.txtImportError = 'El archivo debe ser un Excel(xlsx)';
                //this.enableUpload = false;
            }
        }
    }
    
    uploadDialog() {
        this.showModalImport = true;
        this.urlUpload = environment.serverUrl +
          'ws/informes/excel_analisis/fileUploadData.php';
    }
    
    myUploader(event: { files: { name: string | string[]; }; }) {
        if (event.files.name.indexOf('.xlsx') || event.files.name.indexOf('.xls')) {
            console.log('CORRECTO');
        }
        else { console.log('EXTENSION INCORRECTA'); }
    }
    
    uploaded(event: FileUploadEvent) {
        if (event.originalEvent instanceof HttpResponse) {
            const response: UploadResponse = event.originalEvent.body;
      
            if (response) {        
                const parcelasFound = response.content_found;
                const parcelasNotFound = response.content_not_found;
                const especiesFound = response.especie_found;
                const especiesNotFound = response.especie_not_found;

                const arrayEspeciesFound = [];
                const arrayEspeciesNotFound: number[] = [];
                const arrayParcelasFound: string[] = [];
                const arrayParcelasNotFound: string | string[] = [];

                especiesFound.forEach((it: string) => {
                    arrayEspeciesFound.push(it);
                });
                especiesNotFound.forEach((it: number) => {
                    arrayEspeciesNotFound.push(it);
                });
                parcelasFound.forEach((it: ParcelasModel) => {
                    arrayParcelasFound.push(it.numero ?? '');
                });
                parcelasNotFound.forEach((it: ParcelasModel) => {
                    arrayParcelasNotFound.push(it.numero ?? '');
                });
            
                this.showImportError = true;
                this.txtImportInfo = 'Carga de las Parcelas realizada correctamente<br />';
                this.txtImportInfo += arrayParcelasNotFound;
                this.txtImportError += '<br>';

                this.txtImportInfo += 'Parcelas actualizadas<br />';
                this.txtImportInfo += arrayParcelasFound;
                this.txtImportInfo += '<br/>';
                this.txtImportInfo += '<br/>';

                if (!arrayEspeciesNotFound.length) {
                    this.txtImportError += '¡Todos los Especies/Cultivos han sido encontrados! <br />';
                } else {
                    this.txtImportError = '¡Especies/Cultivos no encontrados! <br />';
                    this.txtImportError += this.unique(arrayEspeciesNotFound);
                }
            }
        }
    }
    
    closeFUDialog() {
        this.showModalImport = false;
        this.showImportError = false;
        location.reload();
    }

    unique(array: number[]) {
        return array.filter(function(a: number) {
            return !array[a] ? true : false;
        }, {});
    }
}
