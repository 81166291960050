<br>

<!-- FORM FIELDS -->
<p-panel>
    <h4>Datos de la tarea fitosanitaria en locales</h4>
         <app-form-builder
            [model]="model"
            [undoEnabled]="false"
            [distributionColumns]="3"
            [fields]="form.formFields">
        </app-form-builder>
    <br>

    <!-- ACTION BUTTONS -->
    <div class="d-flex justify-content-center">
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>
<br>
<p-toast styleClass="custom-toast" [style]="{'zIndex': '1010'}"></p-toast>
