import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { FormRequestTypes } from '../../../../../common/classes/form-request';
import { build, list, pool } from '../../../../../common/classes/request-builder';
import { InputType } from '../../../../../common/components/form-builder/form-builder.component';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { StorageManager } from '../../../../../common/storage-manager.class';
import { CultivosVariedadApiService } from '../../../../../service/api/cultivos-variedad-api.service';
import { VisitasApiService } from '../../../../../service/api/visitas-api.service';
import { DashboardService } from '../../../../../service/events/dashboard.service';
import { AppFormRequest } from '../../../../../view/app-common/form-request/app-form-request';
import { BaseForm } from '../../../../../view/base-form';
import { environment } from '../../../../../../environments/environment';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { VariedadSIEXModel } from 'src/app/models/visitas_variedad_mes/variedadSIEX.model';
import { ItemValuesInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { CultivoSIEXModel } from 'src/app/models/visitas_variedad_mes/cultivoSIEX.model';
import { UserModel } from 'src/app/models/usuarios/user.model';
import { ClienteModel } from 'src/app/models/cliente.model';
import { catchError, map, of } from 'rxjs';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { SeguimientoModel } from 'src/app/models/visitas/seguimiento.model';
import { FileUploadAnswer } from 'src/app/common/utils';

@Component({
    selector: 'app-viveros-pereira-form',
    templateUrl: './viveros-pereira-form.component.html',
    styleUrls: ['./viveros-pereira-form.component.scss']
})
export class ViverosPereiraFormComponent extends BaseForm<SeguimientoModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: false, static: false })
        requestButton = new RequestButtonComponent<SeguimientoModel>();

    public override model: SeguimientoModel = {};
    public adjunto: string | ArrayBuffer | null | undefined;

    public formRequest = new AppFormRequest<SeguimientoModel>();
  
    public distributionOrder = [
        3, 3, 3
    ];

    public cultivos: TypeAheadInterface<CultivoSIEXModel> = list(['...', null]);
    public variedades: TypeAheadInterface<VariedadSIEXModel> = list(['...', null]);
  
    public etiquetas = list(['Estudio', '1'], ['Germinación', '2'], ['Plaga', '3'], ['Deficiencia', '4'],
        ['Bloqueo', '5'], ['Deformaciones', '6'], ['Marcas', '7'], ['Alteración color', '8'], ['Marchitamiento', '9'], ['Pequeña', '10'], 
        ['Grande', '11'], ['Espigada', '12'], ['Estrechamiento cuella', '13'], ['Problema raiz', '14'], ['Mejora', '15'], ['Novedad', '16'],
        ['Error', '17'], ['Lucha biologica', '18']);

    public semanaSiembra = list(['...', null]);
    public semanaEntrega = list(['...', null]);

    public formFieldsLeft: ItemInterface<object>[] = [
        {
            label: 'Año', 
            field: 'año', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Semana', 
            field: 'semana', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Fecha', 
            field: 'fecha', 
            inputType: {type: InputType.CALENDAR}
        },    
        {
            label: 'Planta', 
            field: 'id_cultivo', 
            inputType: {type: InputType.DROPDOWN_SEARCH}, 
            values: this.cultivos, 
            valuePrimaryKey: 'id'
        },
        {
            label: 'Variedad', 
            field: 'id_variedad', 
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.variedades, 
            valuePrimaryKey: 'id',
            filter: (it: { value: VariedadSIEXModel; }) => it.value.codigo_cultivo === (this.cultivos.selected as CultivoSIEXModel).codigo
        },
        {
            label: 'Semana Siembra', 
            field: 'semana_siembra', 
            inputType: {type: InputType.DROPDOWN_SEARCH}, 
            values: this.semanaSiembra
        },
        {
            label: 'Semana Entrega', 
            field: 'semana_entrega', 
            inputType: {type: InputType.DROPDOWN_SEARCH}, 
            values: this.semanaEntrega
        },
        {
            label: 'Remesa', 
            field: 'remesa', 
            inputType: {type: InputType.ONLY_TEXT}, 
            maxLength: 4
        },
        {
            label: 'Observaciones', 
            field: 'observaciones', 
            inputType: {type: InputType.EDIT_TEXTAREA}, 
            audio: true, 
            rows: 10
        },
        {
            label: '',
            field: 'adjunto',
            inputType: {type: InputType.EMPTY}
        }
    ];

    public formFieldsMiddle: ItemInterface<object>[] = [
        {
            label: 'Cuestiones', 
            field: 'cuestiones', 
            inputType: {type: InputType.EDIT_TEXTAREA}, 
            audio: true, 
            rows: 10
        },
        {
            label: 'Medida', 
            field: 'medida', 
            inputType: {type: InputType.EDIT_TEXTAREA}, 
            audio: true, 
            rows: 10
        },
        {
            label: 'Resultados', 
            field: 'resultados', 
            inputType: {type: InputType.EDIT_TEXTAREA}, 
            audio: true, 
            rows: 10
        }
    ];

    public formFieldsRight: ItemInterface<object>[] = [
        {label: 'Etiquetas', 
            field: 'etiquetas',
            inputType: {type: InputType.MULTISELECT},
            multiSelect: true,
            maxHeight: true,
            values: this.etiquetas,        
        }
    ];

    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public visitasApi: VisitasApiService,
        public visitasVariedades: VisitasApiService,
        public override router: Router,
        public http: HttpClient ,
        public cultivosVariedadApi: CultivosVariedadApiService
    ) { 
        super(route,
            router,
            dashboard,
            visitasApi.visitasPereira.PUT,
            visitasApi.visitasPereira.POST ,
            'seguimiento', 
            'Editar Seguimiento',
            'Crear Seguimiento',
            'Duplicar Seguimiento'
        );
        for (let numeroSemana = 1; numeroSemana <= 52; numeroSemana++) {
            this.semanaSiembra.filtered.push({label: numeroSemana, value: numeroSemana.toString()});
            this.semanaEntrega.filtered.push({label: numeroSemana, value: numeroSemana.toString()});
        }
    }

    ngOnInit() {
        const date = new Date();
        this.model.año = date.getFullYear();
        this.initFormData();

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .isGeneric(true)
            .setGetRequest(this.visitasApi.visitasPereira.GET)
            .setPostRequest(this.visitasApi.visitasPereira.POST)
            .setPutRequest(this.visitasApi.visitasPereira.PUT)
            .setFormFields(Array.prototype
                .concat(
                    this.formFieldsLeft,
                    this.formFieldsMiddle,
                    this.formFieldsRight
                )
            )
            .setFieldsToSend((['id', 'cultivo', 'variedad', 'semana'] as string[])
                .concat(
                    this.formFieldsLeft.map(it => it.field ?? ''),
                    this.formFieldsMiddle.map(it => it.field ?? ''),
                    this.formFieldsRight.map(it => it.field ?? '')
                )
            );

        if ( this.getType() === FormRequestTypes.CREATE ){
            this.model.fecha = new Date();
        }
      

        this.formRequest.beforeSend(resolve => {
            const clientId = (StorageManager.getClient() as ClienteModel).id;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
                clientIdFromForm ? clientIdFromForm :
                    clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() as UserModel).id;
            const userIdFromForm = this.model.id_usuario;

            this.model.id_usuario =
                userIdFromForm ? userIdFromForm :
                    userId ? userId : '';

            resolve(true);
        });

        this.formRequest.load();
        this.softInit(this.getType());
    
    }
  
    initFormData() {
   
        pool([
            build(this.cultivosVariedadApi.cultivosSiex.GET, this.cultivos, ['cultivo']),
            build(this.cultivosVariedadApi.variedadSiex.GET, this.variedades, ['variedad'], true),
        ], () => {
      
            this.formRequest.update();
        });
    }


    submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }

        if ((typeof this.model.fecha !== 'string')) {

            if ((this.model.fecha != null)) {
                this.model.fecha = moment(this.model.fecha).format('DD/MM/YYYY');
            } else {
                // La forma antigua para rellerafecha cuando este vacia
                this.model.fecha = moment(this.model.fecha_exp || new Date()).format('DD/MM/YYYY');
            }
        }

        if (this.model.remesa) {
            if (this.model.remesa.length < 4){
                this.model.remesa = '0' + this.model.remesa;
            }
        }

        this.formRequest.send();
    
        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }else if (this.getType() === FormRequestTypes.CREATE){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }

    }

    formChanges(tag: string){

        if (tag === 'fecha'){
            this.model.semana = this.getWeekNumberFromISOString(this.model.fecha as string);
        }
    
        if (tag === 'id_cultivo' || tag === 'id_variedad'){
            const idxCultivo = this.formFieldsLeft.map(it => it.field).indexOf('id_cultivo');
            const idxVariedad = this.formFieldsLeft.map(it => it.field).indexOf('id_variedad');

            this.model.cultivo = 
                ((this.formFieldsLeft[idxCultivo]?.values as ItemValuesInterface<CultivoSIEXModel>).selected as CultivoSIEXModel).cultivo
                ?? '';

            this.model.variedad = 
                ((this.formFieldsLeft[idxVariedad]?.values as ItemValuesInterface<VariedadSIEXModel>)
                    .selected as VariedadSIEXModel).variedad ?? '';
        }

        if (tag === 'semana_siembra' || tag === 'semana_entrega'){
            const semanaSiembra = 
              this.model.semana_siembra as number < 10 ? 
                  '0' + this.model.semana_siembra : 
                  this.model.semana_siembra ? 
                      this.model.semana_siembra : '00';
            const semanaEntrega = 
              this.model.semana_entrega as number < 10 ? 
                  '0' + this.model.semana_entrega : 
                  this.model.semana_entrega ? 
                      this.model.semana_entrega : '00';
            this.model.remesa = semanaSiembra + '' + semanaEntrega;
        }
    }

    getWeekNumberFromISOString(date: string): number {
        const newYear = new Date(new Date(date).getFullYear(), 0, 1);
        let day = newYear.getDay() - 1; // the day of week the year begins on
        day = day >= 0 ? day : day + 7;
        const dayNumber: number =
      Math.floor(
          (new Date(date).getTime() -
          newYear.getTime() -
          (new Date(date).getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
        86400000
      ) + 1;
        let weekNumber: number;
        // if the year starts before the middle of a week
        if (day < 4) {
            weekNumber = Math.floor((dayNumber + day - 1) / 7) + 1;
            if (weekNumber > 52) {
                const nYear = new Date(new Date(date).getFullYear() + 1, 0, 1);
                let nday = nYear.getDay() - 1;
                nday = nday >= 0 ? nday : nday + 7;

                /*  if the next year starts before the middle of
             the week, it is week #1 of that year  */

                weekNumber = nday < 4 ? 1 : 53;
            }
        } else {
            weekNumber = Math.floor((dayNumber + day - 1) / 7);
        }
        return weekNumber;
    }

    // Adjuntos
    public readUrl(input: HTMLInputElement) {
        input.click();
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.adjunto = e.target?.result;
            };
            reader.readAsDataURL(input.files[0]);
            const r = this.http.post(environment.serverUrl + 'ws/seguimiento/upload_file.php', this.adjunto).subscribe(res => {
                const file = res as FileUploadAnswer;
                this.model.adjunto = file.fileName;
                r.unsubscribe();
            });
        }
    }
    public verAdjunto() {
        const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
        if (myWindow) {
            myWindow.location.assign(environment.serverUrl + 'fotos_seguimiento/' + this.model.adjunto);
            myWindow.focus();
        }
    }

    public fileChange(event: Event) {
        const input = event.target as HTMLInputElement;

        if (input.files && input.files.length > 0) {
            const fileList: FileList = input.files;
            if (fileList.length > 0) {
                const file: File = fileList[0] ?? new File([],'');
                const formData: FormData = new FormData();
                formData.append('uploadFile', file, file.name);
                const headers = new HttpHeaders();
                headers.append('Content-Type', 'multipart/form-data');
                headers.append('Accept', 'application/json');

                const name: string[] = file.name.split('.');
                const ext = name[name.length - 1];
                const accepted = ['gif', 'jpeg', 'jpg', 'jiff', 'png', 'svg', 'tiff'];
                let cond = false;

                for (const e of accepted) {
                    if (e === ext) {
                        cond = true;
                    }
                }
            
                if (cond) {
                    this.http.post<FileUploadAnswer>(
                        `${environment.serverUrl}ws/seguimiento/upload_file.php`, 
                        formData, 
                        {headers: headers}
                    ).pipe(
                        map(data => {
                            const res = data || {};
                            this.model.adjunto = res.fileName;
                        }),
                        catchError(error => {
                            console.log(error);
                            return of(null);
                        })
                    ).subscribe();
                } else {
                    alert('Formato de imágen no válido.');
                    this.model.adjunto = '';
                }
            }
        }
    }
}