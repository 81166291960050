<p-panel>
    <div class="d-flex justify-content-left align-content-center">
      <div>
        <h4>Datos de la tarea</h4>
      </div>
    </div>
    <div class="row">
      <h4 class="col-sm">INUNDACIÓN INVERNAL DEL TERRENO:</h4>
      <div class="mb-5 col-sm-12">
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          [fields]="formFields"
          (formChanges)="formChanges($event)">
        </app-form-builder>
      </div>
    </div>
    <div class="row">
      <h4 class="col-sm">ACTUACIÓN RASTROJO / PAJA:</h4>
      <div class="mb-5 col-sm-12">
        <app-form-builder
          [model]="model"
          [distributionColumns]="3"
          [fields]="formFieldsRastrojo"
          (formChanges)="formChanges($event)">
        </app-form-builder>
      </div>
    </div>
    
    <div class="d-flex justify-content-center">
      <div>
        <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
      </div>
      <app-request-button
              (click)="submit()"
              [lockOnSuccess]="true"
              [mode]="requestButtonType"
              [request]="request">
      </app-request-button>
    </div>
  </p-panel>