import {InputType} from '../../../../../../common/components/form-builder/form-builder.component';
import {list} from '../../../../../../common/classes/request-builder';
import {FormRequestTypes} from '../../../../../../common/classes/form-request';
import { environment } from 'src/environments/environment';
import { checkIfRolAdmin } from 'src/app/common/check-role';

import type { ItemInterface, TypeAheadInterface } from '../../../../../../common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import { ClienteModel } from 'src/app/models/cliente.model';
export class TareasMtoPodaFormDefinition {

    public lastFieldChanged = '';

    public fincas: TypeAheadInterface<FincasModel> = list();  
    public trabajadores: TypeAheadInterface<MantenimientoModel> = list();  
    public clientes: TypeAheadInterface<ClienteModel> = list();      
    public prioridades = list(['...'], 'Alta', 'Realizada');

    public requestType: FormRequestTypes = 0;

    public model = {};
    
    public appName = environment.appName;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;    

    public fechaActual = Date.now();

    public formFields: ItemInterface<object>[] = 
        [
            {
                field: 'fecha', 
                label: 'Fecha', 
                inputType: {type: InputType.CALENDAR}, 
                required: true
            },
            {
                field: 'id_finca',
                label: this.fincaName,
                inputType: {type: InputType.DROPDOWN_SEARCH},
                values: this.fincas,
                valuePrimaryKey: 'id',
                filter: (it: { value: FincasModel }) => {
                    if (this.clientes.selected) {
                        return it.value.id_cliente === (this.clientes.selected || {}).id;
                    } else {
                        return true;
                    }
                },
                required: true
            },
            {
                field: 'hora_inicio', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TIME}, 
                required: checkIfRolAdmin() 
            },
            {
                field: 'hora_fin', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TIME}, 
                required: checkIfRolAdmin() 
            },
            {
                field: 'horas_totales', 
                label: 'Horas', 
                inputType: {type: InputType.ONLY_TEXT} 
            },
            {
                field: 'mantenimiento', 
                label: 'Mantenimiento', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'prioridad', 
                label: 'Prioridad/Realizada', 
                inputType: {type: InputType.DROPDOWN}, 
                values: this.prioridades
            },
        ];
    public formFieldsSimple: ItemInterface<object>[] = 
        [
            {
                field: 'cipreses', 
                label: 'Cipreses', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'rosales', 
                label: 'Rosales', 
                inputType: {type: InputType.SWITCH}
            },
        ];
    public formFieldsArbolado: ItemInterface<object>[] = 
        [ 
            {
                field: 'arbolado_olivos', 
                label: 'Olivos', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'arbolado_pinos', 
                label: 'Pinos', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'arbolado_otros', 
                label: 'Otros', 
                inputType: {type: InputType.SWITCH}
            },
        ]; 
    public formFieldsValla: ItemInterface<object>[] = 
        [            
            {
                field: 'valla_hiedra', 
                label: 'Hiedra', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'valla_bouganvilla', 
                label: 'Bouganvilla', 
                inputType: {type: InputType.SWITCH}
            },
        ];
    public formFieldsPalmeras: ItemInterface<object>[] = 
        [       
            {
                field: 'palmeras_canadiensis', 
                label: 'Canadiensis', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'palmeras_washingtonias', 
                label: 'Washingtonias', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'palmeras_dactyliferas', 
                label: 'Dactyliferas', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'palmeras_arecastrum', 
                label: 'Arecastrum', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'palmeras_chapaerop', 
                label: 'Chamaerop', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'palmeras_cicas', 
                label: 'Cicas', 
                inputType: {type: InputType.SWITCH}
            },
        ];
    public formFieldsAcuchillado: ItemInterface<object>[] = 
        [      
            {
                field: 'acuchillado_canadiensis', 
                label: 'Canadiensis', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'acuchillado_washingtonias', 
                label: 'Washingtonias', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'acuchillado_dactyliferas', 
                label: 'Dactyliferas', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'acuchillado_arecastrum', 
                label: 'Arecastrum', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'acuchillado_chapaerop', 
                label: 'Chamaerop', 
                inputType: {type: InputType.SWITCH}
            },
            {
                field: 'acuchillado_cicas', 
                label: 'Cicas', 
                inputType: {type: InputType.SWITCH}
            },
        ];

    public formFieldsTrabajadores: ItemInterface<object>[] = [
        {
            field: 'ids_trabajadores',
            label: 'Profesionales',
            inputType: { type: InputType.MULTISELECT },
            values: this.trabajadores,
            valuePrimaryKey: 'id',
            multiSelect: true,
            filter: (it: { value: { activo: string; }; }) => {
                if (it.value.activo !== '1') {
                    return false;
                }
                return true;
            }
        },
    ];
    
    public formFieldsOrdenTrabajo: ItemInterface<object>[] = [
        {
            field: 'orden_trabajo', 
            label: 'Orden de trabajo', 
            inputType: { type: checkIfRolAdmin() ? InputType.TEXTAREA : InputType.EDIT_TEXTAREA },
        },
    ];

    public formFieldsObs: ItemInterface<object>[] = [
        { 
            field: 'observaciones', 
            label: 'Observaciones', 
            inputType: { type: InputType.EDIT_TEXTAREA } 
        },
    ];
    
    public formChanges(tag: string) {
        this.lastFieldChanged = tag;
    }
}