
<div class="d-flex justify-content-center" style="font-size: 45px" >
  <button (click)="visibleSignature = true;" style="border: none; background-color: none;"><i class="fa fa-pencil-square-o" aria-hidden="false"></i></button>
 
</div>
<p-dialog
        header="Firma"
        [(visible)]="visibleSignature"
        (onShow)="scrollToTop($event)">

    <div  style="width: 80vw !important">
      <br>
      <p-panel>
        <div class="d-flex justify-content-end">
          <button class="btn btn-dark" id="clear">
            Limpiar
          </button>
        </div>
        <br>
        <div class="d-flex justify-content-center signature-component">
          <canvas id="signature-pad"
                  [height]="canvasHeight"
                  [width]="canvasWidth">
          </canvas>
        </div>
        <br>
        <!-- <div class="d-flex justify-content-center">
          <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
          <button class="btn btn-primary mx-2" (click)="saveSign()">Guardar</button>
        </div> -->
        <br>
      </p-panel>


        <div class="d-flex justify-content-center" style="margin-top: 40px">
            <button class="btn btn-dark mx-2" (click)="visibleSignature = false">Cerrar</button>
            <button class="btn btn-success mx-2" (click)="saveSign()">Guardar</button>
        </div>
    </div>
    <br>

</p-dialog>
<br>
