import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReferenciaCatastral } from 'src/app/view/main/2_fincas/parcelas/parcelas-form/dialog/dialog.sigpac.component';

@Injectable({providedIn: 'root'})
export class ReferenciaCatastralervice {
    public url = 'https://locatec.es/proyectos/fitogest/ws/';
    constructor(private http: HttpClient) {

    }

    public getReferenciaCatastral( longitude: string, latitude: string ) {
        return this.http.get<ReferenciaCatastral>( 
            this.url + 'catastro/findReferenciaCatastral.php?latitud=' + latitude + '&longitud=' + longitude
        );
    }
}