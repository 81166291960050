import {RequestHandler} from './request-handler';

export class SyncPolicy<T> {

    private request: RequestHandler<T>;

    constructor(request: RequestHandler<T>){
        this.request = request;
    }

    onLoad(callback: (arg: RequestHandler<T>) => void): SyncPolicy<T> {
        this.load = callback;
        return this;
    }

    onOnlineBack(callback: (arg: RequestHandler<T>) => void): SyncPolicy<T> {
        this.onlineBack = callback;
        return this;
    }

    setCustomPolicy(callback: (arg: RequestHandler<T>) => void): SyncPolicy<T> {
        this.customPolicy = callback;
        return this;
    }

    emitLoad(){
        this.load(this.request);
    }

    emitOnlineBack(){
        this.onlineBack(this.request);
    }

    emitCustomPolicy(){
        this.customPolicy(this.request);
    }
    private load: (arg: RequestHandler<T>) => void = () => {};
    private onlineBack: (arg: RequestHandler<T>) => void = () => {};
    private customPolicy: (arg: RequestHandler<T>) => void = () => {};

}
