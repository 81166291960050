import { ParcelasModel } from '../models/form-common/parcelas.model';
import {RequestHandler} from '../service/OffService/request-handler';
import moment from 'moment';

export class Utils {

    public static es = {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto',
            'septiembre', 'octubre', 'noviembre', 'diciembre'
        ],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Borrar'
    };

    public static isNumber(input: string): boolean {
        return /^[0-9]+([,|.][0-9]+)?$/.test(input + '');
    }

    public static isSet(...values: unknown[]): boolean {
        if (values) {
            for (const item of values) {
                if (item === undefined || item === null) {
                    return false;
                }
            }
        }

        return true;
    }

    public static replaceAll(str: string, search: string | RegExp, replacement: string): string {
        return str.replace(new RegExp(search, 'g'), replacement);
    }

    public static replaceNonNumericValues(input: number | number[], decimals = 2): string | string[] {
        const nonNumericValues = ['Infinity', 'NaN', 'undefined', 'false', 'null'];
        let toReturn: string | string[];

        if (typeof input === 'number') {
            toReturn = input.toFixed(decimals);
            nonNumericValues.forEach(value => {
                toReturn = (toReturn as string).replace(value, '0');
            });
        } else {
            toReturn = [];
            input.forEach(num => {
                let str = num.toFixed(decimals);
                nonNumericValues.forEach(value => {
                    str = str.replace(value, '0');
                });
                (toReturn as string[]).push(str);
            });
        }
        return toReturn;
    }

    public static decimalFormat(input: string | number, nDecimals?: number, millar?: string, comma?: string, progressiveTo?: number) {
        if (nDecimals === undefined) {
            nDecimals = 0;
        }

        if (millar === undefined) {
            millar = '.';
        }

        if (comma === undefined) {
            comma = ',';
        }

        if (input) {
            let rest;

            if (String.prototype.constructor === input.constructor) {
                rest = parseFloat(input.toString()).toFixed(progressiveTo || nDecimals).replace('.', ',');
            } else {
                rest = (+input).toFixed(progressiveTo || nDecimals).replace('.', ',');
            }


            if (progressiveTo) {
                for (let i = nDecimals; i < progressiveTo; i++) {
                    rest = rest.substring(-1) === '0' ?
                        rest.substring(0, rest.length - 1) :
                        rest;
                }
            }

            const hasDecimals = rest.indexOf(',') !== -1;
            let startIndex;

            if (hasDecimals) {
                startIndex = rest.indexOf(',') - 1;
            } else {
                startIndex = rest.length - 1;
            }

            let finalString = '';
            let count = -1;

            for (let index = startIndex; index >= 0; index--) {
                const element = rest[index];

                if (++count === 3) {
                    finalString += millar;
                    count = 0;
                }

                finalString += element;
            }

            if (hasDecimals) {
                return finalString.split('').reverse().join('') + comma + rest.split(',')[1];
            } else {
                return finalString.split('').reverse().join('');
            }
        } else {
            let output = '0';

            if (nDecimals > 0) {
                output += comma;
                for (let i = 0; i < nDecimals; i++) {
                    output += '0';
                }
            }

            return output;
        }
    }

    public static preparePrice(input: string | number) {
        let replaced = Utils.decimalFormat(input).replace('.', '');
        replaced = replaced.replace(',', '');
        replaced = replaced.replace(' ', '');

        return replaced;
    }

    public static b64encode(str: string): string {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(_match, p1) {
                return String.fromCharCode(parseInt('0x' + p1, 10));
            }));
    }

    public static b64decode(str: string): string {
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    }

    public static formatDate(date: Date | string) {
        if (date) {
            if (date.constructor === String.prototype.constructor) {
                const splited = (date as string).split('/');
                date = new Date(splited[1] + '/' + splited[0] + '/' + splited[2]);

                let day: string | number = date.getDate();
                if (day <= 9) {
                    day = '0' + day;
                }
                let month: string | number = date.getMonth() + 1;
                if (month <= 9) {
                    month = '0' + month;
                }

                return day + '/' + month + '/' + date.getFullYear();
            } else if (date.constructor === Date.prototype.constructor) {
                let day: string | number = (date as Date).getDate();
                if (day <= 9) {
                    day = '0' + day;
                }
                let month: string | number = (date as Date).getMonth() + 1;
                if (month <= 9) {
                    month = '0' + month;
                }
                return day + '/' + month + '/' + (date as Date).getFullYear();
            } else {
                throw new Error('Utils.formatDate: Try to formating [' + date.constructor.name + '], Admited types [Date | String]');
            }
        } else {
            throw new Error('Utils.formatDate: Try to formating null | undefined date');
        }
    }

    public static toDate(date: string): Date | undefined | null {
        if ((/((([1-9]|0[1-9]|[12]\d|3[01])[-/]([1-9]|0[1-9]|1[0-2])[-/][12]\d{3}))/).test(date)) {
            const splited = (date as string).split('/');
            const newDate = new Date(splited[1] + '/' + splited[0] + '/' + splited[2]);
            if (newDate) {
                return new Date(splited[1] + '/' + splited[0] + '/' + splited[2]);
            } else {
                return null;
            }
        } else {
            return;
        }
    }

    public static unsuscribe<T>(requests: RequestHandler<T>[]) {
        (requests || []).forEach(req => {
            (req || {
                unsuscribe: () => false
            }).unsuscribe();
        });
    }

    public static removeAccents(s: string): string {
        let r = s.toLowerCase();
        r = r.replace(new RegExp('\\s', 'g'), '');
        r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
        r = r.replace(new RegExp('æ', 'g'), 'ae');
        r = r.replace(new RegExp('ç', 'g'), 'c');
        r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
        r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
        r = r.replace(new RegExp('ñ', 'g'), 'n');
        r = r.replace(new RegExp('[òóôõö]', 'g'), 'o');
        r = r.replace(new RegExp('œ', 'g'), 'oe');
        r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
        r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
        r = r.replace(new RegExp('\\W', 'g'), '');
        return r;
    }

    public static checkIfProductIsValid(fecha_caducidad: string | null | undefined, tipo: string) {
        if (tipo === 'fertilizante') {
            return '🅽🅿🅺';
        } else if ([undefined, null, ''].includes(fecha_caducidad)) {
            return '🛇';
        }

        const diff = moment(new Date()).diff(Utils.toDate(fecha_caducidad ?? ''), 'months');
        if (diff > 12) {
            return '***';
        } else if (diff > 6) {
            return '**';
        } else if (diff >= 0) {
            return '*';
        }

        return '';
    }

    public static isLeapYear(year: number) {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    }

    public static htmlEntities(str: unknown) {
        return String(str).replace('&ntilde;', 'ñ')
            .replace('&Ntilde;', 'Ñ')
            .replace('&amp;', '&')
            .replace('&Ntilde;', 'Ñ')
            .replace('&ntilde;', 'ñ')
            .replace('&Ntilde;', 'Ñ')
            .replace('&Agrave;', 'À')
            .replace('&Aacute;', 'Á')  
            .replace('&Acirc;', 'Â')
            .replace('&Atilde;', 'Ã')   
            .replace('&Auml;', 'Ä')
            .replace('&Aring;', 'Å')
            .replace('&AElig;', 'Æ')
            .replace('&Ccedil;', 'Ç')
            .replace('&Egrave;', 'È')
            .replace('&Eacute;', 'É')
            .replace('&Ecirc;', 'Ê')
            .replace('&Euml;', 'Ë')
            .replace(   '&Igrave;', 'Ì')
            .replace('&Iacute;', 'Í'    )
            .replace('&Icirc;', 'Î' )
            .replace(   '&Iuml;', 'Ï')
            .replace(   '&ETH;', 'Ð')
            .replace(   '&Ntilde;', 'Ñ')
            .replace(   '&Ograve;', 'Ò')
            .replace(   '&Oacute;', 'Ó')
            .replace('&Ocirc;', 'Ô' )
            .replace(   '&Otilde;', 'Õ')
            .replace('&Ouml;', 'Ö'  )
            .replace('&Oslash;', 'Ø'    )
            .replace(   '&Ugrave;' , 'Ù')
            .replace(   '&Uacute;', 'Ú')
            .replace(   '&Ucirc;', 'Û')
            .replace(   '&Uuml;', 'Ü')
            .replace(   '&Yacute;', 'Ý')
            .replace('&THORN;', 'Þ' )
            .replace(   '&szlig;', 'ß')
            .replace(   '&agrave;', 'à')
            .replace(   '&aacute;', 'á')
            .replace(   '&acirc;', 'â')
            .replace(   '&atilde;', 'ã')
            .replace('&auml;', 'ä'  )
            .replace(   '&aring;', 'å')
            .replace(   '&aelig;', 'æ')
            .replace(   '&ccedil;', 'ç')
            .replace('&egrave;', 'è'    )
            .replace('&eacute;', 'é'    )
            .replace('&ecirc;', 'ê' )
            .replace('&euml;', 'ë'  )
            .replace(   '&igrave;', 'ì')
            .replace('&iacute;', 'í'    )
            .replace('&icirc;', 'î' )
            .replace('&iuml;', 'ï'  )
            .replace('&eth;', 'ð'   )
            .replace(   '&ntilde;', 'ñ')
            .replace('&ograve;', 'ò')
            .replace('&oacute;', 'ó')
            .replace('&ocirc;', 'ô')
            .replace('&otilde;', 'õ')
            .replace('&ouml;', 'ö')
            .replace('&oslash;', 'ø')
            .replace('&ugrave;', 'ù')
            .replace('&uacute;', 'ú')
            .replace('&ucirc;', 'û')
            .replace('&uuml;' , 'ü')   
            .replace('&yacute;', 'ý')  
            .replace('&thorn;', 'þ')
            .replace('&yuml;', 'ÿ');
    }

    public static initShowAll(item: string): boolean{

        if (!sessionStorage.getItem(item)){
            sessionStorage.setItem(item, 'false');
        } 

        return sessionStorage.getItem(item) === 'true' ? true : false;
    
    }
  
    public static formatoHorasMinutos(horaEntrada: string, horaSalida: string) {

        let timeStr: string | string[] = horaEntrada;
        timeStr = timeStr.split(':');
    
        const h = timeStr[0] ?? '',
            m = timeStr[1] ?? '';
    
        const newTime = moment( horaSalida + ' 2021-4-5')
            .subtract({'hours': +h, 'minutes': +m})
            .format('HH:mm');
    
    
        return newTime;
       
    }

    public static calculateTotalHours( hora_entrada: moment.MomentInput, hora_salida: moment.MomentInput){
        
        if ( hora_salida == null || hora_entrada == null ) {
            return  '';
        }

        if ( (hora_salida as string).length === 0 || (hora_entrada as string).length === 0 ) {
            return  '';
        }
        
        if (hora_entrada != null && hora_salida != null ) {
     
            let horaFin = moment(hora_salida).format('HH:mm');
            let horaInicio = moment(hora_entrada).format('HH:mm');
            const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
            if (!timeFormat.test(horaInicio)) {
                horaInicio = hora_entrada.toString();
            }
    
            if (!timeFormat.test(horaFin)) {
                horaFin = hora_salida.toString();
            }
    
            const diferencia = Utils.formatoHorasMinutos(horaInicio, horaFin);
            return diferencia;
    
        } else {
            return  '';
        }
    
    }

}

export interface Data {
    name: string;
    message: string;
    csv: string;
    iguales: string;
    err: string;
}
export interface FileUploadAnswer {
    mensage: string;
    data: Data;
    sql: string;
    target_file: string;
    aux_target_file: string;
    file: string;
    fileName: string;
    folder: string;
}

export interface Datum {
    year: string;
    numero: string;
}

export interface RespReferencia {
    statusCode: number;
    statusMessage: string;
    data: Datum[];
}

export interface CustomHTMLElement extends HTMLElement {
    fcSeg: {
      eventRange: {
        def: {
          title: string;
        };
      };
    };
}

export interface UploadResponse {
    content_found: ParcelasModel[];
    content_not_found: ParcelasModel[];
    especie_found: string[];
    especie_not_found: number[];
}