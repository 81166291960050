'use strict';

var possibleNames = require('possible-typed-array-names');
var g = typeof globalThis === 'undefined' ? global : globalThis;

/** @type {import('.')} */
module.exports = function availableTypedArrays() {
  var /** @type {ReturnType<typeof availableTypedArrays>} */out = [];
  for (var i = 0; i < possibleNames.length; i++) {
    if (typeof g[possibleNames[i]] === 'function') {
      // @ts-expect-error
      out[out.length] = possibleNames[i];
    }
  }
  return out;
};