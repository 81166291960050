<br>
<div style="width: auto; overflow: auto">
  <app-common-list-table
    [request]="stockApi.productos"
    [minWidth]="800"
    [cols]="cols"
    actionButtonText="Añadir Producto"
    (actionButtonClick)="addProducto()">
  </app-common-list-table>
</div>
