import { Component } from '@angular/core';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { StorageManager } from 'src/app/common/storage-manager.class';
import { Router } from '@angular/router';
import { TareasApiService } from 'src/app/service/api/tareas-api.service';
import { VentasApiService } from 'src/app/service/api/ventas-api.service';
import { VisitasApiService } from 'src/app/service/api/visitas-api.service';
import { environment } from 'src/environments/environment';

import moment from 'moment';
import { UserActivityApiService } from 'src/app/service/api/user_activity-api.service';

import type { OnInit } from '@angular/core';
import { UserActivityModel } from 'src/app/models/usuarios/user-activity.model';

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
    public hasClients = environment.features.hasClients;
    public tieneVisitas = environment.features.showVisitas;
    public userActivities: UserActivityModel[] = [];
    public userActivitiesFormat: { msg: string; request_method: string; fecha: string; }[] = [];

    public requestTareasFito = this.tareasApi.fitosanitariosGET;
    public requestVentas = this.ventasApi.ventasGET;
    public requestVisitas = this.visitasApi.visitasESTADISTICAS_GET;

    //-----Tareas-----
    //Fitosanitarias (line)
    public requestFitosanitarias = this.tareasApi.fitosanitariosGET;
    //Fertilizantes (line)
    public requestFertilizantes = this.tareasApi.fertilizantes.GET;

    public showVentas = environment.features.showVentas;

    public optionsTareasFito = {
        field: 'id_tratamiento',
        label: 'nombre',
        fieldIsArray: true,
        multipleFields: true,
    };

    public optionsVentas = {
        field: 'cantidad',
        label: 'cliente',
        fieldIsArray: true,
        multipleFields: true,
    };

    public optionsVisitas = {
        field: 'cantidad',
        label: 'cliente',
        fieldIsArray: true,
        multipleFields: true,
    };

    constructor(
        private dashboard: DashboardService,
        private router: Router,
        private userActivityApi: UserActivityApiService,
        private ventasApi: VentasApiService,
        private tareasApi: TareasApiService,
        private visitasApi: VisitasApiService
    ) { }

    async ngOnInit() {
        this.userActivityApi.statisticsGET.toPromise().then(activities => {
            this.userActivities = activities;
            this.formatUserActivities();
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );

        if (StorageManager.getUser().rol !== 'admin') {
            this.router.navigate(['dashboard', 'inicio']);
        } else {
            this.dashboard.setMenuItem('statistics');
        }
    }

    public formatUserActivities() {
        this.userActivitiesFormat = [...this.userActivities].map(it => {
            let msg = '';
            const data = JSON.parse(it.data);

            switch (it.request_method) {
            case 'POST':    msg += 'Se ha añadido un registro ';                    break;
            case 'PUT':     msg += 'Se ha editado el registro #' + data.id + ' ';   break;
            case 'DELETE':  msg += 'Se ha borrado el registro #' + data.id + ' ';   break;
            default:        msg += 'Se ha estado activo recientemente ';            break;
            }

            const table = (it.affected_table as string).replace('_', '\xa0').toUpperCase();
            msg += ' en ' + table + '.';

            const momento = (moment(it.fecha).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) ?
                moment(it.fecha).startOf('second').fromNow() :
                moment(it.fecha).format('DD [de] MMMM [a las] H:mm');
            return {
                msg,
                request_method: it.request_method,
                fecha: momento
            };
        });
    }

    public getUserActivityClass(method: string) {
        switch (method) {
        case 'GET':     return 'green';
        case 'POST':    return 'blue';
        case 'PUT':     return 'yellow';
        case 'DELETE':  return 'red';
        default:        return 'white';
        }
    }
}
