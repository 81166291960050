import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import type { AfterViewInit, ElementRef, OnChanges, OnInit } from '@angular/core';
import { ExpandableItem } from 'src/app/models/menu-item';
@Component({
    selector: 'app-sidenav-cell',
    templateUrl: './sidenav-cell.component.html',
    styleUrls: ['./sidenav-cell.component.scss']
})
export class SidenavCellComponent implements OnInit, OnChanges, AfterViewInit {

    public static readonly CELL_BEHAVE_SELECTED = 'selected';
    public static readonly CELL_BEHAVE_UNSELECTED = 'un-selected';

    @Input() public id = '';
    @Input() public text = '';
    @Input() public icon = ' ';
    @Input() public isSelected = false;
    @Input() public expand: ExpandableItem[] | undefined = [];
    @Output() public cellClickHandler = new EventEmitter<string>();
    @Output() public subcellClickHandler = new EventEmitter<string>();

    @ViewChild('subMenuButton') subMenuButton!: ElementRef;

    public status = '';
    public submenuHeight = '';
    public submenuOpen = '';
    public isOpen = true;
    private buttonHeight = 36;

    constructor() {
    }

    ngOnInit() {
        
        const height = [];
        if (this.expand) {
            for (const e of this.expand) {
                if (!(e.visible === false)) {
                    height.push(e);
                }
            }
        }
        // this.submenuHeight = (height.length > 0) ? (height.length * 36).toString() + 'px' : '0';

        // if (this.isSelected) {
        //     this.status = SidenavCellComponent.CELL_BEHAVE_SELECTED;
        //     if (this.expand && this.expand?.length > 0){
        //         (this.expand[0] as ExpandableItem ).isSelected  = true;
        //     }
            
        // } else {
        //     this.status = SidenavCellComponent.CELL_BEHAVE_UNSELECTED;
        // }

        this.updateSubmenuHeight();
    }

    ngAfterViewInit() {
        // Obtener la altura del botón después de que el DOM se haya renderizado
        if (this.subMenuButton) {
            this.buttonHeight = this.subMenuButton.nativeElement.offsetHeight;
            this.updateSubmenuHeight();
        }
    }

    
    ngOnChanges() {
        if (this.isSelected) {
            this.status = SidenavCellComponent.CELL_BEHAVE_SELECTED;
            this.submenuOpen = this.submenuHeight;
        } else {
            this.status = SidenavCellComponent.CELL_BEHAVE_UNSELECTED;
            this.submenuOpen = '0px';
        }
    }

    public sidenavClicked() {
        const height = [];
        if (this.expand) {
            for (const e of this.expand) {
                if (!(e.visible === false)) {
                    height.push(e);
                }
            }
        }
        // this.submenuHeight = (height.length > 0) ? (height.length * 37).toString() + 'px' : '0';

        this.cellClickHandler.emit(this.id);
        if (this.isOpen) {
            this.isOpen = false;
            this.submenuOpen = '0px';
            this.isSelected = false;

            if (this.expand && this.expand.length > 0 && !this.expand.some(item => item.isSelected)) {
                (this.expand[0] as ExpandableItem).isSelected = true;
            }
        } else {
            this.isOpen = true;
            this.submenuOpen = this.submenuHeight;
            this.isSelected = true;
        }
    }

    public subSidenavClicked(id: string) {   
        if (this.expand) {
            this.expand.forEach(item => {
                item.isSelected = item.id === id;
            });
        }     
        this.subcellClickHandler.emit(id);
    }

    private updateSubmenuHeight() {
        if (this.expand) {
            const visibleItems = this.expand.filter(item => item.visible !== false);
            const totalButtonHeight = this.buttonHeight;
            this.submenuHeight = visibleItems.length > 0 ? `${visibleItems.length * totalButtonHeight}px` : '0';
        }
    }

}
