import { Injectable } from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {IdbServiceService} from '../OffService/idb-service.service';
import {RequestHandler} from '../OffService/request-handler';
import { StockageModel } from 'src/app/models/stock/stokage.model';
import { ProductosList } from 'src/app/models/stock/productos-list.model';

@Injectable({
    providedIn: 'root'
})
export class StockApiService {

    public productos = new RequestHandler<ProductosList>();
    public productosPost = new RequestHandler<StockageModel[]>();
    public balances = new RequestHandler<Record<string,string>[]>();

    public entradas = {
        GET: new RequestHandler<StockageModel[]>(),
        POST: new RequestHandler<StockageModel[]>(),
        PUT: new RequestHandler<StockageModel[]>(),
        DELETE: new RequestHandler<StockageModel>()
    };

    public salidas = {
        GET: new RequestHandler<StockageModel[]>(),
        POST: new RequestHandler<StockageModel[]>(),
        PUT: new RequestHandler<StockageModel[]>(),
        DELETE: new RequestHandler<StockageModel>()
    };

    public stockage = {
        GET: new RequestHandler<StockageModel[]>()
    };

    constructor(
        private syncService: SyncService<StockageModel[]>,
        private syncServiceBalance: SyncService<Record<string,string>[]>,
        private syncServiceDelete: SyncService<StockageModel>,
        private syncServiceProducto: SyncService<ProductosList>,
        private api: ApiRequestService<StockageModel[]>,
        private apiBalance: ApiRequestService<Record<string,string>[]>,
        private apiDelete: ApiRequestService<StockageModel>,
        private apiProducto: ApiRequestService<ProductosList>,
        private idb: IdbServiceService) {

        this.defineProductos();
        this.definePostProducto();
        this.defineEntradas();
        this.defineSalidas();
        this.defineBalances();
        this.defineStockage();
    }


    private defineProductos() {
        this.productos
            .fetch(HttpRequestType.GET, 'stock/productos');

        this.apiProducto
            .registerRequest(this.productos, this.idb.stock_productos);

        this.syncServiceProducto
            .register(this.syncServiceProducto.baseSyncPolicy(this.productos));
    }

    private definePostProducto() {
        this.productosPost
            .fetch(HttpRequestType.POST, 'generic/productos');

        this.api
            .registerRequest(this.productosPost);
    }


    private defineEntradas(){
        this.entradas.GET.fetch(HttpRequestType.GET, 'stock/get');
        this.entradas.POST.fetch(HttpRequestType.POST, 'generic/stock_new');
        this.entradas.PUT.fetch(HttpRequestType.PUT, 'generic/stock_new');
        this.entradas.DELETE.fetch(HttpRequestType.DELETE, 'generic/stock_new');

        this.api.registerRequest(this.entradas.GET);
        this.api.registerRequest(this.entradas.POST);
        this.api.registerRequest(this.entradas.PUT);
        this.apiDelete.registerRequest(this.entradas.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.entradas.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.entradas.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.entradas.PUT));
        this.syncServiceDelete.register(this.syncServiceDelete.baseSyncPolicy(this.entradas.DELETE));
    }

    private defineSalidas(){
        this.salidas.GET.fetch(HttpRequestType.GET, 'stock/salidas');
        this.salidas.POST.fetch(HttpRequestType.POST, 'generic/stock');
        this.salidas.PUT.fetch(HttpRequestType.PUT, 'generic/stock');
        this.salidas.DELETE.fetch(HttpRequestType.DELETE, 'generic/stock');

        this.api.registerRequest(this.salidas.GET);
        this.api.registerRequest(this.salidas.POST);
        this.api.registerRequest(this.salidas.PUT);
        this.apiDelete.registerRequest(this.salidas.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.salidas.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.salidas.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.salidas.PUT));
        this.syncServiceDelete.register(this.syncServiceDelete.baseSyncPolicy(this.salidas.DELETE));
    }

    private defineBalances(){
        this.balances
            .fetch(HttpRequestType.GET, 'stock/balances');

        this.apiBalance
            .registerRequest(this.balances);

        this.syncServiceBalance
            .register(this.syncServiceBalance.baseSyncPolicy(this.balances));
    }

    private defineStockage(){
        this.stockage.GET.fetch(HttpRequestType.GET, 'stock/stockage');

        this.api.registerRequest(this.stockage.GET);

        this.syncService.register(this.syncService.baseSyncPolicy(this.stockage.GET));
    }
}
