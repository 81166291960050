import { VisitasModel } from './../visitas/visitas.model';
import {ClienteModel} from '../cliente.model';
import {FincaModel} from '../finca.model';
import {UserModel} from '../usuarios/user.model';
import { VisitasVariedadMesModel } from '../visitas_variedad_mes/visitas_variedad_mes.model';

export class CitasModel {
    id?: string; 
    title?: string;
    id_cliente?: string;
    cliente?: ClienteModel;
    id_finca = '';
    finca?: FincaModel;
    id_parcela?: string;
    parcela = '';
    id_visita?: string;
    visita?: VisitasModel;
    numVisita?: string;
    id_visitador = '';
    visitador: UserModel = new UserModel('', '');
    fecha = '';
    hora = '';
    realizada?: string;
    variedad?: VisitasVariedadMesModel;
    cultivo?: VisitasVariedadMesModel;
    cultivo_precedente?: VisitasVariedadMesModel;

    cultivo_nombre?: string;
    variedad_nombre?: string;

    imagen1?: string;
    imagen2?: string;
    imagen3?: string;
    email?: string;

    motivo?: string;
    document?: Response;

    concertada?: string;
    observaciones?: string;
    tipo_visita?: string;
    cliente_nombre?: string;
    finca_nombre?: string;
    id_sector?: string;
}
