import {FormRequest, FormRequestTypes} from '../../../common/classes/form-request';
import {RequestHandler} from '../../../service/OffService/request-handler';
import {InputType} from '../../../common/components/form-builder/form-builder.component';
import {AppFormRequestCreate} from './states/app-form-request-create';
import {AppFormRequestEdit} from './states/app-form-request-edit';
import {AppFormRequestDuplicate} from './states/app-form-request-duplicate';

import type { ItemInterface, ItemValuesInterface } from '../../../common/components/form-builder/form-builder.component';

export class AppFormRequest<T> extends FormRequest {

    public requestPOST = new RequestHandler<T[]>();
    public requestPUT = new RequestHandler<T[]>();
    public requestGET = new RequestHandler<T[]>();
    public model = {} as T;
    public modelOld = {} as T;
    public modelChanges = {} as T;
    public registerId = '';
    public formFields: ItemInterface<object>[] = [];
    public generic = false;
    public fieldsToSend: string[] = [];
    public fieldsToRetain: string[] = [];

    public type: FormRequestTypes = 0;
    public createFormRequest: AppFormRequestCreate<T> | undefined;
    public editFormRequest: AppFormRequestEdit<T> | undefined;
    public duplicateFormRequest: AppFormRequestDuplicate<T> | undefined;

    constructor() {
        super();
        this.init();
    }

    // GETTERS
    public setPostRequest(requestPOST: RequestHandler<T[]>): AppFormRequest<T> {
        this.requestPOST = requestPOST;
        return this;
    }

    public setGetRequest(requestGET: RequestHandler<T[]>): AppFormRequest<T> {
        this.requestGET = requestGET;
        return this;
    }

    public setPutRequest(requestPUT: RequestHandler<T[]>): AppFormRequest<T> {
        this.requestPUT = requestPUT;
        return this;
    }

    public setModel(model: T): AppFormRequest<T> {
        this.model = model;
        return this;
    }

    public setModelOld(modelOld: T): AppFormRequest<T> {
        this.modelOld = modelOld;
        return this;
    }

    public setFieldsToSend(fieldsToSend: string[]): AppFormRequest<T> {
        this.fieldsToSend = fieldsToSend;
        return this;
    }

    public setFieldsToRetain(fieldsToRetain: string[]): AppFormRequest<T> {
        this.fieldsToRetain = fieldsToRetain;
        return this;
    }

    public setModelChanges(modelChanges: T): AppFormRequest<T> {
        this.modelChanges = modelChanges;
        return this;
    }

    public setType(type: FormRequestTypes): AppFormRequest<T> {
        this.type = type;
        return this;
    }

    public setFormFields(formFields: ItemInterface<object>[]): AppFormRequest<T> {
        this.formFields = formFields;
        return this;
    }

    public isGeneric(answer: boolean): AppFormRequest<T> {
        this.generic = answer;
        return this;
    }

    public setRegisterId(registerId: string): AppFormRequest<T> {
        this.registerId = registerId;
        return this;
    }


    public checkIfValid(): boolean {
        let cond = true;
        for (const field of this.formFields) {
            if (field && field.required && (field.visible !== false)) {
                if (!(this.model as Record<string,string>)[field.field ?? 0]) {
                    cond = false;
                }
                if (
                    [InputType.DROPDOWN, InputType.DROPDOWN_SEARCH].includes(field.inputType?.type ?? 0) && 
                    (field.values as ItemValuesInterface<object>)?.selected === undefined
                ) {
                    cond = false;
                }
                if (
                    (field.inputType?.type === InputType.TWO_FIELDS_CONCAT) &&
                    (
                        !(this.model as Record<string,string>)[field.subField1 ?? 0] ||
                        !(this.model as Record<string,string>)[field.subField2 ?? 0] || 
                        isNaN((this.model as Record<string, number>)[field.subField1 ?? 0] ?? 0) ||
                        isNaN((this.model as Record<string,number>)[field.subField2 ?? 0] ?? 0)
                    )
                ) {
                    cond = false;
                }
            }

        }
        return cond;
    }

    private init() {
        this.beforeLoad(resolve => {
            if (this.type === undefined) {
                console.error('AppFormRequest @ Check: Parameter TYPE is required, please use setType(type: FormRequestTypes)');
                resolve(false);
            } else {
                resolve(true);
            }
        });

        this.beforeLoad(resolve => {
            switch (this.type) {
            case FormRequestTypes.EDIT:
                this.editFormRequest = new AppFormRequestEdit(this);
                break;
            case FormRequestTypes.CREATE:
                this.createFormRequest = new AppFormRequestCreate(this);
                break;
            case FormRequestTypes.DUPLICATE:
                this.duplicateFormRequest = new AppFormRequestDuplicate(this);
                break;
            }

            resolve(true);
        });

        this.afterLoad(resolve => {
            switch (this.type) {
            case FormRequestTypes.EDIT:
                resolve(this.checkEdit());
                break;
            case FormRequestTypes.CREATE:
                resolve(this.checkCreate());
                break;
            case FormRequestTypes.DUPLICATE:
                resolve(this.checkDuplicate());
                break;
            }

            resolve(true);
        });

        this.beforeSend(resolve => {
            resolve(this.checkIfValid());
        });

        this.afterSend(resolve => {
            this.finish();
            resolve(true);
        });
    }

    private checkCreate(): boolean {
        if (!this.requestPOST) {
            console.error('AppFormRequest @ Check Create: Param requestPOST is required');
            return false;
        }

        if (!this.model) {
            console.error('AppFormRequest @ Check Create: Param model is required');
            return false;
        }
        console.log(this.model);
        return true;
    }

    private checkEdit(): boolean {
        if (!this.requestPUT) {
            console.error('AppFormRequest @ Check Edit: Param requestPUT is required');
            return false;
        }

        if (!this.requestGET) {
            console.error('AppFormRequest @ Check Edit: Param requestGET is required');
            return false;
        }

        if (!this.registerId) {
            console.error('AppFormRequest @ Check Edit: Param registerId is required');
            return false;
        }

        if (!this.model) {
            console.error('AppFormRequest @ Check Edit: Param model is required');
            return false;
        }

        if (this.generic === undefined) {
            console.warn('AppFormRequest @ Check Edit: Param generic not defined, using "id"');
        }

        return true;
    }

    private checkDuplicate(): boolean {

        if (!this.requestPOST) {
            console.error('AppFormRequest @ Check Duplicate: Param requestPOST is required');
            return false;
        }

        if (!this.requestGET) {
            console.error('AppFormRequest @ Check Duplicate: Param requestGET is required');
            return false;
        }

        if (!this.registerId) {
            console.error('AppFormRequest @ Check Duplicate: Param registerId is required');
            return false;
        }

        if (!this.model) {
            console.error('AppFormRequest @ Check Duplicate: Param model is required');
            return false;
        }

        if (this.generic === undefined) {
            console.warn('AppFormRequest @ Check Duplicate: Param generic not defined, using "id"');
        }

        return true;
    }
}
