import {Injectable} from '@angular/core';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { EntidadModel } from 'src/app/models/reto/entidad.model';
import { ProvinciasInterface } from 'src/app/view/main/XI_reto/entidades/entidades-form/entidades-form';

@Injectable({
    providedIn: 'root'
})
export class EntidadesApiService {

    public EntidadesGET = new RequestHandler<EntidadModel[]>();
    public EntidadesDELETE = new RequestHandler<EntidadModel>();
    public EntidadesPUT = new RequestHandler<EntidadModel[]>();
    public EntidadesPOST = new RequestHandler<EntidadModel[]>();

    public provinciasGET = new RequestHandler<ProvinciasInterface[]>();
    public provinciasDELETE = new RequestHandler<ProvinciasInterface>();
    public provinciasPUT = new RequestHandler<ProvinciasInterface[]>();
    public provinciasPOST = new RequestHandler<ProvinciasInterface[]>();


    constructor(
        private syncServiceEntidad: SyncService<EntidadModel[]>,
        private syncServiceEntidadDelete: SyncService<EntidadModel>,
        private syncServiceProvincia: SyncService<ProvinciasInterface[]>,
        private syncServiceProvinciaDelete: SyncService<ProvinciasInterface>,
        private apiEntidad: ApiRequestService<EntidadModel[]>,
        private apiEntidadDelete: ApiRequestService<EntidadModel>,
        private apiProvincia: ApiRequestService<ProvinciasInterface[]>,
        private apiProvinciaDelete: ApiRequestService<ProvinciasInterface>) {

        this.defineEntidadesGET();
        this.defineEntidadesPUT();
        this.defineEntidadesPOST();
        this.defineEntidadesDELETE();

        this.defineprovinciasGET();
        this.defineprovinciasPUT();
        this.defineprovinciasPOST();
        this.defineprovinciasDELETE();
    }

    private defineEntidadesGET() {
        this.EntidadesGET
            .fetch(HttpRequestType.GET, 'entidades/get');

        this.apiEntidad
            .registerRequest(this.EntidadesGET);

        this.syncServiceEntidad
            .register(this.syncServiceEntidad.baseSyncPolicy(this.EntidadesGET));
    }

    private defineEntidadesPUT() {
        this.EntidadesPUT
            .fetch(HttpRequestType.PUT, 'generic/entidades');

        this.apiEntidad
            .registerRequest(this.EntidadesPUT);

        this.syncServiceEntidad
            .register(this.syncServiceEntidad.baseSyncPolicy(this.EntidadesPUT));
    }

    private defineEntidadesPOST() {
        this.EntidadesPOST
            .fetch(HttpRequestType.POST, 'generic/entidades');

        this.apiEntidad
            .registerRequest(this.EntidadesPOST);

        this.syncServiceEntidad
            .register(this.syncServiceEntidad.baseSyncPolicy(this.EntidadesPOST));
    }

    private defineEntidadesDELETE() {
        this.EntidadesDELETE
            .fetch(HttpRequestType.DELETE, 'generic/entidades');

        this.apiEntidadDelete
            .registerRequest(this.EntidadesDELETE);

        this.syncServiceEntidadDelete
            .register(this.syncServiceEntidadDelete.baseSyncPolicy(this.EntidadesDELETE));
    }

    private defineprovinciasGET() {
        this.provinciasGET
            .fetch(HttpRequestType.GET, 'generic/provincias');

        this.apiProvincia
            .registerRequest(this.provinciasGET);

        this.syncServiceProvincia
            .register(this.syncServiceProvincia.baseSyncPolicy(this.provinciasGET));
    }

    private defineprovinciasPUT() {
        this.provinciasPUT
            .fetch(HttpRequestType.PUT, 'generic/provincias');

        this.apiProvincia
            .registerRequest(this.provinciasPUT);

        this.syncServiceProvincia
            .register(this.syncServiceProvincia.baseSyncPolicy(this.provinciasPUT));
    }

    private defineprovinciasPOST() {
        this.provinciasPOST
            .fetch(HttpRequestType.POST, 'generic/provincias');

        this.apiProvincia
            .registerRequest(this.provinciasPOST);

        this.syncServiceProvincia
            .register(this.syncServiceProvincia.baseSyncPolicy(this.provinciasPOST));
    }

    private defineprovinciasDELETE() {
        this.provinciasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/provincias');

        this.apiProvinciaDelete
            .registerRequest(this.provinciasDELETE);

        this.syncServiceProvinciaDelete
            .register(this.syncServiceProvinciaDelete.baseSyncPolicy(this.provinciasDELETE));
    }
}
