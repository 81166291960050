
export class SigPACModel {
    referencia: string = '';
    provincia: string = '';
    municipio: string = '';
    poligono: string = '';
    parcela: string = '';
    recinto: string = '';
    superficie: string = '';
    usoSigpac: string = '';
}