import {Component, EventEmitter, Input, Output} from '@angular/core';
import { environment } from 'src/environments/environment';
import {StorageManager} from '../../../common/storage-manager.class';

import type { OnInit } from '@angular/core';

@Component({
    selector: 'app-action-buttons',
    templateUrl: './action-buttons.component.html',
    styleUrls: ['./action-buttons.component.scss']
})
export class ActionButtonsComponent implements OnInit {

    @Input() hideInfo = false;
    @Input() hideEdit = false;
    @Input() hideDelete = false;
    @Input() showDuplicate = false;
    @Input() showNotification = false;
    @Input() notificationCount = 0;
    @Input() notificationSeverity = 'danger';

    @Output() editRowButtonEvent: EventEmitter<object> = new EventEmitter<object>();
    @Output() deleteRowButtonEvent: EventEmitter<object> = new EventEmitter<object>();
    @Output() showRowButtonEvent: EventEmitter<object> = new EventEmitter<object>();
    @Output() duplicateRowButtonEvent: EventEmitter<object> = new EventEmitter<object>();

    public userSoloCanSeeInfo = environment.features.userSoloCanSeeInfo;
    public userCanDeleteRecords = environment.features.userCanDeleteRecords;
    public showInfoCambios = environment.features.showInfoCambios;
    public appName = environment.appName;

    public userRol = StorageManager.getUser().rol;

    constructor() { }

    ngOnInit() {
        if (this.userRol === 'demo' || this.userRol === 'gerencia' || this.userRol === 'aplicador') {
            this.hideDelete = true;
            this.hideEdit = true;
            if (this.appName === 'ava') {
                this.hideEdit = false;
            }
        }

        if (this.userRol === 'gerencia' || this.userRol === 'aplicador') {
            this.showDuplicate = false;
        }

        if (this.userRol !== 'admin' && (environment.features.showTareasMto || !this.userCanDeleteRecords)) {
            this.hideDelete = true;
        }
    }

}
