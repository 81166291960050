import { features } from './features'; 

features.applicationType = 'cropgest';
features.clientesName = 'Agricultores';
features.clienteName = 'Agricultor';
features.showMultiselectAplicadores = true;
features.showEstadisticas = true;
features.showAnalisis = true;
features.showVisitas = true;
features.showCargaPAC = true;
features.showTractorMarcha = true;
features.showTractorRPM = true;
features.showTractorPresion = true;
features.showTractorBoquillas = true;
features.showVisitasConsultas = true;
features.showDesplazamientoObra = true;
features.showPartida = true;
features.puedeImprimir = true;
features.showAbonado = true;
features.showTratamientosAllProductos = true;
features.showTratamientosExportCsv = true;
features.showPhCaldo = true;
features.showEnviarWhatsapp = true;
features.showEnviarCorreo = true;
features.showEnviarWhatsappVisitas = true;
features.showEnviarCorreoVisitas = true;
features.showTareasBio = true;
features.showOrdenAplicacionProductos = true;
features.showMeteoBtn = true;
features.adminCanSeeAllRecords = true;
features.showVisitasUploadPhotos = true;
features.showAnalisisSectorParcelas = true;
features.showClientesRegepa = true;
features.showCostes = false;
features.showCostesOtros = false;
features.filterAplicadoresByClient = true;
features.showCargaPAC = true;
features.showNPK = false;
features.showParcelasEstacion = true;
features.showTareasFitoMotivoJustificacion = false;
features.showFertilizantesFormShort = true;
features.showTareasFertiGramos = false;
features.showFertilizantesS = true;
features.showParcelasFincasCol = false;
features.showParcelasProvinciaCol = true;
features.showParcelasMunicipioCol = true;
features.userCanSeeClientes = false;
features.showVisitasVariedades = true;
features.hiddenVisitasCerezo = true;
features.hiddenVisitasContador = true;
features.showVisitaCarenciasMultiple = true;
features.userCanSeeCostes = false;
features.showTareasFitoLote = true;
features.showFertilizantesAlbaranFactura = true;
features.showAllCultivosPrecedentes = true;
features.showLocationLatLng = true;
features.showTareasSiembra = true;
features.showLocationLatLng = true;
features.showFincasGenGml = false;
features.showWidthFertilizanteNombre = true;
features.showDesplazamientoObra = false;
features.showTareasBioParcelaMultiple = true;
features.showVentasComercioProveedor = true;
features.showTareasFertiGramos = true;
features.showTareasFertilizantesTipoFertilizacion = true;
features.persisteIdComercio = true;
features.showAnalisisNumeroAnalisis = true;
features.showTareasMaquinaria = true;
features.showAgrupacionEntidadEnParcela = true;
features.showParcelasFincasCol = true;
features.showParcelasActivoCol = false;
features.filterAplicadoresByClient = false;
features.showMaquinariaClienteAsignado = true;
features.showMaquinariaAdjuntoPdf = true;
features.showVentas = true;


features.showUsuariosFirma = true;
features.showBackup = true;

features.showCostes = true;
features.showCostesOtros = true;
features.showCostesProductos = true;

features.showStock = false;
features.showStockProveedor = false;
features.showStockEnvase = false;
features.showStockSemilla = true;
features.showStockReto = false;
features.showStockTipoDeDato = true;

features.showMapaLabels = true;
features.showMapaTratamientos = true;
features.showVentusky = true;
features.showMapaRutas = true;

features.showVisitasCamposPersonalizados = true;
features.showTareasFitoHoras = true;
features.horaFechaFinActualPorDefecto = true;
features.showTareasOtrosDuracion = true;
features.timeStartStopTareas = true;
features.tareasHorasTotales = true;
features.showControlRiego = false;
features.showTareasOtros = true;
features.showTareasAgroambiental = false;
features.showRecetaPascualCabedo = true;
features.showEcorregimenes = false;
features.showTareasBioExportCsv = false;

features.showUsuarios = true;
features.canRecoverPassword = true;
features.canRegister = false;

features.showTareasSiembraProductos = true;

features.showMostrarTodosClientes = true;
features.showUdsCuaderno = true; 
features.showLocationLatLng = true;

features.showUsuariosLogs = true;

features.showConsultaRopo = true;

export const environment = {
    production: true,
    v: '2',
    serverUrl: 'https://benibal.cropgest.com/',
    logoUrl: 'assets/logo_benibal.png',
    appName: 'benibal',
    features: features
};