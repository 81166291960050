import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';

@Injectable({
    providedIn: 'root'
})
export class TrabajadoresService {

    constructor( private http: HttpClient ) {
        
    }

    
    updateTrabajadores(body: MantenimientoModel, id: string ) {
        const data = JSON.stringify(body);
        return this.http.post(environment.serverUrl + '/ws/trabajadores/trabajadores_cargos.php?id=' + id, data);
    }

    deleteTrabajadores(id: string) {
        return this.http.delete(environment.serverUrl + '/ws/trabajadores/trabajadores_cargos.php?id=' + id);
    }

}