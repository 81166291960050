import {Utils} from './../../../../common/utils';
import {CitasModel} from './../../../../models/citas/citas.model';
import {Component, ViewChild} from '@angular/core';
import {CitasApiService} from '../../../../service/api/citas-api.service';
import {VisitasVariedadMesApiService} from '../../../../service/api/visitas_variedad_mes-api.service';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import { StorageManager } from 'src/app/common/storage-manager.class';
import { MessageService } from 'primeng/api';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { BaseView } from 'src/app/view/base-view';
import { TareasApiService } from 'src/app/service/api/tareas-api.service';
import { HttpClient } from '@angular/common/http';
import { SiexApiService } from 'src/app/service/api/siex-api.service';

import type { OnInit, AfterViewInit } from '@angular/core';
import type { FileRemoveEvent, FileSelectEvent, FileUploadEvent } from 'primeng/fileupload';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';
import { ClienteModel } from 'src/app/models/cliente.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { VisitasModel } from 'src/app/models/visitas/visitas.model';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';

@Component({
    selector: 'app-visitas',
    templateUrl: './visitas.component.html',
    styleUrls: ['./visitas.component.scss']
})
export class VisitasComponent extends BaseView<CitasModel> implements OnInit, AfterViewInit {
    @ViewChild('calendar', { static: true })
        calendar: FullCalendarModule = new FullCalendarModule();

    public serverUrl = environment.serverUrl;
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public logoUrl = environment.logoUrl;
    public showGenerarRegistroVisita = environment.features.showGenerarRegistroVisita;
    public showGenerarRecetaVisita = environment.features.showGenerarRecetaVisita;
    public showHorasVisitas = environment.features.showHorasVisitas;
    public showVisitasUploadPhotos = environment.features.showVisitasUploadPhotos;
    public showDocumentUploadVisitas = environment.features.showDocumentUploadVisitas;
    public hasClients = environment.features.hasClients;
    public showVisitasPascualCabedo = environment.features.showVisitasPascualCabedo;
    public usuarioActual = StorageManager.getUser();
    public isMelia = ((this.appName === 'melia') && (this.usuarioActual.nombre === 'ALEJANDRO'));
    public showVisitasPlagas = environment.features.showVisitasPlagas;
    public hasFincas = environment.features.hasFincas;
    public showVisitasARealizar = environment.features.showVisitasARealizar;
    public showMoviendo = environment.features.showMoviendo;
    public showCosecha = environment.features.showCosecha;
    public showNumTallos = environment.features.showNumTallos;
    public clienteName = environment.features.clienteName;
    public showEnviarCorreoVisitas = environment.features.showEnviarCorreoVisitas;
    public showEnviarWhatsappVisitas = environment.features.showEnviarWhatsappVisitas;
    public showVisitasMostrarTodas = environment.features.showVisitasMostrarTodas;
    public showVisitasContadorAgua = environment.features.showVisitasContadorAgua;
    public showVisitasFisiopatias = environment.features.showVisitasFisiopatias;
    public showVisitasCultivos = environment.features.showVisitasCultivos;
    public showVisitasVariedades = environment.features.showVisitasVariedades;
    public showVisitasParcelas = environment.features.showVisitasParcelas;
    public showVisitasVariedadMes = environment.features.showVisitasVariedadMes;
    public showMotivo = environment.features.showMotivo;
    public showLocationLatLng = environment.features.showLocationLatLng;
    public showVisitasFormAbonosFolques = environment.features.showVisitasFormAbonosFolques;
    public showVisitaCarenciasMultiple = environment.features.showVisitaCarenciasMultiple;
    public hiddenVisitasCerezo = environment.features.hiddenVisitasCerezo;
    public showContadorLectura = environment.features.showContadorLectura;
    public showVisitasCamposPersonalizados = environment.features.showVisitasCamposPersonalizados;
    public showEstadoFenologicoAlmendro = environment.features.showEstadoFenologicoAlmendro;
    public showEstadoFenologicoVid = environment.features.showEstadoFenologicoVid;
    public showDialogUploadFile = false;
    public showErrorTypeDocument = false;
    public linkdocument = '';

    public config = {
        canvasScale: 2,
        orientation: 'l',
        width: 210,
        height: 125
    };

    public getRequest = this.citasApi.citas.GET;

    public showRegistroVisita = false;
    public uploadedFiles: string[] = [];
    public instrucciones_manejo: string[] = [];
    public plagas: string[] = [];
    public socioSeleccionado = '';
    public totalVisitas = [
        {year: 2015, count: 0},
        {year: 2016, count: 0},
        {year: 2017, count: 0},
        {year: 2018, count: 0},
        {year: 2019, count: 0},
        {year: 2020, count: 0}
    ];

    // GENERAL
    public isGeneratingCanvas = false;
    public isLoading = false;
    public showAllVisitas = false;

    public deleteRequest: RequestHandler<CitasModel> = this.citasApi.citas.DELETE;
    public citaSeleccionada: CitasModel | null = null;
    public citasArray: {
        id: string,
        title: string,
        POST: RequestHandler<CitasModel>,
        start: string,
        visitador: string,
        color: string
    }[] = [];
    public citasArrayFiltered: {
        id: string,
        title: string,
        POST: RequestHandler<CitasModel>,
        start: string,
        visitador: string,
        color: string
    }[] = [];
    public citas: CitasModel[] = [new CitasModel()];
    public visitasVariedadMes: VisitasVariedadMesModel[] = [];
    public visitasRealizadas = 0;
    public visitasARealizar = 0;
    public imagenesVisita: [ImageInterface[], ImageInterface]  = [[], {source: '', thumbnail: '', title: ''}];
    public documentsVisita: [[], null] = [[], null];
    public variedad = '';

    public date: Date = new Date();
    public fechaSel = '';
    public citasDia = [];
    public showUploadPhotos = false;
    public showEditCita = false;
    public showEliminaCita = false;    
    
    public options: CalendarOptions = {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        locale: esLocale,
        height: 640,
        aspectRatio: 0.8,
        editable: true,
        eventClick: (event: EventClickArg) =>  { 
            this.irAlEvento(event);
        },
        dateClick: () => {
            this.irAlDia();
        },
        datesSet: this.handleEvents.bind(this)

    };
    public options2 = {
        height: 400,
        width: 400,
        aspectRatio: 0.4
    };

    public months = [
        {name: 'Enero', value: 1},
        {name: 'Febrero', value: 2},
        {name: 'Marzo', value: 3},
        {name: 'Abril', value: 4},
        {name: 'Mayo', value: 5},
        {name: 'Junio', value: 6},
        {name: 'Julio', value: 7},
        {name: 'Agosto', value: 8},
        {name: 'Septiembre', value: 9},
        {name: 'Octubre', value: 10},
        {name: 'Noviembre', value: 11},
        {name: 'Diciembre', value: 12},
    ];
    public years: { name: string; value: number; }[] = [];
    public selectedMonth: { name: string; value: number; } = {name: '', value: 0};
    public selectedYear: { name: string; value: number; } = {name: '', value: 0};

    constructor(public citasApi: CitasApiService,
        private dashboard: DashboardService,
        private router: Router,
        private visitasVariedadMesApi: VisitasVariedadMesApiService,
        private siexAPI: SiexApiService,
        public tareasApi: TareasApiService,
        private messageService: MessageService,
        public http: HttpClient)
    {
        super(dashboard, citasApi.citas.GET, citasApi.citas.DELETE);
        for (let i = 2017; i <= 2025; i++) {
            this.years.push( {name: i.toString(), value: i} );
        }
    }

    ngOnInit() {
        this.showAllVisitas = Utils.initShowAll('showAllvisitas');
        this.tareasApi.enviarRecetaCaptura.response(() => {
            if (!this.tareasApi.enviarRecetaCaptura.hasError && this.showRegistroVisita) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Email enviado',
                    detail: 'Se ha enviado el impreso de la visita correctamente.'
                });
                this.showRegistroVisita = false;
            }
        });

        this.dashboard.setSubmenuItem('visitas');
        // this.dashboard.clientChanges$.subscribe(() => {
        //     this.filterArray();
        // });

        const fechaTime = new Date().getTime();
        let fechaTime2: number;
        let dia: number;
        let mes: number;
        let year: number;
        
        this.date.setHours(12, 0, 0);
        // this.dashboard.changeClientActive = true;

        this.citasApi.citas.GET.safePerform({mes: moment().format('MM/YYYY')} as never);
        this.citasApi.citas.GET.response(data => {
            
            this.isLoading = true;
            this.citas = data;
            this.citasArray = data
                .map((it: CitasModel) => {
                    let color = '';

                    const start = moment(
                        Utils.toDate(it.fecha)
                    ).format('YYYY-MM-DD');

                    dia = parseInt(it.fecha.split('/')[0] ?? '', 10);
                    mes = parseInt(it.fecha.split('/')[1]  ?? '', 10);
                    year = parseInt(it.fecha.split('/')[2]  ?? '', 10);

                    fechaTime2 = new Date((mes) + '/' + dia + '/' + year + ' ' + it.hora).getTime();

                    if (fechaTime2 < fechaTime) {
                        color = it.realizada === '1'
                            ? '#55ff55'
                            : '#ff5555';
                    } 
                    else {
                        color = it.realizada === '1'
                            ? '#55ff55'
                            : '#0388b1';
                    }

                    for (const total of this.totalVisitas) {
                        if (total.year === year) {
                            total.count++;
                            it.numVisita = total.year + '/' + total.count;
                        }
                    }

                    const titleFinal = this.hasClients ?
                        (it.cliente || {} as ClienteModel).razon_social || (it.cliente || {} as ClienteModel).nombre :
                        (it.finca || {} as FincasModel).nombre;

                    return {
                        id: it.id ?? '',
                        title: titleFinal,
                        POST: new RequestHandler<CitasModel>,
                        start,
                        visitador: (it.visitador || {} as VisitasModel).nombre,
                        color
                    };

                });
            this.filterArray();
            this.isLoading = false;
        });

        if (environment.features.activateSIEX) {
            this.siexAPI.variedadesGET.safePerform();
            this.siexAPI.variedadesGET.response(data => {
                this.visitasVariedadMes = data;
            });
        } else{
            this.visitasVariedadMesApi.visitasVariedadMes.GET.safePerform();
            this.visitasVariedadMesApi.visitasVariedadMes.GET.response(data => {
                this.visitasVariedadMes = data;
            });
        }
    }

    public changeShowAll(){
        sessionStorage.setItem('showAllvisitas', this.showAllVisitas === true ? 'true' : 'false' );
    }      

    ngAfterViewInit() {
        setTimeout(() => {
            this.removeZIndex();
        }, 100);
    }

    removeZIndex() {
        const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('fc-view fc-month-view fc-basic-view');

        const elementsArray = Array.from(elements);  // Convertim a array
  
        for (const element of elementsArray) {
            element.classList.add('no-zindex');
        }
    }

    filterArray() {
        const clienteActual = StorageManager.getClient();

        this.citasArrayFiltered = this.citasArray.filter(it => {
            if (!clienteActual) {
                return it;
            }
            if (this.hasClients) {
                if (clienteActual.nombre && it.title) {
                    return it.title === clienteActual.nombre;
                } else {
                    if (this.showVisitasMostrarTodas && this.showAllVisitas) {
                        return it;
                    } else {
                        return it.visitador === this.usuarioActual.nombre;
                    }
                }
            }
            return it;
        });
    }

    showSelectedDate(calendar: FullCalendarComponent) {
        const day = new Date().getDate();
        const month = this.selectedMonth ? this.selectedMonth.value : new Date().getMonth() + 1;
        const year = this.selectedYear ? this.selectedYear.value : new Date().getFullYear();
        const date = new Date(`${year}-${month}-${day}`);

        this.citasApi.citas.GET.safePerform({mes: ((month < 10) ? ('0' + month) : month) + '/' + year} as never);
        calendar.getApi().gotoDate(date.getTime());
    }

    irAlDia() {
    }

    irAlEvento(e: EventClickArg) {
        const idCita = e.event._def.publicId;

        if (this.citaSeleccionada && (this.citaSeleccionada.id === e.event._def.publicId)) {
            return;
        }

        for (const cita of this.citas) {
            if (cita.id === idCita) {
                this.citaSeleccionada = cita;
                break;
            }
        }

        if (this.showVisitasFisiopatias && this.citaSeleccionada?.visita) {
            this.citaSeleccionada.visita.fisiopatias_nombres =
                ((this.citaSeleccionada.visita.fisiopatias || '').split(';') || []).join(', ');
        }
        
        if (
            (this.appName === 'abonosfolques' || this.appName === 'ava' || this.applicationType === 'cropgest') && 
            this.citaSeleccionada?.visita
        ) {
            this.citaSeleccionada.visita.carencias_nombres = (this.citaSeleccionada.visita.carencias || '').split(';').join(', ');
        }

        this.getVisitImages();
        this.getVisitImagesMultiple();

        this.visitasRealizadas = 0;

        const mes: number = +moment().format('MMMM');
        if (this.citaSeleccionada?.variedad) {
            this.visitasARealizar = parseInt(this.citaSeleccionada.variedad[mes]?.toString() || '1', 10);
        }

        const realizadas = this.citas
            .filter(it => 
                (it.id_cliente === this.citaSeleccionada?.id_cliente) &&
                (it.id_finca === this.citaSeleccionada?.id_finca) &&
                (!it.visita || (it.visita.id_variedad === (this.citaSeleccionada?.visita || {} as VisitasModel).id_variedad)) &&
                (it.realizada === '1')
            );

        this.visitasRealizadas = (realizadas || []).length;
        if (
            this.citaSeleccionada?.observaciones != null && 
            this.citaSeleccionada.visita?.observaciones != null &&
            this.citaSeleccionada.visita){
            this.citaSeleccionada.observaciones = this.citaSeleccionada.observaciones.replace(/\n/g, '<br>');
            this.citaSeleccionada.visita.observaciones = this.citaSeleccionada.visita.observaciones?.replace(/\n/g, '<br>');
        }
        //console.log(this.citaSeleccionada);
    }

    viewRender(e: FullCalendarComponent) {
        if (this.calendar) {
            const date = e.getApi().getDate() || moment();
            this.citasApi.citas.GET.cancelablePerform({mes: date} as never);
        }
    }

    editCita(citaSel: { fecha: string; hora: string; }) {
        this.showEditCita = true;
        const fecha: string = citaSel.fecha + ' ' + citaSel.hora;
        this.date = moment(fecha, 'DD/MM/YYYY HH:mm').toDate();
    }

    eliminaCita() {        
        this.showEliminaCita = false;

        this.deleteRequest.safePerform(this.citaSeleccionada ?? {} as CitasModel);
        this.deleteRequest.response(() => {
            if (!this.deleteRequest.hasError) {
                location.reload();
            }
            else {
                console.log('Error deleting cita...');
                alert('Error: no se ha podido eliminar la cita. Si este error persiste, por favor, contacte con un administrador.');
            }
        });
    }


    public showUpload() {
        this.showUploadPhotos = true;
    }

    public onUpload() {
        this.getVisitImagesMultiple();
        this.showUploadPhotos = false;

    }

    public onBeforeUpload() {
        this.showUploadPhotos = false;

        setTimeout(() => {
            location.reload();
        }, 1000);
    }

    guardaCita(e: { inputFieldValue: moment.MomentInput; }){
        const dia: string = moment(e.inputFieldValue, 'MM/DD/YYYY HH:mm').format('DD/MM/YYYY');
        const hora: string = moment(e.inputFieldValue, 'MM/DD/YYYY HH:mm').format('HH:mm');
        
        this.citasApi.citas.PUT.response(() => {
            this.citasApi.citas.PUT.unsuscribe();
            location.reload();
        });

        this.citasApi.citas.PUT.perform({
            id: this.citaSeleccionada?.id,
            fecha: dia,
            hora: hora
        } as never);
    }

    irAlDiaCita() {

    }
    
    showVisita() {

        // Revisar si esto hay que hacerlo para todos o solo para esparragos
        /*         if (this.appName === 'esparragosgranada') {
            this.tareasApi.enviarVisitaCaptura.hasError = false;
            this.tareasApi.enviarVisita['error'] = null;
        } else {
            this.tareasApi.enviarRecetaCaptura.hasError = false;
            this.tareasApi.enviarReceta['error'] = null;
        } */

        // Lo hago para todos
        this.tareasApi.enviarVisitaCaptura.hasError = false;
        this.tareasApi.enviarVisita.error = null;

        this.plagas = [];
        this.instrucciones_manejo = [];

        if (this.citaSeleccionada?.visita?.instrucciones_manejo) {
            for (const element of this.citaSeleccionada.visita.instrucciones_manejo.split(';')) {
                this.instrucciones_manejo.push(element);
            }
        }

        if (this.citaSeleccionada?.visita?.plaga1) { 
            this.plagas.push(this.citaSeleccionada.visita.plaga1 + '_' + this.citaSeleccionada.visita.estado_plaga1);
        }
        if (this.citaSeleccionada?.visita?.plaga2) { 
            this.plagas.push(this.citaSeleccionada.visita.plaga2 + '_' + this.citaSeleccionada.visita.estado_plaga2);
        }
        if (this.citaSeleccionada?.visita?.plaga3) { 
            this.plagas.push(this.citaSeleccionada.visita.plaga3 + '_' + this.citaSeleccionada.visita.estado_plaga3);
        }
        if (this.citaSeleccionada?.visita?.plaga4) { 
            this.plagas.push(this.citaSeleccionada.visita.plaga4 + '_' + this.citaSeleccionada.visita.estado_plaga4);
        }



        this.showRegistroVisita = true;

        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        setTimeout(() => {
            if (elements[0]) {
                elements[0].scrollTop = 0;
            }
        }, 100);
    }

    editVisita() {
        this.router.navigate(['dashboard', 'editar-visita', this.citaSeleccionada?.visita?.id],
            {
                queryParams: {
                    cita: JSON.stringify(this.citaSeleccionada)
                }
            }
        );
    }

    getVisitImages() {
        this.imagenesVisita = [[], {source: '', thumbnail: '', title: ''}];
        let subFolder = '';

        if (this.showGenerarRegistroVisita && !['fitogest', 'hernandorena', 'laplana'].includes(this.appName)) {
            subFolder = 'visitas/';
        }

        fetch(this.serverUrl + subFolder + 'visitas/media/' + (this.citaSeleccionada?.visita || {} as VisitasModel).id + '.png')
            .then(r => {
                if (r.status === 200) {
                    const imagen: ImageInterface = {
                        source: this.serverUrl + subFolder + 'visitas/media/' +
                        (this.citaSeleccionada?.visita || {} as VisitasModel).id + '.png',
                        thumbnail: this.serverUrl + subFolder + 'visitas/media/' +
                        (this.citaSeleccionada?.visita || {} as VisitasModel).id + '.png',
                        title: 'Imagen'
                    };
                    this.imagenesVisita[0].push(imagen);
                }
                return;
            }).catch (e => {
                console.log('catch en fetch: ' + e);
            }
            );
        
        fetch(this.serverUrl + subFolder + 'visitas/firmas/' + (this.citaSeleccionada?.visita || {} as VisitasModel).id + '.png')
            .then(r => {
                if (r.status === 200) {
                    this.imagenesVisita[1] = {
                        source: this.serverUrl + subFolder + 'visitas/firmas/' +
                        (this.citaSeleccionada?.visita || {} as VisitasModel).id + '.png',
                        thumbnail: this.serverUrl + subFolder + 'visitas/firmas/' +
                        (this.citaSeleccionada?.visita || {} as VisitasModel).id + '.png',
                        title: 'Firma ' + this.clienteName
                    };
                }
                return;
            }).catch (e => {
                console.log('catch en fetch: ' + e);
            }
            );

        if (this.appName === 'ava') {
           
            fetch(
                this.serverUrl + subFolder + 'visitas/documents/' + 
                (this.citaSeleccionada?.visita || {} as VisitasModel).id + '_0.pdf', { method: 'HEAD' }
            )
                .then( response => {

                    if (response.ok && this.citaSeleccionada?.visita) {
                        this.citaSeleccionada.visita.document = 
                            this.serverUrl + 
                            subFolder + 
                            'visitas/documents/' + 
                            (this.citaSeleccionada.visita || {} as VisitasModel).id + '_0.pdf';
                    } else {
                        throw new Error(response.statusText);
                    }
                    return;
                }).catch (e => {
                    console.log('catch en fetch: ' + e);
                }
                );

            
        }
    }

    public async getVisitImagesMultiple() {
        if (!this.citaSeleccionada?.visita) {
            return;
        }
        
        this.imagenesVisita[0] = [];
        let subFolder = '';
        let errorFound = false;

        if (this.showGenerarRegistroVisita && this.appName !== 'fitogest' && this.appName !== 'hernandorena') {
            subFolder = 'visitas/';
        }

        const route = this.serverUrl + subFolder + 'visitas/media/' + this.citaSeleccionada.visita.id + '_';

        let i = 0;
        while (!errorFound && (i < 20)) {
            await this.fetchImage(route, i).then(response => {
                if (response.status === 200) {
                    const imagen: ImageInterface = {
                        source: route + i + '.png',
                        thumbnail: route + i + '.png',
                        title: 'Imagen #' + (i + 1)
                    };
                    this.imagenesVisita[0].push(imagen);
                } else {
                    errorFound = true;
                }
                return;
            }).catch (e => {
                console.log('catch en fetch: ' + e);
            }
            );
            i++;
        }
    }

    public async fetchImage(route: string, index: number): Promise<Response> {
        return fetch(route + index + '.png');
    }

    getAnoVisita() {
        return (new Date().getFullYear()) + '/' + (this.citaSeleccionada ? this.citaSeleccionada.id : 0);
    }

    public imprimirVisita() {
        html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement, { scale: this.config.canvasScale, useCORS: true,
            logging: false }).then(canvas => {
            const pdf = new jsPDF();
            const data = canvas.toDataURL('image/png');
            pdf.addImage(data, 'PNG', 0, 0, this.config.width, (canvas.height / (canvas.width / this.config.width)), 'alias', 'FAST');

            pdf.save('Visita ' + (this.citaSeleccionada?.fecha || '')
                .replace(/\//g, '-') + ' a las ' + this.citaSeleccionada?.hora);
            return;
        }).catch (e => {
            console.log('catch en fetch: ' + e);
        }
        );
    }

    showImage(img: ImageInterface){
        window.open((img).source, '_blank', `toolbar = no, scrollbars = yes, resizable = yes, 
        left = ${(screen.width - 800) / 2}, top = ${(screen.height - 500) / 4}, width = 800, height = 500)`);
    }

    public enviarCorreo() {
        this.isGeneratingCanvas = true;

        html2canvas(
            document.getElementById('html2canvasDiv') ?? new HTMLElement(), 
            {scale: this.config.canvasScale, logging: false}
        ).then(canvas => {
            const email: string | null | undefined =
                (this.hasFincas && this.citaSeleccionada?.finca && this.citaSeleccionada.finca.email) ?
                    this.citaSeleccionada.finca.email :
                    (this.hasClients && this.citaSeleccionada?.cliente && this.citaSeleccionada.cliente.email) ? 
                        this.citaSeleccionada.cliente.email :
                        null;

            this.isGeneratingCanvas = false;

            //
            // Revisar si esto se hace para todos o solo para esparragos
            //
            if (this.appName === 'esparragosgranada') {
                if (email) {
                    const pdf = new jsPDF();
                    const data = canvas.toDataURL('image/png');
                    pdf.addImage(
                        data, 
                        'PNG', 
                        0, 
                        0, 
                        this.config.width, 
                        (canvas.height / (canvas.width / this.config.width)), 
                        'alias', 
                        'FAST'
                    );
                    const adjunto = encodeURIComponent(window.btoa(pdf.output()));
    
                    this.tareasApi.enviarVisitaCaptura.perform({
                        email: email,
                        generado: adjunto,
                        finca: this.citaSeleccionada?.finca?.nombre,
                        tratamiento: 'Visita ' + (this.citaSeleccionada?.fecha || '')
                            .replace(/\//g, '-') + ' a las ' + this.citaSeleccionada?.hora,
                        imagenes: Array.prototype.concat(
                            (this.imagenesVisita[0] || []).filter(
                                (it: ImageInterface) => it.source).map((it: ImageInterface) => it.source),
                        ),
                        firma: (this.imagenesVisita[1] || {} as ImageInterface).source
                    } as never);
                } else {
                    this.tareasApi.enviarVisitaCaptura.hasError = true;
                    this.tareasApi.enviarVisita['error'] = 'El cliente no tiene email';
                }

            } else { 
                if (email) {
                    const pdf = new jsPDF();
                    const data = canvas.toDataURL('image/png');
                    pdf.addImage(
                        data, 
                        'PNG', 
                        0, 
                        0, 
                        this.config.width, 
                        (canvas.height / (canvas.width / this.config.width)), 
                        'alias', 
                        'FAST'
                    );
                    const adjunto = encodeURIComponent(window.btoa(pdf.output()));
    
                    this.tareasApi.enviarRecetaCaptura.perform({
                        email: email,
                        generado: adjunto,
                        finca: this.citaSeleccionada?.finca?.nombre,
                        tratamiento: 'Visita ' + (this.citaSeleccionada?.fecha || '')
                            .replace(/\//g, '-') + ' a las ' + this.citaSeleccionada?.hora,
                        imagenes: Array.prototype.concat(
                            (this.imagenesVisita[0] || []).filter((it: ImageInterface) => it.source).map((it: ImageInterface) => it.source),
                        ),
                        firma: (this.imagenesVisita[1] || {} as ImageInterface).source
                    } as never);
                } else {
                    this.tareasApi.enviarRecetaCaptura.hasError = true;
                    this.tareasApi.enviarReceta['error'] = 'El cliente no tiene email';
                }
            }

            return;

        }).catch (e => {
            console.log('catch en fetch: ' + e);
        }
        ).then(() => {
            console.log('Adjuntos generados.');
            return;
        }, error => {
            console.log(error);

            this.isGeneratingCanvas = false;
            this.tareasApi.enviarRecetaCaptura.hasError = true;
            this.tareasApi.enviarReceta['error'] = 'Error al enviar email';
            return;
        }).catch (e => {
            console.log('catch en fetch: ' + e);
        }
        );
    }


    public enviarWhatsapp() {
        window.open('https://web.whatsapp.com/', '_blank');
    }

    handleEvents(event: { end: { getMonth: () => number; getFullYear: () => number; }; }): void {
        const numeroMes: string = (event.end.getMonth() === 0) ? '12' : event.end.getMonth().toString();
        const año = (numeroMes === '12') ? event.end.getFullYear() - 1 : event.end.getFullYear();
        this.citasApi.citas.GET.safePerform({mes: ((numeroMes.length === 1) ? ('0' + numeroMes) : numeroMes) + '/' + año} as never);
    }

    showUploadDocument() {
        this.showDialogUploadFile = true;
    }

    getDocuments() {
        console.log('Id de cita seleccionada', (this.citaSeleccionada?.visita || {} as VisitasModel).id);
        
        fetch(
            environment.serverUrl + 
            '/visitas/documents/' + 
            (this.citaSeleccionada?.visita || {} as VisitasModel).id + 
            '_0.pdf'
        ).then(document => {
            if (this.citaSeleccionada) {
                this.citaSeleccionada.document = document;
            }
            return;
        }).catch (e => {
            console.log('catch en fetch: ' + e);
        }
        );
    }

    getStatusUploadDocument(event: FileUploadEvent) {
        console.log(event);
    }

    onClearUploadDocument(event: FileRemoveEvent){
        if (event){
            if (event.originalEvent.isTrusted){
                this.showErrorTypeDocument = false;
            }
        }
    }

    checkTypeDocument(event: FileSelectEvent) {
        
        event.currentFiles.forEach((file) => {
            if (file.type !== 'application/pdf') {
                this.showErrorTypeDocument = true;
            }
        });
    }
}

interface ImageInterface {
    source: string | URL | undefined,
    thumbnail: string,
    title: string
}