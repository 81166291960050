<br>
<div id="gotodate-div" *ngIf="appName !== 'manezylozano'">
    <p>Fecha:</p>
    <p-select id="month-dropdown" [options]="months" [(ngModel)]="selectedMonth" placeholder="Mes" optionLabel="name"
        [showClear]="true" (onChange)="showSelectedDate(calendar)"></p-select>
    <p-select id="year-dropdown" [options]="years" [(ngModel)]="selectedYear" placeholder="Año" optionLabel="name"
        [showClear]="true" (onChange)="showSelectedDate(calendar)"></p-select>

    <p>{{fincaName}}:</p>
    <div class="mr-3" style="margin-top: -20px; width: 180px;">
        <app-form-builder [model]="fincaModel" [distributionColumns]="1" [fields]="formFieldsFinca"
            (formChanges)="filterTratamientosControl()">
        </app-form-builder>
    </div>

    <button type="button" class="btn btn-success mr-2" style="padding: 5px; font-size: .8em;" (click)="crearControl()">
        Añadir Control
    </button>

    <button type="button" class="btn btn-primary mr-2" style="padding: 5px; font-size: .8em;"
        (click)="showDescargarCsv = true" [disabled]="!fincas.selected">
        <i class="fa fa-download"></i>
        Descargar CSV
    </button>

    <button type="button" class="btn btn-primary" style="padding: 5px; font-size: .8em;" (click)="showImprimir()"
        [disabled]="!fincas.selected">
        <i class="fa fa-print"></i>
        Imprimir
    </button>
</div>

<div >
    <!-- <app-spinner *ngIf="loadingTelemetry"></app-spinner> -->
    <div *ngIf="loadingTelemetry">

        <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="6" animationDuration="1.5s">
        </p-progressSpinner>
    </div>
</div>
<div class="row" style="padding-bottom: 20px;" *ngIf="appName==='manezylozano'">
    <div class="col-lg-4 col-md-4 col-sm-12">

        <p-select [options]="maquinarias" [(ngModel)]="selectedMaquinarias" placeholder="Maquinaria"
            optionLabel="name" (onChange)="filterMaquinaria($event)" [filter]="true"></p-select>
    </div>
</div>

<div class="d-flex position-relative" id="control-calendar">
    <full-calendar #calendar [events]="arrayEvents" [options]="options" style="width:100%">
    </full-calendar>
</div>

<p-dialog header="Descargar CSV" [(visible)]="showDescargarCsv" (onShow)="scrollToTop()">
    <div class="container" style="height: 360px; width: 380px; overflow: auto">
        <p>Descargar CSV de <b>{{fincas.selected?.nombre}}</b></p>

        <div>
            <p class="mt-1 mb-0">Mes y año</p>
            <div class="d-flex justify-content-left">
                <p-datePicker [(ngModel)]="dateValue" view="month" dateFormat="MM, yy" [yearNavigator]="true"
                    yearRange="2020:2029">
                </p-datePicker>
            </div>
        </div>
    </div>
    <div class="container" style="text-align:center; max-width:480px; width: auto; overflow: auto">
        <button class="btn btn-secondary" style="margin: 10px;" (click)="showDescargarCsv = false;">Cancelar</button>
        <button class="btn btn-primary" style="margin: 10px;" (click)="downloadCsv();">
            <i class="fa fa-download"></i>
            Descargar CSV
        </button>
    </div>
</p-dialog>

<p-dialog header="Imprimir Control" [(visible)]="showImprimirControl" (onShow)="scrollToTop()">
    <div style="margin-left: 20px; margin-right: 20px; width: 80vw !important; background-color: white;">
        <div id="html2canvasDiv" class="p-4">
            <p class="my-0 text-center font-weight-bold" style="text-decoration: underline;">
                CONTROL DE TRATAMIENTOS PERIÓDICOS {{currentMonthAndYear}}
            </p>

            <table id="table-imprimir-control" class="table-hover w-100 mx-auto" style="max-width: 900px;">
                <tbody>
                    <tr>
                        <td colspan="8" class="text-center font-weight-bold p-0" style="font-size: .8rem">
                            {{fincas.selected?.nombre}}
                        </td>
                    </tr>
                    <div *ngFor="let week of (currentWeekArray || []); let i = index" [attr.data-index]="i">
                        <ng-container>
                            <tr class="text-center bg-gray">
                                <td rowspan="2" style="width: 2%; background-color: white">S. {{week}}</td>
                                <td style="width: 14%;" *ngFor="let day of currentDayMatrix[i]">
                                    {{day}}
                                </td>
                            </tr>
                            <tr style="height: 4rem;">
                                <td *ngFor="let day of currentDayMatrix[i]" [style.backgroundColor]="tratamientosParaImprimir[day - 1] ? 
                                            tratamientosParaImprimir[day - 1]?.color : ''">

                                    <div *ngIf="day && tratamientosParaImprimir[day - 1]" class="text-center"
                                        style="font-size: .65rem;">
                                        <div *ngIf="tratamientosParaImprimir[day - 1]?.plagas">
                                            {{tratamientosParaImprimir[day - 1]?.plagas}}
                                        </div>
                                        <div *ngIf="tratamientosParaImprimir[day - 1]?.title">
                                            {{tratamientosParaImprimir[day - 1]?.title}}
                                        </div>
                                        <div *ngIf="tratamientosParaImprimir[day - 1]?.producto1">
                                            {{tratamientosParaImprimir[day - 1]?.producto1}}
                                            {{tratamientosParaImprimir[day - 1]?.dosis1}} /
                                            {{tratamientosParaImprimir[day - 1]?.riqueza1}}
                                            <br />
                                        </div>
                                        <div *ngIf="tratamientosParaImprimir[day - 1]?.producto2">
                                            {{tratamientosParaImprimir[day - 1]?.producto2}}
                                            {{tratamientosParaImprimir[day - 1]?.dosis2}} /
                                            {{tratamientosParaImprimir[day - 1]?.riqueza2}}
                                            <br />
                                        </div>
                                        <div *ngIf="tratamientosParaImprimir[day - 1]?.producto3">
                                            {{tratamientosParaImprimir[day - 1]?.producto3}}
                                            {{tratamientosParaImprimir[day - 1]?.dosis3}} /
                                            {{tratamientosParaImprimir[day - 1]?.riqueza3}}
                                            <br />
                                        </div>
                                        <div *ngIf="tratamientosParaImprimir[day - 1]?.observaciones">
                                            {{tratamientosParaImprimir[day - 1]?.observaciones}}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </div>
                </tbody>
            </table>
        </div>

        <div class="container mt-3" style="text-align: center; max-width: 480px; width: auto; overflow: auto">
            <button class="btn btn-secondary mx-2" (click)="showImprimirControl = false;">Cancelar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirControl();">
                <i class="fa fa-print"></i>
                Imprimir
            </button>
        </div>
    </div>

</p-dialog>

<a href="#" id="csvLink" style="visibility: hidden; position: fixed;"></a>
<a href="#" id="downloadControl" style="visibility: hidden; position: fixed;"></a>

<div *ngIf="appName==='manezylozano'">
    <app-dialog-tratamiento-manez #dialogTratamientos></app-dialog-tratamiento-manez>

</div>