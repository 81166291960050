import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {BaseForm} from '../../../../base-form';
import {VisitasApiService} from '../../../../../service/api/visitas-api.service';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {build, list, pool} from '../../../../../common/classes/request-builder';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { environment } from '../../../../../../environments/environment';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { TrasiegoModel } from 'src/app/models/visitas/trasiego.model';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { UserModel } from 'src/app/models/usuarios/user.model';
import { ClienteModel } from 'src/app/models/cliente.model';

@Component({
    selector: 'app-visitas-trasiego-form',
    templateUrl: './visitas-trasiego-form.component.html',
    styleUrls: ['./visitas-trasiego-form.component.scss']
})
export class VisitasTrasiegoFormComponent extends BaseForm<TrasiegoModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: false, static: false })
        requestButton = new RequestButtonComponent<TrasiegoModel>();

    public override model: TrasiegoModel = {semana: 0};
    public formRequest = new AppFormRequest<TrasiegoModel>();
    public showVisitasTrasiego10Plagas = environment.features.showVisitasTrasiento10Plagas;

    public fincas = list(['...', null]);
    public parcelas = list(['...', null]);
    public semanas: TypeAheadInterface<number> = list(['...', null],
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
        37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54
    );
    public anos: TypeAheadInterface<number> = list(['...', null],
        2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030
    );
    public plagas = list(
        ['...', null],
        'Empoasca',
        'Mosca blanca',
        'Mosca común',
        'Mosquito',
        'Parasitoides',
        'Pulgón',
        'Scatella',
        'Sciara',
        'Stethorus',
        'Trips',
        'Otros'
    );
    public trampas = list(
        ['...', null],
        'T.S1',
        'T.S4',
        'T.SEMILLERO',
        'T.S5',
        'T.S7',
        'T.S8',
        'T.S10',
        'T.MICROS',
        'FC-T.S1',
        'FC-T.S2'
    );

    public formFields: ItemInterface<object>[] = [
        {
            label: 'Agrupación',
            field: 'id_finca',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id',
            required: true
        },
        {
            label: 'Subparcela',
            field: 'id_parcela',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            valuePrimaryKey: 'id',
            filter: (it: { value: ParcelasModel; }) => {
                const parcela = it.value;
                if (this.fincas.selected && parcela) {
                    return this.fincas.selected.id === parcela.id_finca;
                }
                return true;
            },
            values: this.parcelas,
            required: true
        },
        {
            field: 'semana',
            label: 'Semana',
            inputType: {type: InputType.DROPDOWN},
            values: this.semanas,
        },
        {
            field: 'ano',
            label: 'Año',
            inputType: {type: InputType.DROPDOWN},
            values: this.anos,
        },
        {
            field: 'trampa',
            label: 'Trampa',
            inputType: {type: InputType.DROPDOWN},
            values: this.trampas,
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            inputType: {type: InputType.EMPTY}
        },
        {
            label: 'Plaga #1',
            field: 'plaga1',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.plagas))
        },
        {
            label: 'Cantidad #1',
            field: 'cantidad1',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Plaga #2',
            field: 'plaga2',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.plagas))
        },
        {
            label: 'Cantidad #2',
            field: 'cantidad2',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Plaga #3',
            field: 'plaga3',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.plagas))
        },
        {
            label: 'Cantidad #3',
            field: 'cantidad3',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Plaga #4',
            field: 'plaga4',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.plagas))
        },
        {
            label: 'Cantidad #4',
            field: 'cantidad4',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Plaga #5',
            field: 'plaga5',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.plagas))
        },
        {
            label: 'Cantidad #5',
            field: 'cantidad5',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Plaga #6',
            field: 'plaga6',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.plagas))
        },
        {
            label: 'Cantidad #6',
            field: 'cantidad6',
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Plaga #7',
            field: 'plaga7',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.plagas)),
            visible: this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Cantidad #7',
            field: 'cantidad7',
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Plaga #8',
            field: 'plaga8',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.plagas)),
            visible: this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Cantidad #8',
            field: 'cantidad8',
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Plaga #9',
            field: 'plaga9',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.plagas)),
            visible: this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Cantidad #9',
            field: 'cantidad9',
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Plaga #10',
            field: 'plaga10',
            inputType: {type: InputType.DROPDOWN},
            values: JSON.parse(JSON.stringify(this.plagas)),
            visible: this.showVisitasTrasiego10Plagas
        },
        {
            label: 'Cantidad #10',
            field: 'cantidad10',
            inputType: {type: InputType.EDIT_NUMERIC},
            visible: this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
        {
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasTrasiego10Plagas
        },
    ];


    constructor(
        public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public visitasApi: VisitasApiService,
        public fincasApi: FincasApiService,
        public formApi: FormCommonApiService,
        public override router: Router
    ) {
        super(
            route,
            router,
            dashboard,
            visitasApi.visitasTrasiego.PUT,
            visitasApi.visitasTrasiego.POST,
            'visitas-trasiego',
            'Actualizar Visita',
            'Crear Visita'
        );
    }

    ngOnInit() {
        this.initFormData();

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .isGeneric(true)
            .setGetRequest(this.visitasApi.visitasTrasiego.GET)
            .setPostRequest(this.visitasApi.visitasTrasiego.POST)
            .setPutRequest(this.visitasApi.visitasTrasiego.PUT)
            .setFormFields(this.formFields)
            .setFieldsToSend((['id'] as string[])
                .concat(
                    this.formFields.map(it => it.field ?? '')
                )
            );

        this.formRequest.afterLoad(resolve => {
            if (this.model.semana) {
                this.model.semana = parseInt(this.model.semana.toString(), 10);
            }
            resolve(true);
        });
        this.formRequest.beforeSend(resolve => {
            const clientId = (StorageManager.getClient() as ClienteModel).id;
            const clientIdFromFinca = (this.fincas.selected || {}).id_cliente;
            const clientIdFromForm = this.model['id_cliente'];

            this.model.id_cliente =
                clientIdFromFinca ? clientIdFromFinca :
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = (StorageManager.getUser() as UserModel).id;
            const userIdFromFinca = (this.fincas.selected || {}).id_usuario;
            const userIdFromForm = this.model['id_usuario'];

            this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';

            resolve(true);
        });

        this.formRequest.load();

        this.softInit(this.getType());
    }

    public submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send();
    }

    public formChanges(tag: string) {
        if (tag === 'id_finca') {
            this.model.id_parcela = null;
        }

        this.requestButton.error = '';
    }


    private initFormData() {
        pool([
            build(this.fincasApi.fincasGET, this.fincas, 'nombre'),
            build(this.fincasApi.parcelasGET, this.parcelas, 'nombre', true)
        ], () => {
            this.formRequest.update();
        });
    }

}