<br>

<div style="width: auto; overflow: auto">
    <app-common-list-table
            [request]="getRequest"
            [filtering]="filter"
            [globalConditionalStyle]="globalConditionalStyle"
            [globalVisualTransform]="globalVisualTransform"
            [cols]="cols">

        <ng-template #header>
            <div class="d-flex justify-content-start align-content-center">
                <div class="btn-group ml-2">
                    <button style="font-size: 0.75em !important;"
                            class="btn btn-sm btn-primary"
                            (click)="previousMonth()">
                        <i class="fa fa-chevron-left"></i>
                    </button>
                    <button style="font-size: 0.75em !important;"
                            class="btn btn-sm btn-primary text-capitalize"
                            (click)="actualMonth()">
                        {{month}} {{yearNumber === actualYear ? '' : yearNumber}}
                    </button>
                    <button style="font-size: 0.75em !important;"
                            class="btn btn-sm btn-primary"
                            (click)="nextMonth()">
                        <i class="fa fa-chevron-right"></i>
                    </button>
                </div>
            </div>
        </ng-template>

    </app-common-list-table>
</div>
