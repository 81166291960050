import {Component} from '@angular/core';
import {DashboardService} from '../../../service/events/dashboard.service';

import type { OnInit } from '@angular/core';

@Component({
    selector: 'app-fincas',
    templateUrl: './fincas.component.html',
    styleUrls: ['./fincas.component.scss']
})
export class FincasComponent implements OnInit {

    constructor(private dashboardEvents: DashboardService) {
    }

    ngOnInit() {
        this.dashboardEvents.setMenuItem('fincas');
    }

}
