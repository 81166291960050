import { Component } from '@angular/core';
import { BaseView } from '../../../../view/base-view';
import { TareasApiService } from '../../../../service/api/tareas-api.service';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { list, stack, build } from '../../../../common/classes/request-builder';
import { FincasApiService } from '../../../../service/api/fincas-api.service';
import { environment } from '../../../../../environments/environment';
import { AppFormRequest } from '../../../../view/app-common/form-request/app-form-request';
import { ClientesApiService } from '../../../../service/api/clientes-api.service';
import { InputType } from '../../../../common/components/form-builder/form-builder.component';
import { ProductosApiService } from '../../../../service/api/productos-api';
import { StorageManager } from '../../../../common/storage-manager.class';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { MessageService } from 'primeng/api';

import type { OnInit } from '@angular/core';
import type { ItemInterface, TypeAheadInterface } from '../../../../common/components/form-builder/form-builder.component';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { CalendarioAbonadoModel } from 'src/app/models/tareas/calendario-abonado.model ';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ClienteModel } from 'src/app/models/cliente.model';
import { FertiAbonadoInterface } from './ferti-abonado.interface';

@Component({
    selector: 'app-calendario-abonado',
    templateUrl: './calendario-abonado.component.html',
    styleUrls: ['./calendario-abonado.component.scss']
})
export class CalendarioAbonadoComponent extends BaseView<CalendarioAbonadoModel> implements OnInit {

    public fincaName = environment.features.fincaName;
    public logoUrl = environment.logoUrl;
    public user = StorageManager.getUser();
    public client = StorageManager.getClient();
    public hasSelectedClient = !!(this.client || {}).id;
    public tableData: number[] = [];
    public fincaSeleccionada = '';

    public totalKg: number[] = [];

    public semanasHead: number[] = [
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4
    ];
    public mesesHead: number[] = [
        0, 0, 0, 0,
        1, 1, 1, 1,
        2, 2, 2, 2,
        3, 3, 3, 3,
        4, 4, 4, 4,    
        5, 5, 5, 5,
        6, 6, 6, 6,
        7, 7, 7, 7,
        8, 8, 8, 8,
        9, 9, 9, 9,
        10, 10, 10, 10,
        11, 11, 11, 11
    ];
    public semanasOcultas: number[] = [];
    public mesesOcultos: number[] = [];

    public semanas: number[] = [];
    public meses = [
        ['Enero', 0],
        ['Febrero', 1],
        ['Marzo', 2],
        ['Abril', 3],
        ['Mayo', 4],
        ['Junio', 5],
        ['Julio', 6],
        ['Agosto', 7],
        ['Septiembre', 8],
        ['Octubre', 9],
        ['Noviembre', 10],
        ['Diciembre', 11]
    ];
    public mesesList = list(...this.meses);
    public anyos = list(
        '2020',
        '2021',
        '2022',
        '2023',
        '2024',
        '2025',
        '2026',
        '2027',
        '2028',
        '2029',
        '2030'
    );

    public abonados: CalendarioAbonadoModel[] = [];
    public abonadosTable: number[][] = [
        []
    ];
    public abonadosTableWithId: number[][] = [
        []
    ];

    public isPrinting = false;
    public isSaving = false;
    public hasSaved = false;
    public isDuplicating = false;

    public clientes: TypeAheadInterface<ClienteModel> = list();
    public fincas: TypeAheadInterface<FincasModel> = list();
    public sectores: TypeAheadInterface<SectorModel> = list();
    public parcelas: TypeAheadInterface<ParcelasModel> = list();
    public productos: TypeAheadInterface<ProductosModel> = list();
    public productosTabla: ProductosModel[] = [];
    public productosTablaFiltered: ProductosModel[] = [];

    public formRequest = new AppFormRequest<CalendarioAbonadoModel>();
    public override model: CalendarioAbonadoModel = {
        plantas: 0,
        goteros: 0,
        caudal_gotero: 0,
        edad: '',
        anyo: moment().format('YYYY')
    };
    public modelMes = {ids_meses: [0]};
    public modelDup: CalendarioAbonadoModel = {
        plantas: 0,
        goteros: 0,
        caudal_gotero: 0,
        edad: '',
        anyo: moment().format('YYYY')
    };
    public imprimirModel = {};

    public formFieldsCliente: ItemInterface<object>[] = [
        {
            field: 'id_cliente',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.clientes,
            valuePrimaryKey: 'id',
            visible: !this.hasSelectedClient
        }
    ];
    public formFieldsFinca: ItemInterface<object>[] = [
        {
            field: 'id_finca',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id',
            filter: (it: { value: FincasModel}) => {
                if (this.hasSelectedClient) {
                    return true;
                }
                return it.value.id_cliente === (this.clientes.selected || {}).id;
            }
        }
    ];
    public formFieldsMes: ItemInterface<object>[] = [
        {
            label: 'Ocultar meses',
            field: 'ids_meses',
            inputType: {type: InputType.MULTISELECT},
            values: this.mesesList
        }
    ];
    public formFieldsAnyo: ItemInterface<object>[] = [
        {
            label: 'Año',
            field: 'anyo',
            inputType: {type: InputType.DROPDOWN},
            values: this.anyos
        }
    ];
    public formFieldsDuplicar: ItemInterface<object>[] = [
        {
            label: 'Cliente',
            field: 'id_cliente',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.clientes,
            valuePrimaryKey: 'id',
            visible: !this.hasSelectedClient
        },
        {
            label: 'Finca',
            field: 'id_finca',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id',
            filter: (it: { value: FincasModel }) => {
                if (this.hasSelectedClient) {
                    return true;
                }
                return it.value.id_cliente === (this.clientes.selected || {}).id;
            }
        },
        {
            label: 'Año',
            field: 'anyo',
            inputType: {type: InputType.DROPDOWN},
            values: this.anyos
        }
    ];

    constructor(public tareasApi: TareasApiService,
        private dashboardEvents: DashboardService,
        public dashboard: DashboardService,
        private messageService: MessageService,
        public clientesApi: ClientesApiService,
        public fincasApi: FincasApiService,
        public productosApi: ProductosApiService) {
        super(dashboardEvents, tareasApi.tablaAbonado.GET, tareasApi.tablaAbonado.DELETE);
        for (let i = 1; i <= 48; i++) {
            this.semanas.push(i);
        }
    }

    ngOnInit() {
        this.dashboardEvents.changeClientActive = false;
        this.tareasApi.tablaAbonado.GET.response(res => {
            if (this.isDuplicating) {
                return;
            }
            this.abonados = res;

            this.formatAbonado();
            this.getTotalKg();
        });

        stack([
            build(this.clientesApi.clientesGET, this.clientes, 'nombre', false),
            build(this.fincasApi.fincasGET, this.fincas, 'nombre', true),
            build(this.fincasApi.sectoresGET, this.sectores, 'nombre', false),
            build(this.fincasApi.parcelasGET, this.parcelas, 'nombre', false),
            build(this.productosApi.productos.GET, this.productos, 'nombre', false)
        ]).then(() => {
            this.productosTabla = this.productos.filtered
                .map(it => it.value as ProductosModel)
                .filter(it => it && it.tipo === 'fertilizante')
                .map((it) => { (it as ProductosModel).id = (it as ProductosModel).id ?? ''; return it;})
                .sort((a, b) => (+(a.subtipo ?? '') || 0) - (+(b.subtipo ?? '') || 0));

            if (this.hasSelectedClient) {
                this.model.id_cliente = this.client.id;
                this.clientes.selected = StorageManager.getClient();
                this.formChanges('id_cliente');
            }
            this.formRequest.update();
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );

        this.dashboard.setSubmenuItem('calendario-abonado');
    }

    public formChanges(tag?: string) {
        if (tag === 'ids_meses') {
            this.mesesOcultos = [];
            this.semanasOcultas = [];
            (this.modelMes.ids_meses || []).forEach((model_id_mes: number) => {
                this.mesesOcultos.push(model_id_mes);
                Object.keys(this.mesesHead).forEach(key => {
                    if (this.mesesHead[+key] === model_id_mes) {
                        this.semanasOcultas.push(parseInt(key, 10) + 1);
                    }
                });
            });
        }

        if (tag === 'id_cliente') {
            this.model.id_finca = null;
        }
        if (['id_cliente', 'id_finca', 'anyo'].includes(tag ?? '')) {
            this.model.edad = null;
            this.model.observaciones = null;
            this.model.plantas = 0;
            this.model.goteros = 0;
            this.model.caudal_gotero = 0;

            this.modelMes.ids_meses = [];
            this.semanasOcultas = [];
            this.mesesOcultos = [];

            if (this.fincas.selected) {
                const idsProductos = ((this.fincas.selected || {}).ids_productos || '').split(';');
                this.productosTablaFiltered = this.productosTabla.filter(it => idsProductos.includes(it.id ?? ''));
        
                if (this.model.id_finca) {
                    this.fincaSeleccionada = this.fincas.selected.nombre.toUpperCase();
  
                    this.sectores.filtered
                        .filter(it => it.value)
                        .map(it => it.value as SectorModel)
                        .filter(it => it.id_finca === this.model.id_finca).forEach(it => {
                            this.model.goteros += parseInt(it.goteros || '', 10);
                            this.model.caudal_gotero += parseInt(it.caudal_gotero || '', 10);
                        });
                    this.parcelas.filtered
                        .filter(it => it.value)
                        .map(it => it.value as ParcelasModel)
                        .filter(it => it.id_finca === this.model.id_finca)
                        .forEach(it => {
                            this.model.plantas += parseInt(it.plantas || '', 10);
                        });
  
  
                    this.tareasApi.tablaAbonado.GET.perform({
                        id_finca: this.model.id_finca,
                        anyo: this.model.anyo || moment().year()
                    } as never);
                }
            }

        }
    }

    giveValue() {
        this.getTotalKg();
    }

    public async guardarAbonado(isDuplicating = false): Promise<boolean> {
        if ((this.abonadosTable || []).length <= 1) {
            alert('Debe añadir al menos una línea en la tabla de "Consumos planta / año" antes de guardar.');
            return false;
        }

        if (this.clientes.selected && this.fincas.selected) {
            this.isSaving = true;

            const fertis: FertiAbonadoInterface[] = [];
            this.abonadosTable.forEach((producto, idxProducto) => {
                producto.forEach((valor, idxValor) => {
                    const semana = this.semanas[idxValor - 1];
                    if (semana && this.abonadosTableWithId[idxProducto]) {
                        const ferti = {
                            id: this.abonadosTableWithId[idxProducto]
                                ? this.abonadosTableWithId[idxProducto]?.[idxValor]
                                : null,
                            id_producto: idxProducto,
                            id_finca: this.fincas.selected?.id,
                            valor,
                            semana: this.semanasHead[idxValor - 1],
                            semana_full: semana,
                            mes: this.mesesHead[semana - 1],
                            anyo: this.model.anyo || moment().year(),
                            edad: this.model.edad,
                            observaciones: this.model.observaciones,
                            id_cliente: ((this.clientes.selected || {} as ClienteModel).id || 
                                        (StorageManager.getClient() as ClienteModel).id) ?? '',
                            id_usuario: StorageManager.getUser().id
                        };
    
                        fertis.push(ferti);
                    }
                });
            });

            fertis.forEach(async ferti => {
                if (isDuplicating) {
                    delete ferti.id;
                }
                try {
                    if (ferti && ferti.id) {
                        await this.tareasApi.tablaAbonado.PUT.perform(ferti);
                    } else {
                        await this.tareasApi.tablaAbonado.POST.perform(ferti);
                    }
                } catch (e) {
                    alert('Ha habido un error al guardar. Si este error persiste, por favor, contacte con un técnico.\n\nError: ' + e);
                    this.isSaving = false;
                    return false;
                }
                return false;
            });
            this.isSaving = false;

            setTimeout(() => {
                this.hasSaved = true;
            }, 700);
            setTimeout(() => {
                this.hasSaved = false;
                return true;
            }, 1700);
        } else {
            return false;
        }
        return false;
    }

    formatAbonado() {
        const fertis: number[][] = [];
        const fertisId: number[][] = [];

        this.abonados.forEach(ferti => {
            if (ferti.edad) {
                this.model.edad = ferti.edad;
            }
            if (ferti.observaciones) {
                this.model.observaciones = ferti.observaciones;
            }
            if (!fertis[parseInt(ferti.id_producto?.toString() ?? '', 10)]) {
                fertis[parseInt(ferti.id_producto?.toString() ?? '', 10)] = [];
                fertisId[parseInt(ferti.id_producto?.toString() ?? '', 10)] = [];
            }

            const semana = this.semanas[parseInt(ferti.semana_full?.toString() ?? '', 10) - 1];
            const fert = fertis[parseInt(ferti.id_producto?.toString() ?? '', 10)];
            const fertiId = fertisId[parseInt(ferti.id_producto?.toString() ?? '', 10)];
            if (semana && fertiId && fert) {
                fert[semana] = ferti.valor ?? 0;
                fertiId[semana] = +(ferti.id || 0);
            }
        });

        (this.productosTablaFiltered || []).forEach(producto => {
            if (producto && producto.id && !fertis[+producto.id]) {
                fertis[+producto.id] = [];
            }
        });

        fertis[0] = [];
        this.abonadosTable = fertis;
        this.abonadosTableWithId = fertisId;

    }

    public getTotalKg() {
        this.totalKg = [];

        this.abonadosTable.forEach((abonado: number[], index) => {
            let sum = 0;
            if (abonado) {
                (abonado || [0]).forEach(row => {
                    if (row) {
                        sum += parseFloat(row.toString().replace(',', '.'));
                    }
                });
                this.totalKg[index] = sum;
            }
        });
    }

    public checkNumberFormat(value: string | number) {
        const patt = new RegExp('^d+(d+)?$');

        if (!patt.test(value.toString())) {
            value = 0;
        }

    // if (!(event.code.includes('Digit') || event.code === 'Comma')) {
    //   return;
    // }
    }

    public replaceComma(str: string) {
        return +(str || '').replace(',', '.');
    }

    public imprimirPlan() {
        this.isPrinting = true;
        setTimeout(async () => {
            await html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement(), {scale: 2, logging: false}).then(canvas => {
                const pdf = new jsPDF('l', 'mm', 'a4');
                const data = canvas.toDataURL('image/jpeg');
                pdf.addImage(data, 'jpg', 0, 0, 290, (canvas.height / (canvas.width / 290)), 'alias', 'FAST');
                pdf.save('Plan de abonado ' + this.fincaSeleccionada + ' ' + this.model.anyo);
  
                this.isPrinting = false;
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }, 100);
    }

    public async enviarCorreo() {
        this.isPrinting = true;
        let email = '';
        let adjunto = '';
        await html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement(), {scale: 2, logging: false}).then(async canvas => {
            this.isPrinting = false;

            const pdf = new jsPDF('l', 'mm', 'a4');
            const data = canvas.toDataURL('image/jpeg');
            pdf.addImage(data, 'jpg', 0, 0, 290, (canvas.height / (canvas.width / 290)), 'alias', 'FAST');
            adjunto = encodeURIComponent(window.btoa(pdf.output()));

            if (this.fincas.selected && this.fincas.selected.email) {
                email = this.fincas.selected.email;
            } else if (this.clientes.selected && this.clientes.selected.email) {
                email = this.clientes.selected.email;
            } else {
                email = '';
            }
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        ).then(() => {
            console.log('Adjuntos generados.');
            return;
        }, error => {
            console.log(error);

            this.isPrinting = false;
            this.tareasApi.enviarRecetaCaptura.hasError = true;
            this.tareasApi.enviarRecetaCaptura['error'] = 'Error al envíar email';
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );

        if (email !== '') {
            await this.tareasApi.enviarRecetaCaptura.toPromise({
                email,
                generado: adjunto,
                finca: this.fincas.selected?.nombre,
                tratamiento: 'Plan de Abonado ' + this.fincas.selected?.nombre + ' ' + this.anyos.selected
            } as never).then(() => {
                this.showSuccess('Email enviado.', 'Se ha enviado el plan de abonado a ' + email + '.');
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        } else {
            this.tareasApi.enviarRecetaCaptura.hasError = true;
            this.tareasApi.enviarRecetaCaptura['error'] = 'El cliente no tiene email';
        }
    }

    public override show(modal: string) {
        this.modelDup = Object.assign({}, this.model);
        this.modelMes.ids_meses = [];
        this.semanasOcultas = [];
        this.mesesOcultos = [];
        super.show(modal, this.model);
    }

    public showSuccess(summary: string, detail: string, severity = 'success') {
        this.messageService.add({summary, detail, severity});
    }

    public async duplicarPlan() {
        this.isDuplicating = true;
        await this.tareasApi.tablaAbonado.GET.toPromise({
            id_finca: this.modelDup.id_finca,
            anyo: this.modelDup.anyo
        } as never).then(res => {
            if (res && res.length > 0) {
                if (!confirm('ATENCIÓN: Ya hay datos para este cliente, finca y año. ¿Desea sobrescribirlos?')) {
                    this.isDuplicating = false;
                    return;
                }
            }
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
        // Asignamos el cliente, finca y año a duplicar al modelo prinicipal ahora, para no
        // disparar los eventos de formChanges()
        Object.keys(this.modelDup).forEach(key => {
            this.model[key] = this.modelDup[key] ?? '';
        });
        this.guardarAbonado(true).then(() => {
            this.showSuccess('Duplicar plan de abonado', 'Se ha duplicado el plan de abonado con éxito.');
            this.canShow['duplicar'] = false;
            this.isDuplicating = false;
            return;
        }, (error) => {
            console.log(error);
            this.isDuplicating = false;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }

}