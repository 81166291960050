<br>

<!-- FORM FIELDS -->
<p-panel>
    <h4>Datos del control sanitario</h4>
        <app-form-builder
            [model]="model"
            [distributionColumns]="3"
            (formChanges)="form.formChanges($event); formChanges()"             
            [fields]="form.formFields">
        </app-form-builder>
    <br>

    <!-- ACTION BUTTONS -->
    <div class="d-flex justify-content-center">
        <div>
            <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        </div>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>
<br>
