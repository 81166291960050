import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import { CitasModel } from 'src/app/models/citas/citas.model';
import { VisitasSemanaModel } from 'src/app/models/visitas/visitas-semana.model';

@Injectable({
    providedIn: 'root'
})
export class CitasApiService {

    public citas = {
        GET: new RequestHandler<CitasModel[]>(),
        POST: new RequestHandler<CitasModel[]>(),
        PUT: new RequestHandler<CitasModel[]>(),
        DELETE: new RequestHandler<CitasModel>()
    };


    public visitasSemanaGET = new RequestHandler<VisitasSemanaModel[]>();


    constructor(
        private syncService: SyncService<CitasModel[]>,
        private syncServiceDelete: SyncService<CitasModel>,
        private syncServiceSemana: SyncService<VisitasSemanaModel[]>,
        private api: ApiRequestService<CitasModel[]>,
        private apiDelete: ApiRequestService<CitasModel>,
        private apiSemana: ApiRequestService<VisitasSemanaModel[]>,
    ) {

        this.defineCitas();
        this.defineVisitasSemanaGET();

    }

    private defineCitas() {
        this.citas.GET.fetch(HttpRequestType.GET, 'citas/get');
        this.citas.POST.fetch(HttpRequestType.POST, 'generic/citas');
        this.citas.PUT.fetch(HttpRequestType.PUT, 'generic/citas');
        this.citas.DELETE.fetch(HttpRequestType.DELETE, 'generic/citas');

        this.api.registerRequest(this.citas.GET);
        this.api.registerRequest(this.citas.POST);
        this.api.registerRequest(this.citas.PUT);
        this.apiDelete.registerRequest(this.citas.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.citas.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.citas.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.citas.PUT));
        this.syncServiceDelete.register(this.syncServiceDelete.baseSyncPolicy(this.citas.DELETE));
    }

    private defineVisitasSemanaGET() {
        this.visitasSemanaGET.fetch(HttpRequestType.GET, 'visitas/visitas_semana_get');
        this.apiSemana.registerRequest(this.visitasSemanaGET);
        this.syncServiceSemana.register(this.syncServiceSemana.baseSyncPolicy(this.visitasSemanaGET));
    }
}
