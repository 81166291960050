import { checkIfRolAdmin } from '../../../../../common/check-role';
import { TareasApiService } from '../../../../../service/api/tareas-api.service';
import { FormCommonApiService } from '../../../../../service/api/formcommon-api.service';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../../../../../service/events/dashboard.service';
import { BaseForm } from '../../../../base-form';

import { AppFormRequest } from '../../../../app-common/form-request/app-form-request';
import { FincasApiService } from '../../../../../service/api/fincas-api.service';
import { pool, build, list } from '../../../../../common/classes/request-builder';
import { StorageManager } from '../../../../../common/storage-manager.class';
import { Utils } from '../../../../../common/utils';
import { environment } from '../../../../../../environments/environment';
import { FormRequestTypes } from '../../../../../common/classes/form-request';
import { InputType } from '../../../../../common/components/form-builder/form-builder.component';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import moment from 'moment';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { SubcontratoModel } from 'src/app/models/tareas/subcontrato.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';

const TRUTHY_VALUES = [1, '1', true, 'true'];


@Component({
    selector: 'app-subcontratos-form',
    templateUrl: './subcontratos-form.component.html',
    styleUrls: ['./subcontratos-form.component.css']
})
export class SubcontratosFormComponent extends BaseForm<SubcontratoModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<SubcontratoModel>();

    public hasClients = environment.features.hasClients;
    public clienteName = environment.features.clienteName;
    public serverUrl = environment.serverUrl;
    public appName = environment.appName;

    public formRequest = new AppFormRequest<SubcontratoModel>();
    public override model: SubcontratoModel = {};
    public signatureFile: File = new File([], '');
    public fincas = list();
    public distributionCol = [2, 3];

    public formFields: ItemInterface<object>[] = [
        { 
            field: 'fecha', 
            label: 'Fecha', 
            inputType: { type: InputType.CALENDAR }
        },
        { 
            field: 'id_finca', 
            label: 'Finca', 
            inputType: { type: InputType.DROPDOWN }, 
            values: this.fincas, 
            valuePrimaryKey: 'id'
        },
        { 
            field: 'proveedor', 
            label: 'Proveedor', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        { 
            field: 'concepto', 
            label: 'Concepto', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        { 
            field: 'coste', 
            label: 'Coste', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
    ];

    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public fincasApi: FincasApiService,
        public tareasApi: TareasApiService,
        public override router: Router) {
        super(
            route,
            router,
            dashboard,
            tareasApi.subcontratos.PUT,
            tareasApi.subcontratos.POST,
            'subcontratos',
            'Actualizar Subcontratos',
            'Realizar Subcontratos',
            'Duplicar Subcontratos'
        );
    }

    ngOnInit() {
        this.initFormData();
        this.expandFormRequest();
    
        if (environment.features.showTareasMto) {
            if (this.getType() === 0 || this.getType() === 2) {
                if (StorageManager.getUser().rol === 'user') {
                    const date = new Date;
                    this.model.hora_fin = date;
                }

            }
        }

        const fieldsToSend = (['id', 'id_usuario']).concat(
            this.formFields.map(it => it.field ?? ''),
        );    

        if (this.hasClients) {
            fieldsToSend.push('id_cliente');
        }

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.tareasApi.subcontratos.GET)
            .setPostRequest(this.tareasApi.subcontratos.POST)
            .setPutRequest(this.tareasApi.subcontratos.PUT)
            .setFormFields(this.formFields
            ).setFieldsToSend(fieldsToSend);

        this.formRequest.load();

        this.softInit(this.getType());
        // Añadir fecha actual solo cuando se crea el registro 
        if (this.getType() === FormRequestTypes.CREATE) {
            this.model.fecha = new Date();
        }
    }


    formatoHorasMinutos(horaEntrada: string, horaSalida: string) {
        let timeStr: string | string[] = horaEntrada;
        timeStr = timeStr.split(':');

        const h = timeStr[0] ?? '',
            m = timeStr[1] ?? '';

        const newTime = moment(horaSalida + ' 2021-4-5')
            .subtract({ 'hours': +h, 'minutes': +m })
            .format('HH:mm');


        // console.log({str});
        return newTime;

    }
    
    public formChanges() {
        if (environment.features.showTareasMto) {
            if (this.model.hora_inicio != null && this.model.hora_fin != null) {

                let horaFin = moment(this.model.hora_fin).format('HH:mm');
                let horaInicio = moment(this.model.hora_inicio).format('HH:mm');
                const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
                if (!timeFormat.test(horaInicio)) {
                    horaInicio = this.model.hora_inicio.toString();
                }

                if (!timeFormat.test(horaFin)) {
                    horaFin = this.model.hora_fin.toString();
                }

                const diferencia = this.formatoHorasMinutos(horaInicio, horaFin);
                this.model.horas_totales = diferencia;

            } else {
                this.model.horas_totales = '';
            }
        }
    }

    public async submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
            return;
        }

        const horaFin = moment(this.model.hora_fin, 'HH:mm');
        const horaInicio = moment(this.model.hora_inicio, 'HH:mm');
        const diferenciaEntreHoras = horaFin.diff(horaInicio);
        if (diferenciaEntreHoras < 0) {
            this.requestButton.error = 'La hora de inicio es mayor que la de fin.';
            return;
        }


        this.formRequest.send();

        if ((this.getType() === FormRequestTypes.DUPLICATE)) {
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    private initFormData(): Promise<boolean> {
        console.log(this.fincasApi.fincasGET, this.fincas, 'nombre', true);
    
        return pool([
            build(this.fincasApi.fincasGET, this.fincas, 'nombre', false),

      
      
        ], () => {

            if (this.getType() === FormRequestTypes.CREATE) {
                this.fincasApi.fincasGET.toPromise().then((resp_fincas: FincasModel[]) => {

                    if (resp_fincas.length > 0) {
                        this.model.id_finca = resp_fincas[0]?.id ?? '';
                    }
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
            }

            this.formRequest.update();

        });
    }

    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {
            const sessionData = StorageManager.getSessionDataDonJardin();
            if (this.getType() === 0) {
                if (sessionData[1] === null) {
                    const fechaActual = new Date();
                    if (checkIfRolAdmin()) {
                        this.model.hora_fin = fechaActual;
                    }
                }
                else {
                    this.model.id_finca = sessionData[0] ?? '';
                    this.model.hora_inicio = sessionData[1]?.toString() ?? '';
                    this.model.hora_fin = sessionData[2]?.toString() ?? '';
                }
            }// if();
            const trueFields = [
                'mantenimiento',
                'limpieza_calles',
                'bolsas_basura',
                'limpieza_desagues'
            ];

            trueFields.forEach(f => {
                this.model[f] = TRUTHY_VALUES.includes(this.model[f]?.toString() ?? '');
            });

            const userInfo = StorageManager.getUser();
            if (userInfo.rol === 'user' && this.getType() === FormRequestTypes.CREATE) {
                this.model.ids_trabajadores = userInfo.id.toString();
            }
      

            if (environment.features.showTareasMto && this.getType() === FormRequestTypes.DUPLICATE) {
                delete this.model.hora_fin;
                delete this.model.hora_inicio;
                delete this.model.horas_totales;
            }




            resolve(true);
        });

        this.formRequest.beforeSend(resolve => {
            this.model.fecha = this.model.fecha instanceof Date
                ? Utils.formatDate(this.model.fecha)
                : this.model.fecha ?? '';


            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromFinca = (this.fincas.selected || {}).id_cliente;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
        clientIdFromFinca ? clientIdFromFinca :
            clientIdFromForm ? clientIdFromForm :
                clientId ? clientId : ''; // DEFAULT

            const userId = StorageManager.getUser().id;
      

            if (this.getType() === FormRequestTypes.CREATE || this.getType() === FormRequestTypes.DUPLICATE) {
                this.model.id_usuario = userId ? userId : '';
            }


            this.model.mantenimiento = this.model.mantenimiento === true ? 1 : 0;

            if (this.model.hora_inicio != null) {
                const horaInicio = moment(this.model.hora_inicio, 'HH:mm').format('HH:mm');
                this.model.hora_inicio = horaInicio;
                if (!this.model.hora_inicio.includes(':')) {
                    this.model.hora_inicio = null;
                }
            }

            if (this.model.hora_fin != null) {
                const horaFin = moment(this.model.hora_fin, 'HH:mm').format('HH:mm');
                this.model.hora_fin = horaFin;
                if (!this.model.hora_fin.includes(':')) {
                    this.model.hora_fin = null;
                }
            }
            resolve(true);
        });

   

        this.formRequest.afterFinish(resolve => {
            if (this.getType() === FormRequestTypes.DUPLICATE) {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }
            resolve(true);
        });

    }
}
