<br>
<p-panel>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
      <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
    </div>
  </div>
  <app-form-builder
          [model]="model"
          [undoEnabled]="undoEnabled"
          [distributionColumns]="2"
          [distributionOrder]="distributionOrder"
          [fields]="formFields"
          (formChanges)="formChanges()">
  </app-form-builder>
  <br>
  <div class="distribution_camera_signature" style="display: flex;
  gap: 50px;
  align-items: center;" *ngIf="this.model['signature_img']">
      <div>
          <div *ngIf="this.model['signature_img']" style="display: flex; flex-direction: column;">
              <p>Firma</p>
              <img *ngIf="!this.model['signature_img'].includes('base64')" [src]="serverUrl + 'ws/pedidos/firmas/'+this.model['signature_img'] " class="info-firma-img" alt="firma">
              <img *ngIf="this.model['signature_img'].includes('base64')" [src]="this.model['signature_img'] " class="info-firma-img" alt="firma">
          </div>
      </div>  
      <app-signature (sendFile)="getSignature($event)" (sendTempImg)="updateFirma($event);"></app-signature>
  </div>
  <div class="distribution_camera_signature"  *ngIf="!this.model['signature_img']">
       
      <app-signature (sendFile)="getSignature($event)" (sendTempImg)="updateFirma($event);"></app-signature>
  </div>
  <br>
  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            [lockOnSuccess]="true"
            (click)="submit()"
            [mode]="requestButtonType"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>
