import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';

@Injectable({
    providedIn: 'root'
})
export class VisitasVariedadMesApiService {

    public visitasVariedadMes = {
        GET: new RequestHandler<VisitasVariedadMesModel[]>(),
        POST: new RequestHandler<VisitasVariedadMesModel[]>(),
        PUT: new RequestHandler<VisitasVariedadMesModel[]>(),
        DELETE: new RequestHandler<VisitasVariedadMesModel>()
    };

    public visitasPorCliente = {
        GET: new RequestHandler<VisitasVariedadMesModel[]>(),
        POST: new RequestHandler<VisitasVariedadMesModel[]>(),
        PUT: new RequestHandler<VisitasVariedadMesModel[]>(),
        DELETE: new RequestHandler<VisitasVariedadMesModel>()
    };

    public cultivos = {
        GET: new RequestHandler<VisitasVariedadMesModel[]>(),
        POST: new RequestHandler<VisitasVariedadMesModel[]>(),
        PUT: new RequestHandler<VisitasVariedadMesModel[]>(),
        DELETE: new RequestHandler<VisitasVariedadMesModel>()
    };
    
    public variedades = {
        GET: new RequestHandler<VisitasVariedadMesModel[]>(),
        POST: new RequestHandler<VisitasVariedadMesModel[]>(),
        PUT: new RequestHandler<VisitasVariedadMesModel[]>(),
        DELETE: new RequestHandler<VisitasVariedadMesModel>()
    };

    public cultivosGET = new RequestHandler<VisitasVariedadMesModel[]>();
    public distinctCultivosGET = new RequestHandler<VisitasVariedadMesModel[]>();
    public distinctVariedadesGET = new RequestHandler<VisitasVariedadMesModel[]>();

    constructor(
        private syncService: SyncService<VisitasVariedadMesModel[]>,
        private syncServiceDelete: SyncService<VisitasVariedadMesModel>,
        private api: ApiRequestService<VisitasVariedadMesModel[]>,
        private apiDelete: ApiRequestService<VisitasVariedadMesModel>,
    ) {

        this.defineVisitasVariedadMes();
        this.defineVisitasPorCliente();
        this.defineCultivosGET();
        this.defineDistinctCultivosGET();
        this.defineDistinctVariedadesGET();
        this.defineCultivos();
        this.defineVariedades();

    }

    private defineVisitasVariedadMes() {
        this.visitasVariedadMes.GET.fetch(HttpRequestType.GET, 'visitas_variedad_mes/get');
        this.visitasVariedadMes.POST.fetch(HttpRequestType.POST, 'generic/visitas_variedad_mes');
        this.visitasVariedadMes.PUT.fetch(HttpRequestType.PUT, 'generic/visitas_variedad_mes');
        this.visitasVariedadMes.DELETE.fetch(HttpRequestType.DELETE, 'generic/visitas_variedad_mes');

        this.api.registerRequest(this.visitasVariedadMes.GET);
        this.api.registerRequest(this.visitasVariedadMes.POST);
        this.api.registerRequest(this.visitasVariedadMes.PUT);
        this.apiDelete.registerRequest(this.visitasVariedadMes.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasVariedadMes.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasVariedadMes.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasVariedadMes.PUT));
        this.syncServiceDelete.register(this.syncServiceDelete.baseSyncPolicy(this.visitasVariedadMes.DELETE));
    }

    private defineVisitasPorCliente() {
        this.visitasPorCliente.GET.fetch(HttpRequestType.GET, 'visitas_variedad_mes/clients');
        this.api.registerRequest(this.visitasPorCliente.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.visitasPorCliente.GET));
    }

    private defineDistinctCultivosGET() {
        this.distinctCultivosGET.fetch(HttpRequestType.GET, 'visitas_variedad_mes/distinct_cultivos_get');
        this.api.registerRequest(this.distinctCultivosGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.distinctCultivosGET));
    }

    private defineCultivosGET() {
        this.cultivosGET.fetch(HttpRequestType.GET, 'visitas_variedad_mes/get_cultivos');
        this.api.registerRequest(this.cultivosGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.cultivosGET));
    }

    private defineDistinctVariedadesGET() {
        this.distinctVariedadesGET.fetch(HttpRequestType.GET, 'visitas_variedad_mes/distinct_variedades_get');
        this.api.registerRequest(this.distinctVariedadesGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.distinctVariedadesGET));
    }

    private defineCultivos(){
        this.cultivos.GET.fetch(HttpRequestType.GET, 'visitas_variedad_mes/cultivos');
        this.api.registerRequest(this.cultivos.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.cultivos.GET));
    }

    private defineVariedades() {
        this.variedades.GET.fetch(HttpRequestType.GET, 'visitas_variedad_mes/variedades');
        this.api.registerRequest(this.variedades.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.variedades.GET));
    }
}
