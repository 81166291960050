import { TableModule } from 'primeng/table';
import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {BaseView} from '../../../base-view';
import {environment} from '../../../../../environments/environment';
import moment from 'moment';
import { Filtering } from 'src/app/service/filtering/filtering';
import { StorageManager } from 'src/app/common/storage-manager.class';
import { MessageService } from 'primeng/api';
import { PedidosApiService } from 'src/app/service/api/pedidos-api.service';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { PedidoModel } from 'src/app/models/visitas/pedido.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

@Component({
    selector: 'app-visitas-pedidos',
    templateUrl: './visitas-pedidos.component.html',
    styleUrls: ['./visitas-pedidos.component.scss'],
    providers: [MessageService]
})
export class VisitasPedidosComponent extends BaseView<PedidoModel> implements OnInit {


    @ViewChild('table_cargos', {read: false, static: false}) table_cargos: TableModule = new TableModule();

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public currentUser = StorageManager.getUser();
    public clienteName = environment.features.clienteName;
    public fincaName = environment.features.fincaName;
    public userCanSeeClientes = environment.features.userCanSeeClientes;
    
    public filter: Filtering<PedidoModel> = new Filtering<PedidoModel>();
    
    public distributionOrder = [
        4, 3, 3, 3, 3, 3, 1
    ];

    public getRequest: RequestHandler<PedidoModel[]> = this.pedidosApi.pedidosGET;
    public deleteRequest: RequestHandler<PedidoModel> = this.pedidosApi.pedidosDELETE;

    public pathToForm = 'visitas-pedidos-form';
    public menuItemId = 'visitas-pedidos';

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};
    public override model: PedidoModel = {};
    
    public componentName = 'pedidos';

    public propiedad = {
        selected: ''
    };


    public formFields: ItemInterface<object>[] = [
        {
            label: 'Finca', 
            field: 'finca', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Albarán', 
            field: 'albaran', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Fecha Pedido', 
            field: 'fecha_pedido', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Fecha Entrega', 
            field: 'fecha_entrega', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Producto', 
            field: 'producto_nombre1', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cantidad', 
            field: 'cantidad1', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Lote', 
            field: 'lote1', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Producto 2', 
            field: 'producto_nombre2', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cantidad 2', 
            field: 'cantidad2', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Lote 2', 
            field: 'lote2', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Producto 3', 
            field: 'producto_nombre3', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cantidad 3', 
            field: 'cantidad3', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Lote 3', 
            field: 'lote3', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Producto 4', 
            field: 'producto_nombre4', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cantidad 4', 
            field: 'cantidad4', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Lote 4', 
            field: 'lote4', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Producto 5', 
            field: 'producto_nombre5', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cantidad 5', 
            field: 'cantidad5', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Lote 5', 
            field: 'lote5', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Estado', 
            field: 'estado', 
            inputType: {type: InputType.TEXT}
        },
    ];

    public cols: ColInterface[] = [
        {header: this.clienteName, field: 'cliente'},
        {header: this.fincaName, field: 'finca'},
        {header: 'Fecha Pedido', field: 'fecha_pedido'},
        {header: 'Fecha Entrega', field: 'fecha_entrega'},
        {header: 'Estado', field: 'estado'},
    ];

    constructor(
        public pedidosApi: PedidosApiService,
        private dashboard: DashboardService,
        private router: Router,
    ) {
        super(dashboard, pedidosApi.pedidosGET, pedidosApi.pedidosDELETE);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);
        this.initFilter();
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if (col.field === 'fecha_cad') {
            const fecha = moment(value, 'DD/MM/YYYY');
            const tresMesesAntes = moment(value, 'DD/MM/YYYY').subtract(3, 'months');
            const hoy = moment(moment().format('MM-DD-YYYY' + ' 00:00:00'));

            if (hoy.isSameOrAfter(tresMesesAntes) && hoy.isSameOrBefore(fecha)) {
                return {
                    rowStyle: {
                        backgroundColor: '#ff9a02',
                        textAlign: 'center'
                    }
                };
            } else if (hoy.isAfter(fecha)) {
                return {
                    rowStyle: {
                        backgroundColor: '#f44242',
                        textAlign: 'center'
                    }
                };
            } else {
                return {
                    rowStyle: {
                        textAlign: 'center'
                    }
                };  
            }
        }
        return{ textAlign: 'left'};
    }

    public globalVisualTransform(value: string, col: ColInterface): string {
        if ((col.field === 'fecha_entrega' || col.field === 'fecha_pedido') && !moment(value, 'DD/MM/YYYY').isValid()) {
            return '-';
        }
        return value;
    }

    public add() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', 'crear-visita-pedido']);
        }
    }

    public edit(data: PedidoModel) {
        this.router.navigate(['dashboard', 'editar-visita-pedido', data.id]);
    }

    public duplicate(data: PedidoModel) {
        this.router.navigate(['dashboard', 'duplicar-visita-pedido', data.id]);
    }

    public override deleteRegister() {
        super.deleteRegister();
    }

    public override show(modal: string, data: PedidoModel) {
        super.show(modal, data);
    }

  
    public formChanges() {

    }
    
    private initFilter(){
    }
}
