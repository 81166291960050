<div class="d-flex justify-content-center" style="font-size: 45px">
  <button style="border: none" (click)="visibleCamera = true; showPhoto = false; getStream()">
    <i class="fa fa-camera" 
    aria-hidden="false" 
    style="cursor: pointer;"></i></button>
</div>
<p-dialog header="Camera" [(visible)]="visibleCamera" (onShow)="scrollToTop()">

  <div style="width: 80vw !important;height: 100vh !important;margin: 0 auto;">
    <br>
    <br>
    <p-panel>
     
      <div *ngIf="!loadingCamera" style=" display: inline-block; margin-right: 10px; position: relative; top: 3px;">
        <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="6" animationDuration="1.5s">
        </p-progressSpinner>
      </div>
      <p *ngIf="!showPhoto">Pulsa sobre la pantalla para hacer una captura</p>
      <div *ngIf="!showPhoto" class="d-flex justify-content-center">
        <div>
          <video autoplay style="height: 40vh; width: 80vw;"></video>
        </div>
      </div>
      <p *ngIf="showPhoto">Volver a la camara, pulsa sobre la pantalla</p>
      <div *ngIf="showPhoto" class="d-flex justify-content-center">
        <button style="border: none; max-height: 600px; max-width: 800px;" (click)="getStream()">
          <img style="max-height: 40vvh; max-width: 80vw;" id="imageTag" alt="cámara">
        </button>
      </div>
      <br>

      <br>
    </p-panel>

    
    <div class="d-flex justify-content-center" style="margin-top: 40px">
      <button class="btn btn-dark mx-2" (click)="visibleCamera = false">Cerrar</button>
      <button class="btn btn-danger mx-2" (click)="getStream()" *ngIf="showPhoto"> <i  aria-hidden="false" class="fa fa-trash" style="cursor: pointer; font-size: 20px;"></i></button>
      <button class="btn btn-success mx-2" (click)="takePhoto()" *ngIf="!showPhoto"> <i  aria-hidden="false" class="fa fa-camera" style="cursor: pointer; font-size: 20px;"></i></button>
    </div>
  </div>
  <br>

</p-dialog>