
import {Injectable} from '@angular/core';
import {RequestHandler} from '../OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { CosteModel } from 'src/app/models/coste.model';

@Injectable({
    providedIn: 'root'
})
export class CostesProductosApiService {

    public costesProductos = {
        GET: new RequestHandler<CosteModel[]>(),
    };    

    constructor(private syncService: SyncService<CosteModel[]>,
        private api: ApiRequestService<CosteModel[]>) {

        this.defineCostesProductos();
    }

    private defineCostesProductos() {
        this.costesProductos.GET.fetch(HttpRequestType.GET, 'costes/costes_productos_get');

        this.api.registerRequest(this.costesProductos.GET);

        this.syncService.register(this.syncService.baseSyncPolicy(this.costesProductos.GET));
       
    }
}
