import {Injectable} from '@angular/core';
import {RequestHandler} from '../../../app/service/OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { environment } from '../../../environments/environment';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';
import { TratamientosModel } from 'src/app/models/tratamientos/tratamientos.model';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { CubasModel } from 'src/app/models/form-common/cubas.model';
import { MateriaActivaModel } from 'src/app/models/form-common/materia-activa.model';
import { Uso } from 'src/app/models/productos/uso.model';

@Injectable({
    providedIn: 'root'
})
export class FormCommonApiService {

    public data = new RequestHandler<ProductosModel[]>();

    public cubas = new RequestHandler<CubasModel[]>();
    public tratamientos = new RequestHandler<TratamientosModel[]>();
    public productos = new RequestHandler<ProductosModel[]>();
    public materiaActiva = new RequestHandler<MateriaActivaModel[]>();


    public magramaDetalleProducto = new RequestHandler<Uso[]>();
    public magramaProducto = new RequestHandler<ProductosModel>();
    public magramaAll = new RequestHandler<ProductosModel[]>();
    public mgUsos = new RequestHandler<Uso>();
    public mgAgentes = new RequestHandler<Uso[]>();
    public productosCodigo = new RequestHandler<ProductosModel[]>();

    public variedadesGET = new RequestHandler<VisitasVariedadMesModel[]>();
    public cultivosGET = new RequestHandler<VisitasVariedadMesModel[]>();
    public cultivosPatronGET = new RequestHandler<VisitasVariedadMesModel[]>(); 

    public historicoCheckGET = new RequestHandler<Record<string, string[]>>();
    public productosMasUsosGET = new RequestHandler<ProductosModel[]>();

    constructor(
        private syncServiceProducto: SyncService<ProductosModel[]>,
        private syncServiceMagrama: SyncService<ProductosModel>,
        private syncServiceCuba: SyncService<CubasModel[]>,
        private syncServiceTratamiento: SyncService<TratamientosModel[]>,
        private syncServiceMateria: SyncService<MateriaActivaModel[]>,
        private syncServiceUsos: SyncService<Uso[]>,
        private syncServiceUso: SyncService<Uso>,
        private syncServiceHistorico: SyncService<Record<string, string[]>>,
        private apiProducto: ApiRequestService<ProductosModel[]>,
        private apiMagrama: ApiRequestService<ProductosModel>,
        private apieCuba: ApiRequestService<CubasModel[]>,
        private apiTratamiento: ApiRequestService<TratamientosModel[]>,
        private apiMateria: ApiRequestService<MateriaActivaModel[]>,
        private apiUsos: ApiRequestService<Uso[]>,
        private apiVariedad: ApiRequestService<VisitasVariedadMesModel[]>,
        private apiHistorico: ApiRequestService<Record<string, string[]>>,
        private api: ApiRequestService<Uso>) {


        this.defineData();

        this.defineCubas();
        this.defineTratamientos();
        this.defineProductos();
        this.defineMateriaActiva();
        this.defineMagramaDetalleProducto();
        this.defineMagramaProducto();
        this.defineMagramaProductosCodigo();

        this.defineVariedadesGET();
        this.defineCultivosGET();
        this.defineCultivosPatronGET();        
        this.defineMagramaAll();
        this.defineMagramaUsos();
        this.defineMagramaAgentes();

        this.defineHistoricoCheckGET();
        this.defineProductosMasUsosGET();
    }

    private defineData() {
        this.data.fetch(HttpRequestType.GET, 'mg/_data');
        this.apiProducto.registerRequest(this.data);
        this.syncServiceProducto.register(this.syncServiceProducto.baseSyncPolicy(this.data));
    }

    private defineCubas() {
        this.cubas
            .fetch(HttpRequestType.GET, 'generic/cubas');

        this.apieCuba
            .registerRequest(this.cubas);

        this.syncServiceCuba
            .register(this.syncServiceCuba.baseSyncPolicy(this.cubas));
    }

    private defineTratamientos() {
        this.tratamientos
            .fetch(HttpRequestType.GET, 'generic/tratamientos');

        this.apiTratamiento
            .registerRequest(this.tratamientos);

        this.syncServiceTratamiento
            .register(this.syncServiceTratamiento.baseSyncPolicy(this.tratamientos));
    }

    private defineVariedadesGET() {
        this.variedadesGET
            .fetch(
                HttpRequestType.GET, 
                environment.appName !== 'fitogest' ? 'generic/visitas_variedad_mes' : 'visitas_variedad_mes/get_variedades'
            );

        this.apiVariedad
            .registerRequest(this.variedadesGET);
    }

    private defineCultivosPatronGET(){
        this.cultivosPatronGET
            .fetch(HttpRequestType.GET, 'generic/cultivos_patrones');

        this.apiVariedad
            .registerRequest(this.cultivosPatronGET);        
    }

    private defineCultivosGET() {
        this.cultivosGET
            .fetch(HttpRequestType.GET, 'generic/visitas_variedad_mes');

        this.apiVariedad
            .registerRequest(this.cultivosGET);
    }

    private defineProductos() {
        this.productos
            .fetch(HttpRequestType.GET, 'generic/productos');

        this.apiProducto
            .registerRequest(this.productos);

        this.syncServiceProducto
            .register(this.syncServiceProducto.baseSyncPolicy(this.productos));
    }

    private defineMateriaActiva() {
        this.materiaActiva
            .fetch(HttpRequestType.GET, 'generic/materiaActiva');

        this.apiMateria
            .registerRequest(this.materiaActiva);

        this.syncServiceMateria
            .register(this.syncServiceMateria.baseSyncPolicy(this.materiaActiva));
    }

    private defineMagramaDetalleProducto() {
        this.magramaDetalleProducto
            .fetch(HttpRequestType.GET, 'mg/usos');

        this.apiUsos
            .registerRequest(this.magramaDetalleProducto);

        this.syncServiceUsos
            .register(this.syncServiceUsos.baseSyncPolicy(this.magramaDetalleProducto));
    }

    private defineMagramaProducto() {
        this.magramaProducto
            .fetch(HttpRequestType.GET, 'mg/productos');

        this.apiMagrama
            .registerRequest(this.magramaProducto);

        this.syncServiceMagrama
            .register(this.syncServiceMagrama.baseSyncPolicy(this.magramaProducto));
    }

    private defineMagramaProductosCodigo() {
        this.productosCodigo
            .fetch(HttpRequestType.GET, 'mg/productos_codigo');

        this.apiProducto
            .registerRequest(this.productosCodigo);

        this.syncServiceProducto
            .register(this.syncServiceProducto.baseSyncPolicy(this.productosCodigo));
    }

    private defineMagramaAll() {
        this.magramaAll
            .fetch(HttpRequestType.POST, 'mg/all');

        this.apiProducto
            .registerRequest(this.magramaAll);

        this.syncServiceProducto
            .register(this.syncServiceProducto.baseSyncPolicy(this.magramaAll));
    }

    private defineMagramaUsos() {
        this.mgUsos
            .fetch(HttpRequestType.GET, 'mg/distinct_usos');

        this.api
            .registerRequest(this.mgUsos);

        this.syncServiceUso
            .register(this.syncServiceUso.baseSyncPolicy(this.mgUsos));
    }

    private defineMagramaAgentes() {
        this.mgAgentes
            .fetch(HttpRequestType.GET, 'mg/distinct_agente');

        this.apiUsos
            .registerRequest(this.mgAgentes);

        this.syncServiceUsos
            .register(this.syncServiceUsos.baseSyncPolicy(this.mgAgentes));
    }

    private defineHistoricoCheckGET() {
        this.historicoCheckGET.fetch(HttpRequestType.GET, 'productos/historico_check');
        this.apiHistorico.registerRequest(this.historicoCheckGET);
        this.syncServiceHistorico.register(this.syncServiceHistorico.baseSyncPolicy(this.historicoCheckGET));
    }

    private defineProductosMasUsosGET() {
        this.productosMasUsosGET.fetch(HttpRequestType.GET, 'mg/listado_vademecum');
        this.apiProducto.registerRequest(this.productosMasUsosGET);
        this.syncServiceProducto.register(this.syncServiceProducto.baseSyncPolicy(this.productosMasUsosGET));
    }
}
