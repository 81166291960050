<!-- <p-toast [style]="{marginTop: '80px'}"></p-toast> -->

<div style="display: flex; justify-content: space-between">
  <div class="fill-remaining-space"></div>
  <div>
    <h4>Velocidad (km/h) / Presión (bar) / Rpm / Dosis / Caudal (l/min)</h4>
  </div>
  
  <div>
    <div
      style="
        position: relative;
        top: 20px;
        display: flex;
        justify-content: center;
        gap: 20px;
      "
    >
      <div>
        <div style="display: flex; justify-content: flex-start">
          <!-- <div [ngStyle]="{'pointer-events': this.countChart == 0 ? 'none' : 'all' }"  >
                        <button pButton type="button" icon="fa fa-angle-double-left" 
                            [disabled]="this.countChart == 0"
                            [ngClass]="this.countChart == 0 ? 'fc-prev-button ui-button ui-state-default ui-corner-left ui-state-hover disable-button-chart ' : 'fc-prev-button ui-button ui-state-default ui-corner-left ui-state-hover'"
                            style="height: 47px;  width: 40px; cursor: pointer !important; margin-right: 20px;">
                        </button>
                    </div> -->
          <button
            (click)="replaceContent('begin')"
            [ngClass]="
              this.countChart === 0
                ? 'div-icon-chart-disabled'
                : 'div-icon-chart '
            "
          >
            <i
              [ngClass]="
                this.countChart === 0
                  ? 'icon-chart-pages-disabled'
                  : 'icon-chart-pages '
              "
              class="fa fa-angle-double-left"
              aria-hidden="true"
            ></i>
          </button>
          <button
            (click)="replaceContent('previous')"
            [ngClass]="
              this.countChart === 0
                ? 'div-icon-chart-disabled'
                : 'div-icon-chart '
            "
          >
            <i
              [ngClass]="
                this.countChart === 0
                  ? 'icon-chart-pages-disabled'
                  : 'icon-chart-pages '
              "
              class="fa fa-angle-left"
              aria-hidden="true"
            ></i>
          </button>
        </div>
      </div>
      <div style="position: relative; top: 10px">
        {{ this.countChart + 1 }} / {{ max_chart_pages }}
      </div>
      <button
        pButton
        type="button"
        icon="fa fa-angle-right"
        (click)="replaceContent('next')"
        [disabled]="
          elments_per_page * (this.countChart + 2) >=
          this.grafica_vel.labels.length
        "
        class="fc-next-button ui-button ui-state-default ui-corner-right"
        style="margin-right: 10px"
        [ngClass]="
          elments_per_page * (this.countChart + 2) >=
          this.grafica_vel.labels.length
            ? 'fc-prev-button ui-button ui-state-default  ui-corner-right ui-state-hover disable-button-chart '
            : 'fc-prev-button ui-button ui-state-default  ui-corner-right ui-state-hover'
        "
        style="height: 47px; width: 40px; cursor: pointer !important"
      > </button>
      <button
        pButton
        type="button"
        icon="fa fa-angle-double-right"
        (click)="replaceContent('final')"
        [disabled]="
          elments_per_page * (this.countChart + 2) >=
          this.grafica_vel.labels.length
        "
        class="fc-next-button ui-button ui-state-default"
        style="margin-right: 10px"
        [ngClass]="
          elments_per_page * (this.countChart + 2) >=
          this.grafica_vel.labels.length
            ? ' ui-button ui-state-default   ui-state-hover disable-button-chart '
            : ' ui-button ui-state-default   ui-state-hover'
        "
        style="height: 47px; width: 40px; cursor: pointer !important"
      > </button>
    </div>
  </div>
</div>
<div style="display: flex; justify-content: center;">
    <h5>Clicar para mostrar/ocultar gráfica</h5>
</div>

<p-chart
  type="line"
  [data]="data"
  (onDataSelect)="selectData()"
  height="500"
  [options]="options"
>
</p-chart>
