import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import { ProductosModel } from 'src/app/models/productos/productos.model';

@Injectable({
    providedIn: 'root'
})
export class ProductosApiService {

    public productos = {
        GET: new RequestHandler<ProductosModel[]>(),
        POST: new RequestHandler<ProductosModel[]>(),
        PUT: new RequestHandler<ProductosModel[]>(),
        DELETE: new RequestHandler<ProductosModel>()
    };

    public listadoVademecum = {
        GET: new RequestHandler<ProductosModel[]>(),
        POST: new RequestHandler<ProductosModel[]>(),
        PUT: new RequestHandler<ProductosModel[]>(),
        DELETE: new RequestHandler<ProductosModel>()
    };

    public cultivosPatron = {
        GET: new RequestHandler<ProductosModel[]>(),
    };

    constructor(
        private syncService: SyncService<ProductosModel[]>,
        private syncServiceDelete: SyncService<ProductosModel>,
        private api: ApiRequestService<ProductosModel[]>,
        private apiDelete: ApiRequestService<ProductosModel>,
    ) {

        this.defineProductos();
        this.defineListadoVademecum();
        this.defineCultivosPatron();
    }

    private defineCultivosPatron(){
        this.cultivosPatron.GET.fetch(HttpRequestType.GET, 'productos/cultivos_patron_get');   
        this.api.registerRequest(this.cultivosPatron.GET);  
        this.syncService.register(this.syncService.baseSyncPolicy(this.cultivosPatron.GET));                   
    }

    private defineProductos() {
        this.productos.GET.fetch(HttpRequestType.GET, 'productos/productos_get');
        this.productos.POST.fetch(HttpRequestType.POST, 'generic/productos');
        this.productos.PUT.fetch(HttpRequestType.PUT, 'generic/productos');
        this.productos.DELETE.fetch(HttpRequestType.DELETE, 'generic/productos');

        this.api.registerRequest(this.productos.GET);
        this.api.registerRequest(this.productos.POST);
        this.api.registerRequest(this.productos.PUT);
        this.apiDelete.registerRequest(this.productos.DELETE);


        this.syncService.register(this.syncService.baseSyncPolicy(this.productos.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.productos.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.productos.PUT));
        this.syncServiceDelete.register(this.syncServiceDelete.baseSyncPolicy(this.productos.DELETE));
    }

    private defineListadoVademecum() {
        this.listadoVademecum.GET.fetch(HttpRequestType.GET, 'vademecum/vademecum_listado_get');
        this.listadoVademecum.POST.fetch(HttpRequestType.POST, 'generic/vademecum_listado');
        this.listadoVademecum.PUT.fetch(HttpRequestType.PUT, 'generic/vademecum_listado');
        this.listadoVademecum.DELETE.fetch(HttpRequestType.DELETE, 'generic/vademecum_listado');

        this.api.registerRequest(this.listadoVademecum.GET);
        this.api.registerRequest(this.listadoVademecum.POST);
        this.api.registerRequest(this.listadoVademecum.PUT);
        this.apiDelete.registerRequest(this.listadoVademecum.DELETE);


        this.syncService.register(this.syncService.baseSyncPolicy(this.listadoVademecum.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.listadoVademecum.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.listadoVademecum.PUT));
        this.syncServiceDelete.register(this.syncServiceDelete.baseSyncPolicy(this.listadoVademecum.DELETE));
    }

}
