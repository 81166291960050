<div class="resp-container" style="margin: -19px -21px 0px -35px;">

    <div class="resp-iframe" style="width:100%; height:100%;">
        <iframe 
            style="width: 100%; height: 92%; background-color: aliceblue;"
            [src]="thebluedotsURL">
        </iframe>
    </div>

 

</div>