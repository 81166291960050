<br>
<p-panel>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">Editar Tarea Siembra</h4>
      <h4 *ngIf="flag === 'new'">Crear Tarea Siembra</h4>
    </div>
  </div>
  <div *ngIf="this.applicationType !== 'viverogest'">
    <app-form-builder
        [model]="model"
        [distributionOrder]="distributionOrder"
        [distributionColumns]="4"
        [fields]="formFields"
        (formChanges)="formChanges($event)"
        (selectAllcheck)="selectAll()">
    </app-form-builder>
  </div>
  <div *ngIf="this.applicationType === 'viverogest'">
    <app-form-builder
          [model]="model"
          [distributionOrder]="distributionOrderViverogest"
          [distributionColumns]="4"
          [fields]="formFieldsViverogest"
          (formChanges)="formChanges($event)"
          (selectAllcheck)="selectAll()">
    </app-form-builder>
    <app-dictado
    mensaje="Grabar observaciones" 
    [observaciones]="model.observaciones ?? ''" 
    (sendObservaciones)="getObservaciones($event)"
    ></app-dictado>
  </div>
  <div class="mb-5 col-sm-6" *ngIf="this.showTareasSiembraMaquinarias">
    <h5>Datos de la maquinaria</h5>
    <app-form-builder
            [model]="model"
            (formChanges)="formChanges($event); formChanges($event)"
            [distributionColumns]="3"
            [fields]="formFieldsMaquinarias">
    </app-form-builder>
  </div>
  <br>
  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
            [lockOnSuccess]="true"
            (click)="submit()"
            [mode]="requestButtonType"
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>
