import { Injectable } from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';

@Injectable({
    providedIn: 'root'
})
export class UploadApiService {
    public firmasPOST = new RequestHandler<{id: string, dir: string, firma: string}>();

    constructor(private syncService: SyncService<{id: string, dir: string, firma: string}>,
        private api: ApiRequestService<{id: string, dir: string, firma: string}>) {

        this.defineFirmasPOST();
    }

    private defineFirmasPOST() {
        this.firmasPOST
            .fetch(HttpRequestType.POST, 'upload/firmas');

        this.api
            .registerRequest(this.firmasPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.firmasPOST));
    }


}
