<div class="row justify-content-center mx-2 mt-4">

    <!-- Fertilizantes -->
    <div class="mb-4 col-lg-6 col-md-12">
      <app-common-chart
        title="Consumo de Fertilizantes"
        [request]="requestFertilizantes"
        type="line"
        [options]="{
          field: 'dosis_total',
          label: 'fecha_inicio',
          extraFields: ['sup_cultivada'],
          fieldIsArray: false,
          multipleFields: false
        }">
      </app-common-chart>
    </div>
  
  
    <!-- Fitosanitarias -->
    <div class="mb-4 col-lg-6 col-md-12">
      <app-common-chart
        title="Consumo de Fitosanitarios"
        [request]="requestFitosanitarias"
        type="line"
        [options]="{
          field: 'litros',
          label: 'fecha_inicio',
          fieldIsArray: false,
          multipleFields: false
        }">
      </app-common-chart>
    </div>


  <div class="mb-4 col-lg-3 col-md-12">
    <app-common-chart
      title="Productos fito más utilizados"
      [request]="requestTareasFito"
      type="doughnut"
      [options]="optionsTareasFito">
    </app-common-chart>
  </div>
    <!-- Ventas -->
  <div class="mb-4 col-lg-6 col-md-12">
    <app-common-chart
    title="Ventas por finca y comprador"
    [request]="requestVentas"
    type="bar"
    [options]="{
      field: 'cantidad',
      label: 'finca',
      fieldIsArray: false,
      multipleFields: true
    }">
    </app-common-chart>
  </div>


  <div class="mb-4 col-lg-3 col-md-12" *ngIf="tieneVisitas && hasClients">
    <app-common-chart
      title="Clientes con más visitas"
      [request]="requestVisitas"
      type="pie"
      [options]="optionsVisitas">
    </app-common-chart>
  </div>

</div>
<div class="w-100 row">
  <div class="mb-4 col-lg-12">
    <p-card>
        <p-header>
            <h5 class="p-3 mb-0">Actividad reciente</h5>
        </p-header>
        <div *ngFor="let activity of userActivitiesFormat">
            <div *ngIf="activity?.request_method === 'POST'" class="activity-container" style="background-color: #8cdeff4f;">
                <span>{{activity?.msg}}</span>
                <span class="text-secondary">{{activity?.fecha}}</span>
            </div>
            <div *ngIf="activity?.request_method === 'PUT'" class="activity-container" style="background-color: #fbff7fa3;">
                <span>{{activity?.msg}}</span>
                <span class="text-secondary">{{activity?.fecha}}</span>
            </div>
            <div *ngIf="activity?.request_method === 'DELETE'" class="activity-container" style="background-color: #ffb3b3b5;">
                <span>{{activity?.msg}}</span>
                <span class="text-secondary">{{activity?.fecha}}</span>
            </div>
        </div>
    </p-card>
  </div>
</div>
