import { Component, Input, Output, EventEmitter } from '@angular/core';

import { environment } from '../../../../../../../environments/environment';
import { ReferenciaCatastralervice } from '../../../../../../service/catastral/referencia.catastral.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-dialog-sigpac-form',
    templateUrl: './dialog.sigpac.component.html',
    styleUrls: ['./dialog.sigpac.component.scss']
})
export class DialogSigPacComponent {
    @Input() showSigPac = false;
    @Output() sendSigPac: EventEmitter<object> = new EventEmitter<object>();   

    public hasClients = environment.features.hasClients;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public showMapas = environment.features.showMapas;
    public showSectores = environment.features.showSectores;
    public variedadCanBeVarios = environment.features.variedadCanBeVarios;
    public showAplicadoresActivo = environment.features.showAplicadoresActivo;
    public showDatosMedioambientales = environment.features.showDatosMedioambientales;
    public showParcelasEstacion = environment.features.showParcelasEstacion;
    public showParcelasCodigoTrazabilidad = environment.features.showParcelasCodigoTrazabilidad;
    public customMapColorsList = environment.features.customMapColorsList;
    public appName = environment.appName;
    public otros = environment.features.showParcelasPartida;

    public currLat = 0;
    public currLng = 0;
    public errorNoData = false;
    public showReferencia = false;
    municipio = '';
    parcela = '';
    poligono = '';
    provincia = '';

    constructor( private referenciaCatastralervice: ReferenciaCatastralervice) {

    }

    public scrollToTop() {
        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        if (elements && elements[0]) {
            setTimeout(() => {
                if (elements[0]) {
                    elements[0].scrollTop = 0;
                }
            }, 100);
        }
    }

    public async openDialog() {
        const  referencia = await this.getCurrentLocation();

        if ( referencia  == null ) {
            this.showSigPac = true;
        } else if (referencia === false){}
        else {
            this.sendSigPac.next(referencia);
        }
    }

    getCurrentLocation() {
        this.errorNoData = false;
        this.showReferencia = false;
        this.currLat = 0;
        this.currLng = 0;
        return new Promise((resolve) => {
       
            navigator.geolocation.getCurrentPosition(resp => {
                this.currLat = resp.coords.latitude;
                this.currLng = resp.coords.longitude;
                firstValueFrom(this.referenciaCatastralervice.getReferenciaCatastral( this.currLng.toString(),  this.currLat.toString()))
                    .then( ( referencia ) => {                
                        if ( referencia.status === 400 ) {
                            this.errorNoData = true;
                            resolve(null);
                        } else {               
                            this.showReferencia = true;
                            this.municipio = referencia.municipio ?? '';
                            this.parcela = referencia.parcela ?? '';
                            this.poligono = referencia.poligono ?? '';
                            this.provincia = referencia.provincia ?? '';
                            resolve(referencia);
                        }
                        return;
                    }).catch( (error: string) => {
                        console.log('No se han encontrado la referencia', error);
                    
                    });
               
            },
            err => {
                alert('Necesita dar permisos de ubicación, para obtener los datos SigPAC');
                console.log('No hay permisos', err);
                
                resolve(false);
            });
        });
      
        
    }
}

export interface ReferenciaCatastral{
    data?: string[];
    municipio?: string;
    parcela?: string;
    poligono?: string;
    provincia?: string;
    status: number;
}

