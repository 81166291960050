<div>
    <br>
    <div style="width: auto; overflow: auto">
        <app-common-list-table 
            [request]="tareasApi.otras.GET" 
            [cols]="cols" 
            [filtering]="filter"
            [globalVisualTransform]="globalVisualTransform"
            [globalConditionalStyle]="globalConditionalStyle"
            [customExportCsv]="showTareasBioExportCsv"
            [actionButtonText]="canAddTareas ? btnAnadir : ''"
            (emitSearchText)="updateFooter()"
            (actionButtonClick)="add()">
            <ng-template #header>
                <div *ngIf="this.rolUser ==='responsable' || this.appName === 'benibal'" style="margin-left: 20px;">
                    <button class="mx-2 btn" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white;">
                        <i class="fa fa-download"></i>
                        Exportar listado
                    </button>
                </div>
                <div *ngIf="this.appName === 'ava'" style="margin-left: 10px; margin-right: 10px">
                    <span style="color: white; margin-top: -0px;">
                        Aplicados
                    </span>
                    <div style="font-size: 0.7em" class="btn-group">
                        <button (click)="filtrarRealizados('Si', 'aplicado');sum();"
                            [ngClass]="aplicado.selected === 'Si' ? 'btn btn-primary' : 'btn btn-light'" style="
                                            height: 28px;
                                            width: 40px;
                                            border: 1px solid grey;
                                            border-right: 1px solid grey">
                            SÍ
                        </button>
                        <button (click)="filtrarRealizados('No', 'aplicado');sum();"
                            [ngClass]="aplicado.selected === 'No' ? 'btn btn-primary' : 'btn btn-light'" style="
                                            height: 28px;
                                            width: 40px;
                                            border: 1px solid grey;
                                            border-right: 1px solid grey">
                            NO
                        </button>
                    </div>
                </div>


                <div  *ngIf="showCampaign" id="campaign">
                    <label for="" >Campaña</label>
                    <p-select [options]="years" [(ngModel)]="selectedYears" (onChange)='selectedCampaign()' ></p-select>
                </div>
                <div style="margin-left: 20px; display: flex;" *ngIf="betweenDates">
                    <div style="display: inline-flex; align-items: center;">
                        <p style="color: white; margin-left: 10px; margin-right: 10px; margin-bottom: 0;">F. Inicio</p>
                        <app-datepicker (sendFecha)="getFechaInicio($event)"></app-datepicker>
                    </div>
                    <div style="display: inline-flex; align-items: center; margin-left: 20px;">
                        <p style="color: white; margin-left: 10px; margin-right: 10px; margin-bottom: 0;">F. Fin</p>
                        <app-datepicker (sendFecha)="getFechaFin($event)"></app-datepicker>
                    </div>
                </div>
            </ng-template>
            <ng-template #thEnd>
                <th>
                    <p class="mb-0 d-flex justify-content-center">Acciones</p>
                </th>
            </ng-template>


            <ng-template #tdEnd let-data>
                <td>
                    <div class="d-flex justify-content-center align-items-center">
                        <app-action-buttons [showDuplicate]="canDuplicateTareas" (duplicateRowButtonEvent)="duplicate(data)"
                            (editRowButtonEvent)="edit(data)" (deleteRowButtonEvent)="show('delete', data)" (showRowButtonEvent)="show('info', data)">
                        </app-action-buttons>
                        <button *ngIf="showPrescripcion" class="d-flex justify-content-center align-items-center"
                            tooltipPosition="left" pTooltip="Generar receta" (click)="generarReceta(data)" style="
                                min-height: 20px;
                                min-width: 20px;
                                border: none;
                                border-radius: 3px;
                                background-color: #007b00;
                                color: white;
                                cursor: pointer;
                                margin-left: 10px;
                                ">
                            <i class="fa fa-file-pdf-o"></i>
                        </button>
                    </div>
                </td>
            </ng-template>
            <ng-template #footer let-col *ngIf="['fotoagricultura'].includes(this.appName)">
                <div style="font-size: 0.75em; text-align: left" *ngIf="(['id'].includes(col.field))">
                  TOTAL:
                </div>
                <div *ngIf="(['horas'].includes(col.field))"
                  style="font-size: 0.75em; text-align: center; font-weight: bold; padding-right: 34px">
                  {{this.totalHoras  || 0}} 
                </div>
                
    
            </ng-template>

        </app-common-list-table>
    </div>
</div>


<p-dialog 
    *ngIf="canShow['info']"
    header="Detalle del registro" 
    [(visible)]="canShow['info']" 
    (onShow)="scrollToTop()"
>
    <div style="width: 80vw !important">
        <app-form-builder [fields]="formFields" [distributionColumns]="3" [distributionOrder]="[3,4,3]" [model]="model">
        </app-form-builder>
        <app-form-builder [fields]="formFieldsObs" [distributionColumns]="1" [model]="model">
        </app-form-builder>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog>

<p-dialog 
    *ngIf="canShow['delete']"
    header="Eliminar registro" 
    [(visible)]="canShow['delete']" 
    (onShow)="scrollToTop()"
>
    <div style="width: 80vw !important">
        <app-form-builder [fields]="formFields" [distributionColumns]="3" [distributionOrder]="[3,4, 3]" [model]="model">
        </app-form-builder>
        <br>
        <app-form-builder [fields]="formFieldsObs" [distributionColumns]="1" [model]="model">
        </app-form-builder>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
            style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button (click)="deleteRegister()" [request]="deleteRequest" [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
    </div>
    <br>
</p-dialog>

<!-- VERSIÓN "AgroGEST" -->
<p-dialog *ngIf="showRecetaAgrogest" header="Impreso de la receta" [(visible)]="showReceta"
    (onShow)="scrollToTop()">

    <div *ngIf="receta" id="html2canvasDiv" class="registroDiv mx-auto" style="width: 800px;">
        <div class="mb-4 text-left">
            <img *ngIf="appName === 'agrogest'" src="./assets/logo_agrogest.png" style="width: 150px; height: auto;" alt="Logo AgroGEST"/>
            <img *ngIf="appName === 'buitech'" src="./assets/logo_buitech.png" style="width: 150px; height: auto;" alt="Logo buitech"/>
        </div>

        <h6>General</h6>
        <table class="table table-bordered table-striped table-hover table-receta">
            <thead>
                <th>Finca</th>
                <th>Sector(es)</th>
                <th>Parcela(s)</th>
                <th>Fecha</th>
                <th>Coste total</th>
            </thead>
            <tbody>
                <tr>
                    <td>{{receta.finca}}</td>
                    <td>{{receta.sector}}</td>
                    <td>{{receta.parcelas}}</td>
                    <td>{{receta.fecha}}</td>
                    <td>{{receta.coste || '-'}} €</td>
                </tr>
            </tbody>
        </table>

        <h6>Tarea</h6>
        <table class="table table-bordered table-striped table-hover table-receta">
            <thead>
                <th>Tipo</th>
                <th *ngIf="showTareasOtrasProducto">Producto</th>
                <th *ngIf="showTareasOtrasCantidad">Cantidad</th>
            </thead>
            <tbody>
                <tr>
                    <td>{{receta.tarea}}</td>
                    <td *ngIf="showTareasOtrasProducto">{{receta.producto}}</td>
                    <td *ngIf="showTareasOtrasCantidad">{{receta.cantidad || '-'}}</td>
                </tr>
            </tbody>
        </table>

        <h6>Aplicadores</h6>
        <table class="table table-bordered table-striped table-hover table-receta">
            <thead>
                <th>Maquinaria</th>
                <th>Trabajadores</th>
                <th>Horas</th>
            </thead>
            <tbody>
                <tr>
                    <td>{{receta.maquinaria}}</td>
                    <td>{{receta.trabajadores_nombres}}</td>
                    <td>{{receta.horas_totales|| '-'}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="d-flex justify-content-center">
        <button class="btn btn-dark mx-2" (click)="showReceta = false;">Cerrar</button>
        <button class="btn btn-primary mx-2" (click)="imprimirReceta();">Imprimir</button>
        <button *ngIf="showEnviarCorreo" [disabled]="tareasApi.enviarRecetaCaptura.isLoading" (click)="enviarCorreo()"
            class="btn mx-2" [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'">

            <ng-container *ngIf="!tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                <i class="fa fa-envelope"></i>
                Enviar Correo
            </ng-container>
            <ng-container *ngIf="tareasApi.enviarRecetaCaptura.isLoading && !tareasApi.enviarRecetaCaptura.hasError">
                <i class="fa fa-envelope"></i>
                Enviando...
            </ng-container>
            <ng-container *ngIf="tareasApi.enviarRecetaCaptura.hasError">
                <i class="fa fa-exclamation-triangle"></i>
                {{tareasApi.enviarReceta['error']}}
            </ng-container>
        </button>
    </div>

</p-dialog>

<a href="#" id="downloadReceta" style="visibility: hidden; position: fixed;"></a>

