// Generated by CoffeeScript 1.12.7
(function () {
  var XMLDOMErrorHandler;
  module.exports = XMLDOMErrorHandler = function () {
    function XMLDOMErrorHandler() {}
    XMLDOMErrorHandler.prototype.handleError = function (error) {
      throw new Error(error);
    };
    return XMLDOMErrorHandler;
  }();
}).call(this);