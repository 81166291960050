<!-- (onShow)="scrollToTop($event)" -->
<p-dialog [header]="'SIGPAC'" [(visible)]="showSigPac" (onShow)="scrollToTop()">
                         
  <h4 *ngIf="errorNoData">No se han podido obtener la referencia catastral </h4>
  <br>
  <div *ngIf="showReferencia">
    <div class="row">
      <div class="col-12" style="margin-bottom: 20px;">
        <div style="text-align: center;">
          <h4>Datos de referencia catastral</h4>
        </div>
      </div>
    </div>
      <div class="row">
        <div class="col-lg-6">
          <label for="">Provincia</label>
          <br>
          <input  [(ngModel)]="provincia"  pInputText type="text" />
          <label for="">Municipio</label>
          <br>
          <input  [(ngModel)]="municipio"  pInputText type="text" />
        </div>
        <div class="col-lg-6">
          <label for="">Parcela</label>
          <br>
          <input  [(ngModel)]="parcela"  pInputText type="text" />
          <label for="">Polígono</label>
          <br>
          <input  [(ngModel)]="poligono"  pInputText type="text" />
        </div>
      </div>
  </div>
</p-dialog>