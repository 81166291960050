import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RespReferencia } from '../common/utils';

@Injectable({providedIn: 'root'})

export class RecuperarReferenciaRecetaService {
    constructor(
        private http: HttpClient
    ) { }

    getRecuperarReferencia(year: number){
        return this.http.get<RespReferencia>(environment.serverUrl + 'ws/index.php?p1=tareas&p2=referencia_receta_get&year=' + year);
    }
    
}