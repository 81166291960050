<br>
<div *ngIf="!showSdiClientes"
    style="width: auto; overflow: auto">
    <app-common-list-table
            [request]="getRequest"
            [cols]="cols"
            [filtering]="filter"
            [globalConditionalStyle]="globalConditionalStyle"
            [globalVisualTransform]="globalVisualTransform"
            [actionButtonText]="canAddClients ? 'Añadir ' + clienteName : ''"
            (actionButtonClick)="add()"
            [customExportCsv]="showClientesCsv"
            >

        <ng-template #header>
            <div class="d-flex align-items-center">
                <button *ngIf="applicationType === 'cropgest' && rol === 'admin'" class="mx-2 btn" (click)="exportarClientes()" style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white">
                    <i class="fa fa-download"></i>
                    Exportar Clientes
                </button>
                <div style="margin-left: 20px;" *ngIf="appName === 'hopsteiner'">
                    <button class="mx-2 btn btn-info" (click)="downloadCsv()" style="padding: 5px; font-size: .8rem;">
                        <i class="fa fa-download"></i>
                        Exportar listado
                    </button>
                </div>
                <div *ngIf="showMostrarTodosClientes" class="d-flex justify-content-start align-content-center">
                    <p-inputSwitch
                            [style]="{marginLeft: '15px', marginRight: '10px'}"
                            [(ngModel)]="showAllClients"
                            (onChange)="changeShowAll();filterClients()">
                    </p-inputSwitch>
                    <p style="color: white; font-size: 1.2em; margin-bottom: 0">Mostrar todos</p>
                </div>    
            </div>
        </ng-template>
        <ng-template #thEnd>
            <th *ngIf="showCuadernoDeCampoPorCliente">
                <p class="d-flex justify-content-center" style="margin-bottom: 0">Cuaderno</p>
            </th>
            <th>
                <p class="d-flex justify-content-center"
                   style="margin-bottom: 0">Acciones</p>
            </th>
            <th>
                <p class="d-flex justify-content-center"
                   style="margin-bottom: 0">{{clientesName}}</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td *ngIf="showCuadernoDeCampoPorCliente">
                <div class="d-flex justify-content-center">
                    <div class="justify-content-center">
                        <button (click)="show('generate', data);" class="d-flex justify-content-center align-items-center" style="
                            height: 20px;
                            width: 85px;
                            border: none;
                            border-radius: 3px;
                            background-color: #345fdc;
                            color: white;
                            cursor: pointer;">
                            <i class="fa fa-book"></i>
                            <span>&nbsp;Generar</span>
                        </button>
                    </div>
                </div>
            </td>
            <td>
                <app-action-buttons
                        [hideEdit]="!canEditClients"
                        [hideDelete]="!canDeleteClients"
                        (editRowButtonEvent)="edit(data)"
                        (deleteRowButtonEvent)="show('delete', data)"
                        (showRowButtonEvent)="show('info', data)">
                </app-action-buttons>
            </td>
            <td>
                <div *ngIf="data.id !== currentClient?.id" class="justify-content-center">
                    <button (click)="selectClient(data)"
                         class="d-flex justify-content-center align-items-center"
                         style="
                            height: 20px;
                            width: 85px;
                            border: none;
                            border-radius: 3px;
                            background-color: rgba(24,134,57,0.53);
                            color: white;
                            cursor: pointer;">
                        <i class="fa fa-user"></i>
                        <span>&nbsp;Seleccionar</span>
                    </button>
                </div>
                <div *ngIf="data.id === currentClient?.id" class="justify-content-center">
                    <button (click)="selectClient(data)"
                         class="d-flex justify-content-center align-items-center"
                         style="
                            height: 20px;
                            width: 65px;
                            border: none;
                            border-radius: 3px;
                            background-color: #19923b;
                            color: white;
                            cursor: pointer;">
                        <i class="fa fa-check"></i>
                        <span>&nbsp;Actual</span>
                    </button>
                </div>
            </td>
        </ng-template>
    </app-common-list-table>
</div>

<p-dialog 
    *ngIf="canShow['generate']"
    header="Generar Word" 
    [(visible)]="canShow['generate']" 
    (onShow)="scrollToTop()"
>

    <div [ngStyle]="{'width.vw': '50'}" style="min-height: 40vw !important;">
        <app-form-builder
            [model]="model"
            [fields]="generarFields"
            [distributionColumns]="2">
        </app-form-builder>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('generate')">Cerrar</button>

            <a class="d-flex justify-content-center mx-2">
                <button class="btn btn-primary" (click)="generarCuaderno();">Generar</button>
            </a>

            <a *ngIf="showFincasInformeTratamientos || (user?.nombre === 'ALEJANDRO' && appName === 'melia')" class="d-flex justify-content-center mx-3">
                <button class="btn btn-secondary" (click)="generarCuaderno(true, false);">'Informe Tratamientos'</button>
            </a>

            <a *ngIf="showFincasLibroFertilizantes" class="d-flex justify-content-center mx-3" >
                <button class="btn btn-secondary" (click)="generarCuaderno(false, true);">Generar Libro Fertilizantes</button>
            </a>
        </div>
        <br>
    </div>
</p-dialog>

<p-dialog
    *ngIf="canShow['info']"
    header="Detalle del registro"
    [(visible)]="canShow['info']"
    (onShow)="scrollToTop()"
>

    <div style="width: 80vw !important">
        <app-form-builder
                [fields]="formFields"
                [distributionColumns]="3"
                [model]="model">
        </app-form-builder>
        
        <div *ngIf="showFieldsClientesEstadoReglas">
            <app-form-builder
                    [fields]="formFieldsEstadoReglas"
                    [distributionColumns]="3"
                    [model]="model">
            </app-form-builder>
        </div>
        <div *ngIf="showClientesFirma" style="width: 300px; height: 150px;">
            <h6>Firma</h6>
            <div *ngIf="model?.has_signature" class="canvas-wrap">
                <img [src]="this.pathToFirmas + '_' + this.componentName + '/' + (this.model.id || 0) + '.png'" style="width: 100%; height: auto" alt="Firma"/>
            </div>
        </div>

        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog>

<p-dialog
    *ngIf="canShow['delete']"
    header="Eliminar registro"
    [(visible)]="canShow['delete']"
    (onShow)="scrollToTop()"
>

    <div style="width: 80vw !important">
        <app-form-builder
                [fields]="formFields"
                [distributionColumns]="3"
                [model]="model">
        </app-form-builder>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button
                    [warning]="deleteWarning"
                    (click)="deleteRegister()"
                    [request]="deleteRequest"
                    [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading && deleteWarning"
           style="color: red; text-align: center">
            {{deleteWarningMessage}}
        </p>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
           style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>

</p-dialog>

<!-- INTEGRACIÓN CON SDI -->
<div *ngIf="showSdiClientes"
    style="width: auto; overflow: auto">
    <app-common-list-table
        [request]="getRequestSdi"
        [cols]="colsSdi">

        <ng-template #thEnd>
            <th>
              <p class="d-flex justify-content-center"
                 style="margin-bottom: 0">Acciones</p>
            </th>
          </ng-template>
      
          <ng-template #tdEnd let-data>
            <td>
              <app-action-buttons
                (showRowButtonEvent)="show('infoSdi', data)"
                [hideEdit]="true"
                [hideDelete]="true">
              </app-action-buttons>
            </td>
          </ng-template>
    </app-common-list-table>
</div>

<p-dialog
    *ngIf="canShow['infoSdi']"
    header="Detalle del registro"
    [(visible)]="canShow['infoSdi']"
    (onShow)="scrollToTop()"
>

    <div style="width: 80vw !important">
        <app-form-builder
            [fields]="formFieldsSdi"
            [distributionColumns]="3"
            [model]="model">
        </app-form-builder>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('infoSdi')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog>