// Generated by CoffeeScript 1.12.7
(function () {
  var NodeType, WriterState, XMLDOMImplementation, XMLDocument, XMLDocumentCB, XMLStreamWriter, XMLStringWriter, assign, isFunction, ref;
  ref = require('./Utility'), assign = ref.assign, isFunction = ref.isFunction;
  XMLDOMImplementation = require('./XMLDOMImplementation');
  XMLDocument = require('./XMLDocument');
  XMLDocumentCB = require('./XMLDocumentCB');
  XMLStringWriter = require('./XMLStringWriter');
  XMLStreamWriter = require('./XMLStreamWriter');
  NodeType = require('./NodeType');
  WriterState = require('./WriterState');
  module.exports.create = function (name, xmldec, doctype, options) {
    var doc, root;
    if (name == null) {
      throw new Error("Root element needs a name.");
    }
    options = assign({}, xmldec, doctype, options);
    doc = new XMLDocument(options);
    root = doc.element(name);
    if (!options.headless) {
      doc.declaration(options);
      if (options.pubID != null || options.sysID != null) {
        doc.dtd(options);
      }
    }
    return root;
  };
  module.exports.begin = function (options, onData, onEnd) {
    var ref1;
    if (isFunction(options)) {
      ref1 = [options, onData], onData = ref1[0], onEnd = ref1[1];
      options = {};
    }
    if (onData) {
      return new XMLDocumentCB(options, onData, onEnd);
    } else {
      return new XMLDocument(options);
    }
  };
  module.exports.stringWriter = function (options) {
    return new XMLStringWriter(options);
  };
  module.exports.streamWriter = function (stream, options) {
    return new XMLStreamWriter(stream, options);
  };
  module.exports.implementation = new XMLDOMImplementation();
  module.exports.nodeType = NodeType;
  module.exports.writerState = WriterState;
}).call(this);