import {Filtering} from '../../service/filtering/filtering';
import {RequestHandler} from '../../service/OffService/request-handler';

export function baseSentence(data: any[], field: any, noPlaceholder?: boolean): any[] {
    return (noPlaceholder ? [] : [{label: '...', value: null}])
        .concat(
            (data instanceof Array ? data : [])
                .filter(it => it instanceof Object)
                .filter(field instanceof Array
                    ? () => true
                    : () => true//Filtering.distinctBy.bind({field: field})
                )
                .map(it => ({
                    label: field && field['or']
                        ? it[field['or'][0]] ? it[field['or'][0]] : it[field['or'][1]]
                        : field instanceof Array
                            ? field.map(f => it[f]).join(' ')
                            : field instanceof Function
                                ? field(it)
                                : it[field as string],
                    value: it
                }))
                .sort(
                    Filtering.sort('label', 1)
                )
        );
}

export function filterSentence(data: any[], field: string): any[] {
    return [{label: '...', value: null}]
        .concat(
            (data instanceof Array ? data : [])
                .filter(it => it instanceof Object)
                .sort(Filtering.sort(field, 1))
                .map(it => ({label: it[field], value: it}))
        );
}


export function baseRequest<T>(request: RequestHandler<T>, params?: any): Promise<any> {
    return new Promise((resolve) => {
        request.safePerform(params);
        request.response(data => {
            resolve(data);
            request.unsuscribe();
        });
    });
}

export function list(...values: any[]): TypeAheadInterface {
    return {
        selected: null,
        filtered:
            (values instanceof Array && values.length > 0)
                ? (Array.prototype).concat(
                    values.map(it => (it instanceof Array)
                        ? ({label: it[0], value: it[1]})
                        : ({label: it, value: it})
                    )
                ) : [{label: '...', value: null}],
        values: []
    };
}

export function filterList(...values: any[]): TypeAheadInterface {
    return {
        selected: null,
        filtered: [],
        values:
            (values instanceof Array && values.length > 0)
                ? (Array.prototype).concat(
                    values.map(it => (it instanceof Array)
                        ? ({label: it[0], value: it[1]})
                        : ({label: it, value: it})
                    )
                ) : [{label: 'Cargando...', value: null}]
    };
}

export function build<T>(
    request: RequestHandler<T>, 
    object: TypeAheadInterface | TypeAheadInterface[], 
    label: any, 
    filtrable?: boolean, 
    noPlaceholder?: boolean
): (resolve: (resolved: boolean) => void) => void {
    return (resolve: (resolved: boolean) => void) => {
        baseRequest(request)
            .then(data => baseSentence(data, label, noPlaceholder))
            .then(data => {
                if (object instanceof Array) {
                    object.filter(it => it)
                        .forEach(obj => {
                            obj[filtrable ? 'values' : 'filtered'] = (data || []).slice();
                        });
                } else {
                    object[filtrable ? 'values' : 'filtered'] = data;
                }
                return;
            })
            .then(() => {
                resolve(true);
                return;
            }).catch((err: string) => alert('Error: ' + err));
    };
}

export function buildParams<T>(
    request: RequestHandler<T>, 
    params: any, 
    object: TypeAheadInterface | TypeAheadInterface[], 
    label: string | string[], 
    filtrable?: boolean, 
    noPlaceholder?: boolean
): (resolve: (resolved: boolean) => void) => void {
    return (resolve: (resolved: boolean) => void) => {
        baseRequest(request, params)
            .then(data => baseSentence(data, label, noPlaceholder))
            .then(data => {
                if (object instanceof Array) {
                    object.filter(it => it)
                        .forEach(obj => {
                            obj[filtrable ? 'values' : 'filtered'] = (data || []).slice();
                        });
                } else {
                    object[filtrable ? 'values' : 'filtered'] = data;
                }
                return;
            })
            .then(() => {
                resolve(true);
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
    };
}

export function stack(queue: any[], callback?: Function): Promise<any> {
    return new Promise(queue[0] || ((resolve) => resolve(null)))
        .then((next) => {
            if (callback) {
                callback();
            }

            if (next === true) {
                (queue || []).shift();
                if ((queue || []).length > 0) {
                    return stack(queue, callback);
                } else {
                    return new Promise(resolve => resolve(next)).then(() => next);
                }
            } else if (next === false) {
                return new Promise(resolve => resolve(next)).then(() => next);
            } else {
                return new Promise(resolve => resolve(next)).then(() => true);
            }
        });
}

export function pool(queue: any[], callback?: Function): Promise<any> {
    return new Promise((resolve) => {
        const expected = (queue || []).length;
        let processed = 0;

        const checkHandlers = () => {
            if (callback) {
                callback();
            }
            processed++;
            if (expected === processed) {
                resolve(processed);
            }
        };

        (queue || []).forEach(rq => {
            new Promise(rq).then(checkHandlers).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        });
    });
}

export function promiseMeanful(name: string, action: string): Promise<unknown> {
    console.log(name);
    console.log(action);
    return new Promise((resolve) => resolve);
}

export function promise(): Promise<unknown> {
    return new Promise((resolve) => resolve);
}

interface TypeAheadInterface {
    selected: any;
    values: any[];
    filtered: any[];
    filterCallback?: Filtering<any>;
}

