import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FileUploadAnswer } from '../../utils';
import { catchError, map, of } from 'rxjs';


@Component({
    selector: 'app-upload-images',
    templateUrl: './upload-images.component.html',
    styleUrls: ['./upload-images.component.scss']
})
export class UploadImagesComponent<T> {

    @Input() model = {} as T;

    @Input() folder = '';
    @Input() nombre_imagen = '';
    @Input() tipo = '';

    @Output() sendImageName: EventEmitter<string> = new EventEmitter<string>();

    public adjunto: string | ArrayBuffer | null | undefined = null;
    public imagen = '';

    public modelImagen: 
        { 
            [key: string]: string | null, 
            imagen: string, 
            image_after: string, 
            adjunto: string | null
        } = { 
            imagen: '', 
            image_after: '',
            adjunto: null
        };

    constructor(
        private http: HttpClient
    ) { 
        this.modelImagen = this.model as { [key: string]: string | null, imagen: string, image_after: string, adjunto: string | null};
    }

    // Adjuntos
    public readUrl(input: { click: () => void; files: Blob[]; }) {
        input.click();
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = (e) => {
                this.adjunto = e.target?.result ?? '';
            };
            reader.readAsDataURL(input.files[0]);
            const r = this.http.post<FileUploadAnswer>(
                environment.serverUrl + 'ws/upload/upload_file_generic.php', 
                this.adjunto
            ).subscribe(res => {
                const file = res;
                if ( this.nombre_imagen == null ) {
                    this.modelImagen.imagen = file.fileName;
                } else {
                    this.modelImagen[this.nombre_imagen] = file.fileName;
                }
        
                this.imagen = file.fileName;
                r.unsubscribe();
            });
        }
    }

    public fileChange(event: File) {
        const fileList: File = event;
        if (fileList) {
            const file: File = fileList;
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            formData.append('folder', this.folder );
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'multipart/form-data');
            headers.append('Accept', 'application/json');

            const name: string[] = file.name.split('.');
            const ext = name[name.length - 1];
            const accepted = ['gif', 'jpeg', 'jpg', 'jiff', 'png', 'svg', 'tiff'];
            let cond = false;

            for (const e of accepted) {
                if (e === ext) {
                    cond = true;
                }
            }

            if (cond) {
                this.http.post<FileUploadAnswer>(
                    `${environment.serverUrl}ws/upload/upload_file_generic.php`, 
                    formData, 
                    { headers: headers }
                ).pipe(
                    map(data => {
                        const res = data;
   
                        if ( this.nombre_imagen == null ) {
                            this.modelImagen.imagen = res.fileName;
                        } else {
                            (this.model as Record<string,string>)[this.nombre_imagen] = res.fileName;
                        }
          
                        this.imagen = res.fileName;
                        this.sendImageName.emit(res.fileName);
         
          
                    }),catchError(error => {
                        console.log(error);
                        return of(null);
                    })
                ).subscribe();
            } else {
                alert('Formato de imágen no válido.');
                this.modelImagen.adjunto = null;
            }
        }
    }

    public verImagen() {
        const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
        if ( this.nombre_imagen == null ) {
            myWindow?.location.assign(environment.serverUrl + 'mto/'  + this.folder + '/' + this.modelImagen.imagen);
        } else {
            myWindow?.location.assign(environment.serverUrl + 
                'mto/'  + 
                this.folder + 
                '/' + 
                this.modelImagen[this.nombre_imagen]
            );
        }
    
        myWindow?.focus();
    }
}
