import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { StorageManager } from 'src/app/common/storage-manager.class';

import type { UrlTree } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ShowMapasVentuskyGuardService {
    public nombre = StorageManager.getUser().nombre;
    public apellidos = StorageManager.getUser().apellidos ? StorageManager.getUser().apellidos : '';
    public appName = environment.appName; 

    constructor(public router: Router) {}

    canActivate(): boolean | UrlTree {
        if ( (this.appName !== 'manezylozano' && this.nombre.toLowerCase() !== 'quique' && this.apellidos.toLowerCase() !== 'lozano') && 
            !environment.features.showVentusky ) {
            return this.router.parseUrl('/login');
        }

        return true;
    }
}