import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { StorageManager } from '../../common/storage-manager.class';

import type { UrlTree } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ShowUsuariosGuardService {

    constructor(public router: Router) {}

    canActivate(): boolean | UrlTree {
        if (!environment.features.showUsuarios || (StorageManager.getUser().rol !== 'admin')) {
            return this.router.parseUrl('/login');
        }

        return true;
    }
}
