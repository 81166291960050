import {Injectable} from '@angular/core';
import {RequestHandler} from 'src/app/service/OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { RecogidaModel } from 'src/app/models/recogida.model';

@Injectable({
    providedIn: 'root'
})
export class RecogidasApiService {

    public recogidasGET = new RequestHandler<RecogidaModel[]>();
    public recogidasPUT = new RequestHandler<RecogidaModel[]>();
    public recogidasPOST = new RequestHandler<RecogidaModel[]>();
    public recogidasDELETE = new RequestHandler<RecogidaModel>();

    public plazoSeguridadGET = new RequestHandler<ProductosModel[]>();

    constructor(
        private syncService: SyncService<RecogidaModel[]>,
        private syncServiceDelete: SyncService<RecogidaModel>,
        private api: ApiRequestService<RecogidaModel[]>,
        private apiDelete: ApiRequestService<RecogidaModel>,
        private apiProducto: ApiRequestService<ProductosModel[]>
    ) {

        this.defineRecogidasGET();
        this.defineRecogidasPUT();
        this.defineRecogidasPOST();
        this.defineRecogidasDELETE();
        this.definePlazoSeguridadGET();
    }

    private defineRecogidasGET() {
        this.recogidasGET
            .fetch(HttpRequestType.GET, 'recogidas/get');

        this.api
            .registerRequest(this.recogidasGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.recogidasGET));
    }

    private defineRecogidasPUT() {
        this.recogidasPUT
            .fetch(HttpRequestType.PUT, 'generic/recogidas');

        this.api
            .registerRequest(this.recogidasPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.recogidasPUT));
    }

    private defineRecogidasPOST() {
        this.recogidasPOST
            .fetch(HttpRequestType.POST, 'generic/recogidas');

        this.api
            .registerRequest(this.recogidasPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.recogidasPOST));
    }

    private defineRecogidasDELETE() {
        this.recogidasDELETE
            .fetch(HttpRequestType.DELETE, 'generic/recogidas');

        this.apiDelete
            .registerRequest(this.recogidasDELETE);

        this.syncServiceDelete
            .register(this.syncServiceDelete.baseSyncPolicy(this.recogidasDELETE));
    }

    private definePlazoSeguridadGET() {
        this.plazoSeguridadGET.fetch(HttpRequestType.GET, 'recogidas/plazo_seguridad_get');
        this.apiProducto.registerRequest(this.plazoSeguridadGET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.recogidasGET));
    }
}


