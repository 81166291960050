<br>
<p-panel>
  <div class="d-flex justify-content-left align-content-center">
    <div>
      <h4 *ngIf="flag === 'edit'">{{headerTitleWhenEdit}}</h4>
      <h4 *ngIf="flag === 'new'">{{headerTitleWhenNew}}</h4>
    </div>
    <div style="display: flex; max-width: 40%;gap:20px;  position: absolute; right: 2rem;">
      <div>
        <input type="file" #adjuntarFoto (change)="fileChange($event)" style="display: none;" accept="image/*"/>
        <button *ngIf="model?.adjunto" class="btn btn-link" (click)="verAdjunto()">Ver</button>
        <input type="button" class="btn btn-success"
          style='border-radius: 5px;
            font-size: 1em;
            padding: 6px 12px;
            font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif;'
          value="Añadir foto" (click)="readUrl(adjuntarFoto)" />
      </div>
    </div>
  </div>
  <div id='responsive' class="d-flex justify-content-between">
    <app-form-builder style="width: 30%"
        [model]="model" 
        [distributionColumns]="1" 
        [fields]="formFieldsLeft" 
        (formChanges)="formChanges($event)">
    </app-form-builder>
    <app-form-builder style="width: 40%; margin-left: 5%; margin-right: 5%"
        [model]="model" 
        [distributionColumns]="1" 
        [fields]="formFieldsMiddle" 
        (formChanges)="formChanges($event)">
    </app-form-builder>
    <app-form-builder
        [model]="model" 
        [distributionColumns]="1" 
        [fields]="formFieldsRight" 
        (formChanges)="formChanges($event)">
    </app-form-builder>
  </div>
  <br>

  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button 
            [lockOnSuccess]="true" 
            (click)="submit()" 
            [mode]="requestButtonType" 
            [request]="request">
    </app-request-button>
  </div>
</p-panel>
<br>