<p-panel>

    <h4>Datos del Producto Bio</h4>
    <div class="form-row">
        <div class="form-group col-sm-12">
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="3"
                    (formChanges)="formChanges($event)"
                    [fields]="topFormFields">
            </app-form-builder>
        </div>
    </div>

    <br>

    <div class="d-flex justify-content-center">
        <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>
