import {Injectable} from '@angular/core';
import {RequestHandler} from './request-handler';
import {BaseApi} from '../api/base-api.class';
import { HttpClient } from '@angular/common/http';
import Dexie from 'dexie';
import {StorageManager} from '../../common/storage-manager.class';
import { environment } from 'src/environments/environment';
import { UserModel } from 'src/app/models/usuarios/user.model';
import { ParamsInterface } from './params.interface';
import { BaseResponseModel } from 'src/app/models/base-response.model';
import { ClienteModel } from 'src/app/models/cliente.model';

export enum HttpRequestType {
    GET, POST, PUT, DELETE
}
@Injectable({
    providedIn: 'root'
})

export class ApiRequestService<T> extends BaseApi<T> {

    public appName = environment.appName;
    public hasClients = environment.features.hasClients;
    public filterAplicadoresByClient = environment.features.filterAplicadoresByClient;
    public userCanSeeAllRecords = environment.features.userCanSeeAllRecords;


    constructor(http: HttpClient) {
        super(http);

        this.proxyCheck = (type: HttpRequestType, endpoint: string) => {
            if ((StorageManager.getUser() || {}).rol === 'demo') {
                if (type !== HttpRequestType.GET && !(endpoint === 'mg/all')) {
                    alert('La versión DEMO no permite: Crear, Editar o Eliminar');
                    return false;
                }
            }

            return true;
        };

        this.inject.params = {
            'id_cliente': (endpoint: string, params?: ParamsInterface) => {
                const included = this.toUrl(
                    'fincas/get',
                    'sectores/get',
                    'parcelas/get',
                    'ventas/ventas',
                    'tareas/fitosanitarios_get',
                    'tareas/fertilizantes_get',
                    'tareas/tareas_bio_get',
                    'costes/costes_productos_get',
                    'costes/costes_tareas_otras_get',

                );

                if (!this.hasClients) {
                    return null;
                }
                if (this.filterAplicadoresByClient) {
                    included.push('trabajadores/trabajadores', 'trabajadores/maquinarias');
                }
                return included.some(it => endpoint.includes(it))
                    ? !params || !params['id_cliente']
                        ? (StorageManager.getClient() || {}).id
                        : null
                    : null;
            }
        };

        this.inject.payload = {
            'id_usuario': (endpoint: string, data: Record<string,string>) => {
                const result
                    = (!endpoint.includes('visitas')
                    && !endpoint.includes('citas')
                    && !endpoint.includes('usuarios')
                    && !endpoint.includes('analisis')
                    && !data['id_usuario'])
                        ? (StorageManager.getUser() || {} as UserModel).id
                        : null;
                return result;
            },
            'id_cliente': (endpoint: string, data: Record<string,string>) => {
                if (!this.hasClients) {
                    return null;
                }
                const cliente = data as T;
                return (!endpoint.includes('clientes')
                    && !endpoint.includes('visitas')
                    && !endpoint.includes('analisis')
                    && !endpoint.includes('usuarios')
                    && !data['id_cliente'])
                    ? (StorageManager.getClient() || {}).id
                    : data['id'] === (StorageManager.getClient() || {}).id
                        ? StorageManager.saveClient(cliente as ClienteModel)
                        : null;
            },
        };
    }


    public registerRequest(request: RequestHandler<T>, table?: Dexie.Table<T, number>) {
        let isOnline;

        this.promising(request);

        request.onPerform(() => {
            const data = request.getRequestData();

            if (!request.value && table) {
                table.toArray()
                    .then(value => {
                        if (value) {
                            request.emitResponse(value as T);
                        }
                        return;
                    }).catch (e => {
                        console.log('catch en table.toArray(): ' + e);
                    }
                    );
            }

            if (!navigator) { isOnline = true; } else { isOnline = navigator.onLine; }

            if (isOnline) {
                request.emitFetchStatus('syncing');

                if (data.type === HttpRequestType.PUT) {
                    data.endpoint += '/' + data.params.id;
                }

                if (data.type === HttpRequestType.DELETE) {
                    data.endpoint += '/' + data.params.id;
                }

                this.fromType(data.type)(data.endpoint, data.params)
                    .then(this.getEntityDataList.bind(this))
                    .then((value) => {
                        if (request.isCancelable) {
                            if (data.validationNumber === request.validationNumber) {
                                request.emitResponse(value as T);
                            }
                        } else {
                            request.emitResponse(value as T);
                        }

                        if (table) {
                            table.bulkPut(value);
                        }
                        return;
                    })
                    .then(() => {
                        request.emitFetchStatus('finish');
                        return;
                    })
                    .catch(err => {
                        request.emitFetchStatus('error');
                        throw err;
                    });
            }

        });
    }

    private toUrl(...urls: string[]): string[] {
        return urls
            .filter(it => it)
            .map(it => '?p1=' + it.split('/')[0] + '&p2=' + it.split('/')[1]);
    }


    private promising(request: RequestHandler<T>) {
        request.promise = () => new Promise((resolve, reject) => {
            const data = request.getRequestData();

            request.emitFetchStatus('syncing');

            if (data.type === HttpRequestType.PUT) {
                data.endpoint += '/' + data.params.id;
            }

            if (data.type === HttpRequestType.DELETE) {
                data.endpoint += '/' + data.params.id;
            }

            this.fromType(data.type)(data.endpoint, data.params)
                .then(this.getEntityDataList.bind(this))
                .then((value) => {
                    request.emitResponse(value as T);
                    resolve(value as T);
                    return;
                })
                .then(() => {
                    request.emitFetchStatus('finish');
                    return;
                })
                .catch(err => {
                    request.emitFetchStatus('error');
                    reject(err);
                    throw err;
                });
        });
    }

    private fromType(type: HttpRequestType): (endpoint: string, data?: ParamsInterface) => Promise<BaseResponseModel<T>> {
        switch (type) {
        case HttpRequestType.GET:
            return this.get.bind(this);
        case HttpRequestType.POST:
            return this.post.bind(this);
        case HttpRequestType.PUT:
            return this.put.bind(this);
        case HttpRequestType.DELETE:
            return this.delete.bind(this);
        }
    }
}