import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {StorageManager} from '../../common/storage-manager.class';

import type { UrlTree } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ShowRetoGuardService {

    constructor(public router: Router) {}

    canActivate(): boolean | UrlTree {
    
        if (environment.appName !== 'melia' && StorageManager.getUser().nombre !== 'agrogibe' && !environment.features.showReto) {
            return this.router.parseUrl('/login');        
        }

        return true;
    }
}
