import { Component } from '@angular/core';
import { InputType } from 'src/app/common/components/form-builder/form-builder.component';
import { list, stack, build } from 'src/app/common/classes/request-builder';
import { TrabajadoresApiService } from 'src/app/service/api/trabajadores-api.service';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { BaseView } from 'src/app/view/base-view';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { TareasApiService } from 'src/app/service/api/tareas-api.service';
import SignaturePad from 'signature_pad';
import { AppFormRequest } from 'src/app/view/app-common/form-request/app-form-request';
import { ProductosApiService } from 'src/app/service/api/productos-api';
import { Utils } from 'src/app/common/utils';
import { StockApiService } from 'src/app/service/api/stock-api.service';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import type { OnInit } from '@angular/core';
import type { ItemInterface, TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';
import { RiegoModel, TableRiego } from 'src/app/models/tareas/riego.model';
import { StockRiegoInterface } from './stock-riego.interface';
import { StockageModel } from 'src/app/models/stock/stokage.model';

const TRUTHY_VALUES = [1, '1', true, 'true'];
const FORBIDDEN_VALUES = [0, '0', null, 'null', Infinity, 'Infinity', false, 'false', '', undefined, 'undefined', NaN, 'NaN'];
@Component({
    selector: 'app-control-riego',
    templateUrl: './control-riego.component.html',
    styleUrls: ['./control-riego.component.scss']
})
export class ControlRiegoComponent extends BaseView<RiegoModel> implements OnInit {

    // Placeholder
    public alwaysTrue: boolean[] = [];
    public alwaysFalse: boolean[] = [];

    public modelResumen: Record<string, RiegoModel | Record<string, number>> = {};
    public formRequest = new AppFormRequest<RiegoModel>();
    public entradas: StockageModel[] = [];
    public salidas: StockRiegoInterface = {
        real: {},
        teorico: {}
    };
    public fincas = list();
    public sectores = list();
    public productos = list();
    public trabajadores = list();
    public anyosList = list('2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030');
    public mesesList = list(['Enero', '1'], ['Febrero', '2'], ['Marzo', '3'], ['Abril', '4'],
        ['Mayo', '5'], ['Junio', '6'], ['Julio', '7'], ['Agosto', '8'],
        ['Septiembre', '9'], ['Octubre', '10'], ['Noviembre', '11'], ['Diciembre', '12']);
    public diasArray: number[] = [];
    public mesesArray: {label: string, index: string, value: string}[] = [];

    public formFieldsFinca: ItemInterface<object>[] = [
        {
            hasLabel: false,
            field: 'id_finca',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.fincas,
            valuePrimaryKey: 'id'
        }
    ];
    public formFieldsOperario: ItemInterface<object>[] = [
        {
            hasLabel: false,
            field: 'id_trabajador',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.trabajadores,
            valuePrimaryKey: 'id'
        }
    ];
    public formFieldsFecha: ItemInterface<object>[] = [
        {
            field: 'anyo',
            hasLabel: false,
            inputType: {type: InputType.DROPDOWN},
            values: this.anyosList
        },
        {
            field: 'mes',
            hasLabel: false,
            inputType: {type: InputType.DROPDOWN},
            values: this.mesesList
        }
    ];

    public stockage: StockRiegoInterface = {
        real: {},
        teorico: {},
        day: '',
        month: '',
        year: 0
    };
    public columnCount = 2; // Columnas fijas, éstas no cambiarán nunca
    public columnCountArray = Array(2);
    public columnCountResumen = 3; // Columnas fijas, éstas no cambiarán nunca
    public columnCountResumenArray = Array(3);
    public sectoresCount = 0;
    public productosCount = 0;
    public isShowingResumen = false;
    public isShowingStockage = false;  
    public isPrinting = false;  
    public formIsDirty = false;

    constructor(
        dashboardEvents: DashboardService,
        private fincasApi: FincasApiService,
        private productosApi: ProductosApiService,
        private trabajadoresApi: TrabajadoresApiService,
        private tareasApi: TareasApiService,
        private stockApi: StockApiService
    ) {
        super(dashboardEvents, tareasApi.controlRiego.GET, tareasApi.controlRiego.DELETE);
    }

    ngOnInit() {
        stack([
            build(this.fincasApi.fincasGET, this.fincas, 'nombre', false),
            build(this.fincasApi.sectoresGET, this.sectores, 'nombre', true, true),
            build(this.productosApi.productos.GET, this.productos, 'nombre', true, true),
            build(this.trabajadoresApi.trabajadoresGET, this.trabajadores, 'nombre', false)
        ]).then(() => {
            this.fincas.filtered = (this.fincas.filtered || []).filter(it => it && it.label);
            this.formRequest.update();
            return;
        }).then(() => {
            this.getColumnCount();
            this.getDiasArray();
            this.initModel();
            return;
        }).catch (e => {
            console.log('catch en stack: ' + e);
        }
        );
    }

    public getMesesArray() {
        this.mesesArray = JSON.parse(JSON.stringify(this.mesesList.filtered.map(it => {
            it.index = this.model.anyo + '_' + it.value;
            return it;
        })));

        // Diferencia de índices entre el mes seleccionado y Enero (para mover todos los meses):
        // Esto es así porque el valor de cada mes es su posición en el array + 1
        const indexDiff = parseInt(this.model.mes ?? '', 10) - 1;

        // Mueve todos los meses hasta que el primero sea el seleccionado.
        for (let i = 0; i < indexDiff; i++) {
            const m = this.mesesArray.shift();

            if (m) {
                m.index = ((this.model.anyo || 0) + 1) + '_' + m.value; 
                this.mesesArray.push(m);
            }
        }
    }

    public clearSigPad(sigpad: SignaturePad) {
        sigpad.clear();
    }

    public formChanges(tag: string) {
        if (!['mes', 'anyo', 'id_finca'].includes(tag)) {
            this.formIsDirty = true;
        }

        if (['mes', 'anyo'].includes(tag)) {
            this.getDiasArray();
        }
        if (tag === 'id_finca') {
            const idsProductos = (this.fincas.selected ? this.fincas.selected.ids_productos : '').split(';');
            this.sectores.filtered = this.sectores.values.filter(it => it.value && (it.value.id_finca === this.model.id_finca));
            this.productos.filtered = this.productos.values.filter(it => it.value && idsProductos.includes(it.value.id));

            this.getColumnCount();
        }

        if (['mes', 'anyo', 'id_finca'].includes(tag) && this.model.id_finca) {
            this.tareasApi.controlRiego.GET.toPromise({mes: this.model.mes, anyo: this.model.anyo, id_finca: this.model.id_finca} as never)
                .then(res => {

                    if (res && res[0]) {
                        this.model = res[0];
                    } else {
                        const modelOld = {mes: this.model.mes ?? '', anyo: this.model.anyo ?? 0, id_finca: this.model.id_finca ?? ''};
                        this.model = modelOld;
                        this.formIsDirty = false;
                    }
                    this.getColumnCount();
                    this.initModel();
                    return;
                }).catch (e => {
                    console.log('catch en controlRiego: ' + e);
                }
                );
        }

        if (tag.includes('sector')) {
            const parts = tag.split('_');
            const indexLinea = +(parts[1] || 0);
            const indexSector = parts[3];

            if (indexLinea) {
                const patt = new RegExp('^\\d:\\d\\d$');
                if (this.model.table?.[indexLinea] && 
                    !patt.test(this.model.table[indexLinea]?.['sector_' + indexSector]?.toString() ?? '')) {
                    //this.model.table[indexLinea]['sector_' + indexSector] = '0:00';
                }
            }        
        }

        if (tag.includes('producto')) {
            const parts = tag.split('_');            
            const indexLinea = +(parts[1] || 0);
            const indexProducto = parts[3];

            if (indexLinea) {
                const patt = new RegExp('^\\d+$');
                if (this.model.table?.[indexLinea] &&
                    !patt.test(this.model.table[indexLinea]?.['producto_' + indexProducto]?.toString() ?? '')) {
                    //this.model.table[indexLinea]['producto_' + indexProducto] = 0;
                }
    
            }        }

        if (tag.includes('lluvia')) {
            const parts = tag.split('_');
            const indexLinea = +(parts[1] || 0);

            if (indexLinea) {
                const patt = new RegExp('^\\d+$');
                if (this.model.table?.[indexLinea] && 
                    !patt.test(this.model.table[indexLinea]?.['lluvia']?.toString() ?? '')) {
                    //this.model.table[indexLinea]['lluvia'] = '';
                }
            }
        }

    }

    public async toggleResumen() {
        this.isShowingResumen = !this.isShowingResumen;

        if (this.isShowingResumen) {
            this.getMesesArray();

            this.modelResumen[0] = {
                goterosTotal: 0,
                caudalTotal: 0,
                totalM3: 0,
                totalLluvia: 0
            };

            (this.fincas.selected.sectores || []).forEach((sector: { goteros: string; caudal_gotero: string; }) => {
                if (this.modelResumen[0]?.goterosTotal !== undefined && this.modelResumen[0].caudalTotal !== undefined ) {
                    this.modelResumen[0].goterosTotal += parseInt(sector.goteros, 10) || 0;
                    this.modelResumen[0].caudalTotal += parseInt(sector.caudal_gotero, 10) || 0;
                }
            });

            const mesInicio = moment(this.model.mes, 'M').format('M');
            const mesFin = moment(this.model.mes, 'M').subtract(1, 'month').format('M');

            await this.tareasApi.resumenConsumosAguaAbonos.GET.toPromise({
                id_finca: this.model.id_finca,
                anyo1: this.model.anyo,
                anyo2: (this.model.anyo || 0) + 1,
                mes_inicio: mesInicio,
                mes_fin: mesFin
            } as never)
                .then(res => {
                    res.forEach((row: RiegoModel) => {
                        // Índice del mes y modelo de datos
                        const idx = row.anyo + '_' + row.mes;
                        let resumen = this.modelResumen[idx];
                        if (!resumen) {
                            resumen = {
                                m3: 0,
                                lluvia: 0,
                                horasTotal: 0
                            };
                        }

                        // Horas de riego por sector (SÓLO PARA ACTIVOS)
                        if (TRUTHY_VALUES.includes(row.show_sectores ?? '') && resumen.m3 !== undefined) {
                            resumen.m3 += row.m3 ?? 0;
                        }


                        if (resumen.lluvia !== undefined) {
                            // Litros de lluvia recogidos
                            resumen.lluvia += row.lluvia || 0;
                        }

                        // Productos (SÓLO PARA ACTIVOS)
                        if (TRUTHY_VALUES.includes(row.show_productos ?? '')) {
                            const idsProductos = (row.ids_productos || '').split(';');
                            const cantidadesProductos = (row.cantidades_productos || '').split(';');
  
                            (this.productos.filtered || []).map(it => it.value).forEach(prod => {
                                // Cantidades de producto
                                let cantidad = parseFloat(cantidadesProductos[idsProductos.indexOf(prod.id)] || '0');
                                if (FORBIDDEN_VALUES.includes(cantidad)) {
                                    cantidad = 0;
                                }
  
                                if (resumen && resumen?.['producto_' + prod.id] !== undefined) {
                                    resumen['producto_' + prod.id] = 0;
                                    resumen['producto_' + prod.id] += cantidad.toString();
                                    
                                }
                                if (this.modelResumen && !this.modelResumen['producto_total_' + prod.id]) {
                                    this.modelResumen['producto_total_' + prod.id] = {};
                                    //this.modelResumen['producto_total_' + prod.id] += cantidad.toString();
                                }


                                // Compuestos (N, P, K, Ca)
                                const compuestos = {
                                    N:  FORBIDDEN_VALUES.includes(parseFloat(prod.N))  ? 0 : parseFloat(prod.N),
                                    P:  FORBIDDEN_VALUES.includes(parseFloat(prod.P))  ? 0 : parseFloat(prod.P),
                                    K:  FORBIDDEN_VALUES.includes(parseFloat(prod.K))  ? 0 : parseFloat(prod.K),
                                    Ca: FORBIDDEN_VALUES.includes(parseFloat(prod.Ca)) ? 0 : parseFloat(prod.Ca)
                                };

                                for (const key of Object.keys(compuestos)) {
                                    if (!this.modelResumen['total' + key]) {
                                        this.modelResumen['total' + key] = {};
                                    }
                                    //this.modelResumen['total' + key] += (cantidad * (compuestos[key] / 100));
                                }
                            });
                        }
                    });

                    for (const mes of this.mesesArray) {
                        if (this.modelResumen[mes.index] && this.modelResumen[0]?.totalM3 && this.modelResumen[0].totalLluvia) {
                            this.modelResumen[0].totalM3 += this.modelResumen[mes.index]?.m3 ?? 0;
                            this.modelResumen[0].totalLluvia += this.modelResumen[mes.index]?.lluvia ?? 0;
                        }
                    }

                    const supTotal = parseFloat(this.fincas.selected.superficie || '0');
                    if (this.modelResumen[0]) {
                        this.modelResumen[0].totalN = +Utils.replaceNonNumericValues((this.modelResumen[0]?.totalN || 0));
                        this.modelResumen[0].totalP = +Utils.replaceNonNumericValues((this.modelResumen[0]?.totalP || 0));
                        this.modelResumen[0].totalK = +Utils.replaceNonNumericValues((this.modelResumen[0]?.totalK || 0));
                        this.modelResumen[0].totalCa = +Utils.replaceNonNumericValues((this.modelResumen[0]?.totalCa || 0));
                        this.modelResumen[0].totalM3Anyo = +Utils.replaceNonNumericValues((this.modelResumen[0]?.totalM3 || 0) / supTotal);
                        this.modelResumen[0].totalAnyoN = +Utils.replaceNonNumericValues((this.modelResumen[0]?.totalN || 0) / supTotal);
                        this.modelResumen[0].totalAnyoP = +Utils.replaceNonNumericValues((this.modelResumen[0]?.totalP || 0) / supTotal);
                        this.modelResumen[0].totalAnyoK = +Utils.replaceNonNumericValues((this.modelResumen[0]?.totalK || 0) / supTotal);
                        this.modelResumen[0].totalAnyoCa = +Utils.replaceNonNumericValues((this.modelResumen[0]?.totalCa || 0) / supTotal);
                    }
                    return;
                });
        }
    }

    public save() {
        if (!this.model.id_finca || !this.model.mes || !this.model.anyo) {
            alert('Debe especificar una finca y una fecha.');
            return;
        }
    
        const head = {...this.model};
    
        delete head.table;
        delete head.table_unformatted;

        if (this.model.table?.[0] ) {
            this.model.table.shift();
        }

        if (this.model.id) {
            this.tareasApi.controlRiego.PUT.toPromise(head as never).then(() => {
                this.saveTable();
                return;
            }).catch (e => {
                console.log('catch en controlRiego: ' + e);
            }
            );
        } else {
            this.tareasApi.controlRiego.POST.toPromise(head as never).then(res => {
                this.model.id = res[0]?.id ?? '';
                this.saveTable();
                return;
            }).catch (e => {
                console.log('catch en controlRiego: ' + e);
            }
            );
        }
 
    }

    public saveTable() {
        const rowsToSend = [];
        for (const [, row] of (Object.entries(this.model.table ?? {}) as [string, TableRiego][])) {
            if (row && Object.keys(row).length > 1) {
                let idsSectores = '';
                let idsProductos = '';
                let horasSectores = '';
                let cantidadesProductos = '';
  
                Object.keys(row).forEach(field => {
                    if (field.indexOf('sector_') !== -1) {
                        if (field.split('_')) {
                            idsSectores += field.split('_')[1] + ';';
                            horasSectores += row[+field] + ';';
                        }
                    }
                    if (field.indexOf('producto_') !== -1) {
                        if (field.split('_')) {
                            idsProductos += field.split('_')[1] + ';';
                            cantidadesProductos += row[+field] + ';';
                        }
                    }
                });
  
                idsSectores = (idsSectores || '').slice(0, -1);
                idsProductos = (idsProductos || '').slice(0, -1);
                horasSectores = (horasSectores || '').slice(0, -1);
                cantidadesProductos = (cantidadesProductos || '').slice(0, -1);
        
                const rowToSend = {
                    id: row.id,
                    id_control: this.model.id,
                    dia: row.dia,
                    lluvia: row.lluvia || null,
                    idsSectores,
                    idsProductos,
                    horasSectores,
                    cantidadesProductos,
                    show_sectores: TRUTHY_VALUES.includes( row.show_sectores ?? ''),
                    show_productos: TRUTHY_VALUES.includes(row.show_productos ?? '')
                };
                rowsToSend.push(rowToSend);
            }
        }

        this.tareasApi.controlRiegoTable.POST.toPromise(rowsToSend as never).then(() => {
            this.formIsDirty = false;
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );

        if (!this.formIsDirty) {
            this.tareasApi.controlRiego.GET.toPromise({mes: this.model.mes, anyo: this.model.anyo, id_finca: this.model.id_finca} as never)
                .then(res => {

                    if (res && res[0]) {
                        this.model = res[0];
                    } else {
                        const modelOld = {mes: this.model.mes ?? '', anyo: this.model.anyo ?? 0, id_finca: this.model.id_finca ?? ''};
                        this.model = modelOld;
                        this.formIsDirty = false;
                    }
                    this.getColumnCount();
                    this.initModel();
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
        }
    }

    public print() {
        this.isPrinting = true;

        setTimeout(async () => {
            await html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement(), {scale: 2, logging: false}).then(canvas => {
                let title = 'Control de riego ' + this.fincas.selected.nombre + ' ' + this.model.anyo + '_' + this.model.mes;
                let pdfWidth = 210;
                const config: {orientation: 'p'|'l'; units: 'mm'; size: string} = {
                    orientation: 'p',
                    units: 'mm',
                    size: 'a4'
                };
                if (this.isShowingResumen) {
                    title = 'Resumen de consumos ' + this.fincas.selected.nombre + ' ' + this.model.anyo;
                    config.orientation = 'l';
                    pdfWidth = 290;
                }
                const pdf = new jsPDF(config.orientation, config.units, config.size);

                const dataUrl = canvas.toDataURL('image/png');
                pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, (canvas.height / (canvas.width / pdfWidth)), 'alias', 'FAST');
                pdf.save(title);
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            ).then(() => {
                // Atención: isPrinting se debe asignar a 'false' DESPUÉS de guardar el PDF. De lo contrario, los elementos
                // HTML que se muestran/ocultan a la hora de imprimir no lo harán correctamente.
                this.isPrinting = false;
                return;
            }).catch (e => {
                console.log('catch en getPosition: ' + e);
            }
            );
        }, 700);

    }

    generarDatos() {
        const idsSectores = this.sectores.filtered.filter(it => it && it.value).map(it => it.value.id);
        const idsProductos = this.productos.filtered.filter(it => it && it.value).map(it => it.value.id);

        this.model.table = this.model.table?.map((row: TableRiego) => {
            for (const idSector of idsSectores) {
                row['sector_' + idSector] = Math.round((Math.random() * 8)) + ':' + Math.round((Math.random() * 59));
            }
            for (const idProducto of idsProductos) {
                row['producto_' + idProducto] = Math.round((Math.random() * 300)).toString();
            }
            row.lluvia = Math.round((Math.random() * 5)).toString();
            return row;
        }) as TableRiego[];
    }

    public showStockage() {
        this.isShowingStockage = true;


        for (const [, row] of (Object.entries(this.model.table || {}) as [string, TableRiego][])) {
            Object.keys(row).forEach(s_key => {
                if (s_key.includes('producto_')) {
                    const parts = (s_key || '').split('_');
                    
                    if (parts[1]) {
                        if (!this.salidas[parts[1]]) {
                            this.salidas[parts[1]] = 0;
                        }
                        (this.salidas[parts[1]] as number) += parseFloat(row[s_key]?.toString() ?? '');
    
                        if (this.salidas.teorico && !this.salidas.teorico?.[parts[1]]) {
                            (this.salidas.teorico[parts[1] ?? ''] as number) = 0;
                        }
                        if (row.show_productos && this.salidas.teorico?.[parts[1]]) {
                            (this.salidas.teorico[parts[1] ?? ''] as number) += parseFloat(row[s_key]?.toString() ?? '');
                        }
                    }
                }
            });
        }

        this.stockApi.entradas.GET.toPromise().then(res => {
            this.entradas = res.filter((it: StockageModel) => (it.id_finca === this.model.id_finca) &&
          ((moment(it.fecha, 'DD/MM/YYYY').month() + 1).toString() === this.model.mes));

            const today = moment();
            this.stockage.day = today.format('DD');
            this.stockage.month = today.format('MMMM');
            this.stockage.year = today.year();

            this.entradas.forEach(entrada => {
                if (!this.stockage[entrada.id_producto1 ?? '']) {
                    this.stockage[entrada.id_producto1 ?? ''] = -(this.salidas[entrada.id_producto1 ?? ''] || 0);
                }
                if (this.stockage.teorico && !this.stockage.teorico?.[entrada.id_producto1 ?? '']) {
                    this.stockage.teorico[entrada.id_producto1 ?? ''] = -(this.salidas.teorico?.[entrada.id_producto1 ?? ''] || 0);
                }
                this.stockage[entrada.id_producto1 ?? ''] += entrada.unidades.toString();
                if (this.stockage.teorico !== undefined && this.stockage.teorico[entrada.id_producto1 ?? ''] !== undefined) {
                    //this.stockage.teorico[entrada.id_producto1 ?? ''] += entrada.unidades;
                }
            });
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }

    /**
   * Suma uno
   * @param n Es un número
   */
    public sumOne(n: number): number {
    // Devuelve n + 1
        return parseInt(n.toString(), 10) + 1;
    }

    private getColumnCount() {
    // Debe haber una anchura mínima de una celda para que se muestre bien la tabla.
        this.sectoresCount = this.listIsEmpty(this.sectores) ? 1 : this.sectores.filtered.length;
        this.productosCount = this.listIsEmpty(this.productos) ? 1 : this.productos.filtered.length;

        this.columnCount = 2 + this.sectoresCount + this.productosCount; // 2 son fijas
        this.columnCountArray = [];
        this.columnCountResumen = 3 + this.productosCount;
        this.columnCountResumenArray = [];

        // Primera tabla
        if (this.listIsEmpty(this.sectores)){
            this.columnCountArray.push('sector_null');
        } else {
            this.columnCountArray = this.columnCountArray
                .concat(this.sectores.filtered
                    .filter(it => it.value)
                    .map(it => 'sector_' + it.value.id));
        }

        this.columnCountArray.push('lluvia');
        // const ids_productos = (this.model.ids_productos || '').split(';');

        if (this.listIsEmpty(this.productos)){
            this.columnCountArray.push('producto_null');
        } else {
            this.columnCountArray = this.columnCountArray
                .concat(this.productos.filtered
                    .filter(it => it.value)
                    .map(it => 'producto_' + it.value.id));
        }

        // Segunda tabla
        this.columnCountResumenArray = this.productos.filtered
            .filter(it => it.value)
            .map(it => 'producto_' + it.value.id);
    }

    private getDiasArray() {
        this.diasArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
            17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
        switch (parseInt(this.model.mes ?? '', 10)) {
        case 2:
        { 
            const d = Utils.isLeapYear(this.model.anyo || 0) ? 2 : 3;
            this.diasArray = this.diasArray.slice(0, this.diasArray.length - d);
            break; 
        }
        case 4:
            this.diasArray = this.diasArray.slice(0, this.diasArray.length - 1);
            break;
        case 6:
            this.diasArray = this.diasArray.slice(0, this.diasArray.length - 1);
            break;
        case 9:
            this.diasArray = this.diasArray.slice(0, this.diasArray.length - 1);
            break;
        case 11:
            this.diasArray = this.diasArray.slice(0, this.diasArray.length - 1);
            break;
        }

        this.diasArray.forEach(dia => {
            this.alwaysTrue[dia] = true;
            this.alwaysFalse[dia] = false;
        });
    }


    private listIsEmpty(ta: TypeAheadInterface<object>): boolean {
        return !ta.filtered[0] || (ta.filtered[0].value === null);
    }

    private initModel() {
        if (!this.model.anyo) {
            this.model.anyo = new Date().getFullYear();
        }
        if (!this.model.mes) {
            this.model.mes = (new Date().getMonth() + 1).toString();
        }
        if (!this.model.table) {
            this.model.table = [];
        }

        for (const i of this.diasArray) {
            if (this.model.id) {
                const found = (this.model.table_unformatted || []).find((it: TableRiego) => it && (it.dia === i));
                if (found) {
                    found.show_sectores = found.show_sectores === '1';
                    found.show_productos = found.show_productos === '1';
                    if (FORBIDDEN_VALUES.includes(found.lluvia)) {
                        found.lluvia = null;
                    }

                    const idsSectores = found.ids_sectores?.split(';');
                    const idsProductos = found.ids_productos?.split(';');
                    const horasSectores = found.horas_sectores?.split(';');
                    const cantidadesProductos = found.cantidades_productos?.split(';');

                    idsSectores?.forEach((id: string, index: string | number) => {
                        if (id && horasSectores?.[+index] && !FORBIDDEN_VALUES.includes(horasSectores[+index])) {
                            found['sector_' + id] = horasSectores[+index] || '';
                        }
                    });
                    idsProductos?.forEach((id: string, index: string | number) => {
                        if (id && cantidadesProductos?.[+index] && !FORBIDDEN_VALUES.includes(cantidadesProductos[+index])) {
                            found['producto_' + id] = cantidadesProductos[+index] || '';
                        }
                    });

                    this.model.table[i] = found;
                    delete this.model.table[i]?.ids_sectores;
                    delete this.model.table[i]?.ids_productos;
                    delete this.model.table[i]?.horas_sectores;
                    delete this.model.table[i]?.cantidades_productos;
                } else {
                    this.model.table[i] = {
                        dia: i
                    };
                }
            } else {
                this.model.table[i] = {
                    dia: i
                };
            }
        }
    }
}