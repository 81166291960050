import {Injectable} from '@angular/core';
import {RequestHandler} from 'src/app/service/OffService/request-handler';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import { PedidoModel } from 'src/app/models/visitas/pedido.model';

@Injectable({
    providedIn: 'root'
})
export class PedidosApiService {

    public pedidosGET = new RequestHandler<PedidoModel[]>();
    public pedidosPUT = new RequestHandler<PedidoModel[]>();
    public pedidosPOST = new RequestHandler<PedidoModel[]>();
    public pedidosDELETE = new RequestHandler<PedidoModel>();

    public enviarEmail = new RequestHandler<PedidoModel>();

    constructor(
        private syncService: SyncService<PedidoModel[]>,
        private syncServiceDelete: SyncService<PedidoModel>,
        private syncServiceEmail: SyncService<PedidoModel>,
        private api: ApiRequestService<PedidoModel[]>,
        private apiDelete: ApiRequestService<PedidoModel>,
        private apiEmail: ApiRequestService<PedidoModel>
    ) {

        this.definePedidosGET();
        this.definePedidosPUT();
        this.definePedidosPOST();
        this.definePedidosDELETE();
        this.defineEnviarEmail();
    }

    private definePedidosGET() {
        this.pedidosGET
            .fetch(HttpRequestType.GET, 'pedidos/get');

        this.api
            .registerRequest(this.pedidosGET);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.pedidosGET));
    }

    private definePedidosPUT() {
        this.pedidosPUT
            .fetch(HttpRequestType.PUT, 'pedidos/put');

        this.api
            .registerRequest(this.pedidosPUT);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.pedidosPUT));
    }

    private definePedidosPOST() {
        this.pedidosPOST
            .fetch(HttpRequestType.POST, 'pedidos/duplicate');

        this.api
            .registerRequest(this.pedidosPOST);

        this.syncService
            .register(this.syncService.baseSyncPolicy(this.pedidosPOST));
    }

    private definePedidosDELETE() {
        this.pedidosDELETE
            .fetch(HttpRequestType.DELETE, 'pedidos/delete');

        this.apiDelete
            .registerRequest(this.pedidosDELETE);

        this.syncServiceDelete
            .register(this.syncServiceDelete.baseSyncPolicy(this.pedidosDELETE));
    }

    private defineEnviarEmail() {
        this.enviarEmail
            .fetch(HttpRequestType.POST, 'pedidos/enviar_email');

        this.apiEmail
            .registerRequest(this.enviarEmail);

        this.syncServiceEmail
            .register(this.syncServiceEmail.baseSyncPolicy(this.enviarEmail));
    }    
}


