<p-panel>
  <div style="display:flex; gap: 20px">
    <h4>Registrar movimiento</h4>
    <div *ngIf="loadingData">
      <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="6" animationDuration="1.5s">
      </p-progressSpinner>
  </div>
  </div>
  <app-form-builder
    [model]="model"
    [undoEnabled]="undoEnabled"
    [distributionColumns]="4"
    [fields]="formFieldsTop"
    (formChanges)="formChanges($event)">
  </app-form-builder>
  <br>
  <app-form-builder
    *ngIf="!showStockTipoDeDato"
    [model]="modelLinea"
    [undoEnabled]="undoEnabled"
    [distributionColumns]="showStockMarcaComercial ? 4 : 3"
    [fields]="formFieldsLineas"
    (formChanges)="formChanges($event)">
  </app-form-builder>
  <br>
  <!-- Form Fields de Armentia -->
  <app-form-builder
    [model]="modelLinea"
    [distributionColumns]="appName === 'pyf' ? 6 : this.showStockAdditionalFields ? 4 : 3"
    [fields]="formFieldsLineasWithTipoDato"
    (formChanges)="formChanges($event)">
  </app-form-builder>
  <br>
  <app-form-builder
  *ngIf="showStockTipoDeDato && !this.showStockAdditionalFields"
    [class.unvisible]="!['Fitosanitario', 'Fertilizante'].includes(model.tipo_dato ?? '')"
    [model]="model"
    [distributionColumns]="3"
    [fields]="formFieldsUnidadesMedida"
    (formChanges)="formChanges($event)">
  </app-form-builder>
  <br>
  <!-- /Form Fields de Armentia -->

  <app-form-builder
    [model]="model"
    [undoEnabled]="undoEnabled"
    [distributionColumns]="2"
    [fields]="formFieldsBottom"
    (formChanges)="formChanges($event)">
  </app-form-builder>
  <br>

  <div class="d-flex justify-content-center">
    <div>
      <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
    </div>
    <app-request-button
      [lockOnSuccess]="true"
      (click)="submit()"
      [error]="error"
      [mode]="requestButtonType"
      [request]="request">
    </app-request-button>
  </div>
  <br>
</p-panel>
