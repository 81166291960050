import { InputType } from '../../../../../../common/components/form-builder/form-builder.component';
import { environment } from 'src/environments/environment';
import { list } from 'src/app/common/classes/request-builder';

import type { ItemInterface, TypeAheadInterface } from '../../../../../../common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ProductosModel } from 'src/app/models/productos/productos.model';

export class ProductosFertilizantesFormDefinition {

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public fertilizantesNombreRequired = environment.features.fertilizantesNombreRequired;
    public fertilizantesUdsMedidaRequired = environment.features.fertilizantesUdsMedidaRequired;
    public showFertilizantesSubtipo = environment.features.showFertilizantesSubtipo;
    public showFertisComposicion = environment.features.showFertisComposicion;
    public showFertilizantesNPK = environment.features.showFertilizantesNPK;
    public showFertilizantesCa = environment.features.showFertilizantesCa;
    public showFertilizantesS = environment.features.showFertilizantesS;
    public showFertilizantesMg = environment.features.showFertilizantesMg;
    public showDescripcion = environment.features.showDescripcion;
    public showPrecio = environment.features.showPrecio;
    public showRoturaStock = environment.features.showFertiRoturaStock;
    public showCostes = environment.features.showCostes;

    public materialFertilizante = list(['...', null],
        ['Estiercol líquido de aves', '1'],
        ['Estiércol líquido de bovino', '2'],
        ['Estiércol líquido de porcino', '3'],
        ['Estiércol sólido de aves', '4'],
        ['Estiércol sólido de bovino', '5'],
        ['Estiércol sólido de caprino', '6'],
        ['Estiércol sólido de equino', '7'],
        ['Estiércol sólido de ovino', '8'],
        ['Inhibidores', '9'],
        ['Otros tipos de estiércol sólido: conejo, visón, zorro…', '10'],
        ['Productos fertilizantes bioestimulantes', '11'],
        ['Productos fertilizantes enmiendas calizas', '12'],
        ['Productos fertilizantes enmiendas orgánicas', '13'],
        ['Productos fertilizantes inorgánicos', '14'],
        ['Productos fertilizantes orgánicos', '15'],
        ['Productos fertilizantes organominerales', '16'],
        ['Productos fertilizantes otras enmiendas (retenedoras humedad, ácidas, etc.)', '17'],
        ['Residuos: Materiales que, sin haberse empleado en la elaboración de un producto fertilizante', '18'],
        ['Residuos: Subproductos de origen animal no aptos para consumo humano', '19'],
        ['Residuos: compost de alperujo', '20'],
        ['Residuos: alperujo desecado', '21'],
        ['Residuos: Lodos de tratamiento de aguas residuales urbanas (lodos EDAR)', '22'],
        [
            'Residuos: Lodos calizos procedentes del proceso Kraft de fabricación de pasta de papel, para elevar el pH de suelos ácidos',
            '23'
        ]);

    public fincas: TypeAheadInterface<FincasModel> = {
        selected: null,
        filtered: [{ label: 'Cargando...', value: null }],
        values: []
    };

    public productos: TypeAheadInterface<ProductosModel> = {
        selected: null,
        filtered: [{ label: 'Cargando...', value: null }],
        values: []
    };

    public estado: TypeAheadInterface<string> = {
        selected: null,
        filtered: [
            { label: '...', value: null },
            { label: 'Activo', value: 'Activo' },
            { label: 'No Activo', value: 'No Activo' },
        ],
        values: []
    };

    public unidades: TypeAheadInterface<string> = {
        selected: null,
        filtered: [
            { label: '...', value: null },
            { label: 'unidades', value: 'unidades' },
            { label: 'sobres', value: 'sobres' },
            { label: 'sacos', value: 'sacos' },
            { label: 'toneladas', value: 'toneladas' },
            { label: 'gr', value: 'gr' },
            { label: 'kilos', value: 'kilos' },
            { label: 'litros', value: 'litros' },
            { label: 'c.c.', value: 'c.c.' },
        ],
        values: []
    };

    public unidades_costes: TypeAheadInterface<string> = {
        selected: null,
        filtered: [
            { label: '...', value: null },
            { label: 'kilos', value: 'kilos' },
            { label: 'litros', value: 'litros' }
        ],
        values: []
    };

    public subtipos: TypeAheadInterface<string> = {
        selected: null,
        filtered: [
            { label: '...', value: null },
            { label: 'A', value: '1' },
            { label: 'B', value: '2' },
            { label: 'C', value: '3' },
        ],
        values: []
    };

    public empresa = list(['...', null]);


    public topFormFields: ItemInterface<object>[] = [
        {
            label: this.appName === 'vginer' ? 'Fertilizante' : 'Nombre o Marca Comercial', 
            field: 'nombre',
            inputType: { type: InputType.EDIT_TEXT },
            required: this.fertilizantesNombreRequired
        },
        {
            label: this.appName === 'agropecuariaramirogruas' ?
                'Descripción (Tipo Fertilizante)' :
                'Descripción',
            field: 'descripcion',
            inputType: { type: InputType.EDIT_TEXTAREA },
            visible: this.showDescripcion
        },
        {
            label: '',
            field: 'imagen',
            inputType: { type: InputType.EMPTY },
            visible: this.appName === 'agroleza'
        },
    ];

    public bottomFormFieldsLeft: ItemInterface<object>[] = [
        { 
            label: 'Precio', 
            field: 'precio', 
            inputType: { type: InputType.EDIT_NUMERIC }, 
            visible: this.showPrecio 
        },
        {
            label: 'Unidades medida',
            field: 'unidades',
            inputType: { type: InputType.DROPDOWN },
            values: this.showCostes ? this.unidades_costes : this.unidades,
            required: this.fertilizantesUdsMedidaRequired || this.showCostes
        },
        { 
            label: 'Envase', 
            field: 'envase', 
            inputType: { type: InputType.EDIT_TEXT } 
        },
        {
            label: 'Rotura stock',
            field: 'rotura_stock',
            inputType: { type: InputType.EDIT_NUMERIC },
            visible: this.showRoturaStock
        },
        {
            label: 'Subtipo', 
            field: 'subtipo', 
            inputType: { type: InputType.DROPDOWN }, 
            values: this.subtipos,
            visible: this.showFertilizantesSubtipo
        }
    ];

    public bottomFormFieldsRight: ItemInterface<object>[] = [
        { label: 'N%', field: 'N', inputType: { type: InputType.EDIT_NUMERIC }, visible: this.showFertilizantesNPK },
        { label: 'P%', field: 'P', inputType: { type: InputType.EDIT_NUMERIC }, visible: this.showFertilizantesNPK },
        { label: 'K%', field: 'K', inputType: { type: InputType.EDIT_NUMERIC }, visible: this.showFertilizantesNPK },
        { label: 'Ca%', field: 'Ca', inputType: { type: InputType.EDIT_NUMERIC }, visible: this.showFertilizantesCa },
        { label: 'S%', field: 'S', inputType: { type: InputType.EDIT_NUMERIC }, visible: this.showFertilizantesS },
        { label: 'Mg%', field: 'Mg', inputType: { type: InputType.EDIT_NUMERIC }, visible: this.showFertilizantesMg }
    ];

    public formFieldsMaterialFerti: ItemInterface<object>[] = [
        {
            field: 'material_ferti',
            label: 'Material Fertilizante',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: this.materialFertilizante
        },
        { 
            field: 'n_total', 
            placeholder: 'macronutriente_1', 
            label: 'N Total', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            field: 'n_organico', 
            placeholder: 'macronutriente_3', 
            label: 'N Orgánico', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            field: 'n_nitrico', 
            placeholder: 'macronutriente_2', 
            label: 'N Nítrico', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            field: 'n_amoniacal', 
            placeholder: 'macronutriente_4', 
            label: 'N Amoniacal', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            field: 'n_ureico', 
            placeholder: 'macronutriente_5', 
            label: 'N Ureico', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            field: 'p2o5_total', 
            placeholder: 'macronutriente_6', 
            label: 'P2O5 Total', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            field: 'p2o5_agua', 
            placeholder: 'macronutriente_7', 
            label: 'P205 Soluble en Agua', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        {
            field: 'p2o5_amonico',
            placeholder: 'macronutriente_8',
            label: 'P205 Soluble en Citrato Amónico Neutro y Agua',
            inputType: { type: InputType.EDIT_NUMERIC },
            visible: this.applicationType !== 'cropgest'
        },
        {
            field: 'k20_total',
            placeholder: 'macronutriente_9',
            label: 'K20 Total',
            inputType: { type: InputType.EDIT_NUMERIC },
            hasLabel: false
        },
        {
            field: 'materia_organica',
            placeholder: 'macronutriente_10',
            label: 'Materia orgánica',
            inputType: { type: InputType.EDIT_NUMERIC }
        },
        {
            field: 'calcio',
            placeholder: 'macronutriente_11',
            label: 'Calcio (CaO)',
            inputType: { type: InputType.EDIT_NUMERIC },
            visible: this.applicationType !== 'cropgest'
        },
        {
            field: 'nombre_tipo',
            placeholder: 'macronutriente_12',
            label: 'Nombre del Tipo',
            inputType: { type: InputType.EDIT_TEXT },
            visible: this.applicationType !== 'cropgest'
        },
        {
            field: 'dosis',
            placeholder: 'macronutriente_13',
            label: 'Dosis',
            inputType: { type: InputType.TEXT },
            visible: this.applicationType !== 'cropgest'
        },
        {
            field: 'tratamiento_recibido',
            placeholder: 'macronutriente_15',
            label: 'Tratamiento Recibido',
            inputType: { type: InputType.TEXT },
            visible: this.applicationType !== 'cropgest'
        },
    ];

    public formFieldsEmpresaResiduos: ItemInterface<object>[] = [
        {
            field: 'empresa',
            label: 'Empresa suministradora (para estiércoles y residuos)',
            inputType: { type: InputType.DROPDOWN },
            values: this.empresa
        },
        {
            field: 'nif_empresa',
            label: 'NIF de Empresa suministradora (para estiércoles y residuos)',
            inputType: { type: InputType.EDIT_TEXT }
        },
    ];

    public baselineFormFields: ItemInterface<object>[] = [
        { 
            label: 'Composición', 
            field: 'composicion', 
            inputType: { type: InputType.EDIT_TEXT }, 
            visible: this.showFertisComposicion 
        },
    ];

    public formFieldsMetalesPesados: ItemInterface<object>[] = [
        { 
            label: 'Cadmio (Cd)', 
            field: 'm_cadmio', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            label: 'Cobre (Cu)', 
            field: 'm_cobre', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            label: 'Plomo (Pb)', 
            field: 'm_plomo', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            label: 'Niquel (Ni)', 
            field: 'm_niquel', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            label: 'Zinc (Zn)', 
            field: 'm_zinc', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            label: 'Mercurio (Hg)', 
            field: 'm_mercurio', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
        { 
            label: 'Cromo total (Cr)', 
            field: 'm_cromo_total', 
            inputType: { type: InputType.EDIT_NUMERIC } 
        },
    ];
}