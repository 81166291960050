import { DashboardService } from '../../../../../service/events/dashboard.service';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseForm } from '../../../../base-form';
import { FertilizantesFormDefinition } from './form-control/form-definition';
import { TareasApiService } from '../../../../../service/api/tareas-api.service';
import { FileUploadAnswer, Utils } from '../../../../../common/utils';
import { AppFormRequest } from '../../../../app-common/form-request/app-form-request';
import { FincasApiService } from '../../../../../service/api/fincas-api.service';
import { ProductosApiService } from '../../../../../service/api/productos-api';
import { build, pool, buildParams } from '../../../../../common/classes/request-builder';
import { TrabajadoresApiService } from '../../../../../service/api/trabajadores-api.service';
import { FormRequestTypes } from '../../../../../common/classes/form-request';
import { InputType } from '../../../../../common/components/form-builder/form-builder.component';
import { ClientesApiService } from '../../../../../service/api/clientes-api.service';
import { firstValueFrom, Subject } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { StorageManager } from '../../../../../common/storage-manager.class';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import moment from 'moment';
import { checkIfRolAdmin } from '../../../../../common/check-role';
import { HttpClient } from '@angular/common/http';
import { UsuariosApiService } from '../../../../../service/api/usuarios-api.service';

import type { OnDestroy, OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { TareaFertiModel } from 'src/app/models/tareas/tarea-ferti.model';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
@Component({
    selector: 'app-fito-form',
    templateUrl: './fertilizantes-form.component.html',
    styleUrls: ['./fertilizantes-form.component.scss']
})
export class FertilizantesFormComponent extends BaseForm<TareaFertiModel> implements OnInit, OnDestroy {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<TareaFertiModel>();

    public rol = StorageManager.getUser().rol;
    public loadingData = true;

    public serverUrl = environment.serverUrl;
    public horaFechaFinActualPorDefecto = environment.features.horaFechaFinActualPorDefecto;
    public tipoSuperficieParcelas = environment.features.hasSuperficieCultivada ? 'Sup.\xa0Cultivada' : 'S.\xa0Sigpac';
    public sectorName = environment.features.sectorName;
    public showAbonado = environment.features.showAbonado;
    public showTareasFertiObservaciones = environment.features.showTareasFertiObservaciones;
    public showFertilizantesFormShort = environment.features.showFertilizantesFormShort;
    public showFertilizantesNPK = environment.features.showFertilizantesNPK;
    public showFertilizantesCa = environment.features.showFertilizantesCa;
    public showFertilizantesS = environment.features.showFertilizantesS;
    public showFertilizantesMg = environment.features.showFertilizantesMg;
    public udsByParcela = environment.features.showUnidadesFertilizantesByParcela;
    public showKilosHectarea = environment.features.showKilosHectarea;
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = environment.features.userCanSeeClientes ? true : (this.rol === 'admin');
    public showRecetaFertiAgrogest = environment.features.showRecetaFertiAgrogest;
    public clienteName = environment.features.clienteName;
    public parcelasName = environment.features.parcelasName;
    public showTareasFertiGramos = environment.features.showTareasFertiGramos;
    public textoSuperficie = environment.features.textoSuperficie;
    public showClientesNotas = environment.features.showClientesNotas;

    public override model: TareaFertiModel = {};

    public getRequest = (this.getType() === FormRequestTypes.DUPLICATE) ?
        this.tareasApi.fertilizantes.GET_GENERIC :
        this.tareasApi.fertilizantes.GET;

    public formRequest = new AppFormRequest<TareaFertiModel>();
    public recalculateEmmiter = new Subject();
    public mostrarPlan = true;
    public distributionOrder = this.appName === 'coliberica' ? [3, 4, 3] : [3, 6, 2];
    public distributionOrderPac = [3, 6, 4, 2, 2];
    public errorMessage = '';
    public calculoSectores: Record<string, number> = {};
    public parcelas: ParcelasModel[] = [];
    public kgPorSectorTabIndex = 0;
    public alreadyLoaded = false;
    public mostrarBloqueSectores = false;
    public totalN = 0;
    public totalP = 0;
    public totalK = 0;
    public totalCa = 0;
    public totalS = 0;
    public totalMg = 0;
    public totalesTabla = {
        superficie: 0,
        n: 0,
        p: 0,
        k: 0,
        ca: 0,
        mg: 0,
        s: 0
    };
    public udFertN: number[] = [];
    public udFertP: number[] = [];
    public udFertK: number[] = [];
    public udFertCa: number[] = [];
    public udFertS: number[] = [];
    public udFertMg: number[] = [];
    public superfSect: number[] = [];

    public planProductos: ProductosModel[] = [];
    public planSectores: SectorModel[] = [];
    public showPlanAbonado = false;

    public form: FertilizantesFormDefinition = new FertilizantesFormDefinition();
    contador = 0;
    public intervaloParcelas: string | number | NodeJS.Timeout | undefined = '';

    /*** Firma DonJardin ***/
    public signatureFile: File = new File([], '');

    constructor(
        public fincasApi: FincasApiService,
        public tareasApi: TareasApiService,
        public productosApi: ProductosApiService,
        public trabajadoresApi: TrabajadoresApiService,
        private userApi: UsuariosApiService,
        public override router: Router,
        public override route: ActivatedRoute,
        public dashboardEvents: DashboardService,
        public clientesApi: ClientesApiService,
        private http: HttpClient
    ) {
        super(
            route,
            router,
            dashboardEvents,
            tareasApi.fertilizantes.PUT,
            tareasApi.fertilizantes.POST,
            'fertilizantes',
            'Editar Tarea Fertilizante',
            'Realizar Tarea Fertilizante',
            'Duplicar Tarea Fertilizante'
        );
    }

    ngOnInit() {
        if (environment.features.horaFechaFinActualPorDefecto) {
            if (this.getType() === 0 || this.getType() === 2) {
                const date = new Date;
                this.model.hora_fin = date.toString();
            }
        }

        this.initFormData();
        this.expandFormFields();
        this.expandFormRequest();

        let formFieldsToSend = (
            [
                'id', 
                'id_usuario', 
                'capacidad_cuba', 
                'dosis_cuba', 
                'cultivo', 
                'variedad', 
                'sup_cultivada', 
                'horas_totales'
            ]
        )
            .concat(
                this.form.formFieldsSectorLeft.map(it => it.field ?? ''),
                this.form.formFieldsBottomLeft.map(it => it.field ?? '')
                    .filter(field => !(
                        ['gramos1', 'gramos2', 'gramos3', 'gramos4', 'gramos5', 'gramos6', 'gramos7']
                            .includes(field ?? '')
                    )),
                this.form.formFieldsPac.map(it => it.field ?? ''),
                this.form.formFieldsAbonado.map(it => it.field ?? '')
            );
        if (this.showTareasFertiGramos) {
            formFieldsToSend = formFieldsToSend.concat(['gramos1', 'gramos2', 'gramos3', 'gramos4', 'gramos5', 'gramos6', 'gramos7']);
        }
        if (this.hasClients && this.userCanSeeClientes) {
            formFieldsToSend.push('id_cliente');
        }
        if (this.appName === 'albaran_factura') {
            formFieldsToSend.push('albaran_factura');
        }
        if (environment.features.showTareasMto) {
            formFieldsToSend.push('signature_img');
        }
        if (this.appName === 'pyf') {
            formFieldsToSend = formFieldsToSend.filter((field: string | string[]) => {
                if (field.includes('kg_ha')) {
                    return false;
                }
                return true;
            });
        }

        if (this.appName === 'donjardin') {
            formFieldsToSend.push('id_ref_usuario');
        }


        this.formRequest
            .setType(this.getType())
            .isGeneric(this.getType() === FormRequestTypes.DUPLICATE)
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.getRequest)
            .setPostRequest(this.tareasApi.fertilizantes.POST)
            .setPutRequest(this.tareasApi.fertilizantes.PUT)
            .setFormFields(Array.prototype
                .concat(
                    this.form.formFieldsSectorLeft,
                    this.form.formFieldsBottomLeft,
                    this.form.formFieldsObs,
                    this.form.formFieldsOrdenTrabajo,
                    this.form.formFieldsPac,
                    this.form.formFieldsAbonado
                )
            )
            .setFieldsToSend(formFieldsToSend);

        this.formRequest.load();
        const intervalo = setInterval(() => {
            this.model.asesor = StorageManager.getUser().nombre;

            if (this.showKilosHectarea) {
                clearInterval(intervalo);
            }
            if (this.getTotalArboles() != null || this.getTotalArboles() !== 0) {
                this.calculateKilosPorSector();
                this.countSuperficieParcelas();
                clearInterval(intervalo);
            }

            this.contador++;
            if (this.contador >= 1000) {
                clearInterval(intervalo);
            }
        }, 1000);
        this.softInit(this.getType());
        this.form.requestType = this.getType();
    }

    updateImageModel(event: string) {
        this.model.imagen = event;
    }

    updateAfterImageModel(event: string) {
        this.model.image_after = event;
    }

    getSignature(event: File) {
        this.signatureFile = event;
    }

    updateFirma(event: string) {
        this.model.signature_img = event;
        this.formRequest.update();
    }

    public calculaNPKs() {

        const superfTotal = this.getTotalSuperficieParcelas();

        this.totalN = 0;
        this.totalP = 0;
        this.totalK = 0;
        this.totalCa = 0;
        this.totalS = 0;
        this.totalMg = 0;

        let totalNSequiador = 0;
        let totalPSequiador = 0;

        //Carga N,P,K de productos seleccionados
        let N: number;
        let P: number;
        let K: number;
        let CA: number;
        let S: number;
        let MG: number;

        const prods = [
            this.form.productos1.selected,
            this.form.productos2.selected,
            this.form.productos3.selected,
            this.form.productos4.selected,
            this.form.productos5.selected,
            this.form.productos6.selected,
            this.form.productos7.selected
        ];

        let i = 1;
        let dosis = 0;
        (prods || []).forEach(producto => {
            if (producto) {
                dosis = parseFloat(this.model['dosis' + i]?.toString() ?? '');

                N = (dosis * +(producto.N || 0)) / 100;
                P = (dosis * +(producto.P || 0)) / 100;
                K = (dosis * +(producto.K || 0)) / 100;
                CA = (dosis * +(producto.Ca || 0)) / 100;
                S = (dosis * +(producto.S || 0)) / 100;
                MG = (dosis * +(producto.Mg || 0)) / 100;

                if (this.appName === 'prosanzcu') {
                    N = N / superfTotal;
                    P = P / superfTotal;
                    K = K / superfTotal;
                }

                this.totalN += N;
                this.totalP += P;
                this.totalK += K;
                this.totalCa += CA;
                this.totalS += S;
                this.totalMg += MG;
            }
            i++;
        });

        if (this.appName === 'sequiador') {
            if (this.model.n_agua) {
                totalNSequiador = this.totalN + this.model.n_agua;
            }else {
                totalNSequiador = this.totalN; 
            }
            if (this.model.p205) {
                totalPSequiador = this.totalP + this.model.p205;
            }else {
                totalPSequiador = this.totalP; 
            }
        }

        /* for (let i = 1; i <= 7; i++) {
            if (this.appName == 'pyf' || this.appName == 'covidai'){
                // if (parseFloat(this.model[`dosis${i}`]) === 0.0) {
                //     this.model[`dosis${i}`] = null;
                // }
            }
            else{
                if (parseInt(this.model[`dosis${i}`]) === 0) {
                    this.model[`dosis${i}`] = null;
                }
            }
        } */

        const idsParcelas = (this.model.id_parcelas || '').split(';');
        const proporcionSect: number[] = [];
        let mayorProporcion = 0;

        if (this.form.showTareasFertiSectorMultiple) {
            (this.form.sectores.selected as SectorModel[] || []).forEach((sector, d) => {
                this.superfSect[d] = 0;
                (this.form.parcelas.filtered || []).map(it => it.value).forEach(parcela => {
                    if (sector.id === parcela.id_sector) {
                        if (idsParcelas.includes(parcela.id) && this.superfSect[d]) {
                            this.superfSect[d] += parseFloat(
                                (parcela.superficie_cultivada || parcela.superficie_sigpac || '0.00').replace(',', '.')
                            );
                        }
                    }
                });
                this.superfSect[d] = parseFloat(this.superfSect[d]?.toFixed(2) ?? '');

                if (mayorProporcion < (sector.proporcionArboles || 0)) {
                    mayorProporcion = sector.proporcionArboles ?? 0;
                }

                if (sector.proporcionArboles === 0) {
                    sector.proporcionArboles = 1;
                }
                proporcionSect[d] = sector.proporcionArboles ?? 0;
            });
        }

        i = 0;
        this.totalesTabla = {
            superficie: 0,
            n: 0,
            p: 0,
            k: 0,
            ca: 0,
            mg: 0,
            s: 0
        };
        if (this.udsByParcela) {
            i = 0;
            this.udFertN = [];
            this.udFertP = [];
            this.udFertK = [];
            this.udFertCa = [];
            this.udFertS = [];
            this.udFertMg = [];
            (this.form.parcelas.filtered || []).map(it => it.value).forEach(parcela => {
                if (idsParcelas.includes(parcela.id)) {
                    const superficie = parseFloat(
                        (parcela.superficie_cultivada || parcela.superficie_sigpac || '0.00').replace(',', '.'));
                    console.log(superficie);
                    if ([0, '0', Infinity, 'Infinity', NaN, 'NaN', false, undefined].includes(superficie)) {
                        this.udFertN.splice(parcela.id, 0, 0);
                        this.udFertP.splice(parcela.id, 0, 0);
                        this.udFertK.splice(parcela.id, 0, 0);
                        this.udFertCa.splice(parcela.id, 0, 0);
                    }
                    else {

                        if (this.appName === 'sequiador') {

                            this.udFertN.splice(parcela.id, 0, totalNSequiador);
                            this.udFertP.splice(parcela.id, 0, totalPSequiador);
                            this.udFertK.splice(parcela.id, 0, this.totalK / superficie);
                            this.udFertCa.splice(parcela.id, 0, this.totalCa / superficie);
                            this.udFertS.splice(parcela.id, 0, this.totalS / superficie);
                            this.udFertMg.splice(parcela.id, 0, this.totalMg / superficie);
                        } else if (this.appName === 'prosanzcu') {
                            this.udFertN.splice(parcela.id, 0, this.totalN * superficie);
                            this.udFertP.splice(parcela.id, 0, this.totalP * superficie);
                            this.udFertK.splice(parcela.id, 0, this.totalK * superficie);
                        } else {
                            this.udFertN.splice(parcela.id, 0, this.totalN / superficie);
                            this.udFertP.splice(parcela.id, 0, this.totalP / superficie);
                            this.udFertK.splice(parcela.id, 0, this.totalK / superficie);
                            this.udFertCa.splice(parcela.id, 0, this.totalCa / superficie);
                            this.udFertS.splice(parcela.id, 0, this.totalS / superficie);
                            this.udFertMg.splice(parcela.id, 0, this.totalMg / superficie);
                        }

                    }

                    let superficieSector = this.superfSect[i];

                    if (superficieSector) {
                        superficieSector += parseFloat(
                            (parcela.superficie_cultivada || parcela.superficie_sigpac || '0.00').replace(',', '.')
                        );
                        this.superfSect[i] = superficieSector;
                    }
                }
                i++;
            });
        }
        else {
            this.superfSect.forEach(superf => {
                const s = superf;
                if ([0, '0', Infinity, 'Infinity', NaN, 'NaN', false, undefined].includes(s)) {
                    this.udFertN[i] = 0;
                    this.udFertP[i] = 0;
                    this.udFertK[i] = 0;
                    this.udFertCa[i] = 0;
                    this.udFertS[i] = 0;
                    this.udFertMg[i] = 0;
                } else {
                    if (this.appName === 'esparragosgranada') {
                        this.udFertN[i] = +(this.totalN * s)
                            .toFixed(2).toString().replace('.', ',');
                        this.udFertP[i] = +(this.totalP * s)
                            .toFixed(2).toString().replace('.', ',');
                        this.udFertK[i] = +(this.totalK * s)
                            .toFixed(2).toString().replace('.', ',');
                        this.udFertCa[i] = +(this.totalCa * s)
                            .toFixed(2).toString().replace('.', ',');
                        this.udFertS[i] = +(this.totalS * s)
                            .toFixed(2).toString().replace('.', ',');
                        this.udFertMg[i] = +(this.totalMg * s)
                            .toFixed(2).toString().replace('.', ',');

                    } else if (this.appName === 'prosanzcu') {
                        this.udFertN[i] = ((this.totalN * s));
                        this.udFertP[i] = ((this.totalP * s));
                        this.udFertK[i] = ((this.totalK * s));
                        this.udFertCa[i] = ((this.totalCa * s));
                        this.udFertS[i] = ((this.totalS * s));
                        this.udFertMg[i] = ((this.totalMg * s));

                    } else {
                        this.udFertN[i] = (this.totalN / s);
                        this.udFertP[i] = (this.totalP / s);
                        this.udFertK[i] = (this.totalK / s);
                        this.udFertCa[i] = (this.totalCa / s);
                        this.udFertS[i] = (this.totalS / s);
                        this.udFertMg[i] = (this.totalMg / s);
                    }

                    this.totalesTabla.superficie += s;
                    this.totalesTabla.n += (this.udFertN[i] ?? 0);
                    this.totalesTabla.p += (this.udFertP[i] ?? 0);
                    this.totalesTabla.k += (this.udFertK[i] ?? 0);
                    this.totalesTabla.ca += (this.udFertCa[i] ?? 0);
                    this.totalesTabla.s += (this.udFertS[i] ?? 0);
                    this.totalesTabla.mg += (this.udFertMg[i] ?? 0);
                }
                i++;
            });

        }

        if (this.appName === 'abonosfolques' || this.appName === 'pyf') {
            i = 0;
            this.totalesTabla.n = 0;
            this.totalesTabla.p = 0;
            this.totalesTabla.k = 0;
            this.totalesTabla.ca = 0;

            this.superfSect.forEach(superf => {
                if ([0, '0', Infinity, 'Infinity', NaN, 'NaN', false, undefined].includes(this.totalesTabla.superficie)) {
                    this.udFertN[i] = 0;
                    this.udFertP[i] = 0;
                    this.udFertK[i] = 0;
                    this.udFertCa[i] = 0;
                } else {
                    this.udFertN[i] = (superf / this.totalesTabla.superficie) * this.totalN;
                    this.udFertP[i] = (superf / this.totalesTabla.superficie) * this.totalP;
                    this.udFertK[i] = (superf / this.totalesTabla.superficie) * this.totalK;
                    this.udFertCa[i] = (superf / this.totalesTabla.superficie) * this.totalCa;
                }

                this.totalesTabla.n += (this.udFertN[i] ?? 0);
                this.totalesTabla.p += (this.udFertP[i] ?? 0);
                this.totalesTabla.k += (this.udFertK[i] ?? 0);
                this.totalesTabla.ca += (this.udFertCa[i] ?? 0);
                i++;
            });
        }

    }

    public async submit() {

        if (this.appName !== 'agrosalvi') {
            delete this.model['facturado'];
        }// if();

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }

        if (!environment.features.showTareasMto) {
            delete this.model.aplicado;
            
            if (this.appName !== 'fotoagricultura') {
                delete this.model.ids_trabajadores;
            }
        } else {
            const horaFin = moment(this.model.hora_fin, 'HH:mm');
            const horaInicio = moment(this.model.hora_inicio, 'HH:mm');
            const diferenciaEntreHoras = horaFin.diff(horaInicio);

            if (diferenciaEntreHoras < 0) {
                this.requestButton.error = 'La hora de inicio es mayor que la de fin.';
                return;
            }// if();

            if (this.signatureFile != null) {
                const formData = new FormData();
                formData.append('fileToUpload', this.signatureFile);
                formData.append('database', 'tareas_fertilizantes');
                formData.append('folder', 'fertilizantes');
                formData.append('field', 'signature_img');
                const signature = await firstValueFrom(
                    this.http.post<FileUploadAnswer>(this.serverUrl + 'ws/tareas/fileUploadGeneric.php', formData)
                );
                this.model.signature_img = signature.target_file.replace('../../images/fertilizantes/', '');
            }// if();
        }

        if (this.appName !== 'agropecuariaramirogruas') {
            delete this.model.grupo;
            delete this.model.tipo_riego;
            delete this.model.produccion_estimada;
            delete this.model.necesidad_maxima;
            delete this.model.aporte;
            delete this.model.cosecha_obtenida;
        }// if();

        if (this.appName !== 'onda' && this.appName !== 'lacooperativa') {
            delete this.model.estado_tarea;
            delete this.model.empresa_pagadora;
        }// if();

        delete this.model.fecha_riego;
        delete this.model.fecha_asesoramiento;

        this.formRequest.send();

        if ((this.getType() === FormRequestTypes.DUPLICATE)) {
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

    public formSectores() {
        this.calculateDiferencePorSector();
        this.calculateGramosPorArbol();
        console.log('formSectores');
    }

    public formChanges(tag: string) {
        if (environment.features.showTareasMto || environment.features.tareasHorasTotales || environment.features.showTareasFertiHoras) {
            if (this.model.hora_inicio != null && this.model.hora_fin != null) {

                let horaFin = moment(this.model.hora_fin).format('HH:mm');
                let horaInicio = moment(this.model.hora_inicio).format('HH:mm');
                const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
                if (!timeFormat.test(horaInicio)) {
                    horaInicio = this.model.hora_inicio;
                }

                if (!timeFormat.test(horaFin)) {
                    horaFin = this.model.hora_fin;
                }

                const diferencia = this.formatoHorasMinutos(horaInicio, horaFin);
                this.model.horas_totales = diferencia;
            } else {
                this.model.horas_totales = '';
            }
        }
        this.form.lastFieldEdited = tag;
        this.requestButton.error = '';

        if (tag === 'numero_dias' || tag === 'dias_semana') {
            this.validate();
        }

        if (tag.includes('dosis') || tag === 'id_parcelas') {
            this.calculateKilosPorSector();
            this.calculateGramosPorArbol();
            console.log('dosis');
        }

        if (tag.includes('gramos') && this.appName !== 'prosanzcu') {
            this.calculateKilosTotal();
            this.calculateKilosPorSector();
        }

        if (tag === 'id_finca') {
            this.model.ids_sectores = null;
            this.model.id_parcelas = null;
        }

        if (tag === 'ids_sectores') {
            this.model.id_parcelas = null;
            this.calculateKilosPorSector();
        }

        if (tag === 'id_cliente') {
            this.model.id_finca = null;
            this.model.ids_sectores = null;
            this.model.id_parcelas = null;

            pool([
                buildParams(this.fincasApi.fincasGET, { id_cliente: this.model.id_cliente }, this.form.fincas,
                    'nombre', true),
                buildParams(this.fincasApi.sectoresGET, { id_cliente: this.model.id_cliente }, this.form.sectores,
                    'nombre', true),
                buildParams(this.fincasApi.parcelasGET, { id_cliente: this.model.id_cliente }, this.form.parcelas,
                    'nombre', true),
                buildParams(this.trabajadoresApi.maquinariaGET, { id_cliente: this.model.id_cliente }, this.form.maquinarias,
                    'nombre')
            ], () => {
                this.form.fincas.filtered = this.form.fincas.values;
                this.form.sectores.filtered = [];
                this.form.parcelas.values = this.form.parcelas.values.filter(it => (it.label !== '...'));
            }).then(() => {
                this.formRequest.update();
                return;
            }).catch (e => {
                console.log('catch en pool: ' + e);
            }
            );
        }

        if (tag === 'declara_buenas_practicas') {
            this.form.formFieldsPac.map(it => {

                if (it.field === 'codigo_buena_practica') {
                    if (!this.model.declara_buenas_practicas) {
                        it.inputType = { type: InputType.TEXT };
                        it.visible = false;
                    } else {
                        it.inputType = { type: InputType.DROPDOWN };
                    }
                }
            });
        }

        setTimeout(() => {
            this.recalculateEmmiter.next(true);
        }, 5);

        this.countSuperficieParcelas();
        this.calculaNPKs();
        if (this.appName === 'pyf') {
            this.divideKgByHa();
        }
    }

    public selectAll() {
        this.calculaNPKs();
        this.countSuperficieParcelas();
    }

    public validate() {
        this.errorMessage = '';

        if (this.model.dias_semana) {
            if ((this.form.weekDays.selected || []).length !== parseInt(this.model.numero_dias ?? '', 10)) {
                this.errorMessage = 'Días Semana no coincide con Número Días';
            }
        }
    }

    // NORMAL
    public calculateKilosPorSector() {
        if (!this.form.sectores.selected) {
            return;
        }
        // if (this.getType() === FormRequestTypes.EDIT) {
        //     return;
        // }
        if (this.showKilosHectarea) {
            this.calculateKilosPorSectorProsanzcu();
        } else {
            const totalArboles = this.getTotalArboles();

            this.getProductsWithDosis()
                .forEach((_producto, index) => {
                    const cantidadCorrespondientePorSector = (
                        parseInt((this.form.sectores.selected[this.kgPorSectorTabIndex || 0] || {}).plantas, 10)
                        * +(this.model['dosis' + (index + 1)] || 0)
                    ) / totalArboles;

                    this.calculoSectores['dosis' + (index + 1)] = parseFloat(cantidadCorrespondientePorSector.toFixed(2));
                });
        }
    }

    public calculateKilosPorSectorProsanzcu() {
        const totalHectareas = this.getTotalSuperficieParcelas();

        this.getProductsWithDosis()
            .forEach((_producto, index) => {
                const cantidadCorrespondientePorSector = (
                    parseInt((this.form.sectores.selected[this.kgPorSectorTabIndex || 0] || {}).plantas, 10)
                    * +(this.model['dosis' + (index + 1)] || 0)
                ) / totalHectareas;

                this.calculoSectores['dosis' + (index + 1)] = parseFloat(cantidadCorrespondientePorSector.toFixed(2));
            });
    }



    public calculateDiferencePorSector() {
        let totalArboles;
        if (this.showKilosHectarea) {
            totalArboles = this.getTotalSuperficieParcelas();
        } else {
            totalArboles = this.getTotalArboles();
        }

        this.getProductsWithDosis()
            .forEach((_producto, index) => {
                const dosisKey = 'dosis' + (index + 1);
                const totalKg = totalArboles * (this.calculoSectores[dosisKey] || 0) / this.getArbolesBySector(index);


                const oldKgPorSector = (
                    parseInt((this.form.sectores.selected[this.kgPorSectorTabIndex || 0] || {}).plantas, 10)
                    * totalKg
                ) / totalArboles;

                const newKgPorSector = this.calculoSectores[dosisKey] ?? 0;

                const newTotalKg = newKgPorSector * totalKg / oldKgPorSector;

                this.model[dosisKey] = parseFloat(newTotalKg.toFixed(2));
            });
    }

    // SUPPORT

    public getProductsWithDosis(): ProductosModel[] {
        return [
            this.form.productos1.selected ?? {} as ProductosModel,
            this.form.productos2.selected ?? {} as ProductosModel,
            this.form.productos3.selected ?? {} as ProductosModel,
            this.form.productos4.selected ?? {} as ProductosModel,
            this.form.productos5.selected ?? {} as ProductosModel,
            this.form.productos6.selected ?? {} as ProductosModel,
            this.form.productos7.selected ?? {} as ProductosModel
        ].filter(it => it)
            .map((producto: ProductosModel, index: number) => ({
                id_producto: producto.id,
                nombre: producto.nombre,
                materia_activa: producto.materia_activa,
                dosis: +(this.model['dosis' + (index + 1)] || 0)
            }));
    }

    public override getItemPosition(formFields: ItemInterface<object>[], field: string) {
        let position = 0;
        formFields.forEach((item, index) => {
            if (item.field === field) {
                position = index;
            }
        });
        return position;
    }

    public countSuperficieParcelas() {
        this.model.parcelas_superficie = this.getTotalSuperficieParcelas();
        this.model.parcelas_superficie = Utils.decimalFormat(this.model.parcelas_superficie, 2, '.', ',', 3);

        const idxParcelas = this.getItemPosition(this.form.formFieldsSectorLeft, 'id_parcelas');
        
        (this.form.formFieldsSectorLeft[idxParcelas] as ItemInterface<ParcelasModel>).label = 
            this.parcelasName + ' (' + this.tipoSuperficieParcelas
         + ':\xa0' + this.model.parcelas_superficie + '\xa0' + this.textoSuperficie + ')';
    }

    public divideKgByHa() {
        let contador = 1;
        if (this.model.parcelas_superficie == null || this.model.parcelas_superficie === 0 || this.model.parcelas_superficie === 0.00) {
            for (let index = 0; index < this.form.formFieldsBottomLeft.length; index++) {
                if (this.form.formFieldsBottomLeft[index] == null || this.form?.formFieldsBottomLeft[index]?.field == null) {
                } else {
                    if (this.form?.formFieldsBottomLeft[index]?.field?.includes('kg_ha')) {
                        this.model['kg_ha' + contador] = '';
                        contador++;
                    }
                }


            }
        } else {
            for (let index = 0; index < 7; index++) {
                if (this.model['dosis' + (index + 1)] != null) {

                    if (this.model['dosis' + (index + 1)] === 0) {
                        this.model['kg_ha' + (index + 1)] = '';
                    } else {
                        const kg = +parseFloat(this.model['dosis' + (index + 1)]?.toString() ?? '');
                        const superficie = parseFloat(this.model.parcelas_superficie.toString().replace(',', '.'));
                        this.model['kg_ha' + (index + 1)] = Utils.decimalFormat((kg / superficie), 2, '.', ',', 2);
                    }

                }

            }
        }
    }


    override goBack() {
        this.router.navigate(['dashboard', 'fertilizantes']);
    }

    generarInforme() {
        const prod = {} as ProductosModel;
        const sect = {} as SectorModel;

        const prods = [
            this.form.productos1.selected,
            this.form.productos2.selected,
            this.form.productos3.selected,
            this.form.productos4.selected,
            this.form.productos5.selected,
            this.form.productos6.selected,
            this.form.productos7.selected
        ];
        (prods || []).forEach(producto => {
            if (producto) {
                prod.id = producto.id ?? '';
                prod.nombre = producto.nombre;
                prod.cantidad = +(producto.cantidad ?? 0);
                this.planProductos.push(prod);
            }
        });

        (this.form.sectores.selected || []).forEach((sector: SectorModel) => {
            sect.id = sector.id ?? '';
            sect.nombre = sector.nombre;

            this.planSectores.push(sect);
        });

        this.showPlanAbonado = true;

    }

    public override ngOnDestroy() {
        clearInterval(this.intervaloParcelas);
    }

    formatoHorasMinutos(horaEntrada: string, horaSalida: string) {
        let timeStr: string | string[] = horaEntrada;
        timeStr = timeStr.split(':');

        const h = timeStr[0] ?? '',
            m = timeStr[1] ?? '';

        const newTime = moment(horaSalida + ' 2021-4-5')
            .subtract({ 'hours': +h, 'minutes': +m })
            .format('HH:mm');


        return newTime;

    }


    public getObservaciones(event: string) {
        this.model.observaciones = event;
    }

    public getOrdenTrabajo(event: string) {
        this.model.orden_trabajo = event;
    }

    private expandFormFields() {

        if (environment.features.showTareasMto) {
            this.model.aplicado = [1, '1', true, 'true'].includes(this.model.aplicado ?? false);

            const sessionData = StorageManager.getSessionDataDonJardin();
            if (this.getType() === 0) {
                if (sessionData[1] === null) {
                    const fechaActual = new Date();
                    if (checkIfRolAdmin()) {
                        this.model.hora_fin = fechaActual.toString();
                    }// if();
                }// if();
                else {
                    this.model.id_finca = sessionData[0] ?? '';
                }// if();
            }// if();
        }// if();

        if (this.hasClients && this.userCanSeeClientes && (this.getType() === FormRequestTypes.DUPLICATE)) {
            this.form.formFieldsSectorLeft.unshift(
                {
                    field: 'id_cliente',
                    label: this.clienteName,
                    inputType: { type: InputType.DROPDOWN_SEARCH },
                    values: this.form.clientes,
                    valuePrimaryKey: 'id',
                    visible: this.hasClients,
                    required: true
                },
            );
            this.distributionOrder = [4, 5, 2];
        }

        this.formRequest.beforeSend(resolve => {
            if (this.showClientesNotas) {
                delete this.model.notas_cliente;
            }
            this.model.fecha_inicio = this.model.fecha_inicio
                ? Utils.formatDate(this.model.fecha_inicio)
                : this.model.fecha_inicio ?? '';

            this.model.fecha_fin = this.model.fecha_fin
                ? Utils.formatDate(this.model.fecha_fin)
                : this.model.fecha_fin ?? '';

            this.model.fecha_riego = this.model.fecha_riego
                ? Utils.formatDate(this.model.fecha_riego)
                : this.model.fecha_riego ?? '';

            if (this.applicationType !== 'cropgest'){
                delete this.model.fecha_riego;
            }

            this.model.fecha_asesoramiento = this.model.fecha_asesoramiento
                ? Utils.formatDate(this.model.fecha_asesoramiento)
                : this.model.fecha_asesoramiento ?? '';


            if (this.applicationType !== 'cropgest'){
                delete this.model.fecha_asesoramiento;
            }
            // Si solo hay un sector no existe la función filter(), por lo que no se guarda.
            if (this.form.sectores.selected && this.form.sectores.selected.length > 1) {
                this.model.horas_during = (this.form.sectores.selected || [])
                    .filter((it: SectorModel) => it && it.horas_dia_menos_45min)
                    .map((it: SectorModel) => it.horas_dia_menos_45min)
                    .join(';');

                this.model.horas_post = (this.form.sectores.selected || [])
                    .filter((it: SectorModel) => it && it.tiempoPostRiego)
                    .map((it: SectorModel) => it.tiempoPostRiego)
                    .join(';');

            }

            for (let i = 1; i <= 7; i++) {
                if (!this.model[`id_producto${i}`]) {
                    this.model[`id_producto${i}`] = null;
                    this.model[`dosis${i}`] = null;
                }
            }

            if (this.hasClients) {
                const clientId = (StorageManager.getClient() || {}).id;
                const clientIdFromFinca = (this.form.fincas.selected || {}).id_cliente;
                const clientIdFromForm = this.model.id_cliente;

                this.model.id_cliente =
                    clientIdFromFinca ? clientIdFromFinca :
                        clientIdFromForm ? clientIdFromForm :
                            clientId ? clientId : ''; // DEFAULT
            }

            const userId = StorageManager.getUser().id;
            const userIdFromFinca = (this.form.fincas.selected || {}).id_usuario;
            const userIdFromForm = this.model.id_usuario;

            if (!environment.features.showTareasMto) {
                this.model.id_usuario =
                    userIdFromFinca ? userIdFromFinca :
                        userIdFromForm ? userIdFromForm :
                            userId ? userId : '';
            }
            else {
                if (this.getType() === FormRequestTypes.CREATE || this.getType() === FormRequestTypes.DUPLICATE) {
                    this.model.id_usuario = userId ? userId : '';
                }// if();
            }// if();

            if (this.model.hora_inicio != null) {
                const horaInicio = moment(this.model.hora_inicio, 'HH:mm').format('HH:mm');
                this.model.hora_inicio = horaInicio;
                if (!this.model.hora_inicio.includes(':')) {
                    this.model.hora_inicio = null;
                }
            }

            if (this.model.hora_fin != null) {
                const horaFin = moment(this.model.hora_fin, 'HH:mm').format('HH:mm');
                this.model.hora_fin = horaFin;
                if (!this.model.hora_fin.includes(':')) {
                    this.model.hora_fin = null;
                }
            }

            this.model.cultivo = (this.form.parcelas.selected || []).map((it: VisitasVariedadMesModel) => it.cultivo).join(';');
            this.model.variedad = (this.form.parcelas.selected || []).map((it: VisitasVariedadMesModel) => it.variedad).join(';');
            this.model.sup_cultivada = (this.form.parcelas.selected || [])
                .map((it: ParcelasModel) => parseFloat(
                    (it.superficie_cultivada || it.superficie_sigpac || '0.00').replace(',', '.') || '0.00')
                )
                .reduce((a: string, b: string) => a + b, 0);

            resolve(true);
        });


        this.formRequest.afterSend(resolve => {
            StorageManager.saveSessionDataDonJardin(
                this.model.id_finca ?? '', 
                this.model.hora_inicio ?? '', 
                this.model.hora_fin?.toString() ?? ''
            );
            resolve(true);
        });
    }


    private initFormData(): Promise<unknown> {

        const buildsRequest = [
            build(this.fincasApi.fincasGET, this.form.fincas, 'nombre', true),
            build(this.fincasApi.sectoresGET, this.form.sectores, 'nombre', true),
            build(this.fincasApi.parcelasGET, this.form.parcelas, 'nombre', true),
            build(this.trabajadoresApi.trabajadoresGET, this.form.trabajadores,
                (it: MantenimientoModel) => it.nombre + ' ' + (it.apellidos || ''),
                true, true),
            build(this.trabajadoresApi.maquinariaGET, this.form.maquinarias, 'nombre'),
            build(this.clientesApi.clientesGET, this.form.clientes, { or: ['razon_social', 'nombre'] }),
            build(this.productosApi.productos.GET, [
                this.form.productos1,
                this.form.productos2,
                this.form.productos3,
                this.form.productos4,
                this.form.productos5,
                this.form.productos6,
                this.form.productos7
            ], 'nombre', true)
        ];

        if (environment.features.showAssignUserTasksFitosanitario || environment.features.showAssignUserInAllTasks) {
            buildsRequest.push(build(this.userApi.usuariosGET, this.form.users, 'nombre'));
        }

        return pool(buildsRequest, () => {
            this.recalculateEmmiter.next(true);

            setTimeout(() => {
                this.calculaNPKs();
                this.calculateGramosPorArbol();
                this.calculateKilosPorSector();
                this.countSuperficieParcelas();
            }, 1000);

            this.formRequest.update();

            if (this.showClientesNotas && this.getType() === 0) {
                this.model.notas_cliente = StorageManager.getClient().notas_cliente;
            }

            if (this.form.fincas.filtered.length === 2 && this.getType() === 0) {
                this.model.id_finca = this.form.fincas.filtered[1].value.id;
            }
        }).then(() => {
            if (this.getType() === FormRequestTypes.EDIT) {
                this.loadingData = true;
                this.intervaloParcelas = setInterval(() => {

                    this.form.formFieldsSectorLeft.map(it => {
                        if (it.field === 'codigo_buena_practica') {
                            it.inputType = { type: InputType.DROPDOWN };
                        }
                    });

                    this.loadingData = true;
                    if (this.form.parcelas.values != null && this.model.id != null) {
                        this.countSuperficieParcelas();
                        this.loadingData = false;
                        clearInterval(this.intervaloParcelas);
                        if (this.appName === 'pyf') {
                            this.divideKgByHa();
                        }
                    }
                }, 1000);
            }

            this.loadingData = false;
            return;
        }).catch (e => {
            console.log('catch en pool: ' + e);
        }
        );
    }

    private expandFormRequest() {
        if (StorageManager.getUser().tipo === 'comunero') {
            this.model.id_cliente = StorageManager.getUser().id_cliente ?? '';
        }

        this.formRequest.afterLoad(resolve => {
            if (this.appName === 'agrosalvi') {
                this.model.facturado = this.model.facturado === '1';
            }

            if (this.applicationType === 'cropgest') {
                this.model.declara_buenas_practicas = this.model.declara_buenas_practicas === '1';
                this.model.plan_abonado = this.model.plan_abonado === '1';

                const intervalo = setInterval(() => {
                    if (this.form.maquinarias.filtered.length <= 1) {

                    } else {
                        this.form.maquinarias.filtered.forEach(row => {
                            if (row.value && row.value.fecha_ultima_inspeccion) {
                                const auxFechaCad = (row.value.fecha_ultima_inspeccion as string).split('/');

                                const edadMaquinaria = moment().year() - moment(row.value.fecha_adquisicion, 'DD/MM/YYYY').year();

                                if (edadMaquinaria > 10 || edadMaquinaria.toString() === 'NaN') {
                                    if (parseInt(auxFechaCad[2] ?? '', 10) >= 2020) {
                                        auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + 3).toString();
                                    } else {
                                        auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + 5).toString();
                                    }
                                } else {
                                    auxFechaCad[2] = (parseInt(auxFechaCad[2] ?? '', 10) + 10).toString();
                                }

                                const fechaCaducidad = auxFechaCad[0] + '/' + auxFechaCad[1] + '/' + auxFechaCad[2];

                                let tiempoAntes = false;
                                if (this.appName === 'prodalbar') {
                                    tiempoAntes = moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment().add(1, 'years'));
                                } else {
                                    tiempoAntes = moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment().add(3, 'months'));
                                }

                                if (moment(fechaCaducidad, 'DD/MM/YYYY').isBefore(moment())) {
                                    row.label = '** ' + row.label;
                                } else if (
                                    moment(fechaCaducidad, 'DD/MM/YYYY').isSameOrAfter(moment()) && tiempoAntes
                                ) {
                                    row.label = '* ' + row.label;
                                }
                            }
                        });
                        clearInterval(intervalo);
                    }
                }, 500);
            }

            if (this.horaFechaFinActualPorDefecto && this.getType() !== FormRequestTypes.EDIT) {
                const fechaActual = new Date();

                if (this.appName !== 'sirga') {
                    this.model.fecha_fin = fechaActual.toString();
                }

                this.model.hora_fin = fechaActual.toString();
            }

            const userInfo = StorageManager.getUser();
            if (userInfo.rol === 'user' && this.getType() === FormRequestTypes.CREATE) {
                this.model['ids_trabajadores'] = userInfo.id.toString();
            }

            if (environment.features.showTareasMto && this.getType() === FormRequestTypes.DUPLICATE) {
                delete this.model.hora_fin;
                delete this.model.hora_inicio;
                delete this.model.horas_totales;
            }
            if (environment.features.showTareasMto) {
                if (this.getType() === FormRequestTypes.EDIT) {
                    this.tareasApi.fertilizantes.GET_GENERIC.response((resp) => {
                        if (resp[0]?.hora_fin == null || (resp[0].hora_fin as string).length === 0) {
                            if (StorageManager.getUser().rol === 'user') {
                                const date = new Date;
                                this.model.hora_fin = date.toString();
                            }
                        }
                    });
                }

                if (environment.features.showTareasMto) {
                    if (StorageManager.getUser().rol === 'user') {
                        this.model.aplicado = true;
                    }
                }

                if (this.getType() === FormRequestTypes.DUPLICATE || this.getType() === FormRequestTypes.CREATE) {

                    if (StorageManager.getUser().rol === 'user') {
                        const date = new Date;
                        this.model.hora_fin = date.toString();
                    }

                }
            }

            resolve(true);
        });
    }

    // NORMAL
    private calculateGramosPorArbol() {
        // if (this.getType() === FormRequestTypes.EDIT) {
        //     return;
        // }
        if (this.showKilosHectarea) {
            this.calculateKilosPorHectarea();
        } else {
            const totalArboles = this.getTotalArboles();
            const totalHectareas = this.getTotalSuperficieParcelas();

            this.getProductsWithDosis()
                .forEach((producto, index) => {
                    const gramosArbol = ((producto.dosis || 0) > 0)
                        ? (totalHectareas > 0 && this.appName === 'abonosfolques')
                            ? ((((producto.dosis || 0) / totalArboles) * totalHectareas) * 1000)
                            : (((producto.dosis || 0) / totalArboles) * 1000)
                        : 0;

                    this.model['gramos' + (index + 1)] = parseFloat(gramosArbol.toFixed(2));

                });
        }
    }


    // NORMAL
    private calculateKilosTotal() {
        // if (this.getType() === FormRequestTypes.EDIT) {
        //     return;
        // }
        if (this.showKilosHectarea) {
            this.calculateHectareasPorKilo();
        } else {
            const totalArboles = this.getTotalArboles();

            if (totalArboles !== 0) {
                this.getProductsWithDosis()
                    .forEach((_producto, index) => {
                        const kgTotal = totalArboles * +(this.model['gramos' + (index + 1)] || 0) / 1000;
                        this.model['dosis' + (index + 1)] = parseFloat(kgTotal.toFixed(2));
                    });
            }

        }
    }

    /*
     * PROSANZCU
     *
     * Cambiamos gramos/árbol por kg/ha
     */

    private calculateKilosPorHectarea() {
        const totalHectareas = this.getTotalSuperficieParcelas();

        this.getProductsWithDosis()
            .forEach((producto, index) => {
                const kilosHectarea = (producto.dosis || 0) > 0
                    ? (producto.dosis || 0) / totalHectareas
                    : 0;

                this.model['gramos' + (index + 1)] = parseFloat(kilosHectarea.toFixed(3));
            });
    }

    private calculateHectareasPorKilo() {
        const totalHectareas = this.getTotalSuperficieParcelas();

        this.getProductsWithDosis()
            .forEach((_producto, index) => {
                const kgTotal = totalHectareas * +(this.model['gramos' + (index + 1)] || 0);
                this.model['dosis' + (index + 1)] = parseFloat(kgTotal.toFixed(2));
            });
    }

    private getTotalArboles(): number {
        if (this.form.showFertilizantesParcelas) {
            return (this.form.parcelas.selected || [])
                .filter((it: ParcelasModel) => it && it.plantas)
                .map((it: ParcelasModel) => parseInt(it.plantas ?? '', 10))
                .reduce((a: number, b: number) => a + b, 0);
        }
        return (this.form.sectores.selected || [])
            .filter((it: SectorModel) => it && it.plantas)
            .map((it: SectorModel) => parseInt(it.plantas ?? '', 10))
            .reduce((a: number, b: number) => a + b, 0);
    }

    private getTotalSuperficieParcelas(): number {
        if (this.form.showFertilizantesParcelas) {
            return (this.form.parcelas.selected || [])
                .filter((it: ParcelasModel) => it && (it.superficie_cultivada || it.superficie_sigpac))
                .map((it: ParcelasModel) => parseFloat(
                    ((it.superficie_cultivada ?? 0) || (it.superficie_sigpac || 0)).toString().replace(',', '.')))
                .reduce((a: number, b: number) => a + b, 0);
        }
        return (this.form.sectores.selected || [])
            .filter((it: SectorModel) => it && (it.superficie_cultivada || it.superficie_sigpac))
            .map((it: SectorModel) => parseFloat(
                ((it.superficie_cultivada ?? 0)|| (it.superficie_sigpac ?? 0)).toString().replace(',', '.')))
            .reduce((a: number, b: number) => a + b, 0);
    }

    private getArbolesBySector(index: number): number {
        return parseInt((this.form.sectores.selected || [])[index].plantas || 0, 10);
    }
}