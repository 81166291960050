<p-panel>
    <h4>Datos del fertilizante</h4>
        <div class="row">
            <div class="col-sm-11">
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="3"
                        (formChanges)="formChanges($event)"
                        [fields]="form.topFormFields">
                </app-form-builder>
            </div>
            <div *ngIf="this.appName==='agroleza'" class="col-sm-1">
                <input type="file" #adjuntarFoto (change)="fileChange($event)" style="display: none;" accept="image/*"/>
                <div class="d-flex justify-content-center">
                    <input type="button" class="btn"
                    style='border-radius: 5px;
                        font-size: 1em;
                        padding: 6px 12px;
                        font-family: "Roboto", "Trebuchet MS", Arial, Helvetica, sans-serif; background-color: #27A745;color:white;'
                    value="Añadir foto" (click)="readUrl(adjuntarFoto)" />
                </div>
                <img [src]="serverUrl + 'fotos_productos/' + model.imagen" *ngIf="model?.imagen" width="100%" height="100%" alt="Foto Producto">
            </div>
        </div>
    <div class="row">
        <div class="col-sm-6">
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="2"
                    (formChanges)="formChanges($event)"
                    [fields]="form.bottomFormFieldsLeft">
            </app-form-builder>
        </div>
        <div class="col-sm-6">
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="6"
                    (formChanges)="formChanges($event)"
                    [fields]="form.bottomFormFieldsRight">
            </app-form-builder>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <app-form-builder
                    [model]="model"
                    [distributionColumns]="2"
                    (formChanges)="formChanges($event)"
                    [fields]="form.baselineFormFields">
            </app-form-builder>
        </div>
    </div>
    <br>
    <h4 *ngIf="applicationType === 'cropgest'" >Empresa</h4>
    <div class="row" *ngIf="applicationType === 'cropgest'">
        <div class="col">   
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="4"
                        (formChanges)="formChanges($event)"
                        [fields]="form.formFieldsEmpresaResiduos">
                </app-form-builder>
        </div>
    </div>
    <br>
    <h4 *ngIf="applicationType === 'cropgest'" >Macronutrientes</h4>
    <div class="row" *ngIf="applicationType === 'cropgest'">
        <div class="col">   
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="4"
                        (formChanges)="formChanges($event)"
                        [fields]="form.formFieldsMaterialFerti">
                </app-form-builder>
        </div>
    </div>
    <br>
    <h4 *ngIf="applicationType === 'cropgest'" >Metales Pesados</h4>
    <div class="row" *ngIf="applicationType === 'cropgest'">
        <div class="col">   
                <app-form-builder
                        [model]="model"
                        [distributionColumns]="4"
                        (formChanges)="formChanges($event)"
                        [fields]="form.formFieldsMetalesPesados">
                </app-form-builder>
        </div>
    </div>
    <br>

    <div class="d-flex justify-content-center">
        <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
        <app-request-button
                (click)="submit()"
                [mode]="requestButtonType"
                [lockOnSuccess]="true"
                [request]="request">
        </app-request-button>
    </div>
</p-panel>
<br>
