import { environment } from './../../../environments/environment';
import {Injectable} from '@angular/core';
import {SyncService} from '../OffService/sync.service';
import {ApiRequestService, HttpRequestType} from '../OffService/api-request.service';
import {RequestHandler} from '../OffService/request-handler';
import { CosteModel } from 'src/app/models/coste.model';
import { TareaOtraModel } from 'src/app/models/tareas/tarea-otra.model';
import { TareaFitoModel } from 'src/app/models/tareas/tarea-fito.model';
import { TareaFertiModel } from 'src/app/models/tareas/tarea-ferti.model';
import { TareasBioModel } from 'src/app/models/tareas/tareas-bio.model';
import { TareaPlantacionModel } from 'src/app/models/tareas/tarea-plantacion.model';
import { TareaRiegoModel } from 'src/app/models/tareas/tarea-riego.model';
import { TareaPodaModel } from 'src/app/models/tareas/tarea-poda.model';
import { TareaPiscinasModel } from 'src/app/models/tareas/tarea-piscinas.model';
import { TareaLimpiezaGeneralModel } from 'src/app/models/tareas/tarea-limpieza-general.model';
import { TareaCespedModel } from 'src/app/models/tareas/tarea-cesped.model';
import { TareaEdificiosModel } from 'src/app/models/tareas/tarea-edificios.model';
import { TareaAlquilerMaquinariaModel } from 'src/app/models/tareas/tarea-alquiler-maquinaria.model';
import { SubcontratoModel } from 'src/app/models/tareas/subcontrato.model';
import { TareaSiembraModel } from 'src/app/models/tareas/tarea-siembra.model';
import { FitoLocalesModel } from 'src/app/models/tareas/fito-locales.model';
import { ControlSanitarioModel } from 'src/app/models/tareas/control-sanitario.model';
import { RiegoModel } from 'src/app/models/tareas/riego.model';
import { CalendarioTareaModel } from 'src/app/models/tareas/calendario-tarea.model';
import { VisitasModel } from 'src/app/models/visitas/visitas.model';
import { VisitasSemanaModel } from 'src/app/models/visitas/visitas-semana.model';
import { CalendarioAbonadoModel } from 'src/app/models/tareas/calendario-abonado.model ';
import { FertiAbonadoInterface } from 'src/app/view/main/5_tareas/calendario-abonado/ferti-abonado.interface';
import { AgroambientalModel } from 'src/app/models/tareas/agroambientales.model';
import { EcorregimenModel } from 'src/app/models/tareas/ecorregimen.model';

@Injectable({
    providedIn: 'root'
})
export class TareasApiService {

    public diasSemanaGET = new RequestHandler<VisitasSemanaModel[]>();

    public fitosanitariosGET = new RequestHandler<TareaFitoModel[]>();
    public fitosanitariosGET_GENERIC = new RequestHandler<TareaFitoModel[]>();
    public fitosanitariosDELETE = new RequestHandler<TareaFitoModel>();
    public fitosanitariosPUT = new RequestHandler<TareaFitoModel[]>();
    public fitosanitariosPOST = new RequestHandler<TareaFitoModel[]>();

    public enviarReceta = new RequestHandler<TareaFitoModel>();
    public enviarRecetaCaptura = new RequestHandler<TareaFitoModel>();
    public enviarVisita = new RequestHandler<VisitasModel>();    
    public enviarVisitaCaptura = new RequestHandler<VisitasModel>();

    
    public calendarioGET = new RequestHandler<CalendarioTareaModel[]>();
    
    public fertilizantes = {
        GET: new RequestHandler<TareaFertiModel[]>(),
        GET_GENERIC: new RequestHandler<TareaFertiModel[]>(),
        DELETE: new RequestHandler<TareaFertiModel>(),
        PUT: new RequestHandler<TareaFertiModel[]>(),
        POST: new RequestHandler<TareaFertiModel[]>(),
    };

    public bio = {
        GET: new RequestHandler<TareasBioModel[]>(),
        DELETE: new RequestHandler<TareasBioModel>(),
        PUT: new RequestHandler<TareasBioModel[]>(),
        POST: new RequestHandler<TareasBioModel[]>(),
    };
    public mtoPoda = {
        GET: new RequestHandler<TareaPodaModel[]>(),
        DELETE: new RequestHandler<TareaPodaModel>(),
        PUT: new RequestHandler<TareaPodaModel[]>(),
        POST: new RequestHandler<TareaPodaModel[]>(),
    };

    public mtoCesped = {
        GET: new RequestHandler<TareaCespedModel[]>(),
        GET_GENERIC: new RequestHandler<TareaCespedModel[]>(),
        DELETE: new RequestHandler<TareaCespedModel>(),
        PUT: new RequestHandler<TareaCespedModel[]>(),
        POST: new RequestHandler<TareaCespedModel[]>(),
    };

    public mtoLimpiezaGeneral = {
        GET: new RequestHandler<TareaLimpiezaGeneralModel[]>(),
        DELETE: new RequestHandler<TareaLimpiezaGeneralModel>(),
        PUT: new RequestHandler<TareaLimpiezaGeneralModel[]>(),
        POST: new RequestHandler<TareaLimpiezaGeneralModel[]>(),
    };

    public mtoRiego = {
        GET: new RequestHandler<TareaRiegoModel[]>(),
        DELETE: new RequestHandler<TareaRiegoModel>(),
        PUT: new RequestHandler<TareaRiegoModel[]>(),
        POST: new RequestHandler<TareaRiegoModel[]>(),
    };
    
    public mtoPiscinas = {
        GET: new RequestHandler<TareaPiscinasModel[]>(),
        DELETE: new RequestHandler<TareaPiscinasModel>(),
        PUT: new RequestHandler<TareaPiscinasModel[]>(),
        POST: new RequestHandler<TareaPiscinasModel[]>(),
    };

    public plantacion = {
        GET: new RequestHandler<TareaPlantacionModel[]>(),
        DELETE: new RequestHandler<TareaPlantacionModel>(),
        PUT: new RequestHandler<TareaPlantacionModel[]>(),
        POST: new RequestHandler<TareaPlantacionModel[]>(),
    };

    public alquilerMaquinaria = {
        GET: new RequestHandler<TareaAlquilerMaquinariaModel[]>(),
        DELETE: new RequestHandler<TareaAlquilerMaquinariaModel>(),
        PUT: new RequestHandler<TareaAlquilerMaquinariaModel[]>(),
        POST: new RequestHandler<TareaAlquilerMaquinariaModel[]>(),
    };

    public edificios = {
        GET: new RequestHandler<TareaEdificiosModel[]>(),
        DELETE: new RequestHandler<TareaEdificiosModel>(),
        PUT: new RequestHandler<TareaEdificiosModel[]>(),
        POST: new RequestHandler<TareaEdificiosModel[]>(),
    };

    public otras = {
        GET: new RequestHandler<TareaOtraModel[]>(),
        DELETE: new RequestHandler<TareaOtraModel>(),
        PUT: new RequestHandler<TareaOtraModel[]>(),
        POST: new RequestHandler<TareaOtraModel[]>()
    };
    public costes = {
        GET: new RequestHandler<CosteModel[]>(),
        DELETE: new RequestHandler<CosteModel>(),
        PUT: new RequestHandler<CosteModel>(),
        POST: new RequestHandler<CosteModel>()
    };

    public listaTareas = {
        GET: new RequestHandler<TareaInterface[]>(),
        DELETE: new RequestHandler<TareaInterface>(),
        PUT: new RequestHandler<TareaInterface[]>(),
        POST: new RequestHandler<TareaInterface[]>()
    };

    public tablaAbonado = {
        GET: new RequestHandler<CalendarioAbonadoModel[]>(),
        POST: new RequestHandler<FertiAbonadoInterface>(),
        PUT: new RequestHandler<FertiAbonadoInterface>(),
        DELETE: new RequestHandler<CalendarioAbonadoModel>()
    };

    public controlRiego = {
        GET: new RequestHandler<RiegoModel[]>(),
        POST: new RequestHandler<RiegoModel[]>(),
        PUT: new RequestHandler<RiegoModel[]>(),
        DELETE: new RequestHandler<RiegoModel>()
    };

    public controlSanitario = {
        GET: new RequestHandler<ControlSanitarioModel[]>(),
        POST: new RequestHandler<ControlSanitarioModel[]>(),
        PUT: new RequestHandler<ControlSanitarioModel[]>(),
        DELETE: new RequestHandler<ControlSanitarioModel>()
    };

    public fitoLocales = {
        GET: new RequestHandler<FitoLocalesModel[]>(),
        POST: new RequestHandler<FitoLocalesModel[]>(),
        PUT: new RequestHandler<FitoLocalesModel[]>(),
        DELETE: new RequestHandler<FitoLocalesModel>()
    };


    public controlRiegoLinea = {
        GET: new RequestHandler<RiegoModel[]>(),
        POST: new RequestHandler<RiegoModel[]>(),
        PUT: new RequestHandler<RiegoModel[]>(),
        DELETE: new RequestHandler<RiegoModel>()
    };
    
    public controlRiegoTable = {
        POST: new RequestHandler<RiegoModel[]>()
    };
    
    public resumenConsumosAguaAbonos = {
        GET: new RequestHandler<RiegoModel[]>(),
        POST: new RequestHandler<RiegoModel[]>(),
        PUT: new RequestHandler<RiegoModel[]>(),
        DELETE: new RequestHandler<RiegoModel>()
    };

    public siembra = {
        GET: new RequestHandler<TareaSiembraModel[]>(),
        POST: new RequestHandler<TareaSiembraModel[]>(),
        PUT: new RequestHandler<TareaSiembraModel[]>(),
        DELETE: new RequestHandler<TareaSiembraModel>()
    };

    public justificacion_actuacion = {
        GET: new RequestHandler<unknown>(),
        POST: new RequestHandler<unknown>(),
        PUT: new RequestHandler<unknown>(),
        DELETE: new RequestHandler<unknown>()
    };

    public post_cosecha = {
        GET: new RequestHandler<unknown>(),
        POST: new RequestHandler<unknown>(),
        PUT: new RequestHandler<unknown>(),
        DELETE: new RequestHandler<unknown>()
    };

    public edificaciones = {
        GET: new RequestHandler<unknown>(),
        POST: new RequestHandler<unknown>(),
        PUT: new RequestHandler<unknown>(),
        DELETE: new RequestHandler<unknown>()
    };

    public medidasFito = {
        GET: new RequestHandler<unknown>(),
        POST: new RequestHandler<unknown>(),
        PUT: new RequestHandler<unknown>(),
        DELETE: new RequestHandler<unknown>()
    };

    public medidasPreventivas = {
        GET: new RequestHandler<unknown>(),
        POST: new RequestHandler<unknown>(),
        PUT: new RequestHandler<unknown>(),
        DELETE: new RequestHandler<unknown>()
    };

    public actividadesAgraria = {
        GET: new RequestHandler<unknown>(),
        POST: new RequestHandler<unknown>(),
    };

    public detalleActividadesAgraria = {
        GET: new RequestHandler<unknown>(),
        POST: new RequestHandler<unknown>(),
    };

    public buenasPracticas = {
        GET: new RequestHandler<unknown>(),
        POST: new RequestHandler<unknown>()
    };

    public riego = {
        GET: new RequestHandler<unknown>(),
        POST: new RequestHandler<unknown>(),
        PUT: new RequestHandler<unknown>(),
        DELETE: new RequestHandler<unknown>()    
    };

    public subcontratos = {
        GET: new RequestHandler<SubcontratoModel[]>(),
        POST: new RequestHandler<SubcontratoModel[]>(),
        PUT: new RequestHandler<SubcontratoModel[]>(),
        DELETE: new RequestHandler<SubcontratoModel>()    
    };

    public agroambientales = {
        GET: new RequestHandler<AgroambientalModel[]>(),
        POST: new RequestHandler<AgroambientalModel[]>(),
        PUT: new RequestHandler<AgroambientalModel[]>(),
        DELETE: new RequestHandler<AgroambientalModel>()
    };
    
    public ecorregimenes = {
        GET: new RequestHandler<EcorregimenModel[]>(),
        POST: new RequestHandler<EcorregimenModel[]>(),
        PUT: new RequestHandler<EcorregimenModel[]>(),
        DELETE: new RequestHandler<EcorregimenModel>()        
    };

    constructor(
        private syncServiceSemana: SyncService<VisitasSemanaModel[]>,
        private syncServiceReceta: SyncService<TareaFitoModel>,
        private syncServiceVisita: SyncService<VisitasModel>,
        private syncServiceFerti: SyncService<TareaFertiModel[]>,
        private syncServiceFertiDelete: SyncService<TareaFertiModel>,
        private syncServiceBio: SyncService<TareasBioModel[]>,
        private syncServiceBioDelete: SyncService<TareasBioModel>,
        private syncServicePoda: SyncService<TareaPodaModel[]>,
        private syncServicePodaDelete: SyncService<TareaPodaModel>,
        private syncServiceCesped: SyncService<TareaCespedModel[]>,
        private syncServiceCespedDelete: SyncService<TareaCespedModel>,
        private syncServiceLimpieza: SyncService<TareaLimpiezaGeneralModel[]>,
        private syncServiceLimpiezaDelete: SyncService<TareaLimpiezaGeneralModel>,
        private syncServiceRiego: SyncService<TareaRiegoModel[]>,
        private syncServiceRiegoDelete: SyncService<TareaRiegoModel>,
        private syncServicePiscina: SyncService<TareaPiscinasModel[]>,
        private syncServicePiscinaDelete: SyncService<TareaPiscinasModel>,
        private syncServicePlantacion: SyncService<TareaPlantacionModel[]>,
        private syncServicePlantacionDelete: SyncService<TareaPlantacionModel>,
        private syncServiceAlquiler: SyncService<TareaAlquilerMaquinariaModel[]>,
        private syncServiceAlquilerDelete: SyncService<TareaAlquilerMaquinariaModel>,
        private syncServiceEdificio: SyncService<TareaEdificiosModel[]>,
        private syncServiceEdificioDelete: SyncService<TareaEdificiosModel>,
        private syncServiceFito: SyncService<TareaFitoModel[]>,
        private syncServiceFitoDelete: SyncService<TareaFitoModel>,
        private syncServiceCoste: SyncService<CosteModel[]>,
        private syncServiceOtro: SyncService<TareaOtraModel[]>,
        private syncServiceOtroDelete: SyncService<TareaOtraModel>,
        private syncServiceListaTareas: SyncService<TareaInterface[]>,
        private syncServiceListaTareasDelete: SyncService<TareaInterface>,
        private syncServiceAbonado: SyncService<CalendarioAbonadoModel[]>,
        private syncServiceFertiAbonado: SyncService<FertiAbonadoInterface>,
        private syncServiceAbonadoDelete: SyncService<CalendarioAbonadoModel>,
        private syncServiceControl: SyncService<RiegoModel[]>,
        private syncServiceControlDelete: SyncService<RiegoModel>,
        private syncServiceSanidad: SyncService<ControlSanitarioModel[]>,
        private syncServiceSanidadDelete: SyncService<ControlSanitarioModel>,
        private syncServiceFitoLocal: SyncService<FitoLocalesModel[]>,
        private syncServiceFitoLocalDelete: SyncService<FitoLocalesModel>,
        private syncServiceSiembra: SyncService<TareaSiembraModel[]>,
        private syncServiceSiembraDelete: SyncService<TareaSiembraModel>,
        private syncServiceAgroambiental: SyncService<AgroambientalModel[]>,
        private syncServiceAgroambientalDelete: SyncService<AgroambientalModel>,
        private syncServiceEcorregimen: SyncService<EcorregimenModel[]>,
        private syncServiceEcorregimenDelete: SyncService<EcorregimenModel>,
        private syncServiceSubcontrato: SyncService<SubcontratoModel[]>,
        private syncServiceSubcontratoDelete: SyncService<SubcontratoModel>,
        private syncServiceCalendario: SyncService<CalendarioTareaModel[]>,
        private syncService: SyncService<unknown>,
        private apiSemana: ApiRequestService<VisitasSemanaModel[]>,
        private apiReceta: ApiRequestService<TareaFitoModel>,
        private apiVisita: ApiRequestService<VisitasModel>,
        private apiFerti: ApiRequestService<TareaFertiModel[]>,
        private apiFertiDelete: ApiRequestService<TareaFertiModel>,
        private apiBio: ApiRequestService<TareasBioModel[]>,
        private apiBioDelete: ApiRequestService<TareasBioModel>,
        private apiPoda: ApiRequestService<TareaPodaModel[]>,
        private apiPodaDelete: ApiRequestService<TareaPodaModel>,
        private apiCesped: ApiRequestService<TareaCespedModel[]>,
        private apiCespedDelete: ApiRequestService<TareaCespedModel>,
        private apiLimpieza: ApiRequestService<TareaLimpiezaGeneralModel[]>,
        private apiLimpiezaDelete: ApiRequestService<TareaLimpiezaGeneralModel>,
        private apiRiego: ApiRequestService<TareaRiegoModel[]>,
        private apiRiegoDelete: ApiRequestService<TareaRiegoModel>,
        private apiPiscina: ApiRequestService<TareaPiscinasModel[]>,
        private apiPiscinaDelete: ApiRequestService<TareaPiscinasModel>,
        private apiPlantacion: ApiRequestService<TareaPlantacionModel[]>,
        private apiPlantacionDelete: ApiRequestService<TareaPlantacionModel>,
        private apiAlquiler: ApiRequestService<TareaAlquilerMaquinariaModel[]>,
        private apiAlquilerDelete: ApiRequestService<TareaAlquilerMaquinariaModel>,
        private apiEdificio: ApiRequestService<TareaEdificiosModel[]>,
        private apiEdificioDelete: ApiRequestService<TareaEdificiosModel>,
        private apiFito: ApiRequestService<TareaFitoModel[]>,
        private apiFitoDelete: ApiRequestService<TareaFitoModel>,
        private apiCoste: ApiRequestService<CosteModel[]>,
        private apiOtro: ApiRequestService<TareaOtraModel[]>,
        private apiOtroDelete: ApiRequestService<TareaOtraModel>,
        private apiListaTareas: ApiRequestService<TareaInterface[]>,
        private apiListaTareasDelete: ApiRequestService<TareaInterface>,
        private apiAbonado: ApiRequestService<CalendarioAbonadoModel[]>,
        private apiFertiAbonado: ApiRequestService<FertiAbonadoInterface>,
        private apiAbonadoDelete: ApiRequestService<CalendarioAbonadoModel>,
        private apiControl: ApiRequestService<RiegoModel[]>,
        private apiControlDelete: ApiRequestService<RiegoModel>,
        private apiSanidad: ApiRequestService<ControlSanitarioModel[]>,
        private apiSanidadDelete: ApiRequestService<ControlSanitarioModel>,
        private apiFitoLocal: ApiRequestService<FitoLocalesModel[]>,
        private apiFitoLocalDelete: ApiRequestService<FitoLocalesModel>,
        private apiSiembra: ApiRequestService<TareaSiembraModel[]>,
        private apiSiembraDelete: ApiRequestService<TareaSiembraModel>,
        private apiAgroambiental: ApiRequestService<AgroambientalModel[]>,
        private apiAgroambientalDelete: ApiRequestService<AgroambientalModel>,
        private apiEcorregimen: ApiRequestService<EcorregimenModel[]>,
        private apiEcorregimenDelete: ApiRequestService<EcorregimenModel>,
        private apiSubcontrato: ApiRequestService<SubcontratoModel[]>,
        private apiSubcontratoDelete: ApiRequestService<SubcontratoModel>,
        private apiCalendario: ApiRequestService<CalendarioTareaModel[]>,
        private api: ApiRequestService<unknown>) {

        this.defineDiasSemanaGET();
        this.defineFitosanitariosGET();
        this.defineFitosanitariosPOST();
        this.defineFitosanitariosDELETE();
        this.defineFitosanitariosPUT();
        this.defineFertilizantes();
        this.defineBio();
        this.defineMtoPoda();
        this.defineMtoCesped();
        this.defineMtoLimpiezaGeneral();
        this.defineMtoRiego();
        this.defineMtoPiscinas();
        this.definePlantacion();
        this.defineAlquilerMaquinaria();
        this.defineEdificios();
        this.defineEnviarReceta();
        this.defineEnviarRecetaCaptura();
        this.defineEnviarVisita();        
        this.defineEnviarVisitaCaptura();
        this.defineListaTareas();
        this.defineOtros();
        this.defineTablaAbonado();
        this.defineControlRiego();
        this.defineControlSanitario();
        this.defineFitoLocales();
        this.defineControlRiegoLinea();
        this.defineControlRiegoTable();
        this.defineResumenConsumosAguaAbono();
        this.defineSiembra();
        this.defineCostes();
        this.defineJustificacionActuacion();
        this.definePostCosecha();
        this.defineMedidasFito();
        this.defineEdificaciones();
        this.defineOtrasConsultas();
        this.defineRiego();
        this.defineAgroambiental();
        this.defineCalendario();
        this.defineSubcontratos();
        this.defineEcorregimes();
    }   

    public defineDiasSemanaGET() {
        this.diasSemanaGET.fetch(HttpRequestType.GET, 'tareas/dias_semana_get');
        this.apiSemana.registerRequest(this.diasSemanaGET);
        this.syncServiceSemana.register(this.syncServiceSemana.baseSyncPolicy(this.diasSemanaGET));
    }

    public defineEnviarReceta() {
        this.enviarReceta.fetch(HttpRequestType.POST, 'tareas/enviar_receta');
        this.apiReceta.registerRequest(this.enviarReceta);
        this.syncServiceReceta.register(this.syncServiceReceta.baseSyncPolicy(this.enviarReceta));
    }
    public defineEnviarRecetaCaptura() {
        this.enviarRecetaCaptura.fetch(HttpRequestType.POST, 'tareas/enviar_receta_captura');
        this.apiReceta.registerRequest(this.enviarRecetaCaptura);
        this.syncServiceReceta.register(this.syncServiceReceta.baseSyncPolicy(this.enviarRecetaCaptura));
    }
    public defineEnviarVisita() {
        this.enviarVisita.fetch(HttpRequestType.POST, 'tareas/enviar_visita');
        this.apiVisita.registerRequest(this.enviarVisita);
        this.syncServiceVisita.register(this.syncServiceVisita.baseSyncPolicy(this.enviarVisita));
    }    
    public defineEnviarVisitaCaptura() {
        this.enviarVisitaCaptura.fetch(HttpRequestType.POST, 'tareas/enviar_visita_captura');
        this.apiVisita.registerRequest(this.enviarVisitaCaptura);
        this.syncServiceVisita.register(this.syncServiceVisita.baseSyncPolicy(this.enviarVisitaCaptura));
    }

    public defineFertilizantes(){
        this.fertilizantes.GET.fetch(HttpRequestType.GET, 'tareas/fertilizantes_get');
        this.fertilizantes.GET_GENERIC.fetch(HttpRequestType.GET, 'generic/tareas_fertilizantes');
        this.fertilizantes.POST.fetch(HttpRequestType.POST, 'generic/tareas_fertilizantes');
        this.fertilizantes.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_fertilizantes');
        this.fertilizantes.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_fertilizantes');

        this.apiFerti.registerRequest(this.fertilizantes.GET);
        this.apiFerti.registerRequest(this.fertilizantes.GET_GENERIC);
        this.apiFerti.registerRequest(this.fertilizantes.POST);
        this.apiFerti.registerRequest(this.fertilizantes.PUT);
        this.apiFertiDelete.registerRequest(this.fertilizantes.DELETE);

        this.syncServiceFerti.register(this.syncServiceFerti.baseSyncPolicy(this.fertilizantes.GET));
        this.syncServiceFerti.register(this.syncServiceFerti.baseSyncPolicy(this.fertilizantes.GET_GENERIC));
        this.syncServiceFerti.register(this.syncServiceFerti.baseSyncPolicy(this.fertilizantes.POST));
        this.syncServiceFerti.register(this.syncServiceFerti.baseSyncPolicy(this.fertilizantes.PUT));
        this.syncServiceFertiDelete.register(this.syncServiceFertiDelete.baseSyncPolicy(this.fertilizantes.DELETE));
    }

    public defineBio() {
        this.bio.GET.fetch(HttpRequestType.GET, 'tareas/tareas_bio_get');
        this.bio.POST.fetch(HttpRequestType.POST, 'generic/tareas_bio');
        this.bio.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_bio');
        this.bio.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_bio');

        this.apiBio.registerRequest(this.bio.GET);
        this.apiBio.registerRequest(this.bio.POST);
        this.apiBio.registerRequest(this.bio.PUT);
        this.apiBioDelete.registerRequest(this.bio.DELETE);

        this.syncServiceBio.register(this.syncServiceBio.baseSyncPolicy(this.bio.GET));
        this.syncServiceBio.register(this.syncServiceBio.baseSyncPolicy(this.bio.POST));
        this.syncServiceBio.register(this.syncServiceBio.baseSyncPolicy(this.bio.PUT));
        this.syncServiceBioDelete.register(this.syncServiceBioDelete.baseSyncPolicy(this.bio.DELETE));
    }
    public defineMtoPoda() {
        this.mtoPoda.GET.fetch(HttpRequestType.GET, 'tareas/tareas_mto_poda_get');
        this.mtoPoda.POST.fetch(HttpRequestType.POST, 'generic/tareas_mto_poda');
        this.mtoPoda.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_mto_poda');
        this.mtoPoda.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_mto_poda');

        this.apiPoda.registerRequest(this.mtoPoda.GET);
        this.apiPoda.registerRequest(this.mtoPoda.POST);
        this.apiPoda.registerRequest(this.mtoPoda.PUT);
        this.apiPodaDelete.registerRequest(this.mtoPoda.DELETE);

        this.syncServicePoda.register(this.syncServicePoda.baseSyncPolicy(this.mtoPoda.GET));
        this.syncServicePoda.register(this.syncServicePoda.baseSyncPolicy(this.mtoPoda.POST));
        this.syncServicePoda.register(this.syncServicePoda.baseSyncPolicy(this.mtoPoda.PUT));
        this.syncServicePodaDelete.register(this.syncServicePodaDelete.baseSyncPolicy(this.mtoPoda.DELETE));
    }

    public defineMtoCesped() {
        this.mtoCesped.GET.fetch(HttpRequestType.GET, 'tareas/tareas_mto_cesped_get');
        this.mtoCesped.GET_GENERIC.fetch(HttpRequestType.GET, 'tareas/tareas_mto_cesped_get');
        this.mtoCesped.POST.fetch(HttpRequestType.POST, 'generic/tareas_mto_cesped');
        this.mtoCesped.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_mto_cesped');
        this.mtoCesped.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_mto_cesped');

        this.apiCesped.registerRequest(this.mtoCesped.GET);
        this.apiCesped.registerRequest(this.mtoCesped.GET_GENERIC);
        this.apiCesped.registerRequest(this.mtoCesped.POST);
        this.apiCesped.registerRequest(this.mtoCesped.PUT);
        this.apiCespedDelete.registerRequest(this.mtoCesped.DELETE);

        this.syncServiceCesped.register(this.syncServiceCesped.baseSyncPolicy(this.mtoCesped.GET));
        this.syncServiceCesped.register(this.syncServiceCesped.baseSyncPolicy(this.mtoCesped.GET_GENERIC));
        this.syncServiceCesped.register(this.syncServiceCesped.baseSyncPolicy(this.mtoCesped.POST));
        this.syncServiceCesped.register(this.syncServiceCesped.baseSyncPolicy(this.mtoCesped.PUT));
        this.syncServiceCespedDelete.register(this.syncServiceCespedDelete.baseSyncPolicy(this.mtoCesped.DELETE));
    }

    public defineMtoLimpiezaGeneral() {
        this.mtoLimpiezaGeneral.GET.fetch(HttpRequestType.GET, 'tareas/tareas_mto_limpieza_general_get');
        this.mtoLimpiezaGeneral.POST.fetch(HttpRequestType.POST, 'generic/tareas_mto_limpieza_general');
        this.mtoLimpiezaGeneral.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_mto_limpieza_general');
        this.mtoLimpiezaGeneral.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_mto_limpieza_general');

        this.apiLimpieza.registerRequest(this.mtoLimpiezaGeneral.GET);
        this.apiLimpieza.registerRequest(this.mtoLimpiezaGeneral.POST);
        this.apiLimpieza.registerRequest(this.mtoLimpiezaGeneral.PUT);
        this.apiLimpiezaDelete.registerRequest(this.mtoLimpiezaGeneral.DELETE);

        this.syncServiceLimpieza.register(this.syncServiceLimpieza.baseSyncPolicy(this.mtoLimpiezaGeneral.GET));
        this.syncServiceLimpieza.register(this.syncServiceLimpieza.baseSyncPolicy(this.mtoLimpiezaGeneral.POST));
        this.syncServiceLimpieza.register(this.syncServiceLimpieza.baseSyncPolicy(this.mtoLimpiezaGeneral.PUT));
        this.syncServiceLimpiezaDelete.register(this.syncServiceLimpiezaDelete.baseSyncPolicy(this.mtoLimpiezaGeneral.DELETE));
    }
    
    public defineMtoRiego() {
        this.mtoRiego.GET.fetch(HttpRequestType.GET, 'tareas/tareas_mto_riego_get');
        this.mtoRiego.POST.fetch(HttpRequestType.POST, 'generic/tareas_mto_riego');
        this.mtoRiego.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_mto_riego');
        this.mtoRiego.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_mto_riego');

        this.apiRiego.registerRequest(this.mtoRiego.GET);
        this.apiRiego.registerRequest(this.mtoRiego.POST);
        this.apiRiego.registerRequest(this.mtoRiego.PUT);
        this.apiRiegoDelete.registerRequest(this.mtoRiego.DELETE);

        this.syncServiceRiego.register(this.syncServiceRiego.baseSyncPolicy(this.mtoRiego.GET));
        this.syncServiceRiego.register(this.syncServiceRiego.baseSyncPolicy(this.mtoRiego.POST));
        this.syncServiceRiego.register(this.syncServiceRiego.baseSyncPolicy(this.mtoRiego.PUT));
        this.syncServiceRiegoDelete.register(this.syncServiceRiegoDelete.baseSyncPolicy(this.mtoRiego.DELETE));
    }

    public defineMtoPiscinas() {
        this.mtoPiscinas.GET.fetch(HttpRequestType.GET, 'tareas/tareas_mto_piscinas_get');
        this.mtoPiscinas.POST.fetch(HttpRequestType.POST, 'generic/tareas_mto_piscinas');
        this.mtoPiscinas.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_mto_piscinas');
        this.mtoPiscinas.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_mto_piscinas');

        this.apiPiscina.registerRequest(this.mtoPiscinas.GET);
        this.apiPiscina.registerRequest(this.mtoPiscinas.POST);
        this.apiPiscina.registerRequest(this.mtoPiscinas.PUT);
        this.apiPiscinaDelete.registerRequest(this.mtoPiscinas.DELETE);

        this.syncServicePiscina.register(this.syncServicePiscina.baseSyncPolicy(this.mtoPiscinas.GET));
        this.syncServicePiscina.register(this.syncServicePiscina.baseSyncPolicy(this.mtoPiscinas.POST));
        this.syncServicePiscina.register(this.syncServicePiscina.baseSyncPolicy(this.mtoPiscinas.PUT));
        this.syncServicePiscinaDelete.register(this.syncServicePiscinaDelete.baseSyncPolicy(this.mtoPiscinas.DELETE));
    }

    public definePlantacion() {
        this.plantacion.GET.fetch(HttpRequestType.GET, 'tareas/tareas_plantacion_get');
        this.plantacion.POST.fetch(HttpRequestType.POST, 'generic/tareas_plantacion');
        this.plantacion.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_plantacion');
        this.plantacion.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_plantacion');

        this.apiPlantacion.registerRequest(this.plantacion.GET);
        this.apiPlantacion.registerRequest(this.plantacion.POST);
        this.apiPlantacion.registerRequest(this.plantacion.PUT);
        this.apiPlantacionDelete.registerRequest(this.plantacion.DELETE);

        this.syncServicePlantacion.register(this.syncServicePlantacion.baseSyncPolicy(this.plantacion.GET));
        this.syncServicePlantacion.register(this.syncServicePlantacion.baseSyncPolicy(this.plantacion.POST));
        this.syncServicePlantacion.register(this.syncServicePlantacion.baseSyncPolicy(this.plantacion.PUT));
        this.syncServicePlantacionDelete.register(this.syncServicePlantacionDelete.baseSyncPolicy(this.plantacion.DELETE));
    }

    public defineAlquilerMaquinaria() {
        this.alquilerMaquinaria.GET.fetch(HttpRequestType.GET, 'tareas/tareas_alquiler_maquinaria_get');
        this.alquilerMaquinaria.POST.fetch(HttpRequestType.POST, 'generic/tareas_alquiler_maquinaria');
        this.alquilerMaquinaria.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_alquiler_maquinaria');
        this.alquilerMaquinaria.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_alquiler_maquinaria');

        this.apiAlquiler.registerRequest(this.alquilerMaquinaria.GET);
        this.apiAlquiler.registerRequest(this.alquilerMaquinaria.POST);
        this.apiAlquiler.registerRequest(this.alquilerMaquinaria.PUT);
        this.apiAlquilerDelete.registerRequest(this.alquilerMaquinaria.DELETE);

        this.syncServiceAlquiler.register(this.syncServiceAlquiler.baseSyncPolicy(this.alquilerMaquinaria.GET));
        this.syncServiceAlquiler.register(this.syncServiceAlquiler.baseSyncPolicy(this.alquilerMaquinaria.POST));
        this.syncServiceAlquiler.register(this.syncServiceAlquiler.baseSyncPolicy(this.alquilerMaquinaria.PUT));
        this.syncServiceAlquilerDelete.register(this.syncServiceAlquilerDelete.baseSyncPolicy(this.alquilerMaquinaria.DELETE));
    }

    public defineEdificios() {
        this.edificios.GET.fetch(HttpRequestType.GET, 'tareas/tareas_edificios_get');
        this.edificios.POST.fetch(HttpRequestType.POST, 'generic/tareas_edificios');
        this.edificios.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_edificios');
        this.edificios.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_edificios');

        this.apiEdificio.registerRequest(this.edificios.GET);
        this.apiEdificio.registerRequest(this.edificios.POST);
        this.apiEdificio.registerRequest(this.edificios.PUT);
        this.apiEdificioDelete.registerRequest(this.edificios.DELETE);

        this.syncServiceEdificio.register(this.syncServiceEdificio.baseSyncPolicy(this.edificios.GET));
        this.syncServiceEdificio.register(this.syncServiceEdificio.baseSyncPolicy(this.edificios.POST));
        this.syncServiceEdificio.register(this.syncServiceEdificio.baseSyncPolicy(this.edificios.PUT));
        this.syncServiceEdificioDelete.register(this.syncServiceEdificioDelete.baseSyncPolicy(this.edificios.DELETE));
    }

    public defineFitosanitariosGET() {
        this.fitosanitariosGET.fetch(HttpRequestType.GET, 'tareas/fitosanitarios_get');
        this.fitosanitariosGET_GENERIC.fetch(HttpRequestType.GET, 'generic/tareas_fitosanitarias');

        this.apiFito.registerRequest(this.fitosanitariosGET);
        this.apiFito.registerRequest(this.fitosanitariosGET_GENERIC);

        this.syncServiceFito.register(this.syncServiceFito.baseSyncPolicy(this.fitosanitariosGET));
        this.syncServiceFito.register(this.syncServiceFito.baseSyncPolicy(this.fitosanitariosGET_GENERIC));
    }

    public defineFitosanitariosPOST() {
        this.fitosanitariosPOST
            .fetch(HttpRequestType.POST, 'generic/tareas_fitosanitarias');

        this.apiFito
            .registerRequest(this.fitosanitariosPOST);

        this.syncServiceFito
            .register(this.syncServiceFito.baseSyncPolicy(this.fitosanitariosPOST));
    }

    public defineFitosanitariosDELETE() {
        this.fitosanitariosDELETE
            .fetch(HttpRequestType.DELETE, 'generic/tareas_fitosanitarias');

        this.apiFitoDelete
            .registerRequest(this.fitosanitariosDELETE);

        this.syncServiceFitoDelete
            .register(this.syncServiceFitoDelete.baseSyncPolicy(this.fitosanitariosDELETE));
    }

    public defineFitosanitariosPUT() {
        this.fitosanitariosPUT
            .fetch(HttpRequestType.PUT, 'generic/tareas_fitosanitarias');

        this.apiFito
            .registerRequest(this.fitosanitariosPUT);

        this.syncServiceFito
            .register(this.syncServiceFito.baseSyncPolicy(this.fitosanitariosPUT));
    }

    private defineCostes() {
        if ( environment.features.showCostesAll) {
            this.costes.GET.fetch(HttpRequestType.GET, 'costes/costes_tareas_otras_get');
        } else {
            this.costes.GET.fetch(HttpRequestType.GET, 'tareas/costes_tareas_get');
        }

        this.apiCoste.registerRequest(this.costes.GET);
        this.syncServiceCoste.register(this.syncServiceCoste.baseSyncPolicy(this.costes.GET));
        
    }

    private defineOtros() {
        this.otras.GET.fetch(HttpRequestType.GET, 'tareas/otros_get');
        this.otras.POST.fetch(HttpRequestType.POST, 'generic/tareas_otras');
        this.otras.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_otras');
        this.otras.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_otras');

        this.apiOtro.registerRequest(this.otras.GET);
        this.apiOtro.registerRequest(this.otras.POST);
        this.apiOtro.registerRequest(this.otras.PUT);
        this.apiOtroDelete.registerRequest(this.otras.DELETE);

        this.syncServiceOtro.register(this.syncServiceOtro.baseSyncPolicy(this.otras.GET));
        this.syncServiceOtro.register(this.syncServiceOtro.baseSyncPolicy(this.otras.POST));
        this.syncServiceOtro.register(this.syncServiceOtro.baseSyncPolicy(this.otras.PUT));
        this.syncServiceOtroDelete.register(this.syncServiceOtroDelete.baseSyncPolicy(this.otras.DELETE));
    }

    private defineListaTareas() {
        this.listaTareas.GET.fetch(HttpRequestType.GET, 'generic/tareas');
        this.listaTareas.POST.fetch(HttpRequestType.POST, 'generic/tareas');
        this.listaTareas.PUT.fetch(HttpRequestType.PUT, 'generic/tareas');
        this.listaTareas.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas');

        this.apiListaTareas.registerRequest(this.listaTareas.GET);
        this.apiListaTareas.registerRequest(this.listaTareas.POST);
        this.apiListaTareas.registerRequest(this.listaTareas.PUT);
        this.apiListaTareasDelete.registerRequest(this.listaTareas.DELETE);

        this.syncServiceListaTareas.register(this.syncServiceListaTareas.baseSyncPolicy(this.listaTareas.GET));
        this.syncServiceListaTareas.register(this.syncServiceListaTareas.baseSyncPolicy(this.listaTareas.POST));
        this.syncServiceListaTareas.register(this.syncServiceListaTareas.baseSyncPolicy(this.listaTareas.PUT));
        this.syncServiceListaTareasDelete.register(this.syncServiceListaTareasDelete.baseSyncPolicy(this.listaTareas.DELETE));
    }

    private defineTablaAbonado() {
        this.tablaAbonado.GET.fetch(HttpRequestType.GET, 'tareas/tabla_abonado_get');
        this.tablaAbonado.POST.fetch(HttpRequestType.POST, 'generic/tabla_abonado');
        this.tablaAbonado.PUT.fetch(HttpRequestType.PUT, 'generic/tabla_abonado');
        this.tablaAbonado.DELETE.fetch(HttpRequestType.DELETE, 'tareas/tabla_abonado_delete');

        this.apiAbonado.registerRequest(this.tablaAbonado.GET);
        this.apiFertiAbonado.registerRequest(this.tablaAbonado.POST);
        this.apiFertiAbonado.registerRequest(this.tablaAbonado.PUT);
        this.apiAbonadoDelete.registerRequest(this.tablaAbonado.DELETE);

        this.syncServiceAbonado.register(this.syncServiceAbonado.baseSyncPolicy(this.tablaAbonado.GET));
        this.syncServiceFertiAbonado.register(this.syncServiceFertiAbonado.baseSyncPolicy(this.tablaAbonado.POST));
        this.syncServiceFertiAbonado.register(this.syncServiceFertiAbonado.baseSyncPolicy(this.tablaAbonado.PUT));
        this.syncServiceAbonadoDelete.register(this.syncServiceAbonadoDelete.baseSyncPolicy(this.tablaAbonado.DELETE));
    }

    private defineControlRiego() {
        this.controlRiego.GET.fetch(HttpRequestType.GET, 'tareas/control_riego_get');
        this.controlRiego.POST.fetch(HttpRequestType.POST, 'generic/control_riego');
        this.controlRiego.PUT.fetch(HttpRequestType.PUT, 'generic/control_riego');
        this.controlRiego.DELETE.fetch(HttpRequestType.DELETE, 'generic/control_riego');

        this.apiControl.registerRequest(this.controlRiego.GET);
        this.apiControl.registerRequest(this.controlRiego.POST);
        this.apiControl.registerRequest(this.controlRiego.PUT);
        this.apiControlDelete.registerRequest(this.controlRiego.DELETE);

        this.syncServiceControl.register(this.syncServiceControl.baseSyncPolicy(this.controlRiego.GET));
        this.syncServiceControl.register(this.syncServiceControl.baseSyncPolicy(this.controlRiego.POST));
        this.syncServiceControl.register(this.syncServiceControl.baseSyncPolicy(this.controlRiego.PUT));
        this.syncServiceControlDelete.register(this.syncServiceControlDelete.baseSyncPolicy(this.controlRiego.DELETE));
    }

    private defineControlSanitario() {
        this.controlSanitario.GET.fetch(HttpRequestType.GET, 'tareas/control_sanitario_get');
        this.controlSanitario.POST.fetch(HttpRequestType.POST, 'generic/control_sanitario');
        this.controlSanitario.PUT.fetch(HttpRequestType.PUT, 'generic/control_sanitario');
        this.controlSanitario.DELETE.fetch(HttpRequestType.DELETE, 'generic/control_sanitario');

        this.apiSanidad.registerRequest(this.controlSanitario.GET);
        this.apiSanidad.registerRequest(this.controlSanitario.POST);
        this.apiSanidad.registerRequest(this.controlSanitario.PUT);
        this.apiSanidadDelete.registerRequest(this.controlSanitario.DELETE);

        this.syncServiceSanidad.register(this.syncServiceSanidad.baseSyncPolicy(this.controlSanitario.GET));
        this.syncServiceSanidad.register(this.syncServiceSanidad.baseSyncPolicy(this.controlSanitario.POST));
        this.syncServiceSanidad.register(this.syncServiceSanidad.baseSyncPolicy(this.controlSanitario.PUT));
        this.syncServiceSanidadDelete.register(this.syncServiceSanidadDelete.baseSyncPolicy(this.controlSanitario.DELETE));
    }

    private defineFitoLocales() {
        this.fitoLocales.GET.fetch(HttpRequestType.GET, 'tareas/tareas_fito_locales_get');
        this.fitoLocales.POST.fetch(HttpRequestType.POST, 'generic/tareas_fito_locales');
        this.fitoLocales.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_fito_locales');
        this.fitoLocales.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_fito_locales');

        this.apiFitoLocal.registerRequest(this.fitoLocales.GET);
        this.apiFitoLocal.registerRequest(this.fitoLocales.POST);
        this.apiFitoLocal.registerRequest(this.fitoLocales.PUT);
        this.apiFitoLocalDelete.registerRequest(this.fitoLocales.DELETE);

        this.syncServiceFitoLocal.register(this.syncServiceFitoLocal.baseSyncPolicy(this.fitoLocales.GET));
        this.syncServiceFitoLocal.register(this.syncServiceFitoLocal.baseSyncPolicy(this.fitoLocales.POST));
        this.syncServiceFitoLocal.register(this.syncServiceFitoLocal.baseSyncPolicy(this.fitoLocales.PUT));
        this.syncServiceFitoLocalDelete.register(this.syncServiceFitoLocalDelete.baseSyncPolicy(this.fitoLocales.DELETE));
    }

    private defineControlRiegoLinea() {
        this.controlRiegoLinea.GET.fetch(HttpRequestType.GET, 'tareas/control_riego_linea_get');
        this.controlRiegoLinea.POST.fetch(HttpRequestType.POST, 'generic/control_riego_linea');
        this.controlRiegoLinea.PUT.fetch(HttpRequestType.PUT, 'generic/control_riego_linea');
        this.controlRiegoLinea.DELETE.fetch(HttpRequestType.DELETE, 'generic/control_riego_linea');

        this.apiControl.registerRequest(this.controlRiegoLinea.GET);
        this.apiControl.registerRequest(this.controlRiegoLinea.POST);
        this.apiControl.registerRequest(this.controlRiegoLinea.PUT);
        this.apiControlDelete.registerRequest(this.controlRiegoLinea.DELETE);

        this.syncServiceControl.register(this.syncServiceControl.baseSyncPolicy(this.controlRiegoLinea.GET));
        this.syncServiceControl.register(this.syncServiceControl.baseSyncPolicy(this.controlRiegoLinea.POST));
        this.syncServiceControl.register(this.syncServiceControl.baseSyncPolicy(this.controlRiegoLinea.PUT));
        this.syncServiceControlDelete.register(this.syncServiceControlDelete.baseSyncPolicy(this.controlRiegoLinea.DELETE));
    }

    private defineControlRiegoTable() {
        this.controlRiegoTable.POST.fetch(HttpRequestType.POST, 'tareas/control_riego_table_post');
        this.apiControl.registerRequest(this.controlRiegoTable.POST);
        this.syncServiceControl.register(this.syncServiceControl.baseSyncPolicy(this.controlRiegoTable.POST));
    }

    private defineResumenConsumosAguaAbono() {
        this.resumenConsumosAguaAbonos.GET.fetch(HttpRequestType.GET, 'tareas/resumen_consumos_get');
        this.apiControl.registerRequest(this.resumenConsumosAguaAbonos.GET);
        this.syncServiceControl.register(this.syncServiceControl.baseSyncPolicy(this.resumenConsumosAguaAbonos.GET));
    }

    private defineSiembra() {
        this.siembra.GET.fetch(HttpRequestType.GET, 'tareas/siembra_get');
        this.siembra.POST.fetch(HttpRequestType.POST, 'generic/tareas_siembra');
        this.siembra.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_siembra');
        this.siembra.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_siembra');

        this.apiSiembra.registerRequest(this.siembra.GET);
        this.apiSiembra.registerRequest(this.siembra.POST);
        this.apiSiembra.registerRequest(this.siembra.PUT);
        this.apiSiembraDelete.registerRequest(this.siembra.DELETE);

        this.syncServiceSiembra.register(this.syncServiceSiembra.baseSyncPolicy(this.siembra.GET));
        this.syncServiceSiembra.register(this.syncServiceSiembra.baseSyncPolicy(this.siembra.POST));
        this.syncServiceSiembra.register(this.syncServiceSiembra.baseSyncPolicy(this.siembra.PUT));
        this.syncServiceSiembraDelete.register(this.syncServiceSiembraDelete.baseSyncPolicy(this.siembra.DELETE));
    }

    private defineJustificacionActuacion(){
        this.justificacion_actuacion.GET.fetch(HttpRequestType.GET, 'tareas/justificaciones_get');
        this.api.registerRequest(this.justificacion_actuacion.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.justificacion_actuacion.GET));
    }

    private definePostCosecha(){
        this.post_cosecha.GET.fetch(HttpRequestType.GET, 'tareas/postcosecha');
        this.post_cosecha.POST.fetch(HttpRequestType.POST, 'generic/tareas_fitosanitarias_postcosecha');
        this.post_cosecha.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_fitosanitarias_postcosecha');
        this.post_cosecha.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_fitosanitarias_postcosecha');

        this.api.registerRequest(this.post_cosecha.GET);
        this.api.registerRequest(this.post_cosecha.POST);
        this.api.registerRequest(this.post_cosecha.PUT);
        this.api.registerRequest(this.post_cosecha.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.post_cosecha.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.post_cosecha.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.post_cosecha.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.post_cosecha.DELETE));
    }

    private defineEdificaciones(){
        this.edificaciones.GET.fetch(HttpRequestType.GET, 'tareas/edificaciones_get');
        this.edificaciones.POST.fetch(HttpRequestType.POST, 'generic/tareas_fitosanitarias_edificaciones');
        this.edificaciones.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_fitosanitarias_edificaciones');
        this.edificaciones.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_fitosanitarias_edificaciones');
        
        this.api.registerRequest(this.edificaciones.GET);
        this.api.registerRequest(this.edificaciones.POST);
        this.api.registerRequest(this.edificaciones.PUT);
        this.api.registerRequest(this.edificaciones.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.edificaciones.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.edificaciones.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.edificaciones.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.edificaciones.DELETE));
    }

    private defineMedidasFito(){
        this.medidasFito.GET.fetch(HttpRequestType.GET, 'tareas/medidasFito');
        this.api.registerRequest(this.medidasFito.GET);
        this.syncService.register(this.syncService.baseSyncPolicy(this.medidasFito.GET));
    }
    private defineOtrasConsultas(){
        this.actividadesAgraria.GET.fetch(HttpRequestType.GET, 'tareas/actividad_agraria');
        this.detalleActividadesAgraria.GET.fetch(HttpRequestType.GET, 'tareas/detalleActividadAgraria');
        this.buenasPracticas.GET.fetch(HttpRequestType.GET, 'tareas/buenasPracticas');
        this.medidasPreventivas.GET.fetch(HttpRequestType.GET, 'tareas/medidasPreventivas');

        this.api.registerRequest(this.actividadesAgraria.GET);
        this.api.registerRequest(this.detalleActividadesAgraria.GET);
        this.api.registerRequest(this.buenasPracticas.GET);
        this.api.registerRequest(this.medidasPreventivas.GET);

        this.syncService.register(this.syncService.baseSyncPolicy(this.actividadesAgraria.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.detalleActividadesAgraria.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.buenasPracticas.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.medidasPreventivas.GET));
    }

    private defineRiego() {
        this.riego.GET.fetch(HttpRequestType.GET, 'tareas/riego');
        this.riego.POST.fetch(HttpRequestType.POST, 'generic/riego');
        this.riego.PUT.fetch(HttpRequestType.PUT, 'generic/riego');
        this.riego.DELETE.fetch(HttpRequestType.DELETE, 'generic/riego');

        this.api.registerRequest(this.riego.GET);
        this.api.registerRequest(this.riego.POST);
        this.api.registerRequest(this.riego.PUT);
        this.api.registerRequest(this.riego.DELETE);

        this.syncService.register(this.syncService.baseSyncPolicy(this.riego.GET));
        this.syncService.register(this.syncService.baseSyncPolicy(this.riego.POST));
        this.syncService.register(this.syncService.baseSyncPolicy(this.riego.PUT));
        this.syncService.register(this.syncService.baseSyncPolicy(this.riego.DELETE));
    }

    private defineAgroambiental() {
        this.agroambientales.GET.fetch(HttpRequestType.GET, 'tareas/agroambientales_get');
        this.agroambientales.POST.fetch(HttpRequestType.POST, 'generic/tareas_agroambientales');
        this.agroambientales.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_agroambientales');
        this.agroambientales.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_agroambientales');

        this.apiAgroambiental.registerRequest(this.agroambientales.GET);
        this.apiAgroambiental.registerRequest(this.agroambientales.POST);
        this.apiAgroambiental.registerRequest(this.agroambientales.PUT);
        this.apiAgroambientalDelete.registerRequest(this.agroambientales.DELETE);

        this.syncServiceAgroambiental.register(this.syncServiceAgroambiental.baseSyncPolicy(this.agroambientales.GET));
        this.syncServiceAgroambiental.register(this.syncServiceAgroambiental.baseSyncPolicy(this.agroambientales.POST));
        this.syncServiceAgroambiental.register(this.syncServiceAgroambiental.baseSyncPolicy(this.agroambientales.PUT));
        this.syncServiceAgroambientalDelete.register(this.syncServiceAgroambientalDelete.baseSyncPolicy(this.agroambientales.DELETE));
    }

    private defineCalendario() {
        this.calendarioGET.fetch(HttpRequestType.GET, 'tareas/calendario_get');
        this.apiCalendario.registerRequest(this.calendarioGET);
        this.syncServiceCalendario.register(this.syncServiceCalendario.baseSyncPolicy(this.calendarioGET));
    }
    
    private defineSubcontratos() {
        this.subcontratos.GET.fetch(HttpRequestType.GET, 'tareas/subcontratos_get');
        this.subcontratos.POST.fetch(HttpRequestType.POST, 'generic/tareas_subcontratos');
        this.subcontratos.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_subcontratos');
        this.subcontratos.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_subcontratos');

        this.apiSubcontrato.registerRequest(this.subcontratos.GET);
        this.apiSubcontrato.registerRequest(this.subcontratos.POST);
        this.apiSubcontrato.registerRequest(this.subcontratos.PUT);
        this.apiSubcontratoDelete.registerRequest(this.subcontratos.DELETE);

        this.syncServiceSubcontrato.register(this.syncServiceSubcontrato.baseSyncPolicy(this.subcontratos.GET));
        this.syncServiceSubcontrato.register(this.syncServiceSubcontrato.baseSyncPolicy(this.subcontratos.POST));
        this.syncServiceSubcontrato.register(this.syncServiceSubcontrato.baseSyncPolicy(this.subcontratos.PUT));
        this.syncServiceSubcontratoDelete.register(this.syncServiceSubcontratoDelete.baseSyncPolicy(this.subcontratos.DELETE));
    }

    private defineEcorregimes() {
        this.ecorregimenes.GET.fetch(HttpRequestType.GET, 'tareas/ecorregimenes_get');
        this.ecorregimenes.POST.fetch(HttpRequestType.POST, 'generic/tareas_ecorregimenes');
        this.ecorregimenes.PUT.fetch(HttpRequestType.PUT, 'generic/tareas_ecorregimenes');
        this.ecorregimenes.DELETE.fetch(HttpRequestType.DELETE, 'generic/tareas_ecorregimenes');

        this.apiEcorregimen.registerRequest(this.ecorregimenes.GET);
        this.apiEcorregimen.registerRequest(this.ecorregimenes.POST);
        this.apiEcorregimen.registerRequest(this.ecorregimenes.PUT);
        this.apiEcorregimenDelete.registerRequest(this.ecorregimenes.DELETE);

        this.syncServiceEcorregimen.register(this.syncServiceEcorregimen.baseSyncPolicy(this.ecorregimenes.GET));
        this.syncServiceEcorregimen.register(this.syncServiceEcorregimen.baseSyncPolicy(this.ecorregimenes.POST));
        this.syncServiceEcorregimen.register(this.syncServiceEcorregimen.baseSyncPolicy(this.ecorregimenes.PUT));
        this.syncServiceEcorregimenDelete.register(this.syncServiceEcorregimenDelete.baseSyncPolicy(this.ecorregimenes.DELETE));
    }
}

interface TareaInterface {
    id: string;
    nombre: string;
    maquinaria: number;
    materialProducto: number;
    coste: number;
}