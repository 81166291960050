<div id="id-modal-tratamientos">
  <p-dialog
  [header]="title_modal"
  [(visible)]="showDialog"
  (onShow)="scrollToTop()"
  [contentStyle]="{'max-height':'80vh'}"
  (onHide)="deleteInterval()"
  >
  <p-button label="Gráficas" (onClick)="mostrarGrafica()"></p-button>
    <div style="width: 93vw !important" *ngIf="showGraficas">
      <div id="loading" style="margin: 0 auto; width: 100%" *ngIf="showSpinner">
        <img style="width: 50px; display: block; margin: 0 auto; margin-top: 100px" src="assets/icon/load.gif" alt="Cargando"/>
        <br>
        <p style="
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          position: relative;
          bottom: 8px;
          left: 12px;">Generando Gráficas...</p>
      </div>
      <div class="d-flex flex-wrap justify-content-between">
        <div style="flex: 2" class="mr-2">
          <div class="row">
           <!--  <div class="col-4">
              <label for=""><span style="font-weight: bold;">Tratamiento:</span> {{data.n_tratamiento}}</label>
            </div>
            <div class="col-4">
              <label for=""><span style="font-weight: bold;">Nombre:</span> {{data.nombre}}</label>
            </div> -->
            <div class="col-4">  
              <label for=""><span style="font-weight: bold;">Fecha</span> {{data?.aux_fecha}}</label>
            </div>
           <!--  <div class="col-4">
              <label for=""><span style="font-weight: bold;">Campo:</span> {{data.campo_nombre}}</label>
            </div> -->
            <div class="col-6">
              <label for=""><span style="font-weight: bold;">Maquinaria</span> {{data?.maquinaria_nombre}}</label>
            </div>
            <div class="col-2" style="display: flex;">
             <button style="margin-left: 10px;" (click)="hiddenShowSpeed()" pTooltip="Velocidad (km/h)/Presión (bar)" tooltipPosition="top">
               <img src="assets/images/Chart/speed.png" alt=""
               [ngClass]="showSpeed ? 'button-display-charts' : 'button-display-charts-focus'"
               class="button-display-charts">
             </button>  
            </div>
          </div>  
         <br>  
        </div>
      </div>

      <div style="display: flex; justify-content: space-between;">
        
        <div style="display: flex;  gap:20px;">
          <div>Caudal {{quantityFlow}}</div>
          <div>Presión {{quantityPressure}}</div>
          <div>Velocidad {{quantityVelocidad}}</div>
          <div>Dosis {{quantityDosis}}</div>
          <div>Rpm {{quantityRpm}}</div>          
        </div>

        <button class="btn btn-info" (click)="getYestardayTratamientos()"> Ayer </button>
        <!-- <button class="btn btn-info" (click)="getTomorrowTratamientos()">
          Mañana
        </button> -->
      </div>

       <!-- BLOQUE GRÁFICAS-->     
      
      
      
      <div class="col-12" style="margin-top: 20px;" *ngIf="showSpeed">
        <app-grafica-velocidad-manez-lozano
          [grafica_vel]="grafica_vel"
          [grafica_pressure]="grafica_pressure"
          [grafica_rpm]="grafica_rpm"
          [grafica_flow]="grafica_flow"
          [grafica_dose]="grafica_dose"
          *ngIf="grafica_vel">
        </app-grafica-velocidad-manez-lozano>
      </div>

     

      
  
      <br>
      <div class="d-flex justify-content-center">
        <button class="btn btn-dark mx-2" (click)=" showGraficas = false; ">Cerrar</button>
      </div>
    </div>
    <div class="row">

      <!-- BLOQUE RECORRIDO-->
      <div class="col-12" >

        <div style="margin-top: 77px;" >
  
          <div style="display: flex; gap: 20px;justify-content: space-between;">
            <div>
              <h4>Recorrido</h4>
            </div>

            <div style="position: relative; bottom: 10px;" >
              <button style="margin: 10px"
                [ngClass]="updateMap ? 'button-refresh-active ' : ' button-refresh '"
                (click)="routeReDraw()" pTooltip="Actualizar" tooltipPosition="top">
                  <i class="fa fa-refresh " style="padding: 5px;"
                  [ngClass]="updateMap ? 'fa fa-refresh fa-spin ' : ' fa fa-refresh'"
                  aria-hidden="true">
                </i>
  
              </button>
            </div>
          </div>
          <div #gmap class="resp-iframe map-active" style="min-height: 700px !important;" *ngIf="showMap">
          </div>
        </div>
      </div>

     

    </div>
    <br>

  </p-dialog>
</div>