import { ProductosApiService } from '../../../../../service/api/productos-api';
import {FormCommonApiService} from '../../../../../service/api/formcommon-api.service';
import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {BaseForm} from '../../../../base-form';
import {FincasApiService} from '../../../../../service/api/fincas-api.service';
import {TrabajadoresApiService} from '../../../../../service/api/trabajadores-api.service';
import {TratamientosApiService} from '../../../../../service/api/tratamientos-api.service';
import {AlbaranesFormDefinition} from './form-control/form-definition';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {FormRequestTypes} from '../../../../../common/classes/form-request';
import {ClientesApiService} from '../../../../../service/api/clientes-api.service';
import {environment} from '../../../../../../environments/environment';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { SiexApiService } from '../../../../../service/api/siex-api.service';
import { UsuariosApiService } from '../../../../../service/api/usuarios-api.service';

import type { OnInit } from '@angular/core';
import { AlbaranModel } from 'src/app/models/ventas/albaran.model';
import { VentasApiService } from 'src/app/service/api/ventas-api.service';

@Component({
    selector: 'app-formulario-pulverizacion',
    templateUrl: './albaranes-form.component.html',
    styleUrls: ['./albaranes-form.component.scss']
})
export class AlbaranesFormComponent extends BaseForm<AlbaranModel> implements OnInit {
    
    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<AlbaranModel>();
    public form: AlbaranesFormDefinition = new AlbaranesFormDefinition();
    public formRequest = new AppFormRequest<AlbaranModel>();
    public override model: AlbaranModel = {};

    public distributionOrderFormTratamientos = [2, 3, 2];

    public rol = StorageManager.getUser().rol;

    public appName = environment.appName;

    public serverUrl = window.location.hostname === 'localhost'
        ? ''
        : environment.serverUrl;

    public auxServerUrl = environment.serverUrl;
    public showClientesNotas = environment.features.showClientesNotas;

    public getRequest = this.ventasApi.albaranesGET;

 
    constructor(public override route: ActivatedRoute,
        public override dashboard: DashboardService,
        public formApi: FormCommonApiService,
        public usuariosApi: UsuariosApiService,
        public ventasApi: VentasApiService,
        public fincasApi: FincasApiService,
        public trabajadoresApi: TrabajadoresApiService,
        public tratamientosApi: TratamientosApiService,
        public clientesApi: ClientesApiService,
        public productosApi: ProductosApiService,
        public siexApi: SiexApiService,
        public override router: Router,
        public dashboardEvent: DashboardService,
    ) {
        super(
            route,
            router,
            dashboard,
            ventasApi.albaranesPUT,
            ventasApi.albaranesPOST,
            'albaranes',
            'Actualizar Albarán',
            'Generar Albarán',
            'Duplicar Albarán'
        );
    }

    ngOnInit() {
        const fieldsToSend = (['id']).concat(
            this.form.formFieldsCliente.filter(it => it && (it.visible !== false)).map(it => it.field ?? ''),
            this.form.formFieldsConceptos.filter(it => it && (it.visible !== false)).map(it => it.field ?? ''),
        );

       
        this.expandFormFields();
        
        this.expandFormRequest();
        
       
        this.formRequest
            .setType(this.getType())
            .isGeneric(this.getType() === FormRequestTypes.DUPLICATE)
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.getRequest)
            .setPostRequest(this.ventasApi.albaranesPOST)
            .setPutRequest(this.ventasApi.albaranesPUT)
            .setFormFields(Array.prototype
                .concat(
                    this.form.formFieldsCliente.map(it => it.field),
                    this.form.formFieldsConceptos.map(it => it.field)
                )
            )
            .setFieldsToSend(fieldsToSend);

        this.formRequest.load();

        this.softInit(this.getType());
        this.form.requestType = this.getType();
        this.form.model = this.model;

    }
   
    public formChanges(tag: string) {
        console.log(tag);
    }

    public async submit() {

        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }

        this.formRequest.send();

        if ((this.getType() === FormRequestTypes.DUPLICATE)){
            this.router.navigate(['dashboard', this.pathToGoBack]);
        }
    }

  
    private expandFormFields() {
    }

    private expandFormRequest() {
        this.formRequest.afterLoad(resolve => {
            resolve(true);      
        });

        this.formRequest.beforeSend(resolve => {
            resolve(true);
        });
        
        this.formRequest.afterSend(resolve => {
            resolve(true);
        });

        this.formRequest.afterFinish(resolve => {
            resolve(true);
        });
    }
}