import { Component, Input, EventEmitter } from '@angular/core';
import { TrabajadoresApiService } from '../../../../../service/api/trabajadores-api.service';
import { DashboardService } from '../../../../../service/events/dashboard.service';
import { BaseView } from 'src/app/view/base-view';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { Utils } from '../../../../../common/utils';
import { StorageManager } from '../../../../../common/storage-manager.class';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { Filtering } from 'src/app/service/filtering/filtering';
import { Router } from '@angular/router';

import type { OnInit } from '@angular/core';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import { Fichaje } from 'projects/fichajes/src/app/models/Fichajes.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { CargoModel } from 'src/app/models/trabajadores/cargos.model';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-fichajes-decimal',
    templateUrl: './fichajes-decimal.component.html',
    styleUrls: ['./fichajes-decimal.component.scss']
})
export class FichajesDecimalComponent extends BaseView<Fichaje> implements OnInit {

    @Input() saveButtonClick: EventEmitter<object> = new EventEmitter<object>();
    @Input() undoButtonClick: EventEmitter<object> = new EventEmitter<object>();

    public appName = environment.appName;
    public showTrabajadoresCuadrillas = environment.features.showTrabajadoresCuadrillas;
    public showFichajesCargos = environment.features.showFichajesCargos;
    public showFichajesExportarCSV = environment.features.showFichajesExportarCSV;

    public override filtering: Filtering<Fichaje> = new Filtering<Fichaje>();

    public getRequest: RequestHandler<Fichaje[]> = this.trabajadoresApi.fichajesGET;
    public postRequest: RequestHandler<Fichaje[]> = this.trabajadoresApi.fichajesPOST;
    public deleteRequest: RequestHandler<Fichaje> = this.trabajadoresApi.fichajesDELETE;
    public isPerforming = false;

    public usuarioActual = StorageManager.getUser();
    public trabajadores: MantenimientoModel[] = [];
    public cuadrillas: {label: string, value: MantenimientoModel | null}[] = [];
    public cuadrilla: {id: string} = {id: '0'};
    public cargos: {label: string, value: CargoModel | null}[] = [];
    public cargo: {id: string} = {id: '0'};
    public RANDOM_VALUE_MARGIN = (this.appName === 'naturfrut') ? 400 : 0;

    public editingEnabled = false;
    public date = moment().toDate();
    public es = Utils.es;
    public override model = {} as Fichaje;
    public isDownloadingCsv = false;
    public almacenFilter = '';


    public cols: ColInterface[] = [];
    public colsNoEdit: ColInterface[] = [
        { field: 'nif', header: 'NIF' },
        { field: 'nombre', header: 'Nombre' },
        { field: 'horaEntrada', header: 'Entrada (mañana)' },
        { field: 'horaSalida', header: 'Salida (mañana)' },
        { field: 'horaEntradaTarde', header: 'Entrada (tarde)' },
        { field: 'horaSalidaTarde', header: 'Salida (tarde)' },
        { field: 'horas', header: 'Horas' },
        { field: 'cargo_nombre', header: 'Cargo', visible: this.showFichajesCargos }
    ];

    public colsEdit: ColInterface[] = [
        { field: 'nif', header: 'NIF' },
        { field: 'nombre', header: 'Nombre' },
        { field: 'horaEntrada', header: 'Entrada (mañana)', editable: true, maxLength: 5 },
        { field: 'horaSalida', header: 'Salida (mañana)', editable: true, maxLength: 5 },
        { field: 'horaEntradaTarde', header: 'Entrada (tarde)', editable: true, maxLength: 5 },
        { field: 'horaSalidaTarde', header: 'Salida (tarde)', editable: true, maxLength: 5 },
        { field: 'horas', header: 'Horas', editable: true, maxLength: 5 },
        { field: 'cargo_nombre', header: 'Cargo', visible: this.showFichajesCargos }
    ];
    showFichajes = true;

    constructor(public trabajadoresApi: TrabajadoresApiService,
        private dashboardEvent: DashboardService,
        private router: Router
    ) {
        super(dashboardEvent, 
            trabajadoresApi.fichajesGET, 
            trabajadoresApi.fichajesDELETE);
    
    }

    ngOnInit() {
    
        this.initFilters();
        if (this.showTrabajadoresCuadrillas) {
            this.getCuadrillas();
        }
        if (this.showFichajesCargos) {
            this.getCargos();
        }

        this.cols = this.colsNoEdit;
        this.dashboardEvent.setSubmenuItem('fichajes-test');

        this.trabajadoresApi.fichajesGET.response(() => {
            this.isPerforming = false;
        });
    }

    redirectTo(uri: string){
        this.router.navigateByUrl('/', {skipLocationChange: false}).then(() =>
            this.router.navigate([uri])).catch (e => {
            console.log('catch en navigationByUrl: ' + e);
        }
        );
    }

    public changeDate() {
        this.getRequest.safePerform({ fecha: moment(this.date).format('DD/MM/YYYY') } as never);
    }

    public autofillHoras(
        horas: HTMLInputElement, 
        horaEntrada: HTMLInputElement, 
        horaSalida: HTMLInputElement, 
        horaEntradaTarde: HTMLInputElement, 
        horaSalidaTarde: HTMLInputElement
    ) {
        const h1 = moment((horaEntrada.value || '00:00'), 'hh:mm').hours();
        const m1 = moment((horaEntrada.value || '00:00'), 'hh:mm').minutes();
        const h2 = moment((horaEntradaTarde.value || '00:00'), 'hh:mm').hours();
        const m2 = moment((horaEntradaTarde.value || '00:00'), 'hh:mm').minutes();

        let horasMañana = 0;
        let minutosMañana = 0;
        let horasTarde = 0;
        let minutosTarde = 0;

        const diffManana = moment((horaSalida.value || horaEntrada.value || '00:00'), 'hh:mm')
            .subtract(h1, 'hours')
            .subtract(m1, 'minutes');
        const diffTarde = moment((horaSalidaTarde.value || horaEntradaTarde.value || '00:00'), 'hh:mm')
            .subtract(h2, 'hours')
            .subtract(m2, 'minutes');

        if (horaEntrada.value && horaSalida.value) {
            horasMañana = diffManana.hours();
            minutosMañana = diffManana.minutes();
        }
        if (horaEntradaTarde.value && horaSalidaTarde.value) {
            horasTarde = diffTarde.hours();
            minutosTarde = diffTarde.minutes();
        }

        horas.value = (horasMañana + horasTarde + ((minutosMañana + minutosTarde) / 60)).toFixed(2);

        if (+horas.value > 23 || +horas.value < 0 || ['0', 0].includes(horas.value)) {
            horas.value = '';
        }
    }

    public save(horas?: number, horaEntrada?: string, horaSalida?: string, horaEntradaTarde?: string, horaSalidaTarde?: string) {
        this.isPerforming = true;
        const fichajesToSend: Fichaje[] = [];

        const toSend = {
            horaEntrada: '',
            horaSalida: '',
            horaEntradaTarde: '',
            horaSalidaTarde: '',
            horas: ''
        };

        (this.filtering.value || []).forEach(fichajeNode => {
            const fichaje = fichajeNode as Fichaje;
            if (horaEntrada) {
                toSend.horaEntrada = moment(horaEntrada, 'HH:mm')
                    .subtract(Math.floor(Math.random() * this.RANDOM_VALUE_MARGIN), 'seconds').format('HH:mm');
            }
            if (horaSalida) {
                toSend.horaSalida = moment(horaSalida, 'HH:mm')
                    .subtract(Math.floor(Math.random() * this.RANDOM_VALUE_MARGIN), 'seconds').format('HH:mm');
            }
            if (horaEntradaTarde) {
                toSend.horaEntradaTarde = moment(horaEntradaTarde, 'HH:mm')
                    .subtract(Math.floor(Math.random() * this.RANDOM_VALUE_MARGIN), 'seconds').format('HH:mm');
            }
            if (horaSalidaTarde) {
                toSend.horaSalidaTarde = moment(horaSalidaTarde, 'HH:mm')
                    .subtract(Math.floor(Math.random() * this.RANDOM_VALUE_MARGIN), 'seconds').format('HH:mm');
            }
            if (horas) {
                toSend.horas = (horas || '').toString().replace(',', '.');
            }

            fichajesToSend.push({
                id: fichaje.id || (fichaje.id_fichaje ?? ''),
                horas: toSend.horas || (fichaje.horas || '').toString().replace(',', '.'),
                horaEntrada: toSend.horaEntrada || (fichaje.horaEntrada ?? ''),
                horaSalida: toSend.horaSalida || (fichaje.horaSalida ?? ''),
                horaEntradaTarde: toSend.horaEntradaTarde || (fichaje.horaEntradaTarde ?? ''),
                horaSalidaTarde: toSend.horaSalidaTarde || (fichaje.horaSalidaTarde ?? ''),
                fecha: moment(this.date).format('DD/MM/YYYY'),
                id_trabajador: fichaje.id_trabajador ?? '',
                id_cliente: '0',
                id_usuario: this.usuarioActual.id,
            });
        });
        let contador = 0;
        (fichajesToSend || []).forEach(async (fichaje) => {
  
            await this.postRequest.toPromise(fichaje as never);
            contador++;
      
            if (contador === (fichajesToSend.length - 1)) {
       
                this.getRequest.safePerform({ fecha: moment(this.date).format('DD/MM/YYYY') } as never);

                await this.trabajadoresApi.fichajesGET.toPromise();
                this.getRequest = this.trabajadoresApi.fichajesGET;
                this.filtering.filter();
            }

        });

    }

    public edit() {
        this.editingEnabled = !this.editingEnabled;
        if (this.editingEnabled) {
            this.cols = this.colsEdit;
        } else {
            this.cols = this.colsNoEdit;
        }
    }

    public globalConditionalStyle(_value: string, col: ColInterface): ColStylesInterface {
        if (['horas', 'horaEntrada', 'horaSalida', 'horaEntradaTarde', 'horaSalidaTarde'].includes(col.field)) {
            return {
                textAlign: 'center'
            };
        }
        return {};
    }

    public globalVisualTransform(value: string, col: { field: string; }) {
        if (['horas'].includes(col.field) && (value || 0).toString().includes('.')) {
            return Utils.decimalFormat(value, 2);
        } else {
            return value;
        }
    }

    public exportarCSV() {
        this.isDownloadingCsv = true;

        let csv = 'data:text/csv;charset=utf-8,';
        (this.filtering.value || []).forEach((row, i) => {
            if (i === 0) {
                csv += '"' + Object.keys(row).join('";"') + '"\n';
            }
            csv += '"' + Object.values(row).join('";"') + '"\n';
        });

        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;
        csvLink.download = 'fichajes.csv';
        csvLink.href = encodeURI(csv);
        csvLink.click();

        this.isDownloadingCsv = false;
    }

    public formChanges(tag: string) {
        if (tag === 'es-almacen') {
            if (this.almacenFilter !== 'es-almacen') {
                this.almacenFilter = 'es-almacen';
            } else {
                this.almacenFilter = '';
            }
        }

        if (tag === 'no-es-almacen') {
            if (this.almacenFilter !== 'no-es-almacen') {
                this.almacenFilter = 'no-es-almacen';
            } else {
                this.almacenFilter = '';
            }
        }

        this.filtering.filter();

        if (this.filtering.value) {
            this.sortAlphabetically(this.filtering.value, 'nombre');
        }
    }

    public editField(obj: { event: FocusEvent; data: Fichaje; from: string }) {
    // Arreglo decimal de las horas
        if (obj.from === 'horas' && (obj.data.horas || 0).toString().includes(',')) {
            obj.data.horas = parseFloat(obj.data.horas.replace(',', '.')).toString();
            return;
        }

        const timeFormat = new RegExp('^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$');
        if (!timeFormat.test(obj.data[obj.from]?.toString() ?? '')) {
            obj.data[obj.from] = '';
        }

        let horas: string | number = 0;

        // Sólo calculamos la duración de un periodo si existe una hora de inicio y otra de fin de ese mismo periodo.
        if (timeFormat.test(obj.data.horaEntrada) && timeFormat.test(obj.data.horaSalida)) {
            horas += moment(obj.data.horaSalida, 'hh:mm').hours() + (moment(obj.data.horaSalida, 'hh:mm').minutes() / 60);
            horas -= moment(obj.data.horaEntrada, 'hh:mm').hours() + (moment(obj.data.horaEntrada, 'hh:mm').minutes() / 60);
        }

        if (timeFormat.test(obj.data.horaEntradaTarde) && timeFormat.test(obj.data.horaSalidaTarde)) {
            horas += moment(obj.data.horaSalidaTarde, 'hh:mm').hours() + (moment(obj.data.horaSalidaTarde, 'hh:mm').minutes() / 60);
            horas -= moment(obj.data.horaEntradaTarde, 'hh:mm').hours() + (moment(obj.data.horaEntradaTarde, 'hh:mm').minutes() / 60);
        }

        if (horas !== 0) {
            if (horas < 0 || horas > 24) {
                horas = 0;
            }

            if (horas.toString().includes('.')) {
                horas = (horas as number).toFixed(2);
            }

            obj.data.horas = horas.toString();
        }

    }

    private initFilters() {
        this.filtering.addFilter((copy: TreeNode<Fichaje>[] | void) => (copy as TreeNode<Fichaje>[]).filter(row => [1, '1', true].includes((row as Fichaje).activo ?? '')));

        if (this.showTrabajadoresCuadrillas) {
            this.filtering.addFilter((copy: TreeNode<Fichaje>[] | void) => (copy as TreeNode<Fichaje>[]).filter(row => {
                if (this.cuadrilla && this.cuadrilla.id) {
                    return ((row as Fichaje).id === this.cuadrilla.id) || ((row as Fichaje).cuadrilla === this.cuadrilla.id);
                }
                return row;
            }));
        }

        if (this.showFichajesCargos) {
            this.filtering.addFilter((copy: TreeNode<Fichaje>[] | void) => (copy as TreeNode<Fichaje>[]).filter(row => {
                if (this.cargo && this.cargo.id) {
                    return (row as Fichaje).id_cargo === this.cargo.id;
                }
                return row;
            }));
        }

        if (this.appName === 'naturfrut') {
            this.filtering.addFilter((copy: TreeNode<Fichaje>[] | void) => (copy as TreeNode<Fichaje>[]).filter(row => {
                switch (this.almacenFilter) {
                case 'es-almacen':
                    return [true, '1', 1].includes((row as Fichaje).es_almacen ?? '');
                case 'no-es-almacen':
                    return [null, undefined, '0', 0].includes((row as Fichaje).es_almacen?.toString() ?? '');
                default:
                    return true;
                }
            }));
        }
    }

    private sortAlphabetically(collection: TreeNode<Fichaje>[], field: string) {
        collection.sort(function (a, b) {
            let nameA, nameB;
            if (a && b && a && b) {
                nameA = (a.data?.[field] || '').toUpperCase(); // ignore upper and lowercase
                nameB = (b.data?.[field] || '').toUpperCase(); // ignore upper and lowercase
            } else {
                return 0;
            }

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
    }

    private getCuadrillas() {
        this.trabajadoresApi.cuadrillas.GET.toPromise().then(cuadrillas => {
            this.cuadrillas = cuadrillas.map((it: MantenimientoModel) => ({
                label: (it.nombre || '') + ' ' + (it.apellidos || ''),
                value: it
            }));
            this.cuadrillas.unshift({ label: '...', value: null });
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }

    private getCargos() {
        this.trabajadoresApi.cargosGET.toPromise().then(cargos => {
            this.cargos = cargos.map((it: CargoModel) => ({
                label: it.nombre,
                value: it
            }));
            this.cargos.unshift({ label: '...', value: null });
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }
}
